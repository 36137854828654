import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  FaCreditCard,
  FaFileSignature,
  FaGlobe,
  FaMoneyBillWave,
  FaMoneyCheckAlt,
  FaRegCreditCard,
} from "react-icons/fa";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { FaPix } from "../../../components/atoms/Icon/PixIcon";
import { Input } from "../../../components/Form/Input";
import { PaymentTypeCard } from "../../../components/templates/PaymentTypeCard";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { api } from "../../../services/api";
import { InternalHeader } from "../../../components/templates/InternalHeader";

interface ModalProps {
  title: string;
  isOpen: boolean;
}

export function EditPaymentLiquidity() {
  const toast = useToast();
  const { user } = useAuth();
  const [open, setOpen] = useState<ModalProps>({
    isOpen: false,
    title: "",
  });
  const { register, reset, getValues, handleSubmit, formState } = useForm();

  useEffect(() => {
    api
      .get(`/liquidityOfPayments/company/${user.company_id}`)
      .then((response) => {
        reset(response.data);
      });
  }, [reset, user.company_id]);

  const handleCreateAccountPlan: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.patch(`/liquidityOfPayments/company/${user.company_id}`, {
          ...formValue,
        });
        toast({
          title: "Prazo da forma de pagamento atualizada com sucesso!",
          status: "success",
        });
        setOpen({
          title: "",
          isOpen: false,
        });
      } catch (err) {
        toast({
          title: "Não foi possível atualizar o prazo da forma de pagamento",
          status: "error",
        });
      }
    },
    [user.company_id, toast]
  );
  return (
    <>
      <InternalHeader title="Forma de Pagamento" has_filter={false} />
      <Modal
        isCentered
        isOpen={open.isOpen}
        onClose={() =>
          setOpen({
            isOpen: false,
            title: "",
          })
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar - {open.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleCreateAccountPlan)}
              id="add-form"
            >
              {open.title === "Dinheiro" && (
                <Input
                  {...register("money")}
                  label="Dinheiro"
                  rightAddon="dias"
                />
              )}
              {open.title === "Crédito" && (
                <Input
                  {...register("credit")}
                  label="Crédito"
                  rightAddon="dias"
                />
              )}
              {open.title === "Débito" && (
                <Input
                  {...register("debit")}
                  label="Débito"
                  rightAddon="dias"
                />
              )}
              {open.title === "Vale" && (
                <Input
                  {...register("voucher")}
                  label="Vale"
                  rightAddon="dias"
                />
              )}
              {open.title === "Pagamento Online" && (
                <Input
                  {...register("online_payment")}
                  label="Pagamento Online"
                  rightAddon="dias"
                />
              )}
              {open.title === "Pix" && (
                <Input {...register("pix")} label="Pix" rightAddon="dias" />
              )}
              {open.title === "Assinado" && (
                <Input
                  {...register("subscribed")}
                  label="Assinado"
                  rightAddon="dias"
                />
              )}
              {open.title === "Boleto" && (
                <Input
                  {...register("bank_slip")}
                  label="Boleto"
                  rightAddon="dias"
                />
              )}
              {open.title === "Cortesia" && (
                <Input
                  {...register("courtesy")}
                  label="Cortesia"
                  rightAddon="dias"
                />
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() =>
                setOpen({
                  isOpen: false,
                  title: "",
                })
              }
            >
              Fechar
            </Button>
            <SubmitButton formState={formState} text="Salvar" />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex flexWrap="wrap" justify="center" align="center">
        <PaymentTypeCard
          title="Dinheiro"
          value={`${getValues("money")} dias`}
          icon={FaMoneyBillWave}
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          value={`${getValues("debit")} dias`}
          title="Débito"
          icon={FaCreditCard}
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          title="Crédito"
          value={`${getValues("credit")} dias`}
          icon={FaRegCreditCard}
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          title="Vale"
          value={`${getValues("voucher")} dias`}
          icon={FaMoneyCheckAlt}
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          title="Pagamento Online"
          value={`${getValues("online_payment")} dias`}
          icon={FaGlobe}
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          title="Pix"
          value={`${getValues("pix")} dias`}
          icon={FaPix}
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          title="Assinado"
          value={`${getValues("subscribed")} dias`}
          icon={FaFileSignature}
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          title="Cortesia"
          value={`${getValues("courtesy")} dias`}
          icon={FaFileSignature}
        />
        <PaymentTypeCard
          setOpen={(title, value) =>
            setOpen({
              isOpen: value,
              title,
            })
          }
          title="Boleto"
          value={`${getValues("bank_slip")} dias`}
          icon={FaFileSignature}
        />
      </Flex>
    </>
  );
}
