import { Heading } from "@chakra-ui/react";

interface InternalHeadingProps {
  title: string;
}

export function InternalHeading({ title }: InternalHeadingProps) {
  return (
    <Heading as="h1" fontWeight="bold" fontSize="1.8rem" color="blue.350">
      {title}
    </Heading>
  );
}
