import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Flex, SimpleGrid, Skeleton, Stack } from "@chakra-ui/react";
import { api } from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import { AxiosError } from "axios";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";

type UserCompanyFormData = {
  company_id: {
    value: string;
    label: string;
  };
};

const updateUserCompanyFormSchema = yup.object().shape({
  company_id: yup.object().required("Empresa obrigatória"),
});

interface Company {
  id: string;
  name: string;
}

export function EditUserCompany() {
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, formState, reset, setValue, register, getValues } =
    useForm({
      mode: "onBlur",
      defaultValues: {
        company_id: { value: user.company_id, label: user.company.name },
      },
      resolver: yupResolver(updateUserCompanyFormSchema),
    });

  useEffect(() => {
    api
      .get("/companies")
      .then((response) => setCompanies(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
    setValue("company_id", {
      value: user.company_id,
      label: user.company.name,
    });
    reset({ company_id: { value: user.company_id, label: user.company.name } });
    setLoading(true);
  }, [navigate, reset, setValue, user]);

  const handleUpdateUserCompany: SubmitHandler<UserCompanyFormData> =
    useCallback(
      async (formValue) => {
        try {
          const response = await api.patch(`/users/company/${user.id}`, {
            company_id: formValue.company_id.value,
          });

          updateUser(response.data);

          navigate(-1);
        } catch (err) {}
      },
      [navigate, updateUser, user.id]
    );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Empresa do Usuário"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleUpdateUserCompany)}
              id="add-form"
            >
              <Stack spacing="4">
                <SimpleGrid
                  columns={{ base: 1, md: 1 }}
                  spacing={["6", "8"]}
                  w="100%"
                >
                  <MultiSelectControl
                    options={companies.map((acc) => ({
                      label: acc.name,
                      value: acc.id,
                    }))}
                    {...register("company_id")}
                    onChange={(selected) => {
                      setValue("company_id", selected, {
                        shouldValidate: true,
                      });
                    }}
                    value={getValues("company_id")}
                    isMulti={false}
                    label="Empresa"
                    error={errors.company_id}
                    isRequired={true}
                    validaded={false}
                  />
                </SimpleGrid>
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
