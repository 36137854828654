import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SolidButton } from "../../../atoms/Button/SolidButton";

interface ModalConfirmActionProps {
  callbackOnCancel: () => void;
  callbackOnConfirm: () => void;
  textButton: string;
  textBody?: string;
  validate: () => Promise<boolean>;
}

export function ModalConfirmAction({
  callbackOnCancel,
  callbackOnConfirm,
  textButton,
  textBody,
  validate,
}: ModalConfirmActionProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <SolidButton
        text={textButton}
        onClick={async () => {
          if (await validate()) {
            onOpen();
          }
        }}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          callbackOnCancel();
          onClose();
        }}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Realmente deseja confirmar a operação?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{textBody}</Text>
          </ModalBody>
          <ModalFooter>
            <SolidButton
              text="Cancelar"
              onClick={() => {
                callbackOnCancel();
                onClose();
              }}
              bg="#fd9000"
            />
            <SolidButton
              text="Confirmar"
              onClick={() => {
                callbackOnConfirm();
              }}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
