import { Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { Input } from "../../../Form/Input";
import { SelectControl } from "../../Form/SelectControl";
import { FormControl } from "../../../Form/FormControl";
import { CreateSelectControl } from "../../Form/CreateSelectControl";
import { MultiSelectControl } from "../../Form/MultiSelectControl";
import { Dispatch, SetStateAction } from "react";
import { useAuth } from "../../../../hooks/auth";
import { checkPermission } from "../../../../utils/checkPermission";

interface Favored {
  id: string;
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}

interface ExpensesFormProps {
  accountPlanSubcategories: AccountPlanSubcategory[];
  handle: SubmitHandler<FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  getValues: UseFormGetValues<FieldValues>;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  favored: Favored[];
  setFavored: Dispatch<SetStateAction<Favored[]>>;
}

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

export function ExpensesForm({
  accountPlanSubcategories,
  handle,
  register,
  handleSubmit,
  control,
  errors,
  getValues,
  setValue,
  favored,
  setFavored,
}: ExpensesFormProps) {
  const { user } = useAuth();

  return (
    <Flex
      as="form"
      flexDir="column"
      w="100%"
      onSubmit={handleSubmit(handle)}
      id="add-form"
    >
      <Stack spacing="4">
        <SimpleGrid spacingX={4}>
          <CreateSelectControl
            onCreateOption={(value: string) => {
              setValue("favored_name", value, {
                shouldValidate: true,
              });
              setValue("favored_id", value, {
                shouldValidate: true,
              });
              setFavored((prev) => [
                ...prev,
                {
                  id: value.toLowerCase(),
                  name: value,
                  account_plan_subcategory_id: "",
                  company_id: user.company_id,
                  account_plan_subcategory: {
                    name: "",
                  },
                },
              ]);
            }}
            onChange={(select: any) => {
              const selected = favored.find((f) => f.id === select.value);
              if (selected) {
                setValue("favored_id", selected.id, {
                  shouldValidate: true,
                });
                setValue("favored_name", selected.name);
                if (selected.account_plan_subcategory.name) {
                  setValue("account_plan_subcategory_id", {
                    label: selected.account_plan_subcategory.name,
                    value: selected.account_plan_subcategory_id,
                  });
                }
              }
            }}
            options={favored.map((f) => ({
              label: `${f.name} ${
                f.account_plan_subcategory.name
                  ? `- ${f.account_plan_subcategory.name}`
                  : ""
              }`,
              value: f.id,
            }))}
            name="favored"
            label="Favorecido"
            isRequired
            value={
              getValues("favored_id")
                ? {
                    label: getValues("favored_name"),
                    value: getValues("favored_id"),
                  }
                : ""
            }
            error={errors.favored_id}
            validate={getValues("favored_name") && !errors.favored}
          />

          <MultiSelectControl
            options={accountPlanSubcategories.map((acc) => ({
              label: acc.name,
              value: acc.id,
            }))}
            {...register("account_plan_subcategory_id")}
            onChange={(selected) => {
              setValue("account_plan_subcategory_id", selected, {
                shouldValidate: true,
              });
            }}
            value={getValues("account_plan_subcategory_id")}
            validaded={
              getValues("account_plan_subcategory_id") &&
              getValues("account_plan_subcategory_id").value &&
              !errors.account_plan_subcategory_id
            }
            isMulti={false}
            label="Despesa"
            error={errors.account_plan_subcategory_id}
            isRequired={true}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
          <Flex
            flexDirection={"column"}
            borderRadius={8}
            boxShadow="md"
            py="4"
            px="4"
          >
            <SelectControl
              control={control}
              error={errors.form_of_payment}
              {...register("form_of_payment")}
              validaded={
                getValues("form_of_payment") &&
                getValues("form_of_payment") !== ""
              }
              isRequired={true}
              label="Forma de Pagamento"
              options={[
                {
                  id: "boleto",
                  name: "Boleto",
                },
                {
                  id: "caixa",
                  name: "Fundo de Caixa",
                },
                {
                  id: "credito",
                  name: "Cartão de crédito",
                },

                {
                  id: "debito",
                  name: "Cartão de débito",
                },
                {
                  id: "dinheiro",
                  name: "Dinheiro",
                },
                {
                  id: "pix",
                  name: "Pix",
                },
                {
                  id: "transferencia",
                  name: "Transferência",
                },
              ]}
            />
            <Input
              label="Valor"
              isRequired={true}
              error={errors.value}
              validaded={getValues("value") && getValues("value") !== ""}
              {...register("value")}
              leftAddon="R$"
              onChange={(e) => {
                var value = e.target.value;
                if (value.length > 2) {
                  value = value
                    .replace(/^(-)|[^\d.-]+/g, "$1")
                    .replace(/([0-9]{2})$/g, ",$1");
                  e.target.value = value;
                  setValue("value", value, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                } else {
                  setValue("value", e.target.value);
                }
              }}
            />
            <FormControl
              label="Conta"
              isRequired={true}
              name="file"
              error={errors.file}
            >
              <input
                type="file"
                {...register("file")}
                style={{ width: "100%" }}
                accept="image/png,image/jpeg,image/gif,.pdf"
                required
              />
            </FormControl>
          </Flex>
          <SimpleGrid
            bg="#f0f0f0"
            borderRadius={8}
            boxShadow="md"
            py="4"
            px="8"
          >
            <Input
              type="date"
              label="Data de Emissão"
              isRequired={true}
              validaded={
                getValues("date_of_competence") &&
                getValues("dete_of_competence") !== ""
              }
              error={errors.date_of_competence}
              {...register("date_of_competence")}
            />
            <Input
              type="date"
              label="Data do Vencimento"
              isRequired={true}
              error={errors.due_date}
              validaded={getValues("due_date") && getValues("due_date") !== ""}
              {...register("due_date")}
            />
            {checkPermission({
              is_admin: user.is_admin,
              type: "Manager",
            }) ? (
              <Input
                type="date"
                label="Data de Baixa Efetiva"
                isRequired={false}
                validaded={
                  getValues("effective_write_off_date") &&
                  getValues("effective_write_off_date") !== ""
                }
                error={errors.effective_write_off_date}
                {...register("effective_write_off_date")}
              />
            ) : (
              <></>
            )}
          </SimpleGrid>
        </SimpleGrid>
        <Input
          type="text"
          label="Descrição"
          isRequired={false}
          validaded={
            getValues("observation") && getValues("observation") !== ""
          }
          error={errors.observation}
          {...register("observation")}
        />
      </Stack>
    </Flex>
  );
}
