import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { BackButton } from "../../../atoms/Button/BackButton";
import { InternalHeading } from "../../../atoms/Heading/InternalHeading";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  title: string;
  children?: ReactNode;
  has_back_button?: boolean;
}
export function HeaderWithoutFilter({
  title,
  children,
  has_back_button,
}: HeaderProps) {
  const navigate = useNavigate();
  return (
    <Flex justify="space-between" wrap="wrap" mb="5" alignItems="center">
      <Flex alignItems="center">
        <InternalHeading title={title} />
      </Flex>
      <Flex
        flex="1"
        justify="flex-end"
        minW="300px"
        alignItems="center"
        wrap="wrap"
      >
        {has_back_button && (
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          />
        )}
        {children}
      </Flex>
    </Flex>
  );
}
