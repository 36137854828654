import { useState } from "react";
import {
  Icon,
  Tab,
  TabList,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaMoneyBill, FaPercentage } from "react-icons/fa";
import { PreLoader } from "../../PreLoader";
import { DRETbodyHeader } from "../../../Table/Tbody/DRETbodyHeader";
import { DRETbodyCollapse } from "../../../Table/Tbody/DRETbodyCollapse";

interface Month {
  first_date?: string;
  second_date?: string;
  third_date?: string;
  fourth_date?: string;
  fifth_date?: string;
  sixth_date?: string;
  seventh_date?: string;
  eighth_date?: string;
  ninth_date?: string;
  eleventh_date: string;
  tenth_date: string;
  twelfth_date: string;
}

interface DataProps {
  id: string;
  name: string;
  first?: number;
  second?: number;
  third?: number;
  fourth?: number;
  fifth?: number;
  sixth?: number;
  seventh?: number;
  eighth?: number;
  ninth?: number;
  tenth: number;
  eleventh: number;
  twelfth: number;
}

interface Data {
  expenses_operational: DataProps[];
  expenses_non_operational: DataProps[];
  expenses_about_recipe: DataProps[];
  revenues_operational: DataProps[];
  revenues_non_operational: DataProps[];
}

interface DRETableProps {
  classification: Classification;
  data: Data;
  period: string;
  months: Month;
  isLoaded: boolean;
  columns: {
    title: string;
  }[];
  type: "cashier" | "competence"
}

interface ClassificationProps {
  first: number;
  second: number;
  third: number;
  fourth: number;
  fifth: number;
  sixth: number;
  seventh: number;
  eighth: number;
  ninth: number;
  tenth: number;
  eleventh: number;
  twelfth: number;
}

interface Classification {
  expenses_operational: ClassificationProps;
  expenses_non_operational: ClassificationProps;
  revenues_operational: ClassificationProps;
  revenues_non_operational: ClassificationProps;
  expenses_about_recipe: ClassificationProps;
  operating_profit: ClassificationProps;
  gross_profit: ClassificationProps;
  final_result: ClassificationProps;
}

export function DRETable({
  classification,
  data,
  months,
  period,
  columns,
  isLoaded,
  type
}: DRETableProps) {
  const [format, setFormat] = useState<"money" | "percentage">("money");
  const [disabled, setDisabled] = useState({
    expenses_operational: false,
    expenses_non_operational: false,
    revenues_operational: false,
    revenues_non_operational: false,
    expenses_about_recipe: false,
  });
  return (
    <>
      <Tabs align="end" variant="enclosed">
        <TabList>
          <Tab onClick={() => setFormat("money")}>
            <Icon as={FaMoneyBill} />
          </Tab>
          <Tab onClick={() => setFormat("percentage")}>
            <Icon as={FaPercentage} />
          </Tab>
        </TabList>
      </Tabs>
      <TableContainer w="100%">
        <PreLoader isLoaded={isLoaded} message="Buscando DRE...">
          <Table variant="simple" size="sm">
            <Thead bg="#c4c4c4">
              <Tr>
                {columns.map((column) => (
                  <Th>{column.title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              <DRETbodyHeader
                months={months}
                classification={classification.revenues_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                title="Total de Receitas"
                disabled={disabled.revenues_operational}
                setDisabled={() =>
                  setDisabled({
                    ...disabled,
                    revenues_operational: !disabled.revenues_operational,
                  })
                }
              />
              <DRETbodyCollapse
                type={type}
                entity="revenue"
                months={months}
                data={data.revenues_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                disabled={disabled.revenues_operational}
              />
              <DRETbodyHeader
                months={months}
                classification={classification.expenses_about_recipe}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                title="Despesas Sobre Receita"
                disabled={disabled.expenses_about_recipe}
                setDisabled={() =>
                  setDisabled({
                    ...disabled,
                    expenses_about_recipe: !disabled.expenses_about_recipe,
                  })
                }
              />
              <DRETbodyCollapse
                type={type}
                entity="expense"
                months={months}
                data={data.expenses_about_recipe}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                disabled={disabled.expenses_about_recipe}
              />
              <DRETbodyHeader
                months={months}
                classification={classification.gross_profit}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                color="blue"
                title="Lucro Bruto"
                setDisabled={() => {}}
              />
              <DRETbodyHeader
                months={months}
                classification={classification.expenses_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                title="Despesas Operacionais"
                disabled={disabled.expenses_operational}
                setDisabled={() =>
                  setDisabled({
                    ...disabled,
                    expenses_operational: !disabled.expenses_operational,
                  })
                }
              />
              <DRETbodyCollapse
                type={type}
                entity="expense"
                months={months}
                data={data.expenses_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                disabled={disabled.expenses_operational}
              />
              <DRETbodyHeader
                months={months}
                classification={classification.operating_profit}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                color="blue"
                title="Lucro Operacional"
                setDisabled={() => {}}
              />
              <DRETbodyHeader
                months={months}
                classification={classification.expenses_non_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                title="Despesas Não Operacionais"
                disabled={disabled.expenses_non_operational}
                setDisabled={() =>
                  setDisabled({
                    ...disabled,
                    expenses_non_operational:
                      !disabled.expenses_non_operational,
                  })
                }
              />
              <DRETbodyCollapse
                type={type}
                entity="expense"
                months={months}
                data={data.expenses_non_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                disabled={disabled.expenses_non_operational}
              />
              <DRETbodyHeader
                months={months}
                classification={classification.revenues_non_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                title="Outras Receitas"
                disabled={disabled.revenues_non_operational}
                setDisabled={() =>
                  setDisabled({
                    ...disabled,
                    revenues_non_operational:
                      !disabled.revenues_non_operational,
                  })
                }
              />
              <DRETbodyCollapse
                type={type}
                entity="revenue"
                months={months}
                data={data.revenues_non_operational}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                disabled={disabled.revenues_non_operational}
              />
              <DRETbodyHeader
                months={months}
                classification={classification.final_result}
                revenues_operational={classification.revenues_operational}
                format={format}
                period={period}
                color="blue"
                title="Resultado Final"
                setDisabled={() => {}}
              />
            </Tbody>
          </Table>
        </PreLoader>
      </TableContainer>
    </>
  );
}
