import { FaPowerOff } from "react-icons/fa";
import { TableListButton } from "../../../atoms/Button/TableListButton";

interface TurnOffButtonProps {
  link: string;
  onClick: () => void;
}
export function TurnOffButton({ link, onClick }: TurnOffButtonProps) {
  return (
    <TableListButton
      color="white"
      link={link}
      bg="#E53E3E"
      icon={FaPowerOff}
      onClick={onClick}
    />
  );
}
