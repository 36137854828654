import { Box } from "@chakra-ui/react";

import { useAuth } from "../../hooks/auth";

export function Footer() {
  const { user } = useAuth();
  return (
    <Box
      as="footer"
      role="contentinfo"
      ml={{ base: "0", lg: user.is_admin === 7 ? "0" : "64" }}
      mt="5"
    ></Box>
  );
}
