import { DatePicker, TimeRangePickerProps } from "antd";
import dayjs from "dayjs";
import { FilterWithFormControl } from "../../../molecules/FilterWithFormControl";
import { StyleWrapperDateRangePicker } from "./styles";
import locale from "antd/es/date-picker/locale/pt_BR";
import {
  formatDateDatabaseToPtBR,
  formatDatePtBRToDatabase,
} from "../../../../utils/formatDate";
import { useMediaQuery } from "@chakra-ui/react";
interface DateRangeProps {
  onSelectDate: (start: any, end: any) => void;
  startDatePiker?: any;
  dateStart?: string;
  dateEnd?: string;
  showClearDates?: boolean;
  label?: string;
}

export function DateRange({
  onSelectDate,
  dateStart,
  dateEnd,
  label,
  showClearDates = true,
}: DateRangeProps) {
  const { RangePicker } = DatePicker;
  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Hoje", value: [dayjs(), dayjs()] },
    { label: "Ontem", value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")] },
    {
      label: "Últimos 7 dias",
      value: [dayjs().add(-7, "d"), dayjs().add(-1, "d")],
    },
    {
      label: "Últimos 30 dias",
      value: [dayjs().add(-30, "d"), dayjs().add(-1, "d")],
    },
    {
      label: "Esse mês",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Mês passado",
      value: [
        dayjs().add(-1, "M").startOf("month"),
        dayjs().add(-1, "M").endOf("month"),
      ],
    },
    {
      label: "Esse ano",
      value: [dayjs().startOf("year"), dayjs().add(-1, "d")],
    },
    {
      label: "Ano passado",
      value: [
        dayjs().add(-1, "y").startOf("year"),
        dayjs().add(-1, "y").endOf("year"),
      ],
    },
    {
      label: "Últimos 90 dias",
      value: [dayjs().add(-90, "d"), dayjs().add(-1, "d")],
    },
    {
      label: "Últimos 180 dias",
      value: [dayjs().add(-180, "d"), dayjs().add(-1, "d")],
    },
    {
      label: "Últimos 365 dias",
      value: [dayjs().add(-365, "d"), dayjs().add(-1, "d")],
    },
  ];

  const [isMobile] = useMediaQuery("(max-width: 500px)");

  return (
    <FilterWithFormControl label={label ?? ""} width="300px">
      <RangePicker
        separator={true}
        locale={locale}
        presets={isMobile ? [] : [...rangePresets]}
        format="DD/MM/YYYY"
        defaultValue={
          dateEnd && dateStart
            ? [
                dayjs(formatDateDatabaseToPtBR(dateStart), "DD/MM/YYYY"),
                dayjs(formatDateDatabaseToPtBR(dateEnd), "DD/MM/YYYY"),
              ]
            : undefined
        }
        onChange={(dates, dateStrings) => {
          if (dates) {
            onSelectDate(
              formatDatePtBRToDatabase(dateStrings[0]),
              formatDatePtBRToDatabase(dateStrings[1])
            );
          } else {
            onSelectDate("", "");
          }
        }}
        allowClear={showClearDates}
        style={{
          height: "40px",
          width: "300px",
          borderRadius: "5px",
        }}
        panelRender={(panelNode) => (
          <StyleWrapperDateRangePicker>{panelNode}</StyleWrapperDateRangePicker>
        )}
      />
    </FilterWithFormControl>
  );
}
