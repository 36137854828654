import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SidebarNavBoxProps {
  children: ReactNode;
}
export function SidebarNavBox({ children }: SidebarNavBoxProps) {
  return (
    <Box
      borderTop="1px"
      borderColor="gray.250"
      overflowY={{ lg: "auto" }}
      h={{ lg: "calc(((100vh - 1.5rem) - 64px))" }}
      mb={{ base: "30px" }}
    >
      {children}
    </Box>
  );
}
