import { Badge } from "@chakra-ui/react";

export function NewBagdeNavLink() {
  return (
    <Badge
      bg="#216ca5"
      ml="auto"
      mr="1"
      fontSize="10px"
      paddingY="0.5"
      height="min-content"
      color="#ffffff"
    >
      Novo
    </Badge>
  );
}
