import { useEffect, useState } from "react";

import { Card } from "../../../../components/Card";
import { api } from "../../../../services/api";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../../components/organisms/ButtonListBox";
import { useQuery } from "../../../../hooks/query";
import { useNavigate } from "react-router-dom";
import { Table } from "../../../../components/templates/Table";
import { DeleteModal } from "../../../../components/templates/DeleteModal";
import { InternalHeader } from "../../../../components/templates/InternalHeader";
import { CategoryModal } from "../../../../components/templates/Modal/CategoryModal";
import { EditModalButton } from "../../../../components/molecules/TableListButton/EditModalButton";
import { AddButtonWithTextModal } from "../../../../components/molecules/AddButtonWithTextModal";

interface SalesProductCategory {
  id: string;
  name: string;
}
interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListSalesProductCategories() {
  const query = useQuery();
  const navigate = useNavigate();
  const [salesProductCategories, setSalesProductCategories] = useState<
    SalesProductCategory[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState<"create" | "edit">("create");
  const [defaultValue, setDefaultValue] = useState<SalesProductCategory>(
    {} as SalesProductCategory
  );
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  useEffect(() => {
    api
      .get(
        `pricing/salesProductCategories/pagination?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setSalesProductCategories(response.data.salesProductCategories);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate, defaultParams]);

  return (
    <>
      <InternalHeader
        title="Categorias do Produto de Venda"
        has_back_button={true}
        has_filter={false}
      >
        <AddButtonWithTextModal
          onClick={() => {
            setIsOpen(true);
            setActionType("create");
            setDefaultValue({
              id: "",
              name: "",
            });
          }}
        />
      </InternalHeader>
      <CategoryModal
        entity="Categoria do Produto de venda"
        isOpen={isOpen}
        setIsOpen={(value) => setIsOpen(value)}
        actionType={actionType}
        defaultValue={defaultValue}
        defaultLink="pricing/salesProductCategories"
        setCategory={(salesProductCategory) => {
          if (actionType === "create") {
            setSalesProductCategories([
              ...salesProductCategories,
              salesProductCategory,
            ]);
          } else {
            setSalesProductCategories(
              salesProductCategories.map((c) => {
                if (c.id === salesProductCategory.id) {
                  return salesProductCategory;
                }
                return c;
              })
            );
          }
        }}
      />
      <Card
        body={
          <Table
            loading={loading}
            message="Buscando as categorias"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/precificacao/categoria-produto-venda?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={salesProductCategories.map((salesProductCategory) => {
              return {
                tr: [
                  {
                    name: salesProductCategory.name,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditModalButton
                          onClick={() => {
                            setActionType("edit");
                            setIsOpen(true);
                            setDefaultValue({
                              id: salesProductCategory.id,
                              name: salesProductCategory.name,
                            });
                          }}
                        />

                        <DeleteModal
                          onDelete={(id) => {
                            setSalesProductCategories(
                              salesProductCategories.filter(
                                (salesProductCategory) =>
                                  salesProductCategory.id !== id
                              )
                            );
                          }}
                          id={salesProductCategory.id}
                          route="pricing/salesProductCategories/"
                          error_message="Não foi possível remover a categoria do produto de venda"
                          success_message="Categoria do produto de venda removida com sucesso"
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
