import React from "react";
import { FormState, FieldValues } from "react-hook-form";
import { Button } from "@chakra-ui/react";
import { shade } from "polished";

interface SubmitButtonAndUpdateStatusProps {
  text?: string;
  formState?: FormState<FieldValues>;
  isLoading?: boolean;
  loadingText?: string;
  form?: string;
  mt?: string;
  bg?: string;
  color?: string;
  onClick: () => void; // Adicionar a nova propriedade onClick
}

export function SubmitButtonAndUpdateStatus({
  formState,
  text = "Cadastrar",
  isLoading,
  loadingText = "Enviando...",
  form = "add-form",
  mt = "6",
  color = "white",
  bg,
  onClick, // Receber a nova propriedade onClick
}: SubmitButtonAndUpdateStatusProps) {
  const handleClick = () => {
    // Atualizar o status aqui antes de chamar a função onClick

    if (onClick) {
      onClick(); // Chamar a função onClick fornecida
    }
  };

  return (
    <Button
      type="submit"
      form={form}
      color={color}
      bg={bg === undefined ? "#216ca5" : bg}
      _hover={{
        backgroundColor: shade(0.2, bg === undefined ? "#216ca5" : bg),
      }}
      _active={{
        backgroundColor: shade(0.2, bg === undefined ? "#216ca5" : bg),
      }}
      isLoading={isLoading}
      onClick={handleClick} // Use a nova função de clique handleClick
    >
      {text}
    </Button>
  );
}
