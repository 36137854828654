import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "../../../hooks/auth";

import { FaEnvelope, FaLock } from "react-icons/fa";

import { Input } from "../../../components/Form/Input";
import { Auth } from "../../../templates/Auth";
import { AuthTitle } from "../../../components/atoms/Text/AuthTitle";
import { AuthSubmitButton } from "../../../components/atoms/Button/AuthSubmitButton";
import { AuthCardForm } from "../../../components/atoms/Card/AuthCardForm";
import { AuthStack } from "../../../components/atoms/Stack/AuthStack";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../../hooks/toast";

const loginFormSchema = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup.string().required("Senha obrigatória"),
});

export function Login() {
  const { signIn } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(loginFormSchema),
  });

  const { state } = useLocation();

  const handleLogin: SubmitHandler<FieldValues> = (values) => {
    signIn({
      email: values.email,
      password: values.password,
    })
      .then((user: any) => {
        if (state) {
          if (state.from.pathname.startsWith("/error")) {
            navigate("/");
          } else {
            navigate(`${state.from.pathname}${state.from.search}`);
          }
        } else {
          navigate("/");
        }
        toast({
          title: "Login realizado com sucesso",
          status: "success",
        });
      })
      .catch((err) => {
        toast({
          title: "Não foi possível realizar o login",
          status: "error",
        });
      });
  };

  const { errors } = formState;

  return (
    <Auth>
      <AuthCardForm onSubmit={handleSubmit(handleLogin)}>
        <AuthStack>
          <AuthTitle text="Faça o login para iniciar sua sessão" />
          <Input
            type="email"
            placeholder="E-mail"
            error={errors.email}
            icon={FaEnvelope}
            {...register("email")}
          />
          <Input
            type="password"
            placeholder="Senha"
            icon={FaLock}
            error={errors.password}
            {...register("password")}
          />
        </AuthStack>
        <AuthSubmitButton text="Login" isLoading={formState.isSubmitting} />
      </AuthCardForm>
    </Auth>
  );
}
