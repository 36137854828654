import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { useNavigate } from "react-router-dom";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { useAuth } from "../../../hooks/auth";
import { formatMoney } from "../../../utils/money";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { useQuery } from "../../../hooks/query";
import { Table } from "../../../components/templates/Table";
import { Flex } from "@chakra-ui/react";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";
import { BackButton } from "../../../components/atoms/Button/BackButton";

interface FixedExpense {
  id: string;
  value: number;
  form_of_payment: string;
  next_date_of_competence: string;
  first_date_of_competence: string;
  frequency: string;
  account_plan_subcategory: {
    name: string;
  };
  favored?: {
    name: string;
  };
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}
export function ListFixedExpenses() {
  const query = useQuery();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [fixedExpenses, setFixedExpenses] = useState<FixedExpense[]>([]);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  useEffect(() => {
    api
      .get(
        `fixedExpenses/pagination/company/${user.company_id}?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setFixedExpenses(response.data.fixedExpenses);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [defaultParams, navigate, user.company_id]);

  return (
    <>
      <Card
        border="#216ca5"
        title="Despesas Fixa"
        header={
          <Flex justifyContent={"flex-end"}>
            <BackButton onClick={() => navigate(-1)} />
            <AddButton link="/conta-fixa-pagar/cadastrar" />
          </Flex>
        }
        body={
          <Table
            loading={loading}
            message="Buscando as contas a pagar"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/contas-fixa-pagar?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={fixedExpenses.map((fixedExpense) => {
              return {
                tr: [
                  {
                    name: fixedExpense.account_plan_subcategory.name,
                  },
                  {
                    name: fixedExpense.favored ? fixedExpense.favored.name : "",
                  },
                  {
                    name: fixedExpense.frequency,
                  },
                  {
                    name: formatDateBrIndexWithOutHours(
                      fixedExpense.first_date_of_competence
                    ),
                  },
                  {
                    name: formatDateBrIndexWithOutHours(
                      fixedExpense.next_date_of_competence
                    ),
                  },
                  {
                    name: formatMoney(fixedExpense.value),
                  },
                  {
                    name: fixedExpense.form_of_payment,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditButton
                          link={`/conta-fixa-pagar/${fixedExpense.id}`}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setFixedExpenses(
                              fixedExpenses.filter(
                                (expense) => expense.id !== id
                              )
                            );
                          }}
                          id={fixedExpense.id}
                          route="fixedExpenses/"
                          error_message="Não foi possível remover a conta a pagar"
                          success_message="Conta a pagar removida com sucesso"
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "accountPlanSubcategories",
                label: "Conta",
                options: {
                  sort: true,
                },
              },
              {
                name: "favored",
                label: "Favorecido",
                options: {
                  sort: true,
                },
              },
              {
                name: "frequency",
                label: "Periodicidade",
                options: {
                  sort: true,
                },
              },
              {
                name: "first_date_of_competence",
                label: "Primeira Data de Competência",
                options: {
                  sort: true,
                },
              },
              {
                name: "next_date_of_competence",
                label: "Próxima Data de Competência",
                options: {
                  sort: true,
                },
              },
              {
                name: "value",
                label: "Valor",
                options: {
                  sort: true,
                },
              },
              {
                name: "form_of_payment",
                label: "Forma de Pagamento",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
