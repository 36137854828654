import { FormControl, FormLabel } from "@chakra-ui/react";
import { ReactNode } from "react";

interface FilterWithFormControlProps {
  children: ReactNode;
  label: string;
  width?: string;
}

export function FilterWithFormControl({
  children,
  label,
  width = "100%",
}: FilterWithFormControlProps) {
  return (
    <FormControl pt="1" mx={1} w={width}>
      <FormLabel>{label}</FormLabel>
      {children}
    </FormControl>
  );
}
