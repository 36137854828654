import { Accordion } from "@chakra-ui/react";
import { ReactNode } from "react";
import { SidebarNavBox } from "../../../atoms/Box/SidebarNavBox";
import { SidebarNavStack } from "../../../atoms/Stack/SidebarNavStack";

interface SidebarSectionStructureProps {
  children: ReactNode;
}

export function SidebarSectionStructure({
  children,
}: SidebarSectionStructureProps) {
  return (
    <SidebarNavBox>
      <SidebarNavStack>
        <Accordion allowToggle border="1px solid #fff">
          {" "}
          {children}
        </Accordion>
      </SidebarNavStack>
    </SidebarNavBox>
  );
}
