import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Skeleton, Stack } from "@chakra-ui/react";

import { api } from "../../../services/api";
import { useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { useToast } from "../../../hooks/toast";
import { Input } from "../../../components/Form/Input";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";

const editCompanyFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  city: yup
    .string()
    .required("A cidade é obrigatória")
    .min(3, "A cidade deve conter no mínimo 3 caracteres"),
  state: yup
    .string()
    .required("O estado é obrigatória")
    .matches(/[a-zA-Z]{2}/gm, "Digite somente a sigla do Estado")
    .min(2, "O estado deve conter no mínimo 2 caracteres")
    .max(2, "O estado deve conter no mínimo 2 caracteres"),
});

interface Franchise {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
}
export function EditCompany() {
  const navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [franchises, setFranchises] = useState<Franchise[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const { register, handleSubmit, formState, reset, setValue, control } =
    useForm({
      resolver: yupResolver(editCompanyFormSchema),
    });

  useEffect(() => {
    api.get(`/companies/${id}`).then((response) => {
      reset(response.data);
    });
    api
      .get("/franchises")
      .then((response) => setFranchises(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
    api
      .get("/categories")
      .then((response) => setCategories(response.data))
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });

    setLoading(true);
  }, [id, navigate, reset]);

  const handleGetCep = useCallback(
    (cep: string) => {
      axios
        .get(`https://viacep.com.br/ws/${cep.replace("-", "")}/json`)
        .then((response) => {
          setValue("city", response.data.localidade);
          setValue("state", response.data.uf);
        });
    },
    [setValue]
  );

  const handleEditCategory: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/companies/${id}`, {
          name: formValue.name,
          city: formValue.city,
          state: formValue.state,
          franchise_id: formValue.franchise_id,
          category_id: formValue.category_id,
        });
        navigate("/empresas");
        toast({
          title: "Empresa editada com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível editar a empresa",
          status: "error",
        });
      }
    },
    [id, navigate, toast]
  );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Empresa"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditCategory)}
              id="add-form"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
                <SelectControl
                  control={control}
                  name="category_id"
                  isRequired={true}
                  label="Categoria"
                  error={errors.category_id}
                  options={categories}
                />
                <SelectControl
                  control={control}
                  name="franchise_id"
                  isRequired={false}
                  label="Rede"
                  error={errors.franchise_id}
                  options={franchises}
                />
                <Input
                  label="CEP"
                  name="postalCode"
                  maxLength={9}
                  onChange={(e) => {
                    var value = e.target.value;
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/(\d{5})(\d)/, "$1-$2");
                    e.target.value = value;
                  }}
                  onBlur={(e) => handleGetCep(e.target.value)}
                />
                <Input
                  label="Cidade"
                  isRequired={true}
                  error={errors.city}
                  {...register("city")}
                />
                <Input
                  label="Estado"
                  isRequired={true}
                  error={errors.state}
                  {...register("state")}
                />
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} />}
        />
      </Skeleton>
    </>
  );
}
