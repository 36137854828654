import { Flex, Icon, Text } from "@chakra-ui/react";
import { FaCheckDouble, FaTimes } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";

interface CreataSelectProps {
  onCreateOption: (value: string) => void;
  onChange: (select: any) => void;
  options: {
    label: string;
    value: any;
  }[];
  validate?: boolean;
  error?: {
    message?: string;
  };
  value: any;
  name: string;
  isRequired?: boolean;
}
export function CreateSelect({
  onChange,
  onCreateOption,
  options,
  validate,
  error,
  value,
  name,
  isRequired = false,
}: CreataSelectProps) {
  return (
    <Flex flexDirection={"column"}>
      <Flex
        borderRadius={"8px"}
        bg="white"
        border={validate && !error ? "2px" : "1px"}
        borderColor={
          validate && !error
            ? "green.450"
            : error
            ? "red.450"
            : document.getElementById(`react-select-create-${name}`) ===
              document.activeElement
            ? "blue.350"
            : "gray.400"
        }
        color="black"
        p="0.5"
      >
        <CreatableSelect
          id={`react-select-create-${name}`}
          onCreateOption={onCreateOption}
          onChange={onChange}
          options={options}
          required={isRequired}
          placeholder="Selecione ou digite uma nova opção"
          isSearchable={true}
          isMulti={false}
          value={value}
          noOptionsMessage={() => "Nenhuma opção encontrada"}
          formatCreateLabel={(input: string) => `Criar "${input}"`}
          styles={{
            container: (base, state) => ({
              ...base,
              width: "100%",
              border: "none",
              ":focus": {
                outline: "none",
                border: "none",
              },
              ":active": {
                outline: "none",
                border: "none",
              },
              zIndex: 12,
            }),
            control: (base, props) => ({
              ...base,
              border: "none",
              boxShadow: "none",
              ":focus": {
                outline: "none",
                border: "none",
                boxShadow: "none",
              },
              ":active": {
                outline: "none",
                border: "none",
                boxShadow: "none",
              },
            }),
          }}
        />
        {validate && !error && (
          <Icon my="auto" mr="2" color="green" as={FaCheckDouble} />
        )}
        {error && <Icon my="auto" mr="2" color="red" as={FaTimes} />}
      </Flex>
      {error && (
        <Text mt="1" ml="1" color="red.450">
          {error.message}
        </Text>
      )}
    </Flex>
  );
}
