import { Flex, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import { FaQuestionCircle } from "react-icons/fa";
import { Card } from "../../../components/Card";
import { Input } from "../../../components/Form/Input";
import { useCallback, useState } from "react";
import { ModalCogsTarget } from "../../../components/templates/Modal/ModalCogsTarget";
import { InternalHeader } from "../../../components/templates/InternalHeader";

export function CogsTargetCompany() {
  const [costOfInputs, setCostOfInputs] = useState<string>("");
  const [revenue, setRevenue] = useState<string>("");

  const calculateGoal = useCallback(() => {
    const percent =
      Number(revenue) > 0 ? Number(costOfInputs) / Number(revenue) : 0;
    return (100 * Number(percent)).toFixed(1);
  }, [costOfInputs, revenue]);

  return (
    <>
      <InternalHeader
        title="Calculadora da Meta do CMV"
        has_back_button={true}
        has_filter={false}
      />
      <Card
        title=""
        mb="5"
        header={<></>}
        border="#216ca5"
        body={
          <>
            <SimpleGrid columns={{ base: 1, md: 2 }} mt="4" spacingX={6}>
              <Flex flexDirection="column">
                <Tooltip
                  hasArrow
                  label="Total gasto com insumo previsto em um período de 1 mês"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <Text mx="2" cursor="pointer">
                      Gastos com insumo
                    </Text>
                    <FaQuestionCircle size="16px" />
                  </Flex>
                </Tooltip>
                <Input
                  name="costOfInputs"
                  value={costOfInputs}
                  leftAddon={"R$"}
                  onChange={(e) => {
                    var value = e.target.value;
                    if (value.length > 2) {
                      value = value
                        .replace(/\D+/g, "")
                        .replace(/([0-9]{2})$/g, ".$1");
                    }
                    setCostOfInputs(value);
                  }}
                />
              </Flex>
              <Flex flexDirection="column">
                <Tooltip
                  hasArrow
                  label="Faturamento previsto em um período de 1 mês"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <Text mx="2" cursor="pointer">
                      Faturamento
                    </Text>
                    <FaQuestionCircle size="16px" />
                  </Flex>
                </Tooltip>
                <Input
                  name="revenue"
                  value={revenue}
                  leftAddon={"R$"}
                  onChange={(e) => {
                    var value = e.target.value;
                    if (value.length > 2) {
                      value = value
                        .replace(/\D+/g, "")
                        .replace(/([0-9]{2})$/g, ".$1");
                    }
                    setRevenue(value);
                  }}
                />
              </Flex>
            </SimpleGrid>
            <Flex
              mt="6"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Text fontSize="24px">Sugestão de Meta:</Text>
              <Text fontSize="108px" fontWeight="bold" color="#216ca5">
                {calculateGoal()} %
              </Text>
              <ModalCogsTarget />
            </Flex>
          </>
        }
      />
    </>
  );
}
