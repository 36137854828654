import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { shade } from "polished";
import { ElementType } from "react";

interface PaymentTypeCardProps {
  title: string;
  value: string;
  icon?: ElementType;
  setOpen(title: string, value: boolean): void;
}

export function PaymentTypeCard({
  icon,
  title,
  value,
  setOpen,
}: PaymentTypeCardProps) {
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-between"}
      borderRadius="md"
      maxW="300px"
      borderWidth="1px"
      bg="#fff"
      m={1}
      my={5}
      minW="298px"
      borderTop="2px solid #216ca5"
    >
      <Box pb={2}>
        <Box
          style={{
            background: "#fff",
            borderRadius: " 100%",
            boxShadow: "0 8px 10px 0 rgb(0 0 0 / 10%)",
            display: "grid",
            height: "67px",
            margin: "-33px auto 0",
            position: "relative",
            width: "67px",
          }}
        >
          <Flex align="center" justify="center">
            <Icon as={icon} boxSize={7} color="#216ca5" />
          </Flex>
        </Box>
      </Box>
      <Flex flexDirection={"column"} flex={1}>
        <Text
          mt={2}
          fontSize="xl"
          fontWeight="bold"
          lineHeight="short"
          textAlign="center"
        >
          {title}
        </Text>
      </Flex>
      <Flex flexDirection={"column"}>
        <Text
          fontSize="lg"
          fontWeight="regular"
          lineHeight="short"
          textAlign="center"
          color="gray.500"
        >
          {value}
        </Text>
        <Flex my={4} align="center" justify="center">
          <Button
            bg="#216ca5"
            _hover={{
              backgroundColor: shade(0.2, "#216ca5"),
            }}
            _active={{
              backgroundColor: shade(0.2, "#216ca5"),
            }}
            color="white"
            onClick={() => setOpen(title, true)}
          >
            Editar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
