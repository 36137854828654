import { Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

interface AuthStackProps {
  children: ReactNode;
}

export function AuthStack({ children }: AuthStackProps) {
  return (
    <Stack spacing="4" mb={6}>
      {children}
    </Stack>
  );
}
