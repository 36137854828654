import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Input } from "../../../Form/Input";
import { SelectControl } from "../../Form/SelectControl";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";

const createAccountPlanSubcategoryFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  account_plan_category_id: yup
    .string()
    .required("A conta do plano de conta é obrigatório"),
});

interface CreateAccountPlanSubcategoryProps {
  isOpen: boolean;
  onClose: () => void;
  cb: (sub: any) => void;
}

interface AccountPlanCategory {
  id: string;
  name: string;
  type: string;
}

interface AccountPlanSubcategoryFieldValues {
  name: string;
  account_plan_category_id: string;
}

export function ModalCreateAccountPlanSubcategory({
  isOpen,
  onClose,
  cb,
}: CreateAccountPlanSubcategoryProps) {
  const [accountPlanCategories, setAccountPlanCategories] = useState<
    AccountPlanCategory[]
  >([]);

  const { register, handleSubmit, formState, control, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(createAccountPlanSubcategoryFormSchema),
    defaultValues: {
      name: "",
      account_plan_category_id: "",
    },
  });
  const { errors } = formState;
  const toast = useToast();

  useEffect(() => {
    api
      .get(`/accountPlanCategories`)
      .then((response) => setAccountPlanCategories(response.data));
  }, []);

  const handleCreateAccountPlanSubcategory: SubmitHandler<AccountPlanSubcategoryFieldValues> =
    useCallback(
      async (formValue) => {
        try {
          const response = await api.post("/accountPlanSubcategories", {
            name: formValue.name,
            account_plan_category_id: formValue.account_plan_category_id,
          });
          cb(response.data);
          toast({
            title: "Receita/despesa cadastrado com sucesso!",
            status: "success",
          });
          onClose();
          reset()
        } catch (err) {
          toast({
            title: "Não foi possível cadastrar a receita/despesa",
            status: "error",
          });
        }
      },
      [toast, cb, onClose, reset]
    );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrando Receita/Despesa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleCreateAccountPlanSubcategory)}
              id="add-form-subcategory"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
                <SelectControl
                  control={control}
                  label="Conta do Plano de Conta"
                  isRequired={true}
                  error={errors.account_plan_category_id}
                  {...register("account_plan_category_id")}
                  options={accountPlanCategories.map((category) => ({
                    name: `${category.name} - ${
                      category.type === "expenses" ? "Despesa" : "Receita"
                    } `,
                    id: category.id,
                  }))}
                />
              </Stack>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              form="add-form-subcategory"
            >
              Cadastrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
