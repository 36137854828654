import { Flex, ModalBody } from "@chakra-ui/react";
import { useCallback } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/auth";
import { api } from "../../../../services/api";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { Input } from "../../../Form/Input";
import { useToast } from "../../../../hooks/toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface ModalCogsTargetBodyProps {
  onClose: () => void;
}

const updateCogsFormSchema = yup.object().shape({
  cogs_target: yup
    .number()
    .required("Meta é obrigatória")
    .min(0, "Meta deve ser maior ou igual a 0%")
    .max(100, "Meta deve ser menor ou igual a 100%")
    .typeError("Meta deve ser um número"),
});
export function ModalCogsTargetBody({ onClose }: ModalCogsTargetBodyProps) {
  const { user, updateCogs } = useAuth();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(updateCogsFormSchema),
  });

  const handleSubmitGoal: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      api
        .patch(`/companies/cogsTarget/${user.company_id}`, {
          cogs_target: formValue.cogs_target,
        })
        .then((response) => {
          toast({
            title: "Meta definida com sucesso!",
            status: "success",
          });
          updateCogs(Number(formValue.cogs_target));
          onClose();
        })
        .catch((err) => {
          toast({
            title: "Não foi possível definir a meta",
            status: "error",
          });
          onClose();
        });
    },
    [onClose, toast, updateCogs, user.company_id]
  );

  return (
    <ModalBody>
      <Flex
        as="form"
        id="cogs_target"
        flexDirection="column"
        onSubmit={handleSubmit(handleSubmitGoal)}
      >
        <Input
          label="Definir meta"
          isRequired={true}
          {...register("cogs_target")}
          rightAddon="%"
          type="number"
          step={0.1}
          error={errors.cogs_target}
        />
        <Flex justifyContent="flex-end" my="2">
          <SubmitButton bg="#216ca5" form="cogs_target" text="Definir" />
        </Flex>
      </Flex>
    </ModalBody>
  );
}
