import { Link } from "react-router-dom";
import { ReactNode } from "react";

interface InternalLinkProps {
  link: string;
  children: ReactNode;
}

export function InternalLink({ children, link }: InternalLinkProps) {
  return <Link to={link}>{children}</Link>;
}
