import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
export const theme = extendTheme({
  colors: {
    brand: {
      "100": "#f7fafc",
      "900": "#1a202c",
    },
    blue: {
      "350": "#216ca5",
      "650": "#0D00FF",
    },
    blueCyan: {
      "650": "#216ca5",
    },
    boulder: {
      "500": "#7A7A7A",
    },
    cyan: {
      "350": "#00E3FF",
      "650": "#38a3b8",
    },
    emperor: {
      "500": "#515151",
    },
    gray: {
      "50": "#f4f6f9",
      "250": "#dee2e6",
      "450": "#787878",
    },
    orange: {
      "450": "#fd7e14",
    },
    yellow: {
      "450": "#fdc230",
    },
    green: {
      "450": "#53a846",
    },
    red: {
      "450": "#dd4445",
    },
    secondary: "#6c757d",
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
  styles: {
    global: (props: any) => ({
      th: {
        position: "unset !important",
      },
      body: {
        bg: mode("gray.50", "gray.800")(props),
        color: "#27252f",
      },
    }),
  },
});
