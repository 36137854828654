import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../../components/Card";
import { AddButtonWithTextModal } from "../../../../components/molecules/AddButtonWithTextModal";
import { EditModalButton } from "../../../../components/molecules/TableListButton/EditModalButton";
import { ButtonListButton } from "../../../../components/organisms/ButtonListBox";
import { DeleteModal } from "../../../../components/templates/DeleteModal";
import { InternalHeader } from "../../../../components/templates/InternalHeader";
import { ProductModal } from "../../../../components/templates/Modal/ProductModal";
import { Table } from "../../../../components/templates/Table";
import { useQuery } from "../../../../hooks/query";
import { api } from "../../../../services/api";

interface Product {
  id: string;
  name: string;
  efficiency: number;
  merchandise_id: string;
  merchandise?: {
    name: string;
  };
}

interface Merchandise {
  id: string;
  name: string;
  merchandise_subcategory_id: string;
  measurement_unit: string;
  merchandise_subcategory: {
    name: string;
  };
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListProducts() {
  const query = useQuery();
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });
  const [merchandises, setMerchandises] = useState<Merchandise[]>([]);

  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState<"create" | "edit">("create");
  const [defaultValue, setDefaultValue] = useState<Product>({} as Product);

  useEffect(() => {
    setLoading(false);
    api
      .get(
        `/pricing/products/pagination?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setProducts(response.data.products);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });

    api
      .get(`/pricing/merchandises`)
      .then((response) => {
        setMerchandises(response.data);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [defaultParams, navigate]);

  return (
    <>
      <InternalHeader
        title="Produtos de Ficha Técnica"
        has_filter={false}
        has_back_button
      >
        <AddButtonWithTextModal
          onClick={() => {
            setIsOpen(true);
            setActionType("create");
            setDefaultValue({
              id: "",
              name: "",
              efficiency: 0,
              merchandise_id: "",
              merchandise: { name: "" },
            });
          }}
        />
      </InternalHeader>
      <ProductModal
        actionType={actionType}
        defaultLink="pricing/products"
        isOpen={isOpen}
        merchandises={merchandises}
        setIsOpen={setIsOpen}
        setProduct={(product) => {
          if (actionType === "create") {
            setProducts([...products, product]);
          } else {
            setProducts(
              products.map((c) => {
                if (c.id === product.id) {
                  return product;
                }
                return c;
              })
            );
          }
        }}
        defaultValue={defaultValue}
      />
      <Card
        body={
          <Table
            loading={loading}
            message="Buscando as mercadorias"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/precificacao/produtos?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={products.map((product) => {
              return {
                tr: [
                  {
                    name: product.name,
                  },
                  {
                    name: product.efficiency,
                  },
                  {
                    name: product.merchandise?.name,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditModalButton
                          onClick={() => {
                            setActionType("edit");
                            setIsOpen(true);
                            setDefaultValue(product);
                          }}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setProducts(
                              products.filter((product) => product.id !== id)
                            );
                          }}
                          id={product.id}
                          route="pricing/products/"
                          error_message="Não foi possível remover o produto de ficha técnica"
                          success_message="Produto de ficha técnica removido com sucesso"
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "products.name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "products.efficiency",
                label: "Rendimento",
                options: {
                  sort: true,
                },
              },
              {
                name: "merchandise.name",
                label: "Mercadoria",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
