import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { useQuery } from "../../../hooks/query";
import { Table } from "../../../components/templates/Table";
import { checkPermission } from "../../../utils/checkPermission";
import { useAuth } from "../../../hooks/auth";
interface Franchise {
  id: string;
  name: string;
}
interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}
export function ListFranchises() {
  const query = useQuery();
  const { user } = useAuth();

  const navigate = useNavigate();
  const [franchises, setFranchises] = useState<Franchise[]>([]);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });
  useEffect(() => {
    api
      .get(
        `/franchises/pagination?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setFranchises(response.data.franchises);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [defaultParams, navigate]);
  return (
    <>
      <Card
        border="#216ca5"
        title="Redes"
        header={<AddButton link="/rede/cadastrar" />}
        body={
          <Table
            loading={loading}
            message="Buscando as redes"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/redes?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={franchises.map((franchise) => {
              return {
                tr: [
                  {
                    name: franchise.name,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditButton link={`/rede/${franchise.id}`} />
                        {checkPermission({
                          is_admin: user.is_admin,
                          type: "Super Admin",
                        }) ? (
                          <DeleteModal
                            onDelete={(id) => {
                              setFranchises(
                                franchises.filter(
                                  (franchise) => franchise.id !== id
                                )
                              );
                            }}
                            id={franchise.id}
                            route="franchises/"
                            error_message="Não foi possível remover a rede"
                            success_message="Rede removida com sucesso"
                          />
                        ) : (
                          <></>
                        )}
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
