import { formatDateBrIndexWithOutHours } from "./formatDate";
import { formatMoney } from "./money";

interface FormatFieldCompareProps {
  field: string;
  current: any;
  previous: any;
}

export function formatFieldCompare({
  field,
  current,
  previous,
}: FormatFieldCompareProps) {
  switch (field) {
    case "date_of_competence":
    case "date_of_receipt_of_money":
    case "effective_write_off_date":
    case "due_date":
      return {
        current: current ? formatDateBrIndexWithOutHours(current) : "",
        previous: previous ? formatDateBrIndexWithOutHours(previous) : "",
      };
    case "value":
      return {
        current: current || current === 0 ? formatMoney(Number(current)) : "",
        previous:
          previous || previous === 0 ? formatMoney(Number(previous)) : "",
      };
    case "has_cash_closing":
      return {
        current: current ? "Sim" : "Não",
        previous: previous ? "Sim" : "Não",
      };
    default:
      return {
        current: current ? current : "",
        previous: previous ? previous : "",
      };
  }
}
