import { startOfWeek, endOfWeek, format, addWeeks } from "date-fns";

export function getStartAndEndDateOfWeek(year: number, weekNumber: number) {
  const startDate = startOfWeek(
    addWeeks(new Date(year, 0, 1), weekNumber - 1),
    { weekStartsOn: 1 }
  );
  const endDate = endOfWeek(addWeeks(new Date(year, 0, 1), weekNumber - 1), {
    weekStartsOn: 1,
  });

  return {
    startDate: format(startDate, "dd/MM/yyyy"),
    endDate: format(endDate, "dd/MM/yyyy"),
  };
}

export function formatDateBrIndex(date: Date | string) {
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date.toString().replace("Z", "-03:00")));
}
export function dateFromDay(year: number, day: number) {
  var date = new Date(year, 0);
  return new Date(date.setDate(day));
}

export function formatDateBrIndexWithOutHours(date: Date | string) {
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(date.toString().replace("Z", "-03:00")));
}
export function formatDatabaseWithOutHours(date: Date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
}

export function formatDateWithOutHours(date: string) {
  const dateFormat = date.split("-");

  return `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;
}

export function createDate(date: string) {
  return new Date(date.toString().replace("Z", "-03:00"));
}

export function translateMonthDate(month: number) {
  switch (Number(month)) {
    case 1:
      return "Janeiro";
    case 2:
      return "Fevereiro";
    case 3:
      return "Março";
    case 4:
      return "Abril";
    case 5:
      return "Maio";
    case 6:
      return "Junho";
    case 7:
      return "Julho";
    case 8:
      return "Agosto";
    case 9:
      return "Setembro";
    case 10:
      return "Outubro";
    case 11:
      return "Novembro";
    case 12:
      return "Dezembro";
    default:
      return "";
  }
}

export function dateDatabaseToIndex(date: string) {
  const split = date.split("-");

  return `${split[2]}/${split[1]}/${split[0]}`;
}

export function formatMonthAndYear(date?: string | Date) {
  if (!date) {
    return "";
  }
  return Intl.DateTimeFormat("pt-BR", {
    month: "short",
    year: "numeric",
  }).format(new Date(date));
}

export function formatDatePtBRToDatabase(date: string) {
  const split = date.split("/");
  return `${split[2]}-${split[1]}-${split[0]}`;
}

export function formatDateDatabaseToPtBR(date: string) {
  const split = date.split("-");
  return `${split[2]}/${split[1]}/${split[0]}`;
}
