import { Text } from "@chakra-ui/react";

interface AuthTitleProps {
  text: string;
}
export function AuthTitle({ text }: AuthTitleProps) {
  return (
    <Text color="gray.600" m="0" p="4" textAlign="center">
      {text}
    </Text>
  );
}
