import { createStandaloneToast } from "@chakra-ui/react";

const { toast } = createStandaloneToast({
  defaultOptions: {
    duration: 3000,
    isClosable: true,
    position: "top-right",
  },
});

export function useToast() {
  return toast;
}
