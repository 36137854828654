import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, SimpleGrid, Skeleton, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { formatMoney } from "../../../utils/money";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";

const editRevenueFormSchema = yup.object().shape({
  observation: yup.string().notRequired().nullable().typeError("Valor inválido"),
  value: yup.string().required("Valor é obrigatório"),
  form_of_receipt: yup.string().required("Forma de recebimento é obrigatória"),
  account_plan_subcategory_id: yup.string().required("Receita é obrigatória"),
  date_of_competence: yup
    .string()
    .required("Data de competência é obrigatória"),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

export function EditRevenue() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(editRevenueFormSchema),
  });

  const accountPlanSubcategoryId = watch("account_plan_subcategory_id");

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Receita")
      .then((response) => setAccountPlanSubcategories(response.data));

    api.get(`/revenues/${id}`).then((response) => {
      reset({
        ...response.data,
        date_of_competence: response.data.date_of_competence,
        date_of_receipt_of_money: response.data.date_of_receipt_of_money,
        value: formatMoney(response.data.value)
          .replace("R$ ", "")
          .replace(".", ""),
      });
    });
    setLoading(true);
  }, [id, reset]);

  const handleEditRevenue: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/revenues/${id}`, {
          observation: formValue.observation,
          date_of_competence: formValue.date_of_competence,
          date_of_receipt_of_money: formValue.date_of_receipt_of_money,
          form_of_receipt: formValue.form_of_receipt,
          account_plan_subcategory_id: formValue.account_plan_subcategory_id,
          value: formValue.value,
          company_id: user.company_id,
        });
        navigate(-1);
        toast({
          title: "Conta a receber editada com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível editar a conta a receber",
          status: "error",
        });
      }
    },
    [id, navigate, toast, user.company_id]
  );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Receita"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditRevenue)}
              id="add-form"
            >
              <Stack spacing="4">
                <SimpleGrid spacingX={4}>
                  <MultiSelectControl
                    options={accountPlanSubcategories.map((acc) => ({
                      label: acc.name,
                      value: acc.id,
                    }))}
                    {...register("account_plan_subcategory_id")}
                    onChange={(selected) => {
                      setValue("account_plan_subcategory_id", selected.value, {
                        shouldValidate: true,
                      });
                    }}
                    value={{
                      label: accountPlanSubcategories.find(
                        (acc) => acc.id === accountPlanSubcategoryId
                      )?.name,
                      value: accountPlanSubcategoryId,
                    }}
                    validaded={
                      getValues("account_plan_subcategory_id") &&
                      !errors.account_plan_subcategory_id
                    }
                    isMulti={false}
                    label="Receita"
                    error={errors.account_plan_subcategory_id}
                    isRequired={true}
                  />
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={4}>
                  <SimpleGrid borderRadius={8} boxShadow="md" py="4" px="8">
                    <SelectControl
                      control={control}
                      error={errors.form_of_receipt}
                      {...register("form_of_receipt")}
                      isRequired={true}
                      validaded={
                        getValues("form_of_receipt") &&
                        getValues("form_of_receipt") !== ""
                      }
                      label="Forma de recebimento"
                      options={[
                        {
                          id: "dinheiro",
                          name: "Dinheiro",
                        },
                        {
                          id: "credito",
                          name: "Cartão de crédito",
                        },

                        {
                          id: "debito",
                          name: "Cartão de débito",
                        },
                        {
                          id: "vale",
                          name: "Vale",
                        },
                        {
                          id: "online",
                          name: "Pagamento online",
                        },
                        {
                          id: "pix",
                          name: "Pix",
                        },
                        {
                          id: "assinado",
                          name: "Assinado",
                        },
                        {
                          id: "boleto",
                          name: "Boleto",
                        },
                        {
                          id: "cortesia",
                          name: "Cortesia",
                        },
                      ]}
                    />
                    <Input
                      label="Valor"
                      isRequired={true}
                      error={errors.value}
                      {...register("value")}
                      leftAddon="R$"
                      validaded={
                        getValues("value") && getValues("value") !== ""
                      }
                      onChange={(e) => {
                        var value = e.target.value;
                        if (value.length > 2) {
                          value = value
                            .replace(/\D+/g, "")
                            .replace(/([0-9]{2})$/g, ",$1");
                          e.target.value = value;
                          setValue("value", value);
                        }
                      }}
                    />
                  </SimpleGrid>
                  <Flex
                    bg="#f0f0f0"
                    borderRadius={8}
                    boxShadow="md"
                    py="4"
                    px="8"
                    flexDirection={"column"}
                  >
                    <Input
                      type="date"
                      label="Data da Receita"
                      validaded={
                        getValues("date_of_competence") &&
                        getValues("date_of_competence") !== ""
                      }
                      isRequired={true}
                      error={errors.date_of_competence}
                      {...register("date_of_competence")}
                    />
                    <Input
                      type="date"
                      label="Data do Recebimento"
                      isRequired={false}
                      validaded={
                        getValues("date_of_receipt_of_money") &&
                        getValues("date_of_receipt_of_money") !== ""
                      }
                      error={errors.date_of_receipt_of_money}
                      {...register("date_of_receipt_of_money")}
                    />
                  </Flex>
                </SimpleGrid>
                <Input
                  type="text"
                  label="Observação"
                  isRequired={false}
                  validaded={
                    getValues("observation") && getValues("observation") !== ""
                  }
                  error={errors.observation}
                  {...register("observation")}
                />
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
