import { useEffect, useState } from "react";
import { Card } from "../../../components/Card";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { Link, useNavigate } from "react-router-dom";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { useAuth } from "../../../hooks/auth";
import { formatMoney } from "../../../utils/money";
import {
  formatDatabaseWithOutHours,
  formatDateBrIndexWithOutHours,
  formatDateWithOutHours,
} from "../../../utils/formatDate";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { useQuery } from "../../../hooks/query";
import { Table } from "../../../components/templates/Table";
import {
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Box } from "../../../components/templates/Widget/Box";
import { FaCashRegister, FaClipboardList, FaDollarSign } from "react-icons/fa";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { DateRange } from "../../../components/templates/Filters/DateRangePicker";
import { shade } from "polished";

interface Revenue {
  id: string;
  value: number;
  date_of_receipt_of_money: string;
  date_of_competence: string;
  form_of_receipt: string;
  account_plan_subcategory: {
    name: string;
  };
}

interface CashClosing {
  date_of_competence: string;
  total_clients: number;
  value: number;
}
interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

interface Selected {
  date_start_receipt_of_money: string;
  date_end_receipt_of_money: string;
  date_start_competence: string;
  date_end_competence: string;
  type: "revenue" | "closing";
}
export function ListRevenues() {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const query = useQuery();
  const [selected, setSelected] = useState<Selected>({
    date_end_competence:
      query.get("date_end_competence") ??
      formatDatabaseWithOutHours(new Date()),
    date_start_competence:
      query.get("date_start_competence") ??
      formatDatabaseWithOutHours(sevenDaysAgo),
    date_end_receipt_of_money: query.get("date_end_receipt_of_money") ?? "",
    date_start_receipt_of_money: query.get("date_start_receipt_of_money") ?? "",
    type: query.get("type") ?? "revenue",
  } as Selected);

  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalValue, setTotalValue] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 30,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });
  const [revenues, setRevenues] = useState<Revenue[]>([]);
  const [cashClosings, setCashClosings] = useState<CashClosing[]>([]);

  useEffect(() => {
    setLoading(false);
    if (selected.type === "revenue") {
      api
        .get(
          `revenues/pagination/company/${user.company_id}?date_start_receipt_of_money=${selected.date_start_receipt_of_money}&date_end_receipt_of_money=${selected.date_end_receipt_of_money}&date_start_competence=${selected.date_start_competence}&date_end_competence=${selected.date_end_competence}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
        )
        .then((response) => {
          setRevenues(response.data.revenues);
          setTotal(response.data.total);
          setTotalValue(response.data.value);
          setLoading(true);
        })
        .catch((err: AxiosError) => {
          setLoading(true);
          navigate(`/error/${err.response?.status}`);
        });
    } else {
      api
        .get(
          `revenues/cashClosing/pagination/company/${user.company_id}?date_start_competence=${selected.date_start_competence}&date_end_competence=${selected.date_end_competence}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
        )
        .then((response) => {
          setCashClosings(response.data.revenues);
          setTotal(response.data.total);
          setTotalValue(response.data.value);
          setLoading(true);
        })
        .catch((err: AxiosError) => {
          setLoading(true);
          navigate(`/error/${err.response?.status}`);
        });
    }
  }, [defaultParams, selected, navigate, user.company_id]);
  return (
    <>
      <InternalHeader title="Receitas" has_filter={false}></InternalHeader>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={2}>
        <Box
          bg="#43A16A"
          color="#f0f0f0"
          border="#43A16A"
          icon={FaDollarSign}
          title="Total Receitas"
          value={formatMoney(totalValue)}
        />
        <Box
          bg="#CC8956"
          color="#f0f0f0"
          border="#CC8956"
          icon={FaClipboardList}
          title="Títulos"
          value={total}
        />
      </SimpleGrid>

      <Card
        border="#216ca5"
        title=" "
        header={
          <Flex justifyContent={"flex-end"} wrap="wrap">
            <Button
              as={Link}
              to={"/fechamento-caixa"}
              bg="#fd9000"
              color="white"
              mr="4px"
              my="4px"
              _hover={{
                backgroundColor: shade(0.2, "#fd9000"),
              }}
              _active={{
                backgroundColor: shade(0.2, "#fd9000"),
              }}
            >
              {" "}
              <Icon as={FaCashRegister} mr="2" />
              Adicionar Fechamento de Caixa
            </Button>
            <AddButton link="/conta-receber/cadastrar" />
          </Flex>
        }
        body={
          <Tabs
            onChange={(index) => {
              if (index === 0) {
                setRevenues([]);
                setDefaultParams({
                  limit_per_page: 30,
                  page: 1,
                  search: "",
                  order_column: "",
                  order_type: "",
                });
                setSelected({
                  ...selected,
                  type: "revenue",
                });
              } else {
                setRevenues([]);
                setDefaultParams({
                  limit_per_page: 30,
                  page: 1,
                  search: "",
                  order_column: "",
                  order_type: "",
                });
                setSelected({
                  ...selected,
                  type: "closing",
                });
              }
            }}
          >
            <TabList>
              <Tab>Receitas</Tab>
              <Tab>Fechamentos</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Table
                  loading={loading}
                  message="Buscando as contas a receber"
                  params={defaultParams}
                  setDefaultParams={(params) => {
                    setDefaultParams(params);
                    navigate(
                      `/contas-receber?type=revenue&offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
                    );
                  }}
                  total={total}
                  data={revenues
                    .filter((revenue) => revenue.account_plan_subcategory)
                    .map((revenue) => {
                      return {
                        tr: [
                          {
                            name: revenue.account_plan_subcategory.name,
                          },
                          {
                            name: revenue.date_of_competence
                              ? formatDateWithOutHours(
                                  revenue.date_of_competence
                                )
                              : "",
                          },
                          {
                            name: revenue.date_of_receipt_of_money
                              ? formatDateWithOutHours(
                                  revenue.date_of_receipt_of_money
                                )
                              : "",
                          },
                          {
                            name: formatMoney(revenue.value),
                          },
                          {
                            name: revenue.form_of_receipt,
                          },
                          {
                            name: (
                              <ButtonListButton>
                                <EditButton
                                  link={`/conta-receber/${revenue.id}`}
                                />
                                <DeleteModal
                                  onDelete={(id) => {
                                    setRevenues(
                                      revenues.filter(
                                        (revenue) => revenue.id !== id
                                      )
                                    );
                                  }}
                                  id={revenue.id}
                                  route="revenues/"
                                  error_message="Não foi possível remover a conta a pagar"
                                  success_message="Conta a receber removida com sucesso"
                                />
                              </ButtonListButton>
                            ),
                          },
                        ],
                      };
                    })}
                  columns={[
                    {
                      name: "accountPlanSubcategories.name",
                      label: "Conta",
                      options: {
                        sort: true,
                      },
                    },
                    {
                      name: "date_of_competence",
                      label: "Data da Receita",
                      options: {
                        sort: true,
                      },
                      filter: (
                        <DateRange
                          showClearDates={true}
                          label="Receita"
                          dateEnd={selected.date_end_competence}
                          dateStart={selected.date_start_competence}
                          onSelectDate={(start, end) => {
                            setSelected({
                              ...selected,
                              date_start_competence: start,
                              date_end_competence: end,
                            });
                            navigate(
                              `/contas-receber?type=revenue&date_start_receipt_of_money=${selected.date_start_receipt_of_money}&date_end_receipt_of_money=${selected.date_end_receipt_of_money}&date_start_competence=${start}&date_end_competence=${end}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
                            );
                          }}
                        />
                      ),
                    },
                    {
                      name: "date_of_receipt_of_money",
                      label: "Recebimento",
                      options: {
                        sort: true,
                      },
                      filter: (
                        <DateRange
                          showClearDates={true}
                          dateStart={selected.date_start_receipt_of_money}
                          dateEnd={selected.date_end_receipt_of_money}
                          label="Recebimento"
                          onSelectDate={(start, end) => {
                            setSelected({
                              ...selected,
                              date_start_receipt_of_money: start,
                              date_end_receipt_of_money: end,
                            });
                            navigate(
                              `/contas-receber?type=revenue&date_start_receipt_of_money=${start}&date_end_receipt_of_money=${end}&date_start_competence=${selected.date_start_competence}&date_end_competence=${selected.date_end_competence}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
                            );
                          }}
                        />
                      ),
                    },

                    {
                      name: "value",
                      label: "Valor",
                      options: {
                        sort: true,
                      },
                    },
                    {
                      name: "form_of_receipt",
                      label: "Forma de Recebimento",
                      options: {
                        sort: true,
                      },
                    },
                    {
                      name: "action",
                      label: "Ações",
                      options: {
                        sort: false,
                      },
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel>
                <Table
                  loading={loading}
                  message="Buscando as contas a receber"
                  params={defaultParams}
                  setDefaultParams={(params) => {
                    setDefaultParams(params);
                    navigate(
                      `/contas-receber?type=closing&offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
                    );
                  }}
                  total={total}
                  data={cashClosings.map((cashClosing) => {
                    return {
                      tr: [
                        {
                          name: cashClosing.date_of_competence
                            ? formatDateBrIndexWithOutHours(
                                cashClosing.date_of_competence
                              )
                            : "",
                        },
                        {
                          name: formatMoney(cashClosing.value),
                        },
                        {
                          name: cashClosing.total_clients,
                        },
                        {
                          name: (
                            <ButtonListButton>
                              <EditButton
                                link={`/fechamento-caixa?date_of_competence=${cashClosing.date_of_competence}`}
                              />
                            </ButtonListButton>
                          ),
                        },
                      ],
                    };
                  })}
                  columns={[
                    {
                      name: "date_of_competence",
                      label: "Data do Fechamento",
                      options: {
                        sort: true,
                      },
                      filter: (
                        <DateRange
                          showClearDates={true}
                          label="Fechamento"
                          dateEnd={selected.date_end_competence}
                          dateStart={selected.date_start_competence}
                          onSelectDate={(start, end) => {
                            setSelected({
                              ...selected,
                              date_start_competence: start,
                              date_end_competence: end,
                            });
                            navigate(
                              `/contas-receber?type=closing&date_start_receipt_of_money=${selected.date_start_receipt_of_money}&date_end_receipt_of_money=${selected.date_end_receipt_of_money}&date_start_competence=${start}&date_end_competence=${end}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
                            );
                          }}
                        />
                      ),
                    },
                    {
                      name: "value",
                      label: "Valor",
                      options: {
                        sort: true,
                      },
                    },
                    {
                      name: "total_clients",
                      label: "Total de Clientes",
                      options: {
                        sort: true,
                      },
                    },
                    {
                      name: "action",
                      label: "Ações",
                      options: {
                        sort: false,
                      },
                    },
                  ]}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        }
      />
    </>
  );
}
