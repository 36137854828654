import {
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../../../services/api";
import { Input } from "../../../Form/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { useToast } from "../../../../hooks/toast";
interface Category {
  id: string;
  name: string;
}

interface CategoryModalProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  setCategory: (category: Category) => void;
  defaultLink: string;
  actionType: "edit" | "create";
  defaultValue?: {
    name: string;
    id: string;
  };
  entity: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
});

export function CategoryModal({
  isOpen,
  setIsOpen,
  setCategory,
  defaultValue,
  actionType,
  defaultLink,
  entity,
}: CategoryModalProps) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (actionType === "edit" && defaultValue) {
      setValue("name", defaultValue.name);
    } else {
      reset();
    }
  }, [actionType, defaultValue, setValue, isOpen, reset]);
  const handleCreateCategory: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .post(`/${defaultLink}`, {
          name: formValue.name,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `${entity} cadastrada com sucesso`,
          });
          var category = response.data;
          setCategory({
            id: category.id,
            name: category.name,
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          toast({
            status: "error",
            title: `Não foi possível cadastrar a ${entity}`,
          });
        });
    },
    [defaultLink, toast, entity, setCategory, setIsOpen, reset]
  );

  const handleEditCategory: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .put(`/${defaultLink}/${defaultValue?.id}`, {
          name: formValue.name,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `${entity} editada com sucesso`,
          });
          var category = response.data;
          setCategory({
            id: category.id,
            name: category.name,
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          toast({
            status: "error",
            title: `Não foi possível editar a ${entity}`,
          });
        });
    },
    [
      defaultLink,
      defaultValue?.id,
      toast,
      entity,
      setCategory,
      setIsOpen,
      reset,
    ]
  );
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          reset();
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === "create" ? "Adicionar" : "Editar"} {entity}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid
              spacingX={2}
              columns={{ base: 1 }}
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={
                actionType === "edit"
                  ? handleSubmit(handleEditCategory)
                  : handleSubmit(handleCreateCategory)
              }
              id="add-form"
            >
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                {...register("name")}
                error={errors.name}
              />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() => {
                setIsOpen(false);
                reset();
              }}
            >
              Cancelar
            </Button>
            <SubmitButton
              mt={"0"}
              text={actionType === "edit" ? "Editar" : "Cadastrar"}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
