import { Flex, Icon, Text, Button, Box, Heading } from "@chakra-ui/react";
import { FaReply } from "react-icons/fa";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

const errors = [
  {
    code: "404",
    message: "Página não encontrada!",
  },
  {
    code: "403",
    message: "Acesso negado",
  },
  {
    code: "400",
    message: "Erro na requisição",
  },
  {
    code: "401",
    message: "Não autorizado",
  },
  {
    code: "429",
    message: "Excesso de requisições",
  },
  {
    code: "408",
    message: "Tempo de espera ultrapassado",
  },
  {
    code: "500",
    message: "Erro interno no servidor",
  },
  {
    code: "undefined",
    message: "Erro interno no servidor",
  },
  {
    code: "email",
    message:
      "Não foi possível validar o e-mail, entre em contato com um responsável pela B2S",
  },
  {},
];

export function Error() {
  const navigate = useNavigate();
  const { code } = useParams();

  return (
    <Box pt="25vh">
      <Flex
        flexDir="column"
        bg="white"
        minH="200px"
        maxW="500px"
        margin="auto"
        justifyContent="space-around"
        alignItems="center"
        borderRadius="10px"
        boxShadow="md"
      >
        <Flex justifyContent="center" flexDir="column" alignItems="center">
          <Heading color="blue" textAlign="center" fontSize="36">
            {code === "undefined" ? "500" : code}
          </Heading>
          <Text color="blue" fontSize="18px" textAlign="center">
            {
              errors.find((error) => (error.code === code ? true : false))
                ?.message
            }
          </Text>
        </Flex>
        <Button
          as="button"
          colorScheme="facebook"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icon as={FaReply} mr="5px" />
          Voltar
        </Button>
      </Flex>
    </Box>
  );
}
