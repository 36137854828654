import {
  Flex,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SolidButton } from "../../../components/atoms/Button/SolidButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { Card } from "../../../components/Card";
import { FormControl } from "../../../components/Form/FormControl";
import { Input } from "../../../components/Form/Input";
import { MUITable } from "../../../components/Table/MUITable";
import { ModalPrepayment } from "../../../components/templates/Modal/ModalPrepayment";
import { api } from "../../../services/api";
import { formatDateBrIndexWithOutHours } from "../../../utils/formatDate";
import { formatMoney } from "../../../utils/money";
import { MultiSelect } from "../../../components/atoms/Select/MultiSelect";
import { useAuth } from "../../../hooks/auth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../../hooks/toast";

type AntecipationType = {
  type: "date" | "balance";
  start_date: string;
  end_date: string;
  start_date_balance: string;
  balance: string;
  account_plan_subcategories_id: {
    value: string;
    label: string;
  }[];
  form_of_receipt: {
    value: string;
    label: string;
  }[];
};

interface AntecipationForm {
  type: "date" | "balance";
  start_date: string;
  end_date: string;
  start_date_balance: string;
  balance: string;
  account_plan_subcategories_id: {
    value: string;
    label: string;
  }[];
  form_of_receipt: {
    value: string;
    label: string;
  }[];
}

interface Revenue {
  id: string;
  value: number;
  account_plan_subcategory_name: string;
  date_of_receipt_of_money: Date;
  form_of_receipt: string;
}

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

const prepaymentValidation = yup.object().shape({
  type: yup.string().required("Obrigatório"),
  start_date: yup.string().when("type", {
    is: "date",
    then: yup.string().required("Obrigatório"),
  }),
  end_date: yup.string().when("type", {
    is: "date",
    then: yup.string().required("Obrigatório"),
  }),
  start_date_balance: yup.string().when("type", {
    is: "balance",
    then: yup.string().required("Obrigatório"),
  }),
  balance: yup.string().when("type", {
    is: "balance",
    then: yup.string().required("Obrigatório"),
  }),
  account_plan_subcategories_id: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, "O mínimo é 1 opção")
    .required("Obrigatório"),
  form_of_receipt: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, "O mínimo é 1 opção")
    .required("Obrigatório"),
});

export function Prepayment() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const [revenues, setRevenues] = useState<Revenue[]>([]);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm<AntecipationType>({
    resolver: yupResolver(prepaymentValidation),
    defaultValues: {
      type: "date",
      start_date: "",
      end_date: "",
      start_date_balance: "",
      balance: "",
      account_plan_subcategories_id: [],
      form_of_receipt: [],
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Receita")
      .then((response) => setAccountPlanSubcategories(response.data));
  }, []);
  const toast = useToast();

  const handleSubmitRevenues: SubmitHandler<AntecipationForm> = useCallback(
    async (formValue) => {
      api
        .get(
          `/revenues/prepayment?type=${formValue.type}&start_date=${
            formValue.start_date
          }&end_date=${formValue.end_date}&start_date_balance=${
            formValue.start_date_balance
          }&balance=${formValue.balance}&company_id=${
            user.company_id
          }&account_plan_subcategories_id=${formValue.account_plan_subcategories_id
            .map((sub) => sub.value)
            .join(",")}&form_of_receipt=${formValue.form_of_receipt
            .map((form) => form.value)
            .join(",")}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            setRevenues(response.data);
          } else {
            toast({
              title: "Nenhum recebimento encontrado",
              status: "info",
            });
          }
        })
        .catch((err) => {
          toast({
            title: "Valor recebido é inferior ao solicitado para antecipar",
            status: "error",
          });
          console.log(err);
        });
    },
    [user.company_id, toast]
  );

  const form = watch();

  return (
    <>
      <ModalPrepayment
        updated_last={form.type === "balance"}
        revenues_id={revenues.map((revenue) => revenue.id)}
        prepayment_value={
          form.type === "balance"
            ? Number(form.balance)
            : revenues.reduce((total, revenue) => total + revenue.value, 0)
        }
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      />
      <Card
        title="Antecipação"
        header={<BackButton onClick={() => navigate(-1)} />}
        border="#216ca5"
        body={
          <Stack
            as="form"
            id="list-prepayment"
            onSubmit={handleSubmit(handleSubmitRevenues)}
          >
            <FormControl name="" label="Tipo de antecipação">
              <Flex>
                <Text mx="2">Por período</Text>
                <Switch
                  size="lg"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue("type", "balance", {
                        shouldValidate: true,
                      });
                      setValue("balance", "");
                      setValue("start_date_balance", "");
                      setRevenues([]);
                    } else {
                      setValue("type", "date", {
                        shouldValidate: true,
                      });
                      setValue("end_date", "");
                      setValue("start_date", "");
                      setRevenues([]);
                    }
                  }}
                />
                <Text mx="2">Por saldo</Text>
              </Flex>
            </FormControl>
            {form.type === "date" ? (
              <>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={4}>
                  <Input
                    type="date"
                    label="Início"
                    isRequired={true}
                    error={errors.start_date}
                    {...register("start_date")}
                    validaded={form.start_date !== "" && !errors.start_date}
                  />
                  <Input
                    type="date"
                    label="Fim"
                    isRequired={true}
                    error={errors.end_date}
                    {...register("end_date")}
                    validaded={form.end_date !== "" && !errors.end_date}
                  />
                </SimpleGrid>
              </>
            ) : (
              <>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={4}>
                  <Input
                    type="date"
                    label="Início"
                    isRequired={true}
                    error={errors.start_date_balance}
                    {...register("start_date_balance")}
                    validaded={
                      form.start_date_balance !== "" &&
                      !errors.start_date_balance
                    }
                  />
                  <Input
                    label="Saldo em R$"
                    isRequired={true}
                    error={errors.balance}
                    {...register("balance")}
                    leftAddon="R$"
                    onChange={(e) => {
                      var value = e.target.value;
                      if (value.length > 2) {
                        value = value
                          .replace(/\D+/g, "")
                          .replace(/([0-9]{2})$/g, ",$1");
                        value = Number(value.replace(",", ".")).toFixed(2);
                        setValue("balance", value, {
                          shouldValidate: true,
                        });
                        e.target.value = value;
                      } else if (value === "" || value === "0.00") {
                        setValue("balance", "", {
                          shouldValidate: true,
                        });
                      } else {
                        value = (Number(value) / 100).toString();
                        value = value
                          .replace(/\D+/g, "")
                          .replace(/([0-9]{2})$/g, ",$1");
                        value = Number(value.replace(",", ".")).toString();
                        setValue("balance", value, {
                          shouldValidate: true,
                        });
                        e.target.value = value.replace(".", ",");
                      }
                    }}
                    validaded={form.balance !== "" && !errors.balance}
                  />
                </SimpleGrid>
              </>
            )}
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              width={"100%"}
              spacingX={4}
            >
              <FormControl
                name="revenues"
                label="Receitas"
                isRequired
                error={errors.account_plan_subcategories_id}
              >
                <MultiSelect
                  options={accountPlanSubcategories.map((acc) => ({
                    label: acc.name,
                    value: acc.id,
                  }))}
                  {...register("account_plan_subcategories_id")}
                  onChange={(selected) => {
                    setValue(
                      "account_plan_subcategories_id",
                      selected.map((select: any) => select),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                  value={form.account_plan_subcategories_id}
                  validaded={
                    form.account_plan_subcategories_id.length > 0 &&
                    !errors.account_plan_subcategories_id
                  }
                />
              </FormControl>
              <FormControl
                name="form_of_receipt"
                label="Forma de Recebimento"
                isRequired
                error={errors.form_of_receipt}
              >
                <MultiSelect
                  options={[
                    {
                      value: "credito",
                      label: "Cartão de crédito",
                    },
                    {
                      value: "debito",
                      label: "Cartão de débito",
                    },
                    {
                      value: "vale",
                      label: "Vale",
                    },
                    {
                      value: "online",
                      label: "Pagamento online",
                    },

                    {
                      value: "boleto",
                      label: "Boleto",
                    },
                  ]}
                  {...register("form_of_receipt")}
                  value={form.form_of_receipt}
                  onChange={(selected) => {
                    setValue(
                      "form_of_receipt",
                      selected.map((select: any) => select),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                  validaded={
                    form.form_of_receipt.length > 0 && !errors.form_of_receipt
                  }
                />
              </FormControl>
            </SimpleGrid>
          </Stack>
        }
        footer={
          <Flex alignItems={"flex-end"}>
            <SolidButton
              text="Limpar"
              bg="#fd9000"
              onClick={() => {
                setValue("balance", "");
                setValue("start_date_balance", "");
                setValue("end_date", "");
                setValue("start_date", "");
                setValue("account_plan_subcategories_id", []);
                setValue("form_of_receipt", []);
                clearErrors();
                setRevenues([]);
              }}
            />
            <SubmitButton form="list-prepayment" text="Buscar contas" />
          </Flex>
        }
      />
      {revenues.length > 0 && (
        <>
          <Card
            mt="4"
            border="#216ca5"
            title="Recebimentos"
            header={<SolidButton onClick={onOpen} text="Antecipar" />}
            footer={<SolidButton onClick={onOpen} text="Antecipar" />}
            body={
              <MUITable
                title={`Total:
                ${
                  form.type === "balance"
                    ? formatMoney(Number(form.balance))
                    : formatMoney(
                        revenues.reduce(
                          (total, revenue) => total + revenue.value,
                          0
                        )
                      )
                }`}
                columns={[
                  {
                    name: "date_of_receipt_of_money",
                    label: "Data de Recebimento",
                  },
                  {
                    name: "value",
                    label: "Valor",
                  },
                  {
                    name: "description",
                    label: "Descrições",
                  },
                  {
                    name: "form_of_receipt",
                    label: "Forma de Recebimento",
                  },
                ]}
                data={revenues.map((revenue) => [
                  formatDateBrIndexWithOutHours(
                    revenue.date_of_receipt_of_money
                  ),
                  formatMoney(revenue.value),
                  revenue.account_plan_subcategory_name,
                  revenue.form_of_receipt,
                ])}
              />
            }
          />
        </>
      )}
    </>
  );
}
