import { InternalHeader } from "../../../components/templates/InternalHeader";
import {
  FaClipboardList,
  FaCommentDots,
  FaDollarSign,
  FaHandHoldingUsd,
  FaThumbsDown,
} from "react-icons/fa";
import { Box } from "../../../components/templates/Widget/Box";
import { Flex, Image, Select, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { formatMoney } from "../../../utils/money";
import { Card } from "../../../components/Card";
import { useAuth } from "../../../hooks/auth";
import { useQuery } from "../../../hooks/query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import {
  formatDatabaseWithOutHours,
  formatDateBrIndexWithOutHours,
} from "../../../utils/formatDate";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { FileButton } from "../../../components/molecules/FileButton";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { ModalEffectiveWriteOffExpense } from "../../../components/templates/Modal/ModalEffectiveWriteOffExpense";
import { Table } from "../../../components/templates/Table";
import { checkPermission } from "../../../utils/checkPermission";
import { DateRange } from "../../../components/templates/Filters/DateRangePicker";
import { FormControl } from "../../../components/Form/FormControl";
import { calcNpsWithOutAnswers } from "../../../utils/nps";
import { NpsBox } from "../../../components/atoms/Box/NpsBox";
import { DualAxesChart } from "../../../components/templates/Chart/DualAxes";
import LogoFalae from "../../../assets/falae_logo.png";
interface IData {
  revenues: {
    total: number;
    delivery: number;
    salon: number;
    others: number;
  };
  expenses: {
    total: number;
    supplies: number;
    others: number;
  };
  total: number;
  cmv: number;
  falae?: {
    answers: {
      total: string | null;
      promoter: string | null;
      neutral: string | null;
      detractor: string | null;
      goal: string | null;
      discursive_question: {
        discursive_question: string;
        nps: string | null;
        created_at: Date;
      }[];
    };
    clients: number | null;
  };
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}
interface Expense {
  id: string;
  value: number;
  form_of_payment: string;
  effective_write_off_date?: Date;
  date_of_competence: Date;
  file_url?: string;
  file?: string;
  account_plan_subcategory: {
    name: string;
  };
  favored?: {
    name: string;
  };
}
const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

export function Home() {
  const query = useQuery();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [data, setData] = useState<IData>({
    cmv: 0,
    expenses: {
      others: 0,
      supplies: 0,
      total: 0,
    },
    revenues: {
      delivery: 0,
      others: 0,
      salon: 0,
      total: 0,
    },
    total: 0,
  } as IData);
  const [total, setTotal] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    type: query.get("filter") ?? "competence",
    date_start:
      query.get("date_start") ?? formatDatabaseWithOutHours(thirtyDaysAgo),
    date_end: query.get("date_end") ?? formatDatabaseWithOutHours(new Date()),
  });

  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });
  useEffect(() => {
    api
      .get(
        `/dashboard/home?type=${selected.type}&date_start=${selected.date_start}&date_end=${selected.date_end}`
      )
      .then((response) => setData(response.data));
  }, [selected]);

  useEffect(() => {
    const today = formatDatabaseWithOutHours(new Date());

    api
      .get(
        `expenses/pagination/company/${user.company_id}?date_start_due_date=${today}&date_end_due_date=${today}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setExpenses(response.data.expenses);
        setTotal(response.data.total);
        setTotalValue(response.data.value);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [defaultParams, navigate, user.company_id]);

  return (
    <>
      <InternalHeader
        title="Home"
        has_filter={checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        })}
        defaultOpen={checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        })}
      >
        {checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        }) ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} w="100%" spacingX={2}>
            <FormControl name="filter" label="Visualização">
              <Select
                value={selected.type}
                onChange={(e) => {
                  setSelected({
                    ...selected,
                    type: e.target.value,
                  });
                  navigate(
                    `?filter=${e.target.value}&date_start=${selected.date_start}&date_end=${selected.date_end}`
                  );
                }}
              >
                <option value={"cashier"}>Caixa</option>
                <option value={"competence"}>Competência</option>
              </Select>
            </FormControl>
            <DateRange
              showClearDates={false}
              label="Data"
              dateEnd={selected.date_end}
              dateStart={selected.date_start}
              onSelectDate={(start, end) => {
                setSelected({
                  ...selected,
                  date_start: start,
                  date_end: end,
                });
                navigate(
                  `?filter=${selected.type}&date_start=${start}&date_end=${end}`
                );
              }}
            />
          </SimpleGrid>
        ) : (
          <></>
        )}
      </InternalHeader>
      {checkPermission({
        is_admin: user.is_admin,
        type: "Manager",
      }) ? (
        <>
          <SimpleGrid mt="2" columns={{ base: 1, md: 2 }} spacingX={2}>
            <Card
              mb="4"
              title="Gestão de Compra"
              header={<></>}
              border="#2b88a1"
              body={
                <SimpleGrid spacingY={2} spacingX={2}>
                  <Box
                    bg="#ffcf59"
                    color="#000"
                    icon={FaDollarSign}
                    title="CMV"
                    value={`${data.cmv}%`}
                    border="#ffcf59"
                    footer={`Meta: ${user.company.cogs_target}%`}
                  />
                  <DualAxesChart
                    column={[
                      {
                        time: user.company.name,
                        type: "Receitas",
                        value: data.revenues.total,
                      },
                      {
                        time: user.company.name,
                        type: "Insumos",
                        value: data.expenses.supplies,
                      },
                    ]}
                    line={[
                      {
                        time: user.company.name,
                        type: "Meta CMV",
                        cogs:
                          (Number(user.company.cogs_target) *
                            data.revenues.total) /
                          100,
                      },
                    ]}
                  />
                </SimpleGrid>
              }
            />
            {data.falae ? (
              <>
                <Card
                  title="Experiência do Cliente"
                  header={<></>}
                  border="#2b88a1"
                  mb="4"
                  body={
                    <SimpleGrid columns={{ base: 1, lg: 1 }} spacingX={2}>
                      <NpsBox
                        title={`NPS`}
                        nps={calcNpsWithOutAnswers(
                          Number(data.falae.answers.promoter),
                          Number(data.falae.answers.detractor),
                          Number(data.falae.answers.total)
                        )}
                      />

                      <Box
                        bg="cyan.650"
                        color="#fff"
                        icon={FaCommentDots}
                        title="Respostas"
                        value={`${Number(data.falae.answers.total)}`}
                        border="#2b88a1"
                        footer={`Meta: ${Number(data.falae.answers.goal)}`}
                      />
                      <Box
                        bg="red.450"
                        color="#fff"
                        icon={FaThumbsDown}
                        title="Detratores"
                        value={`${Number(data.falae.answers.detractor)}`}
                        border="#C44A54"
                      />
                      <Box
                        bg="orange.450"
                        color="#fff"
                        icon={FaHandHoldingUsd}
                        title="Novos Clientes"
                        value={`${Number(data.falae.clients)}`}
                        border="#FD9000"
                      />
                    </SimpleGrid>
                  }
                />
              </>
            ) : (
              <SimpleGrid>
                <Card
                  filter={"blur(3px)"}
                  title="Experiência do Cliente"
                  header={<></>}
                  border="#2b88a1"
                  mb="4"
                  id="card-falae"
                  body={
                    <SimpleGrid columns={{ base: 1, lg: 1 }} spacingX={2}>
                      <NpsBox title={`NPS`} nps={78} />
                      <Box
                        bg="cyan.650"
                        color="#fff"
                        icon={FaCommentDots}
                        title="Respostas"
                        value={`354`}
                        border="#2b88a1"
                        footer={`Meta: 300`}
                      />
                      <Box
                        bg="red.450"
                        color="#fff"
                        icon={FaThumbsDown}
                        title="Detratores"
                        value={`11`}
                        border="#C44A54"
                      />
                      <Box
                        bg="orange.450"
                        color="#fff"
                        icon={FaHandHoldingUsd}
                        title="Novos Clientes"
                        value={`73`}
                        border="#FD9000"
                      />
                    </SimpleGrid>
                  }
                />
                <Flex
                  marginTop="-600px"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Flex
                    bg="white"
                    zIndex={10}
                    boxShadow="lg"
                    padding={"35px"}
                    borderRadius="50%"
                    border="1px solid #2b88a1"
                  >
                    <Image src={LogoFalae} width="100px" height={"100px"} />
                  </Flex>
                </Flex>
              </SimpleGrid>
            )}
          </SimpleGrid>
        </>
      ) : (
        <></>
      )}
      <Card
        mt="3"
        title="Despesas com vencimento hoje"
        header={<></>}
        border="#C44A54"
        body={
          <>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={2}>
              <Box
                bg="#C44A54"
                color="#f0f0f0"
                border="#C44A54"
                icon={FaDollarSign}
                title="Total Despesas"
                value={formatMoney(totalValue)}
              />
              <Box
                bg="#CC8956"
                color="#f0f0f0"
                border="#CC8956"
                icon={FaClipboardList}
                title="Títulos"
                value={total}
              />
            </SimpleGrid>

            <Table
              loading={loading}
              message="Buscando as contas a pagar"
              params={defaultParams}
              setDefaultParams={(params) => {
                setDefaultParams(params);
                navigate(
                  `?date_start_due_date=${formatDatabaseWithOutHours(
                    new Date()
                  )}&date_end_due_date=${formatDatabaseWithOutHours(
                    new Date()
                  )}&offset=${params.page}&limit=${
                    params.limit_per_page
                  }&search=${params.search}&order_column=${
                    params.order_column
                  }&order_type=${params.order_type}`
                );
              }}
              total={total}
              data={expenses.map((expense) => {
                return {
                  tr: [
                    {
                      name: expense.account_plan_subcategory.name,
                    },
                    {
                      name: expense.favored ? expense.favored.name : "",
                    },
                    {
                      name: expense.date_of_competence
                        ? formatDateBrIndexWithOutHours(
                            expense.date_of_competence
                          )
                        : "",
                    },
                    {
                      name: expense.effective_write_off_date ? (
                        formatDateBrIndexWithOutHours(
                          expense.effective_write_off_date
                        )
                      ) : (
                        <ButtonListButton>
                          <ModalEffectiveWriteOffExpense
                            updateExpense={(expense: Expense) => {
                              setExpenses(
                                expenses.map((e) => {
                                  if (e.id === expense.id) {
                                    return {
                                      ...e,
                                      effective_write_off_date:
                                        expense.effective_write_off_date
                                          ? new Date(
                                              String(
                                                expense.effective_write_off_date
                                              ) + "T03:00:00.000Z"
                                            )
                                          : undefined,
                                    };
                                  }

                                  return e;
                                })
                              );
                            }}
                            expenseId={expense.id}
                          />
                        </ButtonListButton>
                      ),
                    },
                    {
                      name: formatMoney(expense.value),
                    },
                    {
                      name: expense.form_of_payment,
                    },
                    {
                      name: (
                        <ButtonListButton>
                          {expense.file && (
                            <FileButton
                              expense_id={expense.id}
                              title={`${expense.account_plan_subcategory.name}${
                                expense.favored
                                  ? ` - ${expense.favored.name}`
                                  : ""
                              }`}
                            />
                          )}
                          {!expense.effective_write_off_date ? (
                            <>
                              <EditButton link={`/conta-pagar/${expense.id}`} />
                              <DeleteModal
                                onDelete={(id) => {
                                  setExpenses(
                                    expenses.filter(
                                      (expense) => expense.id !== id
                                    )
                                  );
                                }}
                                id={expense.id}
                                route="expenses/"
                                error_message="Não foi possível remover a conta a pagar"
                                success_message="Conta a pagar removida com sucesso"
                              />
                            </>
                          ) : checkPermission({
                              is_admin: user.is_admin,
                              type: "Owner",
                            }) ? (
                            <>
                              <EditButton link={`/conta-pagar/${expense.id}`} />
                              {checkPermission({
                                is_admin: user.is_admin,
                                type: "Super Admin",
                              }) ? (
                                <DeleteModal
                                  onDelete={(id) => {
                                    setExpenses(
                                      expenses.filter(
                                        (expense) => expense.id !== id
                                      )
                                    );
                                  }}
                                  id={expense.id}
                                  route="expenses/"
                                  error_message="Não foi possível remover a conta a pagar"
                                  success_message="Conta a pagar removida com sucesso"
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </ButtonListButton>
                      ),
                    },
                  ],
                };
              })}
              columns={[
                {
                  name: "accountPlanSubcategories",
                  label: "Conta",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "favored",
                  label: "Favorecido",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "date_of_competence",
                  label: "Data Emissão",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "effective_write_off_date",
                  label: "Baixa Efetiva",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "value",
                  label: "Valor",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "form_of_payment",
                  label: "Forma de Pagamento",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "action",
                  label: "Ações",
                  options: {
                    sort: false,
                  },
                },
              ]}
            />
          </>
        }
      />
    </>
  );
}
