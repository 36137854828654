import React, { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, SimpleGrid, Skeleton, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { api } from "../../../services/api";
import { useParams } from "react-router";
import { useAuth } from "../../../hooks/auth";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useToast } from "../../../hooks/toast";
import { useNavigate } from "react-router-dom";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";

const createUserFormSchema = yup.object().shape({
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "No mínimo 6 caracteres"),
  password_confirmation: yup
    .string()
    .oneOf([null, yup.ref("password")], "As senhas precisam ser iguais"),
});

export function EditUserPassword() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user: localUser } = useAuth();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {
      password: undefined,
      password_confirmation: undefined,
    },
    resolver: yupResolver(createUserFormSchema),
  });

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      reset(response.data);
    });
    setLoading(true);
  }, [id, reset, setValue]);

  const handleCreateUser: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api
          .put(`/users/password/${id}`, {
            password: formValue.password,
          })
          .then((response) => {
            navigate(-1);
            toast({
              title: "Senha editada com sucesso!",
              status: "success",
            });
          });
      } catch (err) {
        toast({
          title: "Não foi possível cadastrar o usuário",
          status: "error",
        });
      }
    },
    [id, navigate, toast]
  );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Senha"
          header={
            <>
              {localUser.is_admin <= 1 && (
                <BackButton onClick={() => navigate(-1)} />
              )}
            </>
          }
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleCreateUser)}
              id="add-form"
            >
              <Stack spacing="4">
                <SimpleGrid minChildWidth="240px" spacing={["6", "8"]} w="100%">
                  <Input
                    type="password"
                    label="Senha"
                    isRequired={true}
                    error={errors.password}
                    {...register("password")}
                  />
                  <Input
                    type="password"
                    label="Confirme a senha"
                    isRequired={true}
                    error={errors.password_confirmation}
                    {...register("password_confirmation")}
                  />
                </SimpleGrid>
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
