import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";

const createAccountPlanSubcategoryFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  account_plan_category_id: yup
    .string()
    .required("A Receita/Despesa é obrigatório"),
  priority: yup.number(),
});
interface AccountPlanCategory {
  id: string;
  name: string;
}

export function CreateAccountPlanSubcategory() {
  const navigate = useNavigate();
  const toast = useToast();
  const { register, handleSubmit, formState, setValue, getValues, watch } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(createAccountPlanSubcategoryFormSchema),
    });
  const [accountPlanCategories, setAccountPlanCategories] = useState<
    AccountPlanCategory[]
  >([]);

  const accountPlanCategoryId = watch("account_plan_category_id");

  useEffect(() => {
    api
      .get(`/accountPlanCategories`)
      .then((response) => setAccountPlanCategories(response.data));
  }, []);
  const handleCreateAccountPlanSubcategory: SubmitHandler<FieldValues> =
    useCallback(
      async (formValue) => {
        try {
          await api.post("/accountPlanSubcategories", {
            name: formValue.name,
            account_plan_category_id: formValue.account_plan_category_id,
            priority: formValue.priority,
          });
          navigate("/receitas/despesas");
          toast({
            title: "Receita/Despesa cadastrada com sucesso!",
            status: "success",
          });
        } catch (err) {
          toast({
            title: "Não foi possível cadastrar a Receita/Despesa",
            status: "error",
          });
        }
      },
      [navigate, toast]
    );

  const { errors } = formState;

  return (
    <>
      <Card
        border="#216ca5"
        title="Cadastrar Receita/Despesa"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateAccountPlanSubcategory)}
            id="add-form"
          >
            <Stack spacing="4">
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                error={errors.name}
                {...register("name")}
              />
              <MultiSelectControl
                options={accountPlanCategories.map((acc) => ({
                  label: acc.name,
                  value: acc.id,
                }))}
                {...register("account_plan_category_id")}
                onChange={(selected) => {
                  setValue("account_plan_category_id", selected.value, {
                    shouldValidate: true,
                  });
                }}
                value={{
                  label: accountPlanCategories.find(
                    (acc) => acc.id === accountPlanCategoryId
                  )?.name,
                  value: accountPlanCategoryId,
                }}
                validaded={
                  getValues("account_plan_category_id") &&
                  getValues("account_plan_category_id") !== "" &&
                  !errors.account_plan_category_id
                }
                isMulti={false}
                label="Conta"
                error={errors.account_plan_category_id}
                isRequired={true}
              />
              <Input
                type="number"
                label="Prioridade"
                error={errors.priority}
                {...register("priority")}
              />
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
