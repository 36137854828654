import { useEffect, useState } from "react";

import { Card } from "../../../../components/Card";
import { api } from "../../../../services/api";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../../components/organisms/ButtonListBox";
import { useQuery } from "../../../../hooks/query";
import { useNavigate } from "react-router-dom";
import { Table } from "../../../../components/templates/Table";
import { DeleteModal } from "../../../../components/templates/DeleteModal";
import { InternalHeader } from "../../../../components/templates/InternalHeader";
import { CategoryModal } from "../../../../components/templates/Modal/CategoryModal";
import { EditModalButton } from "../../../../components/molecules/TableListButton/EditModalButton";
import { AddButtonWithTextModal } from "../../../../components/molecules/AddButtonWithTextModal";

interface MerchandiseCategory {
  id: string;
  name: string;
}
interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListMerchandiseCategories() {
  const query = useQuery();
  const navigate = useNavigate();
  const [merchandiseCategories, setMerchandiseCategories] = useState<
    MerchandiseCategory[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState<"create" | "edit">("create");
  const [defaultValue, setDefaultValue] = useState<MerchandiseCategory>(
    {} as MerchandiseCategory
  );
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  useEffect(() => {
    api
      .get(
        `pricing/merchandiseCategories/pagination?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setMerchandiseCategories(response.data.merchandiseCategories);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate, defaultParams]);

  return (
    <>
      <InternalHeader
        title="Categorias da Mercadoria"
        has_back_button={true}
        has_filter={false}
      >
        <AddButtonWithTextModal
          onClick={() => {
            setIsOpen(true);
            setActionType("create");
            setDefaultValue({
              id: "",
              name: "",
            });
          }}
        />
      </InternalHeader>
      <CategoryModal
        entity="Categorias da Mercadoria"
        isOpen={isOpen}
        setIsOpen={(value) => setIsOpen(value)}
        actionType={actionType}
        defaultValue={defaultValue}
        defaultLink="pricing/merchandiseCategories"
        setCategory={(merchandiseCategory) => {
          if (actionType === "create") {
            setMerchandiseCategories([
              ...merchandiseCategories,
              merchandiseCategory,
            ]);
          } else {
            setMerchandiseCategories(
              merchandiseCategories.map((c) => {
                if (c.id === merchandiseCategory.id) {
                  return merchandiseCategory;
                }
                return c;
              })
            );
          }
        }}
      />
      <Card
        body={
          <Table
            loading={loading}
            message="Buscando as categorias"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/precificacao/categoria-mercadoria?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={merchandiseCategories.map((merchandiseCategory) => {
              return {
                tr: [
                  {
                    name: merchandiseCategory.name,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditModalButton
                          onClick={() => {
                            setActionType("edit");
                            setIsOpen(true);
                            setDefaultValue({
                              id: merchandiseCategory.id,
                              name: merchandiseCategory.name,
                            });
                          }}
                        />

                        <DeleteModal
                          onDelete={(id) => {
                            setMerchandiseCategories(
                              merchandiseCategories.filter(
                                (merchandiseCategory) =>
                                  merchandiseCategory.id !== id
                              )
                            );
                          }}
                          id={merchandiseCategory.id}
                          route="pricing/merchandiseCategories/"
                          error_message="Não foi possível remover a categoria da mercadoria"
                          success_message="Categoria da mercadoria removida com sucesso"
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
