import { Text } from "@chakra-ui/react";

interface SidebarTextProps {
  color?: string;
  text: string;
}

export function SidebarText({ color = "black", text }: SidebarTextProps) {
  return (
    <Text ml="4" fontWeight="thin" color={color}>
      {text}
    </Text>
  );
}
