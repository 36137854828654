interface ICheckPermissions {
  is_admin: number;
  type: "Super Admin" | "Admin" | "Owner" | "Manager";
}

export function checkPermission({
  is_admin,
  type,
}: ICheckPermissions): boolean {
  switch (type) {
    case "Super Admin":
      return is_admin === 0;
    case "Admin":
      return is_admin <= 1;
    case "Owner":
      return is_admin <= 3;
    case "Manager":
      return is_admin <= 4;
    default:
      return false
  }
}
