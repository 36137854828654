import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";

interface AccountPlanCategory {
  id: string;
  name: string;
}

const editAccountPlanSubcategoryFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  account_plan_category_id: yup
    .string()
    .required("A conta do plano de conta é obrigatório"),
    priority: yup.number(),
});

export function EditAccountPlanSubcategory() {
  const navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [accountPlanCategories, setAccountPlanCategories] = useState<
    AccountPlanCategory[]
  >([]);

  const { register, handleSubmit, formState, reset, setValue, getValues } =
    useForm({
      resolver: yupResolver(editAccountPlanSubcategoryFormSchema),
    });

  const accountPlanCategoryId = getValues("account_plan_category_id");

  useEffect(() => {
    api.get(`/accountPlanSubcategories/${id}`).then((response) => {
      reset(response.data);
    });
    api
      .get(`/accountPlanCategories`)
      .then((response) => setAccountPlanCategories(response.data));
    setLoading(true);
  }, [id, reset]);

  const handleEditAccountPlanSubcategory: SubmitHandler<FieldValues> =
    useCallback(
      async (formValue) => {
        try {
          await api.put(`/accountPlanSubcategories/${id}`, {
            name: formValue.name,
            account_plan_category_id: formValue.account_plan_category_id,
            priority: formValue.priority,
          });
          navigate(-1);
          toast({
            title: "Receita/Despesa editada com sucesso!",
            status: "success",
          });
        } catch (err) {
          toast({
            title: "Não foi possível editar a conta do Receita/Despesa",
            status: "error",
          });
        }
      },
      [id, navigate, toast]
    );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Receita/Despesa"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditAccountPlanSubcategory)}
              id="add-form"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                  validaded={getValues("name") && !errors.name}
                />
                <MultiSelectControl
                  options={accountPlanCategories.map((acc) => ({
                    label: acc.name,
                    value: acc.id,
                  }))}
                  {...register("account_plan_category_id")}
                  onChange={(selected) => {
                    setValue("account_plan_category_id", selected.value, {
                      shouldValidate: true,
                    });
                  }}
                  value={{
                    label: accountPlanCategories.find(
                      (acc) => acc.id === accountPlanCategoryId
                    )?.name,
                    value: accountPlanCategoryId,
                  }}
                  validaded={
                    getValues("account_plan_category_id") &&
                    getValues("account_plan_category_id") !== "" &&
                    !errors.account_plan_category_id
                  }
                  isMulti={false}
                  label="Conta"
                  error={errors.account_plan_category_id}
                  isRequired={true}
                />
                <Input
                  type="number"
                  label="Prioridade"
                  error={errors.priority}
                  {...register("priority")}
                />
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
