import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../../../services/api";
import { Input } from "../../../Form/Input";
import { ModalConfirmAction } from "../../../organisms/Modal/ModalConfirmAction";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../../../hooks/toast";
import { useAuth } from "../../../../hooks/auth";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../../../utils/money";

type PrepaymentType = {
  prepayment_date: string;
  tax: number;
  date_of_competence: string;
};

interface PrepaymentForm {
  prepayment_date: string;
  tax: number;
  date_of_competence: string;
}

interface ModalPrepaymentProps {
  isOpen: boolean;
  onClose: () => void;
  prepayment_value: number;
  revenues_id: string[];
  updated_last: boolean;
}

export function ModalPrepayment({
  isOpen,
  onClose,
  prepayment_value,
  revenues_id,
  updated_last,
}: ModalPrepaymentProps) {
  const { user } = useAuth();
  const prepaymentValidation = useMemo(() => {
    return yup.object().shape({
      tax: yup
        .number()
        .typeError("Valor inválido")
        .required("Obrigatório")
        .min(0, "Valor da taxa precisa ser maior ou igual a zero")
        .max(prepayment_value, "Valor da taxa precisa ser menor que a quantia antecipada"),

      prepayment_date: yup
        .string()
        .required("Obrigatório")
        .min(10, "Valor inválido"),
      date_of_competence: yup
        .string()
        .required("Obrigatório")
        .min(10, "Valor inválido"),
    });
  }, [prepayment_value]);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    trigger,
    reset,
  } = useForm<PrepaymentType>({
    mode: "onChange",
    defaultValues: {
      prepayment_date: "",
      tax: 0,
      date_of_competence: "",
    },
    resolver: yupResolver(prepaymentValidation),
  });
  const toast = useToast();
  const navigate = useNavigate();

  const form = watch();

  const handleSubmitPrepayment: SubmitHandler<PrepaymentForm> = useCallback(
    async (formValue) => {
      var value_tax = Number(formValue.tax);

      api
        .put(`/revenues/prepayment/${user.company_id}`, {
          prepayment_value: prepayment_value,
          prepayment_date: formValue.prepayment_date,
          prepayment_tax: value_tax,
          revenues_id: revenues_id,
          date_of_competence: formValue.date_of_competence,
          updated_last,
        })
        .then((response) => {
          onClose();
          reset();
          toast({
            title: "Antecipação realizada com sucesso",
            status: "success",
          });
          navigate("/contas-receber");
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: "Não foi possível realizar a antecipação",
            status: "error",
          });
        });
    },
    [
      prepayment_value,
      revenues_id,
      toast,
      user.company_id,
      updated_last,
      onClose,
      reset,
      navigate,
    ]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          reset();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Antecipação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              as="form"
              id="prepayment-info"
              onSubmit={handleSubmit(handleSubmitPrepayment)}
            >
              <Input
                type="date"
                label="Data de Competência"
                error={errors.date_of_competence}
                isRequired={true}
                {...register("date_of_competence")}
                validaded={
                  form.date_of_competence !== "" && !errors.date_of_competence
                }
              />
              <Input
                type="date"
                label="Data Recebimento da Antecipação"
                error={errors.prepayment_date}
                isRequired={true}
                {...register("prepayment_date")}
                validaded={
                  form.prepayment_date !== "" && !errors.prepayment_date
                }
              />

              <Input
                label="Taxa de Antecipação (R$)"
                isRequired={true}
                {...register("tax")}
                leftAddon="R$"
                error={errors.tax}
                onChange={(e) => {
                  var value = e.target.value;
                  if (value.length > 2) {
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    value = Number(value.replace(",", ".")).toFixed(2);
                    setValue("tax", Number(value), {
                      shouldValidate: true,
                    });
                    e.target.value = value;
                  } else if (value === "" || value === "0.00") {
                    setValue("tax", 0, {
                      shouldValidate: true,
                    });
                  } else {
                    value = (Number(value) / 100).toString();
                    value = value
                      .replace(/\D+/g, "")
                      .replace(/([0-9]{2})$/g, ",$1");
                    value = Number(value.replace(",", ".")).toString();
                    setValue("tax", Number(value), {
                      shouldValidate: true,
                    });
                    e.target.value = value.replace(".", ",");
                  }
                }}
                validaded={form.tax !== 0 && !errors.tax}
              />
              <Flex
                flexDirection={"column"}
                bg="#f0f0f0"
                p="4"
                borderRadius="8"
              >
                <Text>
                  Valor Antecipado:
                  <b> {formatMoney(prepayment_value)}</b>
                </Text>
                <Text>
                  Taxa de Antecipação: <b>{formatMoney(Number(form.tax))}</b>
                </Text>
              </Flex>
              <input type="submit" id="prepayment-info-button" hidden />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ModalConfirmAction
              textButton="Antecipar"
              textBody={`O valor antecipado é ${formatMoney(
                prepayment_value
              )} e a taxa de antecipação é ${formatMoney(
                Number(form.tax)
              )}. A data de recebimento da antecipação é o dia ${form.prepayment_date
                .split("-")
                .reverse()
                .join(
                  "/"
                )}. Será gerada automaticamente uma despesa referente a taxa paga pela antecipação.`}
              validate={async () => {
                return await trigger(
                  ["date_of_competence", "prepayment_date", "tax"],
                  {
                    shouldFocus: true,
                  }
                );
              }}
              callbackOnCancel={() => {}}
              callbackOnConfirm={() => {
                const submitButton = document.getElementById(
                  "prepayment-info-button"
                ) as HTMLButtonElement;

                if (submitButton) {
                  submitButton.click();
                }
              }}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
