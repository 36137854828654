import { FaKey } from "react-icons/fa";
import { TableListButton } from "../../atoms/Button/TableListButton";

interface KeyButtonProps {
  link: string;
}

export function KeyButton({ link }: KeyButtonProps) {
  return (
    <TableListButton
      color="black"
      link={link}
      bg="#ECC94B"
      icon={FaKey}
      label="Editar Senha"
    />
  );
}
