import { Icon, Td, Tr } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { formatMoney } from "../../../../utils/money";
import { FaMinus, FaPlus } from "react-icons/fa";

interface ClassificationProps {
  first: number;
  second: number;
  third: number;
  fourth: number;
  fifth: number;
  sixth: number;
  seventh: number;
  eighth: number;
  ninth: number;
  tenth: number;
  eleventh: number;
  twelfth: number;
}

interface Month {
  first_date?: string;
  second_date?: string;
  third_date?: string;
  fourth_date?: string;
  fifth_date?: string;
  sixth_date?: string;
  seventh_date?: string;
  eighth_date?: string;
  ninth_date?: string;
  eleventh_date: string;
  tenth_date: string;
  twelfth_date: string;
}

interface DRETbodyHeaderProps {
  title: string;
  classification: ClassificationProps;
  revenues_operational: ClassificationProps;
  months: Month;
  format: "money" | "percentage";
  period: string;
  color?: "blue" | "black";
  disabled?: boolean;
  setDisabled: () => void;
}

export function DRETbodyHeader({
  classification,
  title,
  months,
  format,
  revenues_operational,
  period,
  color = "black",
  disabled,
  setDisabled,
}: DRETbodyHeaderProps) {
  const formatType = useMemo(
    () => (limit: number, value?: number) => {
      if (format === "money") {
        return formatMoney(value);
      } else {
        if (limit > 0 && value) {
          return `${((100 * value) / limit).toFixed(2).replace(".", ",")}%`;
        }
        return `0.00%`;
      }
    },
    [format]
  );

  const formatWithHeader = useMemo(
    () => (data: ClassificationProps) => {
      return [
        ...(months.first_date
          ? [formatType(revenues_operational.first, data.first)]
          : []),
        ...(months.second_date
          ? [formatType(revenues_operational.second, data.second)]
          : []),
        ...(months.third_date
          ? [formatType(revenues_operational.third, data.third)]
          : []),
        ...(months.fourth_date
          ? [formatType(revenues_operational.fourth, data.fourth)]
          : []),
        ...(months.fifth_date
          ? [formatType(revenues_operational.fifth, data.fifth)]
          : []),
        ...(period === "Anual"
          ? [formatType(revenues_operational.sixth, data.sixth)]
          : []),
        ...(months.seventh_date
          ? [formatType(revenues_operational.seventh, data.seventh)]
          : []),
        ...(months.eighth_date
          ? [formatType(revenues_operational.eighth, data.eighth)]
          : []),
        ...(period !== "Trimestral" && period !== "Mensal"
          ? [formatType(revenues_operational.ninth, data.ninth)]
          : []),
        ...(months.tenth_date
          ? [formatType(revenues_operational.tenth, data.tenth)]
          : []),
        ...(period !== "Mensal"
          ? [formatType(revenues_operational.eleventh, data.eleventh)]
          : []),
        formatType(revenues_operational.twelfth, data.twelfth),
      ];
    },
    [
      revenues_operational.eighth,
      revenues_operational.eleventh,
      revenues_operational.fifth,
      revenues_operational.first,
      revenues_operational.fourth,
      revenues_operational.ninth,
      revenues_operational.second,
      revenues_operational.seventh,
      revenues_operational.sixth,
      revenues_operational.tenth,
      revenues_operational.third,
      revenues_operational.twelfth,
      formatType,
      months.eighth_date,
      months.fifth_date,
      months.first_date,
      months.fourth_date,
      months.second_date,
      months.seventh_date,
      months.tenth_date,
      months.third_date,
      period,
    ]
  );

  const handleCollapse = useCallback(() => {
    if (color === "black") {
      setDisabled();
    }
  }, [color, setDisabled]);

  return (
    <Tr bg={color === "black" ? "gray.200" : "gray.100"}>
      <Td onClick={handleCollapse}>
        {color === "black" ? (
          <Icon fontWeight="normal" as={disabled ? FaPlus : FaMinus} />
        ) : (
          <></>
        )}
      </Td>
      <Td fontWeight={"bold"} color={color}>
        {title}
      </Td>
      {formatWithHeader(classification).map((value) => (
        <Td fontWeight={"bold"} color={color}>
          {value}
        </Td>
      ))}
    </Tr>
  );
}
