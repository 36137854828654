import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../../hooks/auth";
import { api } from "../../../../services/api";
import { checkPermission } from "../../../../utils/checkPermission";
import { formatDateBrIndexWithOutHours } from "../../../../utils/formatDate";
import { formatMoney } from "../../../../utils/money";
import { EditButton } from "../../../molecules/EditButton";
import { FileButton } from "../../../molecules/FileButton";
import { ButtonListButton } from "../../../organisms/ButtonListBox";
import { DeleteModal } from "../../../templates/DeleteModal";
import { ModalEffectiveWriteOffExpense } from "../../../templates/Modal/ModalEffectiveWriteOffExpense";
import { Table } from "../../../templates/Table";

interface Detail {
  id: string;
  value: number;
  form_of_payment: string;
  form_of_receipt: string;
  effective_write_off_date?: Date;
  date_of_competence: Date;
  due_date: Date;
  date_of_receipt_of_money: string;
  file_url?: string;
  file?: string;
  account_plan_subcategory: {
    name: string;
  };
  favored?: {
    name: string;
  };
}
interface ClassificationProps {
  first: number;
  second: number;
  third: number;
  fourth: number;
  fifth: number;
  sixth: number;
  seventh: number;
  eighth: number;
  ninth: number;
  tenth: number;
  eleventh: number;
  twelfth: number;
}

interface Month {
  first_date?: string;
  second_date?: string;
  third_date?: string;
  fourth_date?: string;
  fifth_date?: string;
  sixth_date?: string;
  seventh_date?: string;
  eighth_date?: string;
  ninth_date?: string;
  eleventh_date: string;
  tenth_date: string;
  twelfth_date: string;
}

interface DataProps {
  id: string;
  name: string;
  first?: number;
  second?: number;
  third?: number;
  fourth?: number;
  fifth?: number;
  sixth?: number;
  seventh?: number;
  eighth?: number;
  ninth?: number;
  tenth: number;
  eleventh: number;
  twelfth: number;
}

interface DRETbodyCollapseProps {
  data: DataProps[];
  revenues_operational: ClassificationProps;
  months: Month;
  format: "money" | "percentage";
  period: string;
  disabled: boolean;
  entity: "revenue" | "expense";
  type: "cashier" | "competence";
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

interface ISelect {
  account_plan_category_id: string;
  month: string;
  year: string;
  account_plan_category_name: string;
  value: string;
}

export function DRETbodyCollapse({
  data,
  months,
  format,
  revenues_operational,
  period,
  disabled,
  type,
  entity,
}: DRETbodyCollapseProps) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState<number>(0);

  const [selected, setSelected] = useState<ISelect>({
    account_plan_category_id: "",
    month: "",
    year: "",
    account_plan_category_name: "",
    value: "",
  });
  const [value, setValue] = useState<number>(0);

  const [details, setDetails] = useState<Detail[]>([]);

  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: 10,
    page: 1,
    search: "",
    order_column: "",
    order_type: "",
  });

  const formatType = useMemo(
    () => (limit: number, value?: number) => {
      if (format === "money") {
        return formatMoney(value);
      } else {
        if (limit > 0 && value) {
          return `${((100 * value) / limit).toFixed(2).replace(".", ",")}%`;
        }
        return `0.00%`;
      }
    },
    [format]
  );

  useEffect(() => {
    if (selected.account_plan_category_id) {
      if (entity === "expense") {
        api
          .get(
            `/expenses/month/${user.company_id}?type=${type}&account_plan_category_id=${selected.account_plan_category_id}&month=${selected.month}&year=${selected.year}&limit=${defaultParams.limit_per_page}&offset=${defaultParams.page}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}&search=${defaultParams.search}`
          )
          .then((response) => {
            setDetails(response.data.expenses);
            setTotal(response.data.total);
            setValue(response.data.value);
            setLoading(true);
          })
          .catch((err) => {});
      } else {
        api
          .get(
            `/revenues/month/${user.company_id}?type=${type}&account_plan_category_id=${selected.account_plan_category_id}&month=${selected.month}&year=${selected.year}&limit=${defaultParams.limit_per_page}&offset=${defaultParams.page}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}&search=${defaultParams.search}`
          )
          .then((response) => {
            setDetails(response.data.revenues);
            setTotal(response.data.total);
            setValue(response.data.value);
            setLoading(true);
          })
          .catch((err) => {});
      }
    }
  }, [entity, user, onOpen, defaultParams, selected, type]);

  const formatWithoutHeader = useMemo(
    () => (data: DataProps) => {
      return [
        data.name,
        ...(months.first_date
          ? [formatType(revenues_operational.first, data.first)]
          : []),
        ...(months.second_date
          ? [formatType(revenues_operational.second, data.second)]
          : []),
        ...(months.third_date
          ? [formatType(revenues_operational.third, data.third)]
          : []),
        ...(months.fourth_date
          ? [formatType(revenues_operational.fourth, data.fourth)]
          : []),
        ...(months.fifth_date
          ? [formatType(revenues_operational.fifth, data.fifth)]
          : []),
        ...(period === "Anual"
          ? [formatType(revenues_operational.sixth, data.sixth)]
          : []),
        ...(months.seventh_date
          ? [formatType(revenues_operational.seventh, data.seventh)]
          : []),
        ...(months.eighth_date
          ? [formatType(revenues_operational.eighth, data.eighth)]
          : []),
        ...(period !== "Trimestral" && period !== "Mensal"
          ? [formatType(revenues_operational.ninth, data.ninth)]
          : []),
        ...(months.tenth_date
          ? [formatType(revenues_operational.tenth, data.tenth)]
          : []),
        ...(period !== "Mensal"
          ? [formatType(revenues_operational.eleventh, data.eleventh)]
          : []),
        `${formatType(revenues_operational.twelfth, data.twelfth)}`,
      ];
    },
    [
      revenues_operational.eighth,
      revenues_operational.eleventh,
      revenues_operational.fifth,
      revenues_operational.first,
      revenues_operational.fourth,
      revenues_operational.ninth,
      revenues_operational.second,
      revenues_operational.seventh,
      revenues_operational.sixth,
      revenues_operational.tenth,
      revenues_operational.third,
      revenues_operational.twelfth,
      formatType,
      months.eighth_date,
      months.fifth_date,
      months.first_date,
      months.fourth_date,
      months.second_date,
      months.seventh_date,
      months.tenth_date,
      months.third_date,
      period,
    ]
  );

  return data && !disabled ? (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selected.account_plan_category_name} - {selected.month}/
            {selected.year}
            {defaultParams.search !== ""
              ? `- ${defaultParams.search}`
              : ""} - {formatMoney(value)}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Table
              loading={loading}
              message="Buscando as contas"
              params={defaultParams}
              setDefaultParams={(params) => {
                setDefaultParams(params);
              }}
              total={total}
              data={details.map((detail) => {
                if (entity === "expense") {
                  return {
                    tr: [
                      {
                        name: detail.account_plan_subcategory.name,
                      },
                      {
                        name: detail.favored ? detail.favored.name : "",
                      },
                      {
                        name: detail.date_of_competence
                          ? formatDateBrIndexWithOutHours(
                              detail.date_of_competence
                            )
                          : "",
                      },
                      {
                        name: detail.due_date
                          ? formatDateBrIndexWithOutHours(detail.due_date)
                          : "",
                      },
                      {
                        name: detail.effective_write_off_date ? (
                          formatDateBrIndexWithOutHours(
                            detail.effective_write_off_date
                          )
                        ) : (
                          <ButtonListButton>
                            {checkPermission({
                              is_admin: user.is_admin,
                              type: "Manager",
                            }) ? (
                              <ModalEffectiveWriteOffExpense
                                updateExpense={(expense) => {
                                  setDetails(
                                    details.map((e) => {
                                      if (e.id === expense.id) {
                                        return {
                                          ...e,
                                          effective_write_off_date:
                                            expense.effective_write_off_date
                                              ? new Date(
                                                  String(
                                                    expense.effective_write_off_date
                                                  ) + "T03:00:00.000Z"
                                                )
                                              : undefined,
                                        };
                                      }

                                      return e;
                                    })
                                  );
                                }}
                                expenseId={detail.id}
                              />
                            ) : (
                              <></>
                            )}
                          </ButtonListButton>
                        ),
                      },
                      {
                        name: formatMoney(detail.value),
                      },
                      {
                        name: detail.form_of_payment,
                      },
                      {
                        name: (
                          <ButtonListButton>
                            {detail.file && (
                              <FileButton
                                title={`${
                                  detail.account_plan_subcategory.name
                                }${
                                  detail.favored
                                    ? ` - ${detail.favored.name}`
                                    : ""
                                }`}
                                expense_id={detail.id}
                              />
                            )}
                            {!detail.effective_write_off_date ? (
                              <>
                                <EditButton
                                  link={`/conta-pagar/${detail.id}`}
                                />
                                <DeleteModal
                                  onDelete={(id) => {
                                    setDetails(
                                      details.filter(
                                        (expense) => expense.id !== id
                                      )
                                    );
                                  }}
                                  id={detail.id}
                                  route="expenses/"
                                  error_message="Não foi possível remover a conta a pagar"
                                  success_message="Conta a pagar removida com sucesso"
                                />
                              </>
                            ) : checkPermission({
                                is_admin: user.is_admin,
                                type: "Super Admin",
                              }) ? (
                              <>
                                <EditButton
                                  link={`/conta-pagar/${detail.id}`}
                                />
                                <DeleteModal
                                  onDelete={(id) => {
                                    setDetails(
                                      details.filter(
                                        (expense) => expense.id !== id
                                      )
                                    );
                                  }}
                                  id={detail.id}
                                  route="expenses/"
                                  error_message="Não foi possível remover a conta a pagar"
                                  success_message="Conta a pagar removida com sucesso"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </ButtonListButton>
                        ),
                      },
                    ],
                  };
                } else {
                  return {
                    tr: [
                      {
                        name: detail.account_plan_subcategory.name,
                      },

                      {
                        name: detail.date_of_competence
                          ? formatDateBrIndexWithOutHours(
                              detail.date_of_competence
                            )
                          : "",
                      },

                      {
                        name: detail.date_of_receipt_of_money ? (
                          formatDateBrIndexWithOutHours(
                            detail.date_of_receipt_of_money
                          )
                        ) : (
                          <></>
                        ),
                      },
                      {
                        name: formatMoney(detail.value),
                      },
                      {
                        name: detail.form_of_receipt,
                      },
                      {
                        name: (
                          <ButtonListButton>
                            <EditButton link={`/conta-receber/${detail.id}`} />
                            <DeleteModal
                              onDelete={(id) => {
                                setDetails(
                                  details.filter((datail) => datail.id !== id)
                                );
                              }}
                              id={detail.id}
                              route="revenues/"
                              error_message="Não foi possível remover a conta a pagar"
                              success_message="Conta a receber removida com sucesso"
                            />
                          </ButtonListButton>
                        ),
                      },
                    ],
                  };
                }
              })}
              columns={
                entity === "expense"
                  ? [
                      {
                        name: "accountPlanSubcategories",
                        label: "Conta",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "favored",
                        label: "Favorecido",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "date_of_competence",
                        label: "Data Emissão",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "due_date",
                        label: "Data de Vencimento",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "effective_write_off_date",
                        label: "Baixa Efetiva",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "value",
                        label: "Valor",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "form_of_payment",
                        label: "Forma de Pagamento",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "action",
                        label: "Ações",
                        options: {
                          sort: false,
                        },
                      },
                    ]
                  : [
                      {
                        name: "accountPlanSubcategories",
                        label: "Conta",
                        options: {
                          sort: true,
                        },
                      },

                      {
                        name: "date_of_competence",
                        label: "Data Emissão",
                        options: {
                          sort: true,
                        },
                      },

                      {
                        name: "date_of_receipt_of_money",
                        label: "Data de Recebimento",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "value",
                        label: "Valor",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "form_of_receipt",
                        label: "Forma de Recebimento",
                        options: {
                          sort: true,
                        },
                      },
                      {
                        name: "action",
                        label: "Ações",
                        options: {
                          sort: false,
                        },
                      },
                    ]
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {data.map((row, indexRow) => (
        <Tr>
          <Td fontSize="12" py={1}></Td>
          {formatWithoutHeader(row).map((value, indexValue) => (
            <Td
              _hover={{
                cursor: indexValue > 0 ? "pointer" : "default",
              }}
              onClick={() => {
                if (indexValue > 0) {
                  const year =
                    Object.entries(months)[indexValue][1].split("-")[0];
                  const month =
                    Object.entries(months)[indexValue][1].split("-")[1];
                  const account_plan_category_id = data[indexRow].id;
                  const account_plan_category_name = data[indexRow].name;
                  onOpen();
                  setLoading(false);
                  setDefaultParams({
                    ...defaultParams,
                    search: "",
                  });
                  setSelected({
                    year,
                    month,
                    account_plan_category_id,
                    account_plan_category_name,
                    value,
                  });
                }
              }}
              fontSize="12"
              py={1}
            >
              {value}
            </Td>
          ))}
        </Tr>
      ))}
    </>
  ) : (
    <></>
  );
}
