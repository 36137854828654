import React, { useCallback } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Stack, Switch } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { FormControl } from "../../../components/Form/FormControl";

const createAccountPlanCategoryFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  type: yup.string().required("O tipo é obrigatório"),
  subtype: yup.string().required("O subtipo é obrigatório"),
  is_projected: yup.boolean().nullable(),
});

export function CreateAccountPlanCategory() {
  const navigate = useNavigate();
  const toast = useToast();
  const { register, handleSubmit, formState, control, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(createAccountPlanCategoryFormSchema),
  });

  const watchType = watch("type");
  const recipesSubtypes = [
    {
      id: "Operacional",
      name: "Operacional",
    },
    {
      id: "Não operacional",
      name: "Não operacional",
    },
  ];

  const expensesSubtypes = [
    {
      id: "Operacional",
      name: "Operacional",
    },
    {
      id: "Não operacional",
      name: "Não operacional",
    },
    {
      id: "Sobre receita",
      name: "Sobre receita",
    },
  ];
  const handleCreateAccountPlanCategory: SubmitHandler<FieldValues> =
    useCallback(
      async (formValue) => {
        try {
          await api.post("/accountPlanCategories", {
            name: formValue.name,
            type: formValue.type,
            subtype: formValue.subtype,
            is_projected: formValue.is_projected,
          });
          navigate("/contas");
          toast({
            title: "Conta de conta cadastrado com sucesso!",
            status: "success",
          });
        } catch (err) {
          toast({
            title: "Não foi possível cadastrar a conta",
            status: "error",
          });
        }
      },
      [navigate, toast]
    );

  const { errors } = formState;

  return (
    <>
      <Card
        border="#216ca5"
        title="Cadastrar Conta"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateAccountPlanCategory)}
            id="add-form"
          >
            <Stack spacing="4">
              <Input
                type="text"
                autoFocus
                label="Nome"
                isRequired={true}
                error={errors.name}
                {...register("name")}
              />
              <SelectControl
                control={control}
                label="Tipo"
                isRequired={true}
                error={errors.type}
                {...register("type")}
                options={[
                  {
                    id: "Receita",
                    name: "Receita",
                  },
                  {
                    id: "Despesa",
                    name: "Despesa",
                  },
                ]}
              />
              {(watchType === "Receita" || watchType === "Despesa") && (
                <SelectControl
                  control={control}
                  label="Subtipo"
                  isRequired={true}
                  error={errors.subtype}
                  {...register("subtype")}
                  options={
                    watchType === "Receita" ? recipesSubtypes : expensesSubtypes
                  }
                />
              )}
              {watchType === "Despesa" && (
                <FormControl name="is_projected" label="É projetada?">
                  <Switch size="lg" {...register("is_projected")} />
                </FormControl>
              )}
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
