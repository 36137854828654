import { useCallback, useEffect, useMemo, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Box, Flex } from "@chakra-ui/react";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";
import {
  dateDatabaseToIndex,
  formatDatabaseWithOutHours,
} from "../../../utils/formatDate";
import { MUITable } from "../../../components/Table/MUITable";
import { formatMoney } from "../../../utils/money";
import { RevenueForm } from "../../../components/templates/Forms/RevenueForm";
import { SubmitButtonAndUpdateStatus } from "../../../components/atoms/Button/SubmitButtonAndUpdateStatus";

const createRevenueFormSchema = yup.object().shape({
  observation: yup.string().notRequired(),
  value: yup.string().required("Valor é obrigatório"),
  form_of_receipt: yup.string().required("Forma de recebimento é obrigatória"),
  account_plan_subcategory_id: yup.object().required("Receita é obrigatória"),
  date_of_competence: yup
    .string()
    .required("Data de competência é obrigatória"),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface ILiquidityOfPayment {
  money: number;
  debit: number;
  credit: number;
  voucher: number;
  online_payment: number;
  pix: number;
  subscribed: number;
  courtesy: number;
  bank_slip: number;
}
interface IRevenues {
  name: string;
  account_plan_subcategory_id: string;
  date_of_competence: Date;
  value: string;
  form_of_receipt: string;
}

export function CreateRevenue() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [liquidityOfPayment, setLiquidityOfPayment] =
    useState<ILiquidityOfPayment>({} as ILiquidityOfPayment);
  const [reload, setReload] = useState(false);
  const [revenues, setRevenues] = useState<IRevenues[]>([]);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    getValues,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createRevenueFormSchema),
  });
  watch();
  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Receita")
      .then((response) => setAccountPlanSubcategories(response.data));

    api
      .get<ILiquidityOfPayment>(
        `/liquidityOfPayments/company/${user.company_id}`
      )
      .then((response) => {
        setLiquidityOfPayment(response.data);
      });
  }, [user.company_id]);
  const handleCreateRevenue: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/revenues", {
          observation: formValue.observation,
          date_of_competence: formValue.date_of_competence,
          date_of_receipt_of_money: formValue.date_of_receipt_of_money,
          form_of_receipt: formValue.form_of_receipt,
          account_plan_subcategory_id:
            formValue.account_plan_subcategory_id.value,
          value: formValue.value,
          company_id: user.company_id,
        });

        if (!reload) {
          navigate("/contas-receber");
        } else {
          reset();
          setRevenues([
            ...revenues,
            {
              name:
                accountPlanSubcategories.find(
                  (accountPlanSubcategory) =>
                    accountPlanSubcategory.id ===
                    formValue.account_plan_subcategory_id.value
                )?.name ?? "",
              date_of_competence: formValue.date_of_competence,
              form_of_receipt: formValue.form_of_receipt,
              account_plan_subcategory_id:
                formValue.account_plan_subcategory_id,
              value: formValue.value,
            },
          ]);
        }
        toast({
          title: "Conta a receber cadastrada com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível cadastrar a conta a receber",
          status: "error",
        });
      }
    },
    [
      accountPlanSubcategories,
      navigate,
      reload,
      reset,
      revenues,
      toast,
      user.company_id,
    ]
  );

  const watchFormOfReceipt = watch("form_of_receipt");
  const watchDueDate = watch("date_of_competence");

  const generatedReceiptPayment = useMemo(() => {
    if (watchDueDate) {
      const split = watchDueDate.split("-");

      switch (watchFormOfReceipt) {
        case "dinheiro": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.money
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }
        case "credito": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.credit
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }
        case "debito": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.debit
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }
        case "vale": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.voucher
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }
        case "online": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.online_payment
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }
        case "pix": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.pix
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }
        case "assinado": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.subscribed
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }

        case "boleto": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.bank_slip
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }

        case "cortesia": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.courtesy
          );
          const formatDate = formatDatabaseWithOutHours(date);
          setValue("date_of_receipt_of_money", formatDate);
          return formatDatabaseWithOutHours(date);
        }
        default: {
          return "";
        }
      }
    }
    return "";
  }, [liquidityOfPayment, setValue, watchDueDate, watchFormOfReceipt]);

  const { errors } = formState;

  return (
    <>
      <Card
        border="#216ca5"
        title="Receitas"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <RevenueForm
            accountPlanSubcategories={accountPlanSubcategories}
            control={control}
            errors={errors}
            getValues={getValues}
            handle={handleCreateRevenue}
            generatedReceiptPayment={generatedReceiptPayment}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
          />
        }
        footer={
          <Flex align="baseline">
            <Box mr={1}>
              <SubmitButtonAndUpdateStatus
                formState={formState}
                text="Cadastrar"
                bg="#ff9000"
                isLoading={formState.isSubmitting && reload}
                onClick={() => {
                  setReload(true);
                }}
              />
            </Box>
            <Box onClick={() => setReload(false)}>
              <SubmitButtonAndUpdateStatus
                formState={formState}
                text="Cadastrar e Finalizar"
                isLoading={formState.isSubmitting && !reload}
                onClick={() => {
                  setReload(false);
                }}
              />
            </Box>
          </Flex>
        }
      />
      {revenues.length > 0 && (
        <Box mt={2}>
          <MUITable
            title="Contas a pagar - Adicionadas"
            data={revenues.map((revenue) => [
              revenue.name,
              revenue.form_of_receipt,
              revenue.date_of_competence,
              revenue.value,
            ])}
            columns={[
              {
                name: "Receita",
              },
              {
                name: "Forma de Recebimento",
              },
              {
                name: "Vencimento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: string) => {
                    return dateDatabaseToIndex(value);
                  },
                },
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMoney(value);
                  },
                },
              },
            ]}
          />
        </Box>
      )}
    </>
  );
}
