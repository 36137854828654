export function dateALessThanOrEqualDateB(dateA: string, dateB: string) {
  const [diaA, mesA, anoA] = dateA.split("/");
  const [diaB, mesB, anoB] = dateB.split("/");

  if (parseInt(anoA) <= parseInt(anoB)) {
    if (parseInt(anoA) === parseInt(anoB)) {
      if (parseInt(mesA) <= parseInt(mesB)) {
        if (parseInt(mesA) === parseInt(mesB)) {
          if (parseInt(diaA) <= parseInt(diaB)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function daysBetweenDates(date1: Date, date2: Date): number {
  const milliseconds = Math.abs(date2.getTime() - date1.getTime());
  const days = Math.trunc(milliseconds / (1000 * 60 * 60 * 24));
  return days;
}
