import { Flex, ModalBody } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../hooks/toast";
import { api } from "../../../../services/api";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { Input } from "../../../Form/Input";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface ModalEffectiveWriteOffExpenseBodyProps {
  expenseId: string;
  updateExpense: (expense: any) => void;
  onClose: () => void;
}

const validationSchema = yup.object().shape({
  effective_write_off_date: yup.string().required("Campo obrigatário"),
});

export function ModalEffectiveWriteOffExpenseBody({
  expenseId,
  updateExpense,
  onClose,
}: ModalEffectiveWriteOffExpenseBodyProps) {
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  watch();
  const toast = useToast();

  const navigate = useNavigate();

  const handleSubmitEffectiveWriteOffExpense: SubmitHandler<any> = useCallback(
    async (data: any) => {
      api
        .patch(`/expenses/effective-write-off-date/${expenseId}`, data)
        .then((response) => {
          toast({
            title: "Data de baixa atualizada.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          updateExpense(response.data);
          onClose();
        })
        .catch((error: AxiosError) => {
          navigate(`/error/${error.response?.status}`);
        });
    },
    [expenseId, toast, onClose, navigate, updateExpense]
  );

  return (
    <ModalBody>
      <Flex
        as="form"
        id="cogs_target"
        flexDirection="column"
        onSubmit={handleSubmit(handleSubmitEffectiveWriteOffExpense)}
      >
        <Input
          label="Data de Baixa Efetiva"
          isRequired={true}
          {...register("effective_write_off_date")}
          type="date"
          error={errors.effective_write_off_date}
          validaded={
            getValues("effective_write_off_date") &&
            getValues("effective_write_off_date") !== ""
          }
        />
        <Flex justifyContent="flex-end" my="2">
          <SubmitButton bg="#216ca5" form="cogs_target" text="Definir" />
        </Flex>
      </Flex>
    </ModalBody>
  );
}
