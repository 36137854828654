import { Button, ButtonProps } from "@chakra-ui/react";
import { shade } from "polished";
import { ReactNode } from "react";
import { validIfColorIsValid } from "../../../../utils/color";
interface DinamicSolidButtonProps extends ButtonProps {
  bg: string;
  children: ReactNode;
  color: string;
  onClick: () => void;
}

export function DinamicSolidButton({
  bg,
  color,
  children,
  onClick,
  ...rest
}: DinamicSolidButtonProps) {
  return (
    <Button
      {...rest}
      bg={bg}
      color={color}
      onClick={onClick}
      _hover={{
        backgroundColor: shade(0.2, validIfColorIsValid(bg) ? bg : "#000000"),
      }}
      _active={{
        backgroundColor: shade(0.2, validIfColorIsValid(bg) ? bg : "#000000"),
      }}
    >
      {children}
    </Button>
  );
}
