import { CreateSelect } from "../../../atoms/Select/CreateSelect";
import { FormControl } from "../../../Form/FormControl";

interface CreateSelectControlProps {
  onCreateOption: (value: string) => void;
  onChange: (select: any) => void;
  options: {
    label: string;
    value: any;
  }[];
  validate?: boolean;
  error?: {
    message?: string;
  };
  value: any;
  name: string;
  label: string;
  isRequired?: boolean;
}

export function CreateSelectControl({
  label,
  name,
  onChange,
  onCreateOption,
  options,
  isRequired,
  value,
  validate,
  error,
}: CreateSelectControlProps) {
  return (
    <FormControl label={label} name={name} isRequired={isRequired}>
      <CreateSelect
        onCreateOption={onCreateOption}
        onChange={onChange}
        options={options}
        value={value}
        validate={validate}
        error={error}
        name={name}
        isRequired={isRequired}
      />
    </FormControl>
  );
}
