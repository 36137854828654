import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Skeleton, Stack, Switch } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { FormControl } from "../../../components/Form/FormControl";

const editAccountPlanCategoryFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  type: yup.string().required("O tipo é obrigatório"),
  is_projected: yup.boolean().nullable(),
  subtype: yup.string().required("O subtipo é obrigatório"),
});

export function EditAccountPlanCategory() {
  const navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, reset, control, watch } = useForm({
    resolver: yupResolver(editAccountPlanCategoryFormSchema),
  });

  useEffect(() => {
    api.get(`/accountPlanCategories/${id}`).then((response) => {
      reset(response.data);
      setLoading(true);
    });
  }, [id, reset]);

  const handleEditAccountPlanCategory: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/accountPlanCategories/${id}`, {
          name: formValue.name,
          type: formValue.type,
          subtype: formValue.subtype,
          is_projected: formValue.is_projected,
        });
        navigate("/contas");
        toast({
          title: "Conta do plano de conta editado com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível editar a conta",
          status: "error",
        });
      }
    },
    [id, navigate, toast]
  );

  const { errors } = formState;

  const watchType = watch("type");
  const recipesSubtypes = [
    {
      id: "Operacional",
      name: "Operacional",
    },
    {
      id: "Não operacional",
      name: "Não operacional",
    },
  ];

  const expensesSubtypes = [
    {
      id: "Operacional",
      name: "Operacional",
    },
    {
      id: "Não operacional",
      name: "Não operacional",
    },
    {
      id: "Sobre receita",
      name: "Sobre receita",
    },
  ];

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Conta"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditAccountPlanCategory)}
              id="add-form"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
                <SelectControl
                  control={control}
                  label="Tipo"
                  isRequired={true}
                  error={errors.type}
                  {...register("type")}
                  options={[
                    {
                      id: "Receita",
                      name: "Receita",
                    },
                    {
                      id: "Despesa",
                      name: "Despesa",
                    },
                  ]}
                />
                {(watchType === "Receita" || watchType === "Despesa") && (
                  <SelectControl
                    control={control}
                    label="Subtipo"
                    isRequired={true}
                    error={errors.subtype}
                    {...register("subtype")}
                    options={
                      watchType === "Receita"
                        ? recipesSubtypes
                        : expensesSubtypes
                    }
                  />
                )}
                {watchType === "Despesa" && (
                  <FormControl name="is_projected" label="É projetada?">
                    <Switch size="lg" {...register("is_projected")} />
                  </FormControl>
                )}
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
