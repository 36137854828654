import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, SimpleGrid, Skeleton, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../../hooks/auth";
import { AxiosError } from "axios";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useToast } from "../../../hooks/toast";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { checkPermission } from "../../../utils/checkPermission";

const createUserFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
});

interface Company {
  id: string;
  name: string;
}

export function EditUser() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user: localUser, updateUser } = useAuth();
  const [companies, setCompanies] = useState<Company[]>([]);

  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState, reset, setValue, control } =
    useForm({
      mode: "onBlur",
      resolver: yupResolver(createUserFormSchema),
    });

  useEffect(() => {
    api.get("/companies").then((response) => {
      setCompanies(response.data);
      api
        .get(`/users/${id}`)
        .then((response) => {
          reset(response.data);
          setLoading(true);
        })
        .catch((err: AxiosError) => {});
    });
  }, [id, reset, setValue, navigate]);

  const handleEditUser: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api
          .put(`/users/${id}`, {
            name: formValue.name,
            email: formValue.email,
            company_id: formValue.company_id,
            is_admin: formValue.is_admin,
          })
          .then((response) => {
            if (response.data.id === localUser.id) {
              updateUser(response.data);
            }
          });
        navigate("/usuarios");
        toast({
          title: "Usuário editado com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível cadastrar o usuário",
          status: "error",
        });
      }
    },
    [navigate, id, localUser.id, updateUser, toast]
  );

  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Usuário"
          header={
            <>
              {localUser.is_admin <= 1 && (
                <BackButton onClick={() => navigate(-1)} />
              )}
            </>
          }
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditUser)}
              id="add-form"
            >
              <Stack spacing="4">
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  spacing={["6", "8"]}
                  w="100%"
                >
                  <Input
                    type="text"
                    label="Nome"
                    isRequired={true}
                    error={errors.name}
                    {...register("name")}
                  />
                  <Input
                    type="email"
                    label="E-mail"
                    isRequired={true}
                    error={errors.email}
                    {...register("email")}
                  />
                  <SelectControl
                    control={control}
                    label="Empresa"
                    isRequired={true}
                    error={errors.company_id}
                    {...register("company_id")}
                    options={companies}
                  />
                  <SelectControl
                    control={control}
                    label="Nível"
                    isRequired={true}
                    error={errors.is_admin}
                    {...register("is_admin")}
                    options={[
                      {
                        id: 0,
                        name: "Super Administrador",
                      },
                      {
                        id: 1,
                        name: "Administrador",
                      },
                      {
                        id: 2,
                        name: "Dono de Rede",
                      },
                      {
                        id: 3,
                        name: "Dono de Empresa",
                      },
                      {
                        id: 4,
                        name: "Gestor Operação",
                      },
                      {
                        id: 5,
                        name: "Financeiro - Frente de Loja",
                      },
                    ].filter((value) => {
                      if (
                        checkPermission({
                          is_admin: localUser.is_admin,
                          type: "Super Admin",
                        })
                      ) {
                        return true;
                      }
                      return value.id !== 0;
                    })}
                  />
                </SimpleGrid>
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
