import { SimpleGrid, Text } from "@chakra-ui/react";
import {
  FaFile,
  FaFolderOpen,
  FaHandshake,
  FaTag,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
import { AdminBox } from "../../../components/templates/Widget/AdminBox";
import { InternalHeader } from "../../../components/templates/InternalHeader";

export function ConfigGeneralAdminPage() {
  return (
    <>
      <InternalHeader
        title="Configurações"
        has_filter={false}
        has_back_button={true}
      />
      <Text fontSize={24} color="blue.350" mb="4">
        Gestão de Compra
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacingX={2}>
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFile}
          link="/contas"
          title="Contas"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFolderOpen}
          link="/planos-de-conta"
          title="Plano de conta"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFolderOpen}
          link="/receitas/despesas"
          title="Receitas/Despesas"
        />
      </SimpleGrid>
      <Text fontSize={24} color="blue.350" mb="4">
        Precificação
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacingX={2}>
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFile}
          link="/precificacao/categoria-mercadoria"
          title="Categoria Mercadoria"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFile}
          link="/precificacao/categoria-produto-venda"
          title="Categoria Produto de Venda"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFile}
          link="/precificacao/mercadoria"
          title="Mercadoria"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFile}
          link="/precificacao/subcategoria-mercadoria"
          title="Subcategoria Mercadoria"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFile}
          link="/precificacao/produtos"
          title="Produtos de Ficha Técnica"
        />
      </SimpleGrid>
      <Text fontSize={24} color="blue.350" mb="4">
        Geral
      </Text>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacingX={2}>
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaTag}
          link="/categorias"
          title="Categorias"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaHandshake}
          link="/empresas"
          title="Empresas"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaUsers}
          link="/redes"
          title="Redes"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaUserFriends}
          link="/usuarios"
          title="Usuários"
        />
      </SimpleGrid>
    </>
  );
}
