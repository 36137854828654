import { ReactNode } from "react";
import { HeaderWithFilter } from "../../organisms/Header/HeaderWithFilter";
import { HeaderWithoutFilter } from "../../organisms/Header/HeaderWithoutFilter";

interface InternalHeaderProps {
  has_filter?: boolean;
  children?: ReactNode;
  title: string;
  has_back_button?: boolean;
  defaultOpen?: boolean;
}

export function InternalHeader({
  children,
  has_filter = true,
  title,
  has_back_button = false,
  defaultOpen,
}: InternalHeaderProps) {
  return has_filter ? (
    <HeaderWithFilter
      has_back_button={has_back_button}
      title={title}
      defaultOpen={defaultOpen}
    >
      {children}
    </HeaderWithFilter>
  ) : (
    <HeaderWithoutFilter has_back_button={has_back_button} title={title}>
      {children}
    </HeaderWithoutFilter>
  );
}
