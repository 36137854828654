import {
  Button,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { FaFile } from "react-icons/fa";
import { api } from "../../../services/api";

interface FileButtonProps {
  title?: string;
  expense_id: string;
}

export function FileButton({ expense_id, title }: FileButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [linkFile, setLinkFile] = useState<string>("");

  const handleFileExpense = useCallback(async () => {
    api
      .get(`/expenses/file/${expense_id}`)
      .then((response) => {
        setLinkFile(response.data);
        onOpen();
      })
      .catch((err) => {
        console.log(err);
        setLinkFile("");
      });
  }, [expense_id, onOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comprovante - {title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {linkFile.match(".pdf") ? (
              <>
                <iframe
                  title="pdf"
                  width="100%"
                  height={window.innerHeight * 0.75}
                  src={linkFile}
                />
              </>
            ) : (
              <>
                <Image src={linkFile} />
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        color="white"
        onClick={() => {
          handleFileExpense();
        }}
        mx="1"
        // link={link.replace("localhost:3000/", "")}
        bg="#b9b9b9"
      >
        <Icon as={FaFile} />
      </Button>
    </>
  );
}
