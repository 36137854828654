import { SubmitHandler, useForm, FieldValues } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Divider, Flex, Stack, Text } from "@chakra-ui/react";

import { FaLock } from "react-icons/fa";

import { Input } from "../../../components/Form/Input";
import { Auth } from "../../../templates/Auth";
import { api } from "../../../services/api";
import { useCallback, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../../hooks/toast";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "No mínimo 6 caracteres"),
  password_confirmation: yup
    .string()
    .test("passwords-match", "As senhas precisam ser iguais", function (value) {
      return value === this.parent.password;
    }),
});

export function UserRegistrationPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const { token } = useParams();

  const { register, handleSubmit, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    api
      .get(`/userTokens/token/${token}`)
      .then((response) => {})
      .catch((err) => {
        toast({
          title: "Senha já configurada",
          status: "error",
        });
        navigate("/login");
      });
  }, [token, navigate, toast]);

  const handleUserRegistration: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .put(`/users/registration/completed`, {
          password: formValue.password,
          token: token,
        })
        .then((response) => {
          toast({
            title: "Cadastro finalizado com sucesso",
            status: "success",
          });
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: "Erro ao cadastrar usuário",
            status: "error",
          });
        });
    },
    [navigate, toast, token]
  );

  const { errors } = formState;
  return (
    <Auth>
      <Flex
        as="form"
        maxWidth={{ base: "320", md: "400" }}
        bg="white"
        p="4"
        mb={{ base: "4", md: 0 }}
        mt={{ base: "4", md: 0 }}
        flexDir="column"
        onSubmit={handleSubmit(handleUserRegistration)}
      >
        <Stack spacing="2">
          <Text textAlign="center">
            Complete o seu cadastro e acesse ao Lucre!
          </Text>
          <Divider height="0.2rem" />
          <Text color="gray.600" m="0" px="2" textAlign="center">
            Defina a sua senha
          </Text>
          <Input
            type="password"
            placeholder="Senha"
            icon={FaLock}
            error={errors.password}
            {...register("password")}
          />
          <Input
            type="password"
            placeholder="Confirme sua senha"
            icon={FaLock}
            error={errors.password_confirmation}
            {...register("password_confirmation")}
          />
        </Stack>
        <Button
          type="submit"
          mt="6"
          colorScheme="blue"
          fontWeight="normal"
          isLoading={formState.isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Auth>
  );
}
