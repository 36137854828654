import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useQuery } from "../../../hooks/query";
import { Table } from "../../../components/templates/Table";
import { useAuth } from "../../../hooks/auth";
import { formatDateBrIndex } from "../../../utils/formatDate";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { CompareCurrentPrevius } from "../../../components/templates/Modal/CompareCurrentPrevious";

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface Favored {
  id: string;
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}
interface Log {
  id: string;
  type: string;
  entity: string;
  total: string;
  entity_id: string;
  entity_created_at: string;
  last_updated_at: string;
  account_plan_subcategory_name: string;
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListLogs() {
  const query = useQuery();
  const [logs, setLogs] = useState<Log[]>([]);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const [favored, setFavored] = useState<Favored[]>([]);

  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  useEffect(() => {
    api
      .get("/accountPlanSubcategories")
      .then((response) => setAccountPlanSubcategories(response.data));
  }, []);

  useEffect(() => {
    api.get(`/favored/company/${user.company_id}`).then((response) => {
      setFavored(response.data);
    });
  }, [user.company_id]);

  useEffect(() => {
    api
      .get(
        `/logs/pagination/${user.company_id}?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setLogs(response.data.logs);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [
    defaultParams.limit_per_page,
    defaultParams.order_column,
    defaultParams.order_type,
    defaultParams.page,
    defaultParams.search,
    navigate,
    user.company_id,
  ]);

  return (
    <>
      <InternalHeader title="Logs" has_filter={false} />
      <Card
        border="#2b88a1"
        body={
          <Table
            loading={loading}
            message="Buscando as logs"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/logs?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={logs.map((log) => {
              return {
                tr: [
                  {
                    name: log.account_plan_subcategory_name,
                  },
                  {
                    name: log.entity,
                  },
                  {
                    name: log.total,
                  },
                  {
                    name: formatDateBrIndex(log.entity_created_at),
                  },
                  {
                    name: formatDateBrIndex(log.last_updated_at),
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <CompareCurrentPrevius
                          entity_id={log.entity_id}
                          accountPlanSubcategories={accountPlanSubcategories}
                          favored={favored}
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "account_plan_subcategory_name",
                label: "Conta",
                options: {
                  sort: true,
                },
              },
              {
                name: "entity",
                label: "Tipo",
                options: {
                  sort: true,
                },
              },
              {
                name: "total",
                label: "Total de Alterações",
                options: {
                  sort: true,
                },
              },
              {
                name: "entity_created_at",
                label: "Data de Criação",
                options: {
                  sort: true,
                },
              },
              {
                name: "last_updated_at",
                label: "Data da Última Alteração",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ação",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
