import {
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Stack,
  Icon,
  Flex,
  Divider,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../../../services/api";
import { Input } from "../../../Form/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { useToast } from "../../../../hooks/toast";
import { MultiSelectControl } from "../../Form/MultiSelectControl";
import { useAuth } from "../../../../hooks/auth";
import { FaTrash } from "react-icons/fa";
import { formatMoney } from "../../../../utils/money";

interface TechnicalSheet {
  id: string;
  name: string;
  portion_quantity: number;
  unit_of_measurement: string;
  company_id: string;
  cost_price?: number;
  products_technical_sheet?: {
    product_id: string;
    quantity: number;
  }[];
}

interface TechnicalSheetForm {
  id: string;
  name: string;
  portion_quantity: number;
  unit_of_measurement: string;
  company_id: string;
  cost_price?: string;
  products?: {
    id: string;
    name: string;
    quantity: number;
  }[];
}

interface Product {
  id: string;
  name: string;
  efficiency: number;
  merchandise_id: string;
  merchandise?: { name: string };
  measurement_unit: string;
}

interface ProductsTechnicalSheetModalProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  setTechnicalSheet: (data: TechnicalSheet) => void;
  defaultLink: string;
  actionType: "edit" | "create";
  defaultValue?: TechnicalSheet;
  products: Product[];
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres")
    .typeError("Valor inválido"),
  company_id: yup
    .string()
    .required("A Empresa é obrigatória")
    .uuid("Formato inválido")
    .typeError("Valor inválido"),
  portion_quantity: yup
    .number()
    .required("O rendimento é obrigatório")
    .typeError("Valor inválido"),
  unit_of_measurement: yup
    .string()
    .required("A unidade de medida é obrigatória")
    .typeError("Valor inválido."),
  cost_price: yup.string(),
  products: yup.array().of(
    yup.object().shape({
      id: yup
        .string()
        .uuid("Formato inválido")
        .typeError("Valor inválido")
        .required("O produto é obrigatório"),
      quantity: yup
        .number()
        .min(0, "A Quantidade precisa ser maior que 0")
        .typeError("Valor inválido")
        .required("A quantidade é obrigatória"),
    })
  ),
});

export function ProductsTechnicalSheetModal({
  isOpen,
  setIsOpen,
  defaultValue,
  actionType,
  defaultLink,
  setTechnicalSheet,
  products,
}: ProductsTechnicalSheetModalProps) {
  const toast = useToast();
  const { user } = useAuth();
  const [zIndex, setZIndex] = useState<number[]>([100]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    setError,
    watch,
  } = useForm<TechnicalSheetForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      company_id: user.company_id,
      products: [],
    },
  });

  const productsSelected = watch("products");
  useEffect(() => {
    if (actionType === "edit" && defaultValue && isOpen) {
      setValue("name", defaultValue.name);
      setValue("portion_quantity", defaultValue.portion_quantity);
      setValue("unit_of_measurement", defaultValue.unit_of_measurement);
      setValue("company_id", defaultValue.company_id);
      setValue(
        "cost_price",
        defaultValue.cost_price
          ? formatMoney(defaultValue.cost_price)
              .replace("R$ ", "")
              .replace(".", "")
          : ""
      );
      setValue(
        "products",
        defaultValue.products_technical_sheet?.map((product) => ({
          id: product.product_id,
          name: products.find((p) => p.id === product.product_id)?.name || "",
          quantity: product.quantity,
        })) || []
      );
    } else {
      reset();
    }
  }, [actionType, defaultValue, setValue, isOpen, reset, products]);

  const handleCreateMerchandise: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .post(`/${defaultLink}`, {
          name: formValue.name,
          portion_quantity: formValue.portion_quantity,
          unit_of_measurement: formValue.unit_of_measurement,
          company_id: formValue.company_id,
          cost_price: formValue.cost_price,
          products: formValue.products,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `Ficha técnica cadastrada com sucesso`,
          });
          setTechnicalSheet(response.data);
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          if (err.response.data.message === "Name already exist") {
            toast({
              status: "error",
              title: `Esse nome de ficha técnica já existe`,
            });
            setError(
              "name",
              { message: "Esse nome já está sendo usado." },
              {
                shouldFocus: true,
              }
            );
          } else {
            toast({
              status: "error",
              title: `Não foi possível editar a ficha técnica`,
            });
          }
        });
    },
    [defaultLink, toast, setTechnicalSheet, setIsOpen, reset, setError]
  );
  const handleEditMerchandise: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .put(`/${defaultLink}/${defaultValue?.id}`, {
          name: formValue.name,
          portion_quantity: formValue.portion_quantity,
          unit_of_measurement: formValue.unit_of_measurement,
          company_id: formValue.company_id,
          cost_price: formValue.cost_price,
          products: formValue.products,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `Produto de ficha técnica editado com sucesso`,
          });
          setTechnicalSheet(response.data);
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          if (err.response.data.message === "Name already exist") {
            toast({
              status: "error",
              title: `Esse nome de produto já existe`,
            });
            setError(
              "name",
              { message: "Esse nome já está sendo usado." },
              {
                shouldFocus: true,
              }
            );
          } else {
            toast({
              status: "error",
              title: `Não foi possível editar o produto de ficha técnica`,
            });
          }
        });
    },
    [
      defaultLink,
      defaultValue?.id,
      toast,
      setTechnicalSheet,
      setIsOpen,
      reset,
      setError,
    ]
  );
  watch();
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          reset();
        }}
        isCentered
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === "create" ? "Adicionar" : "Editar"} Ficha Técnica
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid
              spacingX={2}
              columns={{ base: 1 }}
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={
                actionType === "edit"
                  ? handleSubmit(handleEditMerchandise)
                  : handleSubmit(handleCreateMerchandise)
              }
              id="add-form"
            >
              <Input
                type="text"
                label="Nome"
                description={"O nome da receita que será cadastrada"}
                isRequired={true}
                {...register("name")}
                error={errors.name}
                validaded={getValues("name") !== ""}
              />
              <Tabs>
                <TabList>
                  <Tab>Montar Receita</Tab>
                  <Tab>Cadastrar Custo</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Text
                      fontSize={12}
                      textAlign={"justify"}
                      p="4"
                      mb="2"
                      boxShadow={"md"}
                      borderRadius={4}
                      backgroundColor="#f0f0f0"
                    >
                      Nessa aba é possível informar todos os ingredientes usados
                      na receita e suas respectivas quantidades. Assim o Lucre
                      irá calcular o custo de confecção dessa ficha técnica a
                      partir do consumo das mercadorias usadas.
                    </Text>
                    <Stack>
                      {productsSelected &&
                        productsSelected.map((product, index) => {
                          return (
                            <SimpleGrid
                              columns={{ base: 1, md: 2 }}
                              spacingX={2}
                            >
                              <MultiSelectControl
                                description={
                                  "Selecione o produto usado na receita"
                                }
                                options={products.map((product) => ({
                                  label: product.name,
                                  value: product.id,
                                }))}
                                {...register(`products.${index}.id`)}
                                onChange={(selected) => {
                                  setValue(
                                    `products.${index}.name`,
                                    selected.label,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                  setValue(
                                    `products.${index}.id`,
                                    selected.value,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                }}
                                value={
                                  getValues(`products.${index}.id`)
                                    ? {
                                        label: getValues(
                                          `products.${index}.name`
                                        ),
                                        value: getValues(
                                          `products.${index}.id`
                                        ),
                                      }
                                    : ""
                                }
                                validaded={
                                  getValues(`products.${index}.id`) !==
                                    undefined &&
                                  getValues(`products.${index}.id`) !== ""
                                }
                                isMulti={false}
                                label="Produto"
                                error={
                                  errors.products && errors.products[index]?.id
                                }
                                isRequired={true}
                                zIndex={zIndex[index]}
                                onFocus={() =>
                                  setZIndex(
                                    zIndex.map((z, i) => {
                                      if (i === index) {
                                        return 1000;
                                      }
                                      return 10;
                                    })
                                  )
                                }
                              />
                              <Flex alignItems={"flex-end"}>
                                <Input
                                  type="number"
                                  label="Quantidade"
                                  description={
                                    "Quantas unidades de medida são usadas?"
                                  }
                                  step="0.01"
                                  isRequired={true}
                                  {...register(`products.${index}.quantity`)}
                                  error={
                                    errors.products &&
                                    errors.products[index]?.quantity
                                  }
                                  validaded={
                                    getValues(`products.${index}.quantity`) !==
                                    0
                                  }
                                  rightAddon={
                                    products.find((p) => p.id === product.id)
                                      ?.measurement_unit
                                  }
                                />
                                <Button
                                  colorScheme="red"
                                  variant="outline"
                                  ml={2}
                                  mb={1}
                                  onClick={() => {
                                    setValue(
                                      "products",
                                      productsSelected.filter(
                                        (p, i) => i !== index
                                      )
                                    );
                                  }}
                                >
                                  <Icon as={FaTrash} />
                                </Button>
                              </Flex>
                            </SimpleGrid>
                          );
                        })}
                      <Flex>
                        <Button
                          colorScheme="blue"
                          variant="outline"
                          mr={3}
                          onClick={() => {
                            setZIndex([...zIndex, 10]);
                            setValue("products", [
                              ...(productsSelected ? productsSelected : []),
                              {
                                id: "",
                                name: "",
                                quantity: 0,
                              },
                            ]);
                          }}
                        >
                          + Adicionar Produto
                        </Button>
                      </Flex>
                    </Stack>
                  </TabPanel>
                  <TabPanel>
                    <Text
                      fontSize={12}
                      textAlign={"justify"}
                      p="4"
                      mb="2"
                      boxShadow={"md"}
                      borderRadius={4}
                      backgroundColor="#f0f0f0"
                    >
                      Nessa aba é possível informar diretamente o valor do custo
                      de confecção dessa receita, caso já possua essa
                      informação.
                    </Text>
                    <Input
                      label="Custo"
                      error={errors.cost_price}
                      validaded={
                        getValues("cost_price") !== "" && !errors.cost_price
                      }
                      {...register("cost_price")}
                      leftAddon="R$"
                      onChange={(e) => {
                        var value = e.target.value;
                        if (value.length > 2) {
                          value = value
                            .replace(/^(-)|[^\d.-]+/g, "$1")
                            .replace(/([0-9]{2})$/g, ",$1");
                          e.target.value = value;
                          setValue("cost_price", value, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          });
                        } else {
                          setValue("cost_price", e.target.value);
                        }
                      }}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Divider borderBottom={"2px solid #2b88a1"} mb="2" />
              <Flex justifyContent={"flex-end"}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={3}>
                  <MultiSelectControl
                    options={[
                      {
                        value: "g",
                        label: "g",
                      },
                      {
                        value: "Kg",
                        label: "Kg",
                      },
                      {
                        value: "ml",
                        label: "ml",
                      },
                      {
                        value: "L",
                        label: "L",
                      },
                    ]}
                    {...register("unit_of_measurement")}
                    onChange={(selected) => {
                      setValue("unit_of_measurement", selected.value, {
                        shouldValidate: true,
                      });
                    }}
                    value={
                      getValues("unit_of_measurement")
                        ? {
                            label: getValues("unit_of_measurement"),
                            value: getValues("unit_of_measurement"),
                          }
                        : ""
                    }
                    validaded={
                      getValues("unit_of_measurement") !== undefined &&
                      getValues("unit_of_measurement") !== ""
                    }
                    isMulti={false}
                    label="Unidade de medida"
                    error={errors.unit_of_measurement}
                    isRequired={true}
                    zIndex={100}
                    description={
                      "Qual a unidade de medida usada para essa receita?"
                    }
                  />
                  <Input
                    type="number"
                    label="Rendimento"
                    description={
                      "Quantas unidades de medida essa receita rende?"
                    }
                    step="0.01"
                    isRequired={true}
                    {...register("portion_quantity")}
                    error={errors.portion_quantity}
                    validaded={
                      !Number.isNaN(getValues("portion_quantity")) &&
                      getValues("portion_quantity") !== 0 &&
                      String(getValues("portion_quantity")) !== ""
                    }
                    rightAddon={
                      getValues("unit_of_measurement") &&
                      getValues("unit_of_measurement")
                    }
                  />
                </SimpleGrid>
              </Flex>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() => {
                setIsOpen(false);
                reset();
              }}
            >
              Cancelar
            </Button>
            <SubmitButton
              form="add-form"
              mt={"0"}
              text={actionType === "edit" ? "Editar" : "Cadastrar"}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
