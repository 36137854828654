import {
  Avatar,
  Flex,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  MenuDivider,
} from "@chakra-ui/react";
import { FaAngleDown, FaHandshake, FaPowerOff } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import Logo from "../../assets/icon.png";

interface ProfileProps {
  showProfileData?: boolean;
}
export function Profile({ showProfileData = true }: ProfileProps) {
  const { signOut, user } = useAuth();

  return (
    <Box
      zIndex="20"
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-end"}
      flex={1}
    >
      <Menu direction="ltr" closeOnSelect>
        <MenuButton>
          <Flex justify="center" alignItems="center">
            <Flex alignItems="center" zIndex="10">
              {showProfileData && (
                <Box mr="4" textAlign="right">
                  <Text>{user.name}</Text>
                  <Text color="gray.800" fontWeight="bold" fontSize="small">
                    {user.company.name}
                  </Text>
                </Box>
              )}
              <Avatar
                size="md"
                name={user ? user.name : ""}
                src={Logo}
                border="3px solid #adb5bd"
                bg="transparent"
              />
            </Flex>
            <Icon as={FaAngleDown} ml="2" />
          </Flex>
        </MenuButton>
        <MenuList>
          {user.is_admin <= 1 && (
            <>
              <MenuItem as={Link} to="/usuario/empresa">
                <Icon as={FaHandshake} mr="5px" />
                Trocar Empresa
              </MenuItem>
              <MenuDivider />
            </>
          )}
          <MenuItem as={Link} to="/login" onClick={signOut}>
            <Icon as={FaPowerOff} mr="5px" />
            Sair
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
