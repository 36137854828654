import {
  FaBoxes,
  FaBox,
  FaBullseye,
  FaCashRegister,
  FaChartArea,
  FaChartLine,
  FaCommentDollar,
  FaComments,
  FaDollarSign,
  FaFileExport,
  FaFileImport,
  FaHandshake,
  FaListAlt,
  FaShoppingCart,
  FaUserLock,
  FaWrench,
} from "react-icons/fa";
import { useAuth } from "../../../hooks/auth";
import { NavLink } from "../../molecules/Sidebar/NavLink";
import { NavSection } from "../../molecules/Sidebar/NavSection";
import { SidebarSectionStructure } from "../../organisms/Sidebar/SidebarSectionStructure";
import { checkPermission } from "../../../utils/checkPermission";
import { FaFalae } from "../../atoms/Icon/FalaeIcon";

export function AdminSidebarNav() {
  const { user } = useAuth();

  return (
    <SidebarSectionStructure>
      <NavSection title="Gestão de Compra" icon={FaShoppingCart}>
        {checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        }) ? (
          <>
            <NavLink icon={FaChartArea} href={`dre`}>
              DRE
            </NavLink>
            <NavLink icon={FaChartLine} href={`evolucao/cmv`}>
              Evolução CMV
            </NavLink>
          </>
        ) : (
          <></>
        )}
        <NavLink icon={FaFileImport} href={`contas-receber`}>
          Receitas
        </NavLink>
        <NavLink icon={FaFileExport} href={`contas-pagar`}>
          Despesas
        </NavLink>
        {checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        }) ? (
          <>
            <NavLink icon={FaFileImport} href={`antecipacao`}>
              Antecipação de Recebíveis
            </NavLink>
            <NavLink icon={FaHandshake} href={``} tag="soon">
              Conciliação
            </NavLink>
            <NavLink icon={FaCashRegister} href={``} tag="soon">
              Fluxo de Caixa
            </NavLink>
          </>
        ) : (
          <></>
        )}
        <NavLink icon={FaBox} href={`favorecido`} tag="new">
          Favorecidos
        </NavLink>
      </NavSection>
      {checkPermission({
        is_admin: user.is_admin,
        type: "Manager",
      }) ? (
        <>
          <NavSection icon={FaDollarSign} title="Precificação" tag={"soon"}>
            <NavLink
              icon={FaBoxes}
              href={
                checkPermission({
                  is_admin: user.is_admin,
                  type: "Admin",
                })
                  ? `/precificacao/produto-venda`
                  : "/"
              }
              tag="soon"
            >
              Produtos de Venda
            </NavLink>
            <NavLink
              icon={FaListAlt}
              href={
                checkPermission({
                  is_admin: user.is_admin,
                  type: "Admin",
                })
                  ? `/precificacao/ficha-tecnica`
                  : "/"
              }
              tag="soon"
            >
              Fichas Técnica
            </NavLink>
          </NavSection>
          <NavLink mt="4" icon={FaComments} href={`/experiencia`} tag="new">
            Experiência do Cliente
          </NavLink>
        </>
      ) : (
        <></>
      )}
      {checkPermission({
        is_admin: user.is_admin,
        type: "Owner",
      }) ? (
        <NavSection title="Configurações" icon={FaWrench}>
          <NavLink
            icon={FaCashRegister}
            href={`empresas/cadastrar/planos-de-conta/${user.company_id}`}
          >
            Plano de Conta
          </NavLink>
          <NavLink icon={FaCommentDollar} href={`forma-de-pagamento`}>
            Forma de Pagamento
          </NavLink>
          <NavLink icon={FaBullseye} href={`empresa/meta/cmv`}>
            Meta CMV
          </NavLink>
          <NavLink icon={FaListAlt} href={`/logs`}>
            Logs
          </NavLink>
          <NavLink icon={FaFalae} href={`/experiencia/gerenciar`} tag="new">
            Falaê
          </NavLink>
          {checkPermission({
            is_admin: user.is_admin,
            type: "Admin",
          }) ? (
            <NavLink icon={FaUserLock} href={`/config`}>
              Admin
            </NavLink>
          ) : (
            <></>
          )}
        </NavSection>
      ) : (
        <></>
      )}
    </SidebarSectionStructure>
  );
}
