import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSidebarDrawer } from "../../../../hooks/sidebarDrawer";
import { SidebarLogo } from "../../../atoms/Image/SidebarLogo";
import { AdminSidebarNav } from "../../../templates/AdminSidebarNav";

export function MobileSidebar() {
  const { isOpen, onClose } = useSidebarDrawer();
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent bg="white" p="4">
          <DrawerCloseButton mt="6" />
          <DrawerHeader>
            <Link to="/" onClick={() => onClose()}>
              <SidebarLogo />
            </Link>
          </DrawerHeader>
          <DrawerBody>
            <AdminSidebarNav />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
