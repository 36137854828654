import {
  Button,
  Divider,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { ModalEffectiveWriteOffExpenseBody } from "../../../organisms/Modal/ModalEffectiveWriteOffExpenseBody";

interface ModalEffectiveWriteOffExpenseProps {
  expenseId: string;
  updateExpense: (expense: any) => void;
}

export function ModalEffectiveWriteOffExpense({
  expenseId,
  updateExpense,
}: ModalEffectiveWriteOffExpenseProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Tooltip label="Adicionar data de baixa efetiva" hasArrow>
        <Button
          bg="#transparent"
          _hover={{ color: "#216ca5", opacity: 0.8 }}
          _active={{ color: "#216ca5", opacity: 0.6 }}
          color="#216ca5"
          onClick={onOpen}
        >
          <Icon as={FaEdit} color="#216ca5" />
        </Button>
      </Tooltip>
      <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar data de baixa efetiva</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalEffectiveWriteOffExpenseBody
            onClose={onClose}
            expenseId={expenseId}
            updateExpense={updateExpense}
          />
        </ModalContent>
      </Modal>
    </>
  );
}
