import { SimpleGrid, Stack } from "@chakra-ui/react";
import { Card } from "../../../components/Card";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { api } from "../../../services/api";
import { Input } from "../../../components/Form/Input";
import { useAuth } from "../../../hooks/auth";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../../hooks/toast";
import { formatDatabaseWithOutHours } from "../../../utils/formatDate";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";
import { CreateSelectControl } from "../../../components/templates/Form/CreateSelectControl";

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface Favored {
  id: string;
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}

const updateFixedExpenseFormSchema = yup.object().shape({
  observation: yup.string().notRequired(),
  value: yup
    .number()
    .required("Valor é obrigatório")
    .min(0.01, "O valor é R$0,01"),
  form_of_payment: yup.string().required("Forma de pagamento é obrigatória"),
  account_plan_subcategory_id: yup.string().required("Receita é obrigatória"),
  next_date_of_competence: yup.string(),
  first_date_of_competence: yup
    .string()
    .required("Data do competência é obrigatória"),
});

export function UpdateFixedExpense() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const [favored, setFavored] = useState<Favored[]>([]);

  const {
    reset,
    control,
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",

    resolver: yupResolver(updateFixedExpenseFormSchema),
  });

  const accountPlanSubcategoryId = watch("account_plan_subcategory_id");
  watch();
  useEffect(() => {
    api.get(`/favored/company/${user.company_id}`).then((response) => {
      setFavored(response.data);
    });
  }, [user.company_id]);

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Despesa")
      .then((response) => setAccountPlanSubcategories(response.data));
  }, []);

  useEffect(() => {
    api
      .get(`/fixedExpenses/${id}`)
      .then((response) => {
        reset({
          favored_name: response.data?.favored
            ? response.data.favored.name
            : "",
          favored_id: response.data?.favored ? response.data.favored.id : "",
          ...response.data,
          next_date_of_competence: formatDatabaseWithOutHours(
            new Date(response.data.next_date_of_competence)
          ),
          first_date_of_competence: formatDatabaseWithOutHours(
            new Date(response.data.first_date_of_competence)
          ),
        });
      })
      .catch((err) => console.log(err));
  }, [id, reset]);

  const handleSubmitFixedExpense: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      api
        .put(`/fixedExpenses/${id}`, {
          account_plan_subcategory_id: formValue.account_plan_subcategory_id,
          next_date_of_competence: formValue.next_date_of_competence,
          first_date_of_competence: formValue.first_date_of_competence,
          form_of_payment: formValue.form_of_payment,
          frequency: formValue.frequency,
          observation: formValue.observation,
          value: Number(formValue.value),
          favored_name: formValue.favored_name,
        })
        .then((response) => {
          toast({
            title: "Despesa editada com sucesso",
            status: "success",
          });
          navigate(-1);
        })
        .catch((err) => {
          toast({
            title: "Não foi possível editar a conta",
            status: "error",
          });
          console.log(err);
        });
    },
    [navigate, toast, id]
  );

  return (
    <>
      <Card
        title="Despesa Fixa"
        border="#216ca5"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <Stack
            as="form"
            id="update-fixed-expense"
            onSubmit={handleSubmit(handleSubmitFixedExpense)}
          >
            <SimpleGrid>
              <CreateSelectControl
                onCreateOption={(value: string) => {
                  setValue("favored_name", value, {
                    shouldValidate: true,
                  });
                  setValue("favored_id", value, {
                    shouldValidate: true,
                  });
                  setFavored((prev) => [
                    ...prev,
                    {
                      id: value.toLowerCase(),
                      name: value,
                      account_plan_subcategory_id: "",
                      company_id: user.company_id,
                      account_plan_subcategory: {
                        name: "",
                      },
                    },
                  ]);
                }}
                onChange={(select: any) => {
                  const selected = favored.find((f) => f.id === select.value);
                  if (selected) {
                    setValue("favored_id", selected.id, {
                      shouldValidate: true,
                    });
                    setValue("favored_name", selected.name);
                    if (selected.account_plan_subcategory.name) {
                      setValue(
                        "account_plan_subcategory_id",
                        selected.account_plan_subcategory_id
                      );
                      setValue(
                        "account_plan_subcategory_name",
                        selected.account_plan_subcategory.name
                      );
                    }
                  }
                }}
                options={favored.map((f) => ({
                  label: `${f.name} ${
                    f.account_plan_subcategory.name
                      ? `- ${f.account_plan_subcategory.name}`
                      : ""
                  }`,
                  value: f.id,
                }))}
                name="favored"
                label="Favorecido"
                isRequired
                value={
                  getValues("favored_id")
                    ? {
                        label: getValues("favored_name"),
                        value: getValues("favored_id"),
                      }
                    : undefined
                }
                error={errors.favored_id}
                validate={getValues("favored_name") && !errors.favored}
              />
              <MultiSelectControl
                options={accountPlanSubcategories.map((acc) => ({
                  label: acc.name,
                  value: acc.id,
                }))}
                {...register("account_plan_subcategory_id")}
                onChange={(selected) => {
                  setValue("account_plan_subcategory_id", selected.value, {
                    shouldValidate: true,
                  });
                  setValue("account_plan_subcategory_name", selected.label, {
                    shouldValidate: true,
                  });
                }}
                value={
                  accountPlanSubcategoryId
                    ? {
                        label: accountPlanSubcategories.find(
                          (acc) => acc.id === accountPlanSubcategoryId
                        )?.name,
                        value: accountPlanSubcategoryId,
                      }
                    : undefined
                }
                validaded={
                  getValues("account_plan_subcategory_id") &&
                  !errors.account_plan_subcategory_id
                }
                isMulti={false}
                label="Despesa"
                error={errors.account_plan_subcategory_id}
                isRequired={true}
              />
              <Input
                type="text"
                label="Descrição"
                isRequired={false}
                validaded={
                  getValues("observation") !== "" && !errors.observation
                }
                error={errors.observation}
                {...register("observation")}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={4}>
              <SimpleGrid borderRadius={8} boxShadow="md" py="4" px="8">
                <SelectControl
                  control={control}
                  error={errors.frequency}
                  {...register("frequency")}
                  validaded={getValues("frequency") !== ""}
                  isRequired={true}
                  label="Periodecidade"
                  options={[
                    {
                      id: "diaria",
                      name: "Diária",
                    },
                    {
                      id: "semanal",
                      name: "Semanal",
                    },
                    {
                      id: "mensal",
                      name: "Mensal",
                    },
                  ]}
                />
                <SelectControl
                  control={control}
                  error={errors.form_of_payment}
                  {...register("form_of_payment")}
                  validaded={getValues("form_of_payment") !== ""}
                  isRequired={true}
                  label="Forma de Pagamento"
                  options={[
                    {
                      id: "boleto",
                      name: "Boleto",
                    },
                    {
                      id: "caixa",
                      name: "Fundo de Caixa",
                    },
                    {
                      id: "credito",
                      name: "Cartão de crédito",
                    },
    
                    {
                      id: "debito",
                      name: "Cartão de débito",
                    },
                    {
                      id: "dinheiro",
                      name: "Dinheiro",
                    },
                    {
                      id: "pix",
                      name: "Pix",
                    },
                    {
                      id: "transferencia",
                      name: "Transferência",
                    },
                  ]}
                />
                <Input
                  label="Valor"
                  isRequired={true}
                  error={errors.value}
                  validaded={getValues("value") !== 0 && !errors.value}
                  {...register("value")}
                  leftAddon="R$"
                  onChange={(e) => {
                    var value = e.target.value;
                    if (value.length > 2) {
                      value = value
                        .replace(/\D+/g, "")
                        .replace(/([0-9]{2})$/g, ",$1");
                      value = Number(value.replace(",", ".")).toFixed(2);
                      setValue("value", Number(value), {
                        shouldValidate: true,
                      });
                      e.target.value = value;
                    } else if (value === "" || value === "0.00") {
                      setValue("value", 0, {
                        shouldValidate: true,
                      });
                    } else {
                      value = (Number(value) / 100).toString();
                      value = value
                        .replace(/\D+/g, "")
                        .replace(/([0-9]{2})$/g, ",$1");
                      value = Number(value.replace(",", ".")).toString();
                      setValue("value", Number(value), {
                        shouldValidate: true,
                      });
                      e.target.value = value.replace(".", ",");
                    }
                  }}
                />
              </SimpleGrid>
              <SimpleGrid
                bg="#f0f0f0"
                borderRadius={8}
                boxShadow="md"
                py="4"
                px="8"
              >
                <Input
                  type="date"
                  label="Data da Primeira Competência"
                  isRequired={false}
                  validaded={getValues("first_date_of_competence") !== ""}
                  error={errors.first_date_of_competence}
                  {...register("first_date_of_competence")}
                />
                <Input
                  type="date"
                  label="Data da Próxima Competência"
                  isRequired={false}
                  validaded={getValues("next_date_of_competence") !== ""}
                  error={errors.next_date_of_competence}
                  {...register("next_date_of_competence")}
                />
              </SimpleGrid>
            </SimpleGrid>
          </Stack>
        }
        footer={<SubmitButton text="Salvar" form="update-fixed-expense" />}
      />
    </>
  );
}
