import { ElementType } from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
interface AdminBoxProps {
  title: string;
  bg: string;
  color: string;
  icon: ElementType;
  link: string;
  onClick?: () => void;
}
export function AdminBox({
  title,
  bg,
  color,
  icon,
  link,
  onClick,
}: AdminBoxProps) {
  return (
    <Link to={link} onClick={onClick}>
      <Flex
        borderRadius=".25rem"
        bg="white"
        fontSize="2.2rem"
        margin="0 0 10px 0 "
        whiteSpace="nowrap"
        mb="1rem"
        minH="80px"
        p=".5rem"
        w="100%"
        border="1px"
        borderColor={bg}
      >
        <Flex
          borderRadius=".25rem"
          alignItems="center"
          fontSize="1.875rem"
          justify="center"
          textAlign="center"
          w="70px"
          bg={bg}
        >
          <Icon as={icon} color={color} />
        </Flex>
        <Flex flexDir="column" justify="space-around" flex="1" p="0 10px">
          <Text
            display="block"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            fontSize="16px"
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    </Link>
  );
}
