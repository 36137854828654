import { ReactNode, useState } from "react";
import { Flex, Collapse, Icon } from "@chakra-ui/react";
import { Card } from "../../../Card";
import { FaFilter } from "react-icons/fa";
import { InternalHeading } from "../../../atoms/Heading/InternalHeading";
import { BackButton } from "../../../atoms/Button/BackButton";
import { useNavigate } from "react-router-dom";
import { DinamicSolidButton } from "../../../atoms/Button/DinamicSolidButton";
interface HeaderProps {
  title: string;
  children?: ReactNode;
  has_back_button?: boolean;
  defaultOpen?: boolean;
}
export function HeaderWithFilter({
  title,
  children,
  has_back_button,
  defaultOpen = false,
}: HeaderProps) {
  const [open, setOpen] = useState<boolean>(defaultOpen);
  const navigate = useNavigate();

  return (
    <>
      <Flex flexDirection="column" mb="4">
        <Flex justifyContent="flex-end">
          {has_back_button && <BackButton onClick={() => navigate(-1)} />}
          <DinamicSolidButton
            onClick={() => setOpen(!open)}
            color="white"
            bg="#b9b9b9"
            display={{ sm: "unset" }}
          >
            Filtros <Icon as={FaFilter} ml={2} />
          </DinamicSolidButton>
        </Flex>
        <Flex alignItems="center">
          <InternalHeading title={title} />
        </Flex>
      </Flex>
      <Collapse in={open} animateOpacity style={{ overflow: "visible" }}>
        <Card
          mb="4"
          border="#2b88a1"
          w="100%"
          body={
            <>
              <Flex alignItems="center" wrap="wrap" pos="relative">
                {children}
              </Flex>
            </>
          }
        />
      </Collapse>
    </>
  );
}
