import {
  ElementType,
  forwardRef,
  ForwardRefRenderFunction,
  useRef,
} from "react";
import {
  Icon,
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { FormControl } from "./FormControl";
import { FaCheckDouble } from "react-icons/fa";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: any;
  icon?: ElementType;
  isRequired?: boolean;
  description?: string;
  leftAddon?: string;
  leftElement?: string;
  rightAddon?: string;
  validaded?: boolean;
}
const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    error = undefined,
    icon,
    description,
    leftAddon,
    isRequired = false,
    validaded = false,
    rightAddon,
    leftElement,
    ...rest
  },
  ref
) => {
  const rightAddRef = useRef<HTMLDivElement>(null);

  const marginIcon =
    !!icon || rightAddon
      ? rightAddon
        ? `${
            rightAddRef.current?.clientWidth
              ? rightAddRef.current?.clientWidth + 40
              : 80
          }px`
        : "14px"
      : "0";

  return (
    <FormControl
      name={name}
      isRequired={isRequired}
      label={label}
      error={error}
      description={description}
    >
      <InputGroup size="md">
        {leftAddon && <InputLeftAddon children={leftAddon} />}
        {leftElement && <InputLeftElement children={leftElement} />}
        <ChakraInput
          variant="outline"
          name={name}
          id={name}
          ref={ref}
          focusBorderColor="blue.350"
          bg="white"
          border={validaded && !error ? "2px" : "1px"}
          borderColor={validaded && !error ? "green.450" : "gray.400"}
          color="black"
          {...rest}
        />

        <InputRightElement>
          <>
            {validaded && !error && (
              <Icon as={FaCheckDouble} color="green.450" mr={marginIcon} />
            )}
            {!!icon && <InputRightAddon children={<Icon as={icon} />} />}
          </>
        </InputRightElement>

        {rightAddon && (
          <InputRightAddon ref={rightAddRef} children={rightAddon} />
        )}
      </InputGroup>
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
