import {
  Flex,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { Card } from "../../../components/Card";
import { useToast } from "../../../hooks/toast";

import * as yup from "yup";
import { api } from "../../../services/api";

import { FormControl } from "../../../components/Form/FormControl";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { MUITable } from "../../../components/Table/MUITable";
import { formatMoney } from "../../../utils/money";
import {
  createDate,
  formatDatabaseWithOutHours,
  formatDateBrIndexWithOutHours,
} from "../../../utils/formatDate";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../hooks/auth";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { ModalAddButton } from "../../../components/molecules/TableListButton/ModalAddButton";
import { RevenueForm } from "../../../components/templates/Forms/RevenueForm";
import { ExpensesForm } from "../../../components/templates/Forms/ExpensesForm";
import { config } from "../../../services/config";
import { InternalHeader } from "../../../components/templates/InternalHeader";

interface OfxFormat {
  date: Date;
  value: number;
  name: string;
  id: number;
  type: "revenues" | "expenses";
}
interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
  account_plan_category: {
    type: "Receita" | "Despesa";
  };
}

const createRevenueFormSchema = yup.object().shape({
  observation: yup.string().notRequired(),
  value: yup.string().required("Valor é obrigatório"),
  form_of_payment: yup.string().required("Forma de pagamento é obrigatória"),
  account_plan_subcategory_id: yup.object().required("Receita é obrigatória"),
  date_of_competence: yup.string().required("Data do vencimento é obrigatória"),
});

const createExpenseFormSchema = yup.object().shape({
  observation: yup.string().notRequired(),
  value: yup.string().required("Valor é obrigatório"),
  form_of_payment: yup.string().required("Forma de pagamento é obrigatória"),
  account_plan_subcategory_id: yup.object().required("Receita é obrigatória"),
  due_date: yup.string().required("Data do vencimento é obrigatória"),
});

interface ModalProps {
  status: boolean;
  type?: "revenues" | "expenses";
}

interface Favored {
  id: string;
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}

export function Conciliations() {
  const { user } = useAuth();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState<ModalProps>({
    status: false,
  });
  const [id, setId] = useState<number>(-1);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);

  const [founds, setFounds] = useState<OfxFormat[]>([]);
  const [notFounds, setNotFounds] = useState<OfxFormat[]>([]);
  const { register, handleSubmit, formState } = useForm({});
  const {
    register: registerRevenue,
    handleSubmit: handleSubmitRevenue,
    formState: formStateRevenue,
    setValue: setValueRevenue,
    control: controlRevenue,
    getValues: getValuesRevenue,
    reset: resetRevenue,
    watch: watchRevenue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createRevenueFormSchema),
  });
  const { errors: errorsRevenue } = formStateRevenue;

  const {
    register: registerExpense,
    handleSubmit: handleSubmitExpense,
    formState: formStateExpense,
    setValue: setValueExpense,
    control: controlExpense,
    getValues: getValuesExpense,
    reset: resetExpense,
    watch: watchExpense,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createExpenseFormSchema),
  });
  const { errors: errorsExpense } = formStateExpense;
  watchExpense();

  watchRevenue();

  const handleCreateExpense: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        const data = new FormData();
        data.append("file", formValue.file[0]);
        data.append("observation", formValue.observation);
        data.append("due_date", formValue.due_date);
        data.append("date_of_competence", formValue.date_of_competence);
        data.append("favored_name", formValue.favored_name);
        data.append(
          "effective_write_off_date",
          formValue.effective_write_off_date
        );
        data.append("form_of_payment", formValue.form_of_payment);
        data.append(
          "account_plan_subcategory_id",
          formValue.account_plan_subcategory_id.value
        );
        data.append("value", formValue.value);
        data.append("company_id", user.company_id);
        await api.post("/expenses", data, config);

        toast({
          title: "Conta a pagar cadastrada com sucesso!",
          status: "success",
        });
        setIsOpen({
          status: false,
        });
        setFounds([
          ...founds,
          {
            date: formValue.date_of_competence,
            value: Number(formValue.value.replace(",", ".")),
            name: formValue.name,
            type: "expenses",
            id,
          },
        ]);
        setNotFounds(notFounds.filter((notFound) => notFound.id !== id));
        resetExpense();
        setValueExpense("account_plan_subcategory_id", "");
        setValueExpense("form_of_payment", "");
      } catch (err) {
        toast({
          title: "Não foi possível cadastrar a conta a pagar",
          status: "error",
        });
      }
    },
    [
      founds,
      id,
      notFounds,
      resetExpense,
      setValueExpense,
      toast,
      user.company_id,
    ]
  );

  const handleCreateRevenue: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/revenues", {
          observation: formValue.observation,
          date_of_competence: formValue.date_of_competence,
          date_of_receipt_of_money: formValue.date_of_receipt_of_money,
          form_of_receipt: formValue.form_of_receipt,
          account_plan_subcategory_id: formValue.account_plan_subcategory_id,
          value: formValue.value,
          company_id: user.company_id,
        });

        toast({
          title: "Conta a receber cadastrada com sucesso!",
          status: "success",
        });
        setIsOpen({
          status: false,
        });
        setFounds([
          ...founds,
          {
            date: formValue.date_of_receipt_of_money,
            value: Number(formValue.value.replace(",", ".")),
            name: formValue.name,
            id,
            type: "revenues",
          },
        ]);
        setNotFounds(
          notFounds.filter((revenueNotFound) => revenueNotFound.id !== id)
        );
        resetRevenue();
        setValueRevenue("account_plan_subcategory_id", "");
        setValueRevenue("form_of_payment", "");
      } catch (err) {
        toast({
          title: "Não foi possível cadastrar a conta a receber",
          status: "error",
        });
      }
    },
    [
      founds,
      notFounds,
      id,
      resetRevenue,
      setValueRevenue,
      toast,
      user.company_id,
    ]
  );

  const handleUploadOfx: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      const data = new FormData();
      data.append("file", formValue.file[0]);
      api
        .post(`/conciliations`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setFounds(response.data.found);
          setNotFounds(response.data.not_found);
          setAccountPlanSubcategories(response.data.accountPlanSubcategories);
          toast({
            title: "OFX adicionado com sucesso!",
            status: "success",
          });
        })
        .catch((err) => {
          toast({
            title: "Não foi possível adicionar o OFX",
            status: "error",
          });
        });
    },
    [toast]
  );
  const [favored, setFavored] = useState<Favored[]>([]);

  useEffect(() => {
    api.get(`/favored/company/${user.company_id}`).then((response) => {
      setFavored(response.data);
    });
  }, [user.company_id]);

  const { errors } = formState;
  return (
    <>
      <InternalHeader
        title="Conciliação"
        has_back_button={true}
        has_filter={false}
      />
      <Modal
        isOpen={isOpen.status}
        onClose={() =>
          setIsOpen({
            status: false,
          })
        }
        size="4xl"
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar - {getValuesExpense("name")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen.type === "expenses" && (
              <ExpensesForm
                register={registerExpense}
                accountPlanSubcategories={accountPlanSubcategories.filter(
                  (accountPlanSubcategory) =>
                    accountPlanSubcategory.account_plan_category.type ===
                    "Despesa"
                )}
                handle={handleCreateExpense}
                control={controlExpense}
                errors={errorsExpense}
                getValues={getValuesExpense}
                handleSubmit={handleSubmitExpense}
                setValue={setValueExpense}
                favored={favored}
                setFavored={setFavored}
              />
            )}
            {isOpen.type === "revenues" && (
              <RevenueForm
                register={registerRevenue}
                accountPlanSubcategories={accountPlanSubcategories.filter(
                  (accountPlanSubcategory) =>
                    accountPlanSubcategory.account_plan_category.type ===
                    "Receita"
                )}
                handle={handleCreateRevenue}
                control={controlRevenue}
                errors={errorsRevenue}
                getValues={getValuesRevenue}
                handleSubmit={handleSubmitRevenue}
                setValue={setValueRevenue}
                generatedReceiptPayment={getValuesRevenue(
                  "date_of_receipt_of_money"
                )}
              />
            )}
          </ModalBody>

          <ModalFooter borderTopColor="gray.300" borderTopWidth="1px" mt={5}>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                setIsOpen({
                  status: false,
                });
                resetRevenue();
                resetExpense();
              }}
            >
              Cancelar
            </Button>
            {isOpen.type === "expenses" && (
              <SubmitButton mt={"0"} formState={formStateExpense} />
            )}
            {isOpen.type === "revenues" && (
              <SubmitButton mt={"0"} formState={formStateRevenue} />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Card
        border="#216ca5"
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleUploadOfx)}
            id="add-form-ofx"
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
              <FormControl
                label="Ofx"
                isRequired={true}
                name="file"
                error={errors.file}
              >
                <input type="file" {...register("file")} required />
              </FormControl>
            </SimpleGrid>
          </Flex>
        }
        footer={<SubmitButton formState={formState} form="add-form-ofx" />}
      />
      {notFounds.length > 0 && (
        <Card
          mt={2}
          title="Pendetes"
          header={<></>}
          body={
            <MUITable
              data={notFounds.map((notFound) => [
                notFound.id,
                notFound.name,
                notFound.value,
                notFound.type,
                notFound.date,
              ])}
              columns={[
                {
                  name: "Id",
                  options: {
                    filter: false,
                    display: "none",
                  },
                },
                {
                  name: "Nome",
                },
                {
                  name: "Valor",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: number) => {
                      return formatMoney(value);
                    },
                  },
                },
                {
                  name: "Tipo",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (type: string) => {
                      return type === "revenues" ? "Receita" : "Despesa";
                    },
                  },
                },
                {
                  name: "Data",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: string) => {
                      return formatDateBrIndexWithOutHours(value);
                    },
                  },
                },
                {
                  name: "Ações",
                  options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (dataIndex: any, rowIndex: any) => {
                      return (
                        <ButtonListButton>
                          <ModalAddButton
                            onClick={() => {
                              setIsOpen({
                                status: true,
                                type: rowIndex.rowData[3],
                              });
                              setId(rowIndex.rowData[0]);

                              if (rowIndex.rowData[3] === "revenues") {
                                setValueRevenue("name", rowIndex.rowData[1]);
                                setValueRevenue(
                                  "account_plan_subcategory_id",
                                  ""
                                );
                                setValueRevenue(
                                  "value",
                                  formatMoney(rowIndex.rowData[2])
                                    .replace("R$ ", "")
                                    .replace(".", "")
                                );
                                setValueRevenue(
                                  "date_of_receipt_of_money",
                                  formatDatabaseWithOutHours(
                                    createDate(rowIndex.rowData[4])
                                  )
                                );

                                if (String(rowIndex.rowData[1]).match("PIX")) {
                                  setValueRevenue("form_of_payment", "pix");
                                } else {
                                  setValueRevenue("form_of_payment", "");
                                }
                              }

                              if (rowIndex.rowData[3] === "expenses") {
                                setValueExpense("name", rowIndex.rowData[1]);
                                setValueExpense(
                                  "value",
                                  formatMoney(rowIndex.rowData[2])
                                    .replace("R$ ", "")
                                    .replace(".", "")
                                );
                                setValueExpense(
                                  "account_plan_subcategory_id",
                                  ""
                                );

                                setValueExpense(
                                  "effective_write_off_date",
                                  formatDatabaseWithOutHours(
                                    createDate(rowIndex.rowData[4])
                                  )
                                );
                                if (String(rowIndex.rowData[1]).match("PIX")) {
                                  setValueExpense("form_of_payment", "pix");
                                } else {
                                  setValueExpense("form_of_payment", "");
                                }
                              }
                            }}
                          />
                        </ButtonListButton>
                      );
                    },
                  },
                },
              ]}
            />
          }
        />
      )}

      {founds.length > 0 && (
        <Card
          mt={2}
          title="Já cadastrados"
          header={<></>}
          body={
            <MUITable
              data={founds.map((found) => [
                found.name,
                found.value,
                found.type,
                found.date,
              ])}
              columns={[
                {
                  name: "Nome",
                },
                {
                  name: "Valor",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: number) => {
                      return formatMoney(value);
                    },
                  },
                },
                {
                  name: "Tipo",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (type: string) => {
                      return type === "revenues" ? "Receita" : "Despesa";
                    },
                  },
                },
                {
                  name: "Data",
                  options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value: string) => {
                      return formatDateBrIndexWithOutHours(value);
                    },
                  },
                },
              ]}
            />
          }
        />
      )}
    </>
  );
}
