import { FaEdit } from "react-icons/fa";
import { TableListModalButton } from "../../../atoms/Button/TableListModalButton";
interface EditModalButtonProps {
  onClick: () => void;
}

export function EditModalButton({ onClick }: EditModalButtonProps) {
  return (
    <TableListModalButton
      onClick={onClick}
      color="white"
      bg="#2b88a1"
      icon={FaEdit}
      label="Editar"
    />
  );
}
