import { Datum } from "@ant-design/charts";
import { DualAxes } from "@ant-design/plots";
import { formatMoney } from "../../../../utils/money";
interface ColumnLineChartProps {
  column: {
    type: string;
    value: number;
    time: string;
  }[];
  line: {
    cogs: number;
    type: string;
    time: string;
  }[];
}
export function DualAxesChart({ column, line }: ColumnLineChartProps) {
  const max = Math.max(...column.map((item) => item.value)) * 1.1;
  var config = {
    data: [column, line],
    xField: "time",
    yField: ["value", "cogs"],
    seriesField: ["CMV"],
    yAxis: {
      value: {
        min: 0,
        max,
      },
      cogs: {
        min: 0,
        max,
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        isGroup: true,
        seriesField: "type",
        columnWidthRatio: 0.4,
        tooltip: {
          formatter: (datum: Datum) => {
            return {
              name: datum.type,
              value: formatMoney(datum.value),
            };
          },
        },
        color: (datum: Datum) =>
          datum.type === "Insumos" ? "#e36877" : "#71bf7c",
      },
      {
        geometry: "line",
        seriesField: "type",
        smooth: true,
        columnWidthRatio: 0,
        tooltip: {
          formatter: (datum: Datum) => {
            return {
              name: datum.type,
              value: formatMoney(datum.cogs),
            };
          },
        },
        lineStyle: {
          lineWidth: 5,
        },
        color: ["#ffcf59", "#1a365d"],
        point: {
          size: line.length > 1 ? 5 : 10,
        },
      },
    ],
    interactions: [{ type: "element-highlight" }, { type: "active-region" }],
  };
  return <DualAxes {...config} />;
}
