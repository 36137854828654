import { Flex, Icon, List, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { Card } from "../../../components/Card";
import { useToast } from "../../../hooks/toast";
import { api } from "../../../services/api";

interface AccountPlan {
  id: string;
  name: string;
  description: string;
}

interface Company {
  id: string;
  name: string;
  account_plans_id: string[];
}

const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {} as any;
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function CreateAccountPlansCompany() {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [selectedAccountPlans, setSelectedAccountPlans] = useState<
    AccountPlan[]
  >([]);
  const [accountPlans, setAccountPlans] = useState<AccountPlan[]>([]);
  const [company, setCompany] = useState<Company>({
    id: "",
    name: "",
    account_plans_id: [],
  });

  const { handleSubmit } = useForm();

  useEffect(() => {
    api
      .get(`/companies/${id}`)
      .then((response) => {
        setCompany(response.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    api
      .get("/accountPlans")
      .then((response) => {
        const { selected, options } = response.data.reduce(
          (acc: any, accountPlan: any) => {
            const index = company.account_plans_id.findIndex(
              (a) => a === accountPlan.id
            );
            if (index !== -1) {
              acc.selected.push(accountPlan);
            } else {
              acc.options.push(accountPlan);
            }
            return acc;
          },
          { selected: [] as AccountPlan[], options: [] as AccountPlan[] }
        );
        setAccountPlans(options);
        setSelectedAccountPlans(selected);
      })
      .catch((err) => console.log(err));
  }, [company]);

  const onDragEnd = useCallback(
    (event: any) => {
      const { source, destination } = event;

      if (!destination) {
        return;
      }

      if (source.droppableId !== destination.droppableId) {
        const result = move(
          source.droppableId === "items" ? accountPlans : selectedAccountPlans,
          destination.droppableId === "items"
            ? accountPlans
            : selectedAccountPlans,
          source,
          destination
        );

        setAccountPlans(result.items);
        setSelectedAccountPlans(result.selected);
      } else if (source.droppableId === destination.droppableId) {
        const result = reorder(
          source.droppableId === "items" ? accountPlans : selectedAccountPlans,
          source.index,
          destination.index
        );
        if (source.droppableId === "items") {
          setAccountPlans(result);
        } else {
          setSelectedAccountPlans(result);
        }
      }
    },
    [accountPlans, selectedAccountPlans]
  );

  const handleCreateAccountPlansCompany: SubmitHandler<any> =
    useCallback(async () => {
      api
        .post("/accountPlansCompanies/many", {
          account_plans_company: selectedAccountPlans.map((acc) => ({
            account_plan_id: acc.id,
            company_id: company.id,
          })),
          company_id: company.id,
        })
        .then((response) => {
          toast({
            title: "Planos de conta cadastrados com sucesso!",
            status: "success",
          });
        })
        .catch((err) => {
          toast({
            title: "Não foi possível realizar o cadastro",
            status: "error",
          });
        });
    }, [selectedAccountPlans, company, toast]);

  return (
    <>
      <Card
        title={`Planos de Conta - ${company.name}`}
        border="#216ca5"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <>
            <Stack
              as="form"
              onSubmit={handleSubmit(handleCreateAccountPlansCompany)}
              id="add-account-plan-company"
            >
              <SimpleGrid>
                <Flex overflowX="scroll">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                      droppableId="items"
                      type="COLUMN"
                      isCombineEnabled
                    >
                      {(provided: any) => {
                        return (
                          <Flex
                            m="2"
                            w="50%"
                            bg="#f0f0f0"
                            borderRadius={8}
                            boxShadow="md"
                            padding={8}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            flexDirection="column"
                            onClick={() => {}}
                            minWidth="320px"
                          >
                            <Text>Planos de Conta</Text>{" "}
                            <List w="100%" height="400px" overflowY="scroll">
                              {accountPlans.map((accountPlan, index) => (
                                <Draggable
                                  key={accountPlan.id}
                                  draggableId={accountPlan.id}
                                  index={index}
                                >
                                  {(provided: any) => {
                                    return (
                                      <Flex
                                        boxShadow="md"
                                        borderRadius={4}
                                        bg="#ffffff"
                                        p="2"
                                        my="2"
                                        mx="2"
                                        alignItems="center"
                                        justifyContent={"space-between"}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Icon as={FaRegSquare} mr="2" />
                                        <Flex w="100%" flexDirection={"column"}>
                                          {accountPlan.name}
                                          <Text as="small">
                                            {accountPlan.description}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                    );
                                  }}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </List>
                          </Flex>
                        );
                      }}
                    </Droppable>
                    <Droppable
                      droppableId="selected"
                      type="COLUMN"
                      isCombineEnabled
                    >
                      {(provided: any) => {
                        return (
                          <Flex
                            m="2"
                            w="50%"
                            bg="#c9c9c9"
                            borderRadius={8}
                            boxShadow="md"
                            padding={8}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            flexDirection="column"
                            minWidth="320px"
                          >
                            <Text>Selecionados</Text>
                            <List w="100%" height="400px" overflowY="scroll">
                              {selectedAccountPlans.map(
                                (accountPlan, index) => (
                                  <Draggable
                                    key={accountPlan.id}
                                    draggableId={accountPlan.id}
                                    index={index}
                                  >
                                    {(provided: any) => {
                                      return (
                                        <Flex
                                          boxShadow="md"
                                          borderRadius={4}
                                          bg="#ffffff"
                                          p="2"
                                          my="2"
                                          mx="2"
                                          alignItems="center"
                                          justifyContent={"space-between"}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Icon as={FaCheckSquare} mr="2" />
                                          <Flex
                                            w="100%"
                                            flexDirection={"column"}
                                          >
                                            {accountPlan.name}
                                            <Text as="small">
                                              {accountPlan.description}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      );
                                    }}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </List>
                          </Flex>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </Flex>
              </SimpleGrid>
            </Stack>
          </>
        }
        footer={<SubmitButton form="add-account-plan-company" />}
      />
    </>
  );
}
