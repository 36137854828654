import React from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../hooks/auth";

type RouteProps = {
  element: JSX.Element;
} & ReactDOMRouteProps;

export function ManagerRoute({ element, ...rest }: RouteProps) {
  const { user } = useAuth();
  const location = useLocation();

  if (user) {
    if (user.is_admin <= 4) {
      return element;
    }

    return <Navigate to="/error/403" />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}
