import { ReactNode } from "react";
import { Flex, Box, Text, FlexProps, SimpleGrid } from "@chakra-ui/react";

interface CardProps extends FlexProps {
  border?: string;
  title?: string;
  header?: ReactNode;
  footer?: ReactNode;
  body: ReactNode;
}
export function Card({
  title,
  header,
  footer,
  body,
  border,
  ...rest
}: CardProps) {
  return (
    <Flex
      flexDir="column"
      minW="0"
      wordWrap="break-word"
      bg="white"
      bgClip="border-box"
      border="1px solid rgba(0, 0, 0, 0.125)"
      borderRadius="0.25rem"
      borderTop={border ? `3px solid ${border}` : ""}
      {...rest}
    >
      {title && header && (
        <SimpleGrid
          bg="transparent"
          borderBottom="1px solid rgba(0, 0, 0, 0.125)"
          p="0.75rem 1.25rem"
          columns={{ base: 1, md: 1 }}
        >
          <Text fontWeight="bold" fontSize={22} color="gray.700">
            {title}
          </Text>
          <Flex justifyContent="flex-end">{header}</Flex>
        </SimpleGrid>
      )}
      <Box flex="1" minH="1px" p="1.25rem">
        {body}
      </Box>
      {!!footer && (
        <Box
          p="0.75rem 1.25rem"
          bg="rgba(0, 0, 0, 0.03)"
          borderTop="1px solid rgba(0, 0, 0, 0.125)"
          alignItems="end"
        >
          <Flex float="right">{footer}</Flex>
        </Box>
      )}
    </Flex>
  );
}
