import { Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import { Input } from "../../../components/Form/Input";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { api } from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { checkPermission } from "../../../utils/checkPermission";
import { useToast } from "../../../hooks/toast";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";

interface Company {
  id: string;
  name: string;
}
const schema = yup.object().shape({
  name: yup.string().required("O nome é obrigatório"),
  email: yup.string().email().required("O e-mail é obrigatório"),
  is_admin: yup.string().required("O nível de usuário obrigatório"),
  company_id: yup.string().required("A empresa obrigatória"),
});

export function CreateUserRegistrationPage() {
  const { user } = useAuth();
  const toast = useToast();
  const [companies, setCompanies] = useState<Company[]>([]);

  const navigate = useNavigate();

  const { register, handleSubmit, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  useEffect(() => {
    api.get("/companies").then((response) => setCompanies(response.data));
  }, []);

  const handleCreateUserRegistration: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      await api
        .post("/users/registration", {
          name: formValue.name,
          email: formValue.email,
          company_id: formValue.company_id,
          companies_id: formValue.companies_id,
          is_admin: formValue.is_admin,
        })
        .then((response) => {
          toast({
            title: "Usuário cadastrado com sucesso!",
            status: "success",
          });
          navigate(
            `/usuario/link?token=${response.data.token}&email=${response.data.user.email}`
          );
        })
        .catch((err) => {
          toast({
            title: "Erro ao cadastrar usuário",
            status: "error",
          });
        });
    },
    [navigate, toast]
  );

  return (
    <>
      <InternalHeader
        title="Criando link de registro de usuário"
        has_filter={false}
        has_back_button={true}
      />
      <Card
        body={
          <Flex
            as="form"
            id="create-link-user-registration"
            width="100%"
            flexDir="column"
            onSubmit={handleSubmit(handleCreateUserRegistration)}
          >
            <Stack spacing={4}>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={["6", "8"]}
                w="100%"
              >
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
                <Input
                  type="email"
                  label="E-mail"
                  isRequired={true}
                  error={errors.email}
                  {...register("email")}
                />
              </SimpleGrid>
              <SimpleGrid minChildWidth="240px" spacing={["6", "8"]} w="100%">
                <SelectControl
                  control={control}
                  label="Empresa"
                  isRequired={true}
                  error={errors.company_id}
                  {...register("company_id")}
                  options={companies}
                />
                <SelectControl
                  control={control}
                  label="Nível"
                  isRequired={true}
                  error={errors.is_admin}
                  {...register("is_admin")}
                  options={[
                    {
                      id: 0,
                      name: "Super Administrador",
                    },
                    {
                      id: 1,
                      name: "Administrador",
                    },
                    {
                      id: 2,
                      name: "Dono de Rede",
                    },
                    {
                      id: 3,
                      name: "Dono de Empresa",
                    },
                    {
                      id: 4,
                      name: "Gestor Operação",
                    },
                    {
                      id: 5,
                      name: "Financeiro - Frente de Loja",
                    },
                  ].filter((value) => {
                    if (
                      checkPermission({
                        is_admin: user.is_admin,
                        type: "Super Admin",
                      })
                    ) {
                      return true;
                    }
                    return value.id !== 0;
                  })}
                />
              </SimpleGrid>
            </Stack>
          </Flex>
        }
        footer={
          <SubmitButton
            form="create-link-user-registration"
            formState={formState}
          />
        }
      />
    </>
  );
}
