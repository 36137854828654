import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./hooks/auth";
import { SidebarDrawerProvider } from "./hooks/sidebarDrawer";
import Routes from "./routes";
import { theme } from "./styles/theme";
import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert" style={{ maxWidth: "300px" }}>
      <p>{error.message}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export const App = () => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <SidebarDrawerProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </SidebarDrawerProvider>
      </AuthProvider>
    </ChakraProvider>
  </ErrorBoundary>
);
