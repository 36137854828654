import {
  Flex,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { ElementType, useState } from "react";
import { useSidebarDrawer } from "../../../../hooks/sidebarDrawer";
import { SidebarIcon } from "../../../atoms/Icon/SidebarIcon";
import { SidebarText } from "../../../atoms/Text/SidebarText";
import { NewBagdeNavLink } from "../../../atoms/Badge/NewBagdeNavLink";
import { SoonBagdeNavLink } from "../../../atoms/Badge/SoonBagdeNavLink";
import { BagdeLockNavLink } from "../../../atoms/Badge/BagdeLock";

interface NavLinkProps extends ChakraLinkProps {
  icon: ElementType;
  children: string;
  href: string;
  tag?: "new" | "soon" | "lock" | false;
}
export function NavLink({
  icon,
  children,
  href,
  tag = false,
  ...rest
}: NavLinkProps) {
  const location = useLocation();
  const { onClose } = useSidebarDrawer();
  const [color, setColor] = useState<string>("");
  return (
    <ChakraLink
      as={Link}
      display="flex"
      align="stretch"
      {...rest}
      to={href}
      onMouseEnter={() => setColor("blue.350")}
      onMouseLeave={() => setColor("black")}
      _focus={{
        outline: "none",
      }}
      _hover={{
        outline: "none",
      }}
      onClick={() => onClose()}
      alignItems={"center"}
    >
      <Flex align="center">
        <SidebarIcon
          icon={icon}
          color={location.pathname === href ? "blue.350" : color}
        />
        <SidebarText
          text={children}
          color={location.pathname === href ? "blue.350" : color}
        />
      </Flex>
      
      {tag === "new" && <NewBagdeNavLink />}
      {tag === "soon" && <SoonBagdeNavLink />}
      {tag === "lock" && <BagdeLockNavLink />}
    </ChakraLink>
  );
}
