import { SimpleGrid } from "@chakra-ui/react";
import { FaFile, FaFolderOpen } from "react-icons/fa";
import { AdminBox } from "../../../components/templates/Widget/AdminBox";
import { InternalHeader } from "../../../components/templates/InternalHeader";

export function ConfigShoppingAdminPage() {
  return (
    <>
      <InternalHeader
        title="Configurações - Gestão de Compra"
        has_filter={false}
        has_back_button={true}
      />
      <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={2}>
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFile}
          link="/contas"
          title="Contas"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFolderOpen}
          link="/planos-de-conta"
          title="Plano de conta"
        />
        <AdminBox
          bg="blue.350"
          color="white"
          icon={FaFolderOpen}
          link="/receitas/despesas"
          title="Receitas/Despesas"
        />
      </SimpleGrid>
    </>
  );
}
