import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { Link, useNavigate } from "react-router-dom";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { useAuth } from "../../../hooks/auth";
import { formatMoney } from "../../../utils/money";
import {
  formatDatabaseWithOutHours,
  formatDateBrIndexWithOutHours,
} from "../../../utils/formatDate";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { useQuery } from "../../../hooks/query";
import { Table } from "../../../components/templates/Table";
import { Button, Flex, SimpleGrid } from "@chakra-ui/react";
import { FileButton } from "../../../components/molecules/FileButton";
import { ModalEffectiveWriteOffExpense } from "../../../components/templates/Modal/ModalEffectiveWriteOffExpense";
import { Box } from "../../../components/templates/Widget/Box";
import { FaClipboardList, FaDollarSign } from "react-icons/fa";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { checkPermission } from "../../../utils/checkPermission";
import { shade } from "polished";
import { DateRange } from "../../../components/templates/Filters/DateRangePicker";

interface Expense {
  id: string;
  value: number;
  form_of_payment: string;
  effective_write_off_date?: Date;
  date_of_competence: Date;
  due_date: Date;
  file_url?: string;
  file?: string;
  account_plan_subcategory: {
    name: string;
  };
  favored?: {
    name: string;
  };
}
interface Selected {
  date_start_write_off_date?: string;
  date_end_write_off_date?: string;
  date_start_competence?: string;
  date_end_competence?: string;
  date_start_due_date?: string;
  date_end_due_date?: string;
}
interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}
export function ListExpenses() {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const query = useQuery();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<Selected>({
    date_end_competence:
      query.get("date_end_competence") ??
      formatDatabaseWithOutHours(new Date()),
    date_start_competence:
      query.get("date_start_competence") ??
      formatDatabaseWithOutHours(sevenDaysAgo),
    date_end_write_off_date: query.get("date_end_write_off_date") ?? "",
    date_start_write_off_date: query.get("date_start_write_off_date") ?? "",
    date_end_due_date: query.get("date_end_due_date") ?? "",
    date_start_due_date: query.get("date_start_due_date") ?? "",
  } as Selected);

  const [total, setTotal] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 30,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  useEffect(() => {
    setLoading(false);
    api
      .get(
        `expenses/pagination/company/${user.company_id}?date_start_due_date=${selected.date_start_due_date}&date_end_due_date=${selected.date_end_due_date}&date_start_write_off_date=${selected.date_start_write_off_date}&date_end_write_off_date=${selected.date_end_write_off_date}&date_start_competence=${selected.date_start_competence}&date_end_competence=${selected.date_end_competence}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setExpenses(response.data.expenses);
        setTotal(response.data.total);
        setTotalValue(response.data.value);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        setLoading(true);
        navigate(`/error/${err.response?.status}`);
      });
  }, [defaultParams, navigate, selected, user.company_id]);

  return (
    <>
      <InternalHeader
        title="Despesas"
        has_filter={false}
        defaultOpen={true}
      ></InternalHeader>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={2}>
        <Box
          bg="#C44A54"
          color="#f0f0f0"
          border="#C44A54"
          icon={FaDollarSign}
          title="Total Despesas"
          value={formatMoney(totalValue)}
        />
        <Box
          bg="#CC8956"
          color="#f0f0f0"
          border="#CC8956"
          icon={FaClipboardList}
          title="Títulos"
          value={total}
        />
      </SimpleGrid>

      <Card
        border="#216ca5"
        title=" "
        header={
          <Flex justifyContent={"flex-end"} wrap="wrap">
            {checkPermission({
              is_admin: user.is_admin,
              type: "Manager",
            }) ? (
              <Button
                as={Link}
                to={"/contas-fixa-pagar"}
                bg="#fd9000"
                color="white"
                mr="4px"
                my="4px"
                _hover={{
                  backgroundColor: shade(0.2, "#fd9000"),
                }}
                _active={{
                  backgroundColor: shade(0.2, "#fd9000"),
                }}
              >
                {" "}
                Gerenciar Conta Fixa
              </Button>
            ) : (
              <></>
            )}
            <AddButton link="/conta-pagar/cadastrar" />
          </Flex>
        }
        body={
          <Table
            loading={loading}
            message="Buscando as contas a pagar"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/contas-pagar?date_start_due_date=${selected.date_start_due_date}&date_end_due_date=${selected.date_end_due_date}&date_start_write_off_date=${selected.date_start_write_off_date}&date_end_write_off_date=${selected.date_end_write_off_date}&date_start_competence=${selected.date_start_competence}&date_end_competence=${selected.date_end_competence}&offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={expenses.map((expense) => {
              return {
                tr: [
                  {
                    name: expense.account_plan_subcategory.name,
                  },
                  {
                    name: expense.favored ? expense.favored.name : "",
                  },
                  {
                    name: expense.date_of_competence
                      ? formatDateBrIndexWithOutHours(
                          expense.date_of_competence
                        )
                      : "",
                  },
                  {
                    name: expense.due_date
                      ? formatDateBrIndexWithOutHours(expense.due_date)
                      : "",
                  },
                  {
                    name: expense.effective_write_off_date ? (
                      formatDateBrIndexWithOutHours(
                        expense.effective_write_off_date
                      )
                    ) : (
                      <ButtonListButton>
                        {checkPermission({
                          is_admin: user.is_admin,
                          type: "Manager",
                        }) ? (
                          <ModalEffectiveWriteOffExpense
                            updateExpense={(expense: Expense) => {
                              setExpenses(
                                expenses.map((e) => {
                                  if (e.id === expense.id) {
                                    return {
                                      ...e,
                                      effective_write_off_date:
                                        expense.effective_write_off_date
                                          ? new Date(
                                              String(
                                                expense.effective_write_off_date
                                              ) + "T03:00:00.000Z"
                                            )
                                          : undefined,
                                    };
                                  }

                                  return e;
                                })
                              );
                            }}
                            expenseId={expense.id}
                          />
                        ) : (
                          <></>
                        )}
                      </ButtonListButton>
                    ),
                  },
                  {
                    name: formatMoney(expense.value),
                  },
                  {
                    name: expense.form_of_payment,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        {expense.file && (
                          <FileButton
                            expense_id={expense.id}
                            title={`${expense.account_plan_subcategory.name}${
                              expense.favored
                                ? ` - ${expense.favored.name}`
                                : ""
                            }`}
                          />
                        )}
                        {!expense.effective_write_off_date ? (
                          <>
                            <EditButton link={`/conta-pagar/${expense.id}`} />
                            <DeleteModal
                              onDelete={(id) => {
                                setExpenses(
                                  expenses.filter(
                                    (expense) => expense.id !== id
                                  )
                                );
                              }}
                              id={expense.id}
                              route="expenses/"
                              error_message="Não foi possível remover a conta a pagar"
                              success_message="Conta a pagar removida com sucesso"
                            />
                          </>
                        ) : checkPermission({
                            is_admin: user.is_admin,
                            type: "Owner",
                          }) ? (
                          <>
                            <EditButton link={`/conta-pagar/${expense.id}`} />
                            {checkPermission({
                              is_admin: user.is_admin,
                              type: "Super Admin",
                            }) ? (
                              <DeleteModal
                                onDelete={(id) => {
                                  setExpenses(
                                    expenses.filter(
                                      (expense) => expense.id !== id
                                    )
                                  );
                                }}
                                id={expense.id}
                                route="expenses/"
                                error_message="Não foi possível remover a conta a pagar"
                                success_message="Conta a pagar removida com sucesso"
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "accountPlanSubcategories.name",
                label: "Conta",
                options: {
                  sort: true,
                },
              },
              {
                name: "favored",
                label: "Favorecido",
                options: {
                  sort: true,
                },
              },
              {
                name: "date_of_competence",
                label: "Data Emissão",
                options: {
                  sort: true,
                },
                filter: (
                  <DateRange
                    showClearDates={true}
                    label="Emissão"
                    dateStart={selected.date_start_competence}
                    dateEnd={selected.date_end_competence}
                    onSelectDate={(start, end) => {
                      setSelected({
                        ...selected,
                        date_end_competence: end,
                        date_start_competence: start,
                      });
                      navigate(
                        `/contas-pagar?date_start_due_date=${selected.date_start_due_date}&date_end_due_date=${selected.date_end_due_date}&date_start_write_off_date=${selected.date_start_write_off_date}&date_end_write_off_date=${selected.date_end_write_off_date}&date_start_competence=${start}&date_end_competence=${end}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
                      );
                    }}
                  />
                ),
              },
              {
                name: "due_date",
                label: "Data de Vencimento",
                options: {
                  sort: true,
                },
                filter: (
                  <DateRange
                    showClearDates={true}
                    label="Vencimento"
                    dateStart={selected.date_start_due_date}
                    dateEnd={selected.date_end_due_date}
                    onSelectDate={(start, end) => {
                      setSelected({
                        ...selected,
                        date_end_due_date: end,
                        date_start_due_date: start,
                      });

                      navigate(
                        `/contas-pagar?date_start_due_date=${start}&date_end_due_date=${end}&date_start_write_off_date=${selected.date_start_write_off_date}&date_end_write_off_date=${selected.date_end_write_off_date}&date_start_competence=${selected.date_start_competence}&date_end_competence=${selected.date_end_competence}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
                      );
                    }}
                  />
                ),
              },
              {
                name: "effective_write_off_date",
                label: "Baixa Efetiva",
                options: {
                  sort: true,
                },
                filter: (
                  <DateRange
                    showClearDates={true}
                    label="Baixa Efetiva"
                    dateStart={selected.date_start_write_off_date}
                    dateEnd={selected.date_end_write_off_date}
                    onSelectDate={(start, end) => {
                      setSelected({
                        ...selected,
                        date_end_write_off_date: end,
                        date_start_write_off_date: start,
                      });

                      navigate(
                        `/contas-pagar?date_start_due_date=${selected.date_start_due_date}&date_end_due_date=${selected.date_end_due_date}&date_start_write_off_date=${start}&date_end_write_off_date=${end}&date_start_competence=${selected.date_start_competence}&date_end_competence=${selected.date_end_competence}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
                      );
                    }}
                  />
                ),
              },
              {
                name: "value",
                label: "Valor",
                options: {
                  sort: true,
                },
              },
              {
                name: "form_of_payment",
                label: "Forma de Pagamento",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
