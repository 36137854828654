import { Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { Input } from "../../../Form/Input";
import { MultiSelectControl } from "../../Form/MultiSelectControl";
import { SelectControl } from "../../Form/SelectControl";

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface RevenuesFormProps {
  accountPlanSubcategories: AccountPlanSubcategory[];
  handle: SubmitHandler<FieldValues>;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  getValues: UseFormGetValues<FieldValues>;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  generatedReceiptPayment: string;
}

export function RevenueForm({
  generatedReceiptPayment,
  accountPlanSubcategories,
  control,
  errors,
  getValues,
  handle,
  handleSubmit,
  register,
  setValue,
}: RevenuesFormProps) {
  return (
    <Flex
      as="form"
      flexDir="column"
      w="100%"
      onSubmit={handleSubmit(handle)}
      id="add-form"
    >
      <Stack spacing="4">
        <SimpleGrid spacingX={4}>
          <MultiSelectControl
            options={accountPlanSubcategories.map((acc) => ({
              label: acc.name,
              value: acc.id,
            }))}
            {...register("account_plan_subcategory_id")}
            onChange={(selected) => {
              setValue("account_plan_subcategory_id", selected, {
                shouldValidate: true,
              });
            }}
            value={getValues("account_plan_subcategory_id") || ""}
            validaded={
              getValues("account_plan_subcategory_id") &&
              getValues("account_plan_subcategory_id").value &&
              !errors.account_plan_subcategory_id
            }
            isMulti={false}
            label="Receita"
            error={errors.account_plan_subcategory_id}
            isRequired={true}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={4}>
          <SimpleGrid borderRadius={8} boxShadow="md" py="4" px="8">
            <SelectControl
              control={control}
              error={errors.form_of_receipt}
              {...register("form_of_receipt")}
              isRequired={true}
              validaded={
                getValues("form_of_receipt") &&
                getValues("form_of_receipt") !== ""
              }
              label="Forma de recebimento"
              options={[
                {
                  id: "dinheiro",
                  name: "Dinheiro",
                },
                {
                  id: "credito",
                  name: "Cartão de crédito",
                },

                {
                  id: "debito",
                  name: "Cartão de débito",
                },
                {
                  id: "vale",
                  name: "Vale",
                },
                {
                  id: "online",
                  name: "Pagamento online",
                },
                {
                  id: "pix",
                  name: "Pix",
                },
                {
                  id: "assinado",
                  name: "Assinado",
                },
                {
                  id: "boleto",
                  name: "Boleto",
                },
                {
                  id: "cortesia",
                  name: "Cortesia",
                },
              ]}
            />
            <Input
              label="Valor"
              isRequired={true}
              error={errors.value}
              {...register("value")}
              leftAddon="R$"
              validaded={getValues("value") && getValues("value") !== ""}
              onChange={(e) => {
                var value = e.target.value;
                if (value.length > 2) {
                  value = value
                    .replace(/\D+/g, "")
                    .replace(/([0-9]{2})$/g, ",$1");
                  e.target.value = value;
                  setValue("value", value);
                }
              }}
            />
          </SimpleGrid>
          <Flex
            bg="#f0f0f0"
            borderRadius={8}
            boxShadow="md"
            py="4"
            px="8"
            flexDirection={"column"}
          >
            <Input
              type="date"
              label="Data da Receita"
              validaded={
                getValues("date_of_competence") &&
                getValues("date_of_competence") !== ""
              }
              isRequired={true}
              error={errors.date_of_competence}
              {...register("date_of_competence")}
            />
            <Input
              type="date"
              label="Data do Recebimento"
              isRequired={false}
              validaded={
                getValues("date_of_receipt_of_money") &&
                getValues("date_of_receipt_of_money") !== ""
              }
              error={errors.date_of_receipt_of_money}
              {...register("date_of_receipt_of_money")}
              defaultValue={generatedReceiptPayment}
            />
          </Flex>
        </SimpleGrid>
        <Input
          type="text"
          label="Observação"
          isRequired={false}
          validaded={
            getValues("observation") && getValues("observation") !== ""
          }
          error={errors.observation}
          {...register("observation")}
        />
      </Stack>
    </Flex>
  );
}
