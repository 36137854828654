import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { checkPermission } from "../../../utils/checkPermission";
import { useAuth } from "../../../hooks/auth";
import { InternalHeader } from "../../../components/templates/InternalHeader";

const createUserFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "No mínimo 6 caracteres"),
  password_confirmation: yup
    .string()
    .oneOf([null, yup.ref("password")], "As senhas precisam ser iguais"),
});

interface Company {
  id: string;
  name: string;
}

export function CreateUser() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const toast = useToast();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { register, handleSubmit, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(createUserFormSchema),
  });
  const handleCreateUser: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/users", {
          name: formValue.name,
          email: formValue.email,
          password: formValue.password,
          company_id: formValue.company_id,
          is_admin: formValue.is_admin,
        });
        navigate("/usuarios");

        toast({
          title: "Usuário cadastrado com sucesso!",
          status: "success",
        });
      } catch {
        toast({
          title: "Não foi possível cadastrar o usuário",
          status: "error",
        });
      }
    },
    [navigate, toast]
  );

  useEffect(() => {
    api.get("/companies").then((response) => setCompanies(response.data));
  }, []);

  const { errors } = formState;

  return (
    <>
      <InternalHeader
        title="Cadastrar Usuário"
        has_filter={false}
        has_back_button={true}
      />
      <Card
        border="#216ca5"
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateUser)}
            id="add-form"
          >
            <Stack spacing="4">
              <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={4} w="100%">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
                <Input
                  type="email"
                  label="E-mail"
                  isRequired={true}
                  error={errors.email}
                  {...register("email")}
                />
                <Input
                  type="password"
                  label="Senha"
                  isRequired={true}
                  error={errors.password}
                  {...register("password")}
                />
                <Input
                  type="password"
                  label="Confirme a senha"
                  isRequired={true}
                  error={errors.password_confirmation}
                  {...register("password_confirmation")}
                />
                <SelectControl
                  control={control}
                  label="Empresa"
                  isRequired={true}
                  error={errors.company_id}
                  {...register("company_id")}
                  options={companies}
                />
                <SelectControl
                  control={control}
                  label="Nível"
                  isRequired={true}
                  error={errors.is_admin}
                  {...register("is_admin")}
                  options={[
                    {
                      id: 0,
                      name: "Super Administrador",
                    },
                    {
                      id: 1,
                      name: "Administrador",
                    },
                    {
                      id: 2,
                      name: "Dono de Rede",
                    },
                    {
                      id: 3,
                      name: "Dono de Empresa",
                    },
                    {
                      id: 4,
                      name: "Gestor Operação",
                    },
                    {
                      id: 5,
                      name: "Financeiro - Frente de Loja",
                    },
                  ].filter((value) => {
                    if (
                      checkPermission({
                        is_admin: user.is_admin,
                        type: "Super Admin",
                      })
                    ) {
                      return true;
                    }
                    return value.id !== 0;
                  })}
                />
              </SimpleGrid>
            </Stack>
          </Flex>
        }
        footer={<SubmitButton formState={formState} />}
      />
    </>
  );
}
