import {
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../../../services/api";
import { Input } from "../../../Form/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { useToast } from "../../../../hooks/toast";
import { MultiSelectControl } from "../../Form/MultiSelectControl";

interface Merchandise {
  id: string;
  name: string;
  merchandise_subcategory_id: string;
  measurement_unit: string;
  merchandise_subcategory: {
    name: string;
  };
}

interface MerchandiseSubcategory {
  id: string;
  name: string;
}

interface MerchandiseModalProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  setMerchandise: (merchandise: Merchandise) => void;
  defaultLink: string;
  actionType: "edit" | "create";
  defaultValue?: Merchandise;
  merchandiseSubcategories: MerchandiseSubcategory[];
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  merchandise_subcategory_id: yup
    .string()
    .required("A subcategoria da mercadoria é obrigatória")
    .uuid("Formato inválido"),
  measurement_unit: yup.string().required("Unidade de medida é obrigatório"),
});

export function MerchandiseModal({
  isOpen,
  setIsOpen,
  setMerchandise,
  defaultValue,
  actionType,
  defaultLink,
  merchandiseSubcategories,
}: MerchandiseModalProps) {
  const toast = useToast();
  const [zIndex, setZIndex] = useState({
    measurement_unit: 10,
    merchandise_subcategory_id: 10,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (actionType === "edit" && defaultValue) {
      setValue("name", defaultValue.name);
      setValue("measurement_unit", defaultValue.measurement_unit);
      setValue(
        "merchandise_subcategory_id",
        defaultValue.merchandise_subcategory_id
      );
      setValue(
        "merchandise_subcategory_name",
        defaultValue.merchandise_subcategory.name
      );
    } else {
      reset();
    }
  }, [actionType, defaultValue, setValue, isOpen, reset]);
  const handleCreateMerchandise: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .post(`/${defaultLink}`, {
          name: formValue.name,
          measurement_unit: formValue.measurement_unit,
          merchandise_subcategory_id: formValue.merchandise_subcategory_id,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `Mercadoria cadastrada com sucesso`,
          });
          var merchandise = response.data;
          setMerchandise({
            id: merchandise.id,
            name: merchandise.name,
            measurement_unit: merchandise.measurement_unit,
            merchandise_subcategory: {
              name: formValue.merchandise_subcategory_name,
            },
            merchandise_subcategory_id: merchandise.merchandise_subcategory_id,
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          toast({
            status: "error",
            title: `Não foi possível cadastrar a Mercadoria`,
          });
        });
    },
    [defaultLink, toast, setMerchandise, setIsOpen, reset]
  );

  const handleEditMerchandise: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .put(`/${defaultLink}/${defaultValue?.id}`, {
          name: formValue.name,
          measurement_unit: formValue.measurement_unit,
          merchandise_subcategory_id: formValue.merchandise_subcategory_id,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `Mercadoria editada com sucesso`,
          });
          var merchandise = response.data;
          setMerchandise({
            id: merchandise.id,
            name: merchandise.name,
            measurement_unit: merchandise.measurement_unit,
            merchandise_subcategory: {
              name: formValue.merchandise_subcategory_name,
            },
            merchandise_subcategory_id: merchandise.merchandise_subcategory_id,
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          toast({
            status: "error",
            title: `Não foi possível editar a Mercadoria`,
          });
        });
    },
    [defaultLink, defaultValue?.id, toast, setMerchandise, setIsOpen, reset]
  );
  watch();
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          reset();
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === "create" ? "Adicionar" : "Editar"} Mercadoria
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid
              spacingX={2}
              columns={{ base: 1 }}
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={
                actionType === "edit"
                  ? handleSubmit(handleEditMerchandise)
                  : handleSubmit(handleCreateMerchandise)
              }
              id="add-form"
            >
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                {...register("name")}
                error={errors.name}
                validaded={getValues("name") && !errors.name}
              />
              <MultiSelectControl
                options={[
                  {
                    value: "g",
                    label: "g",
                  },
                  {
                    value: "ml",
                    label: "ml",
                  },
                ]}
                {...register("measurement_unit")}
                onChange={(selected) => {
                  setValue("measurement_unit", selected.value, {
                    shouldValidate: true,
                  });
                }}
                value={
                  getValues("measurement_unit")
                    ? {
                        label: getValues("measurement_unit"),
                        value: getValues("measurement_unit"),
                      }
                    : ""
                }
                validaded={
                  getValues("measurement_unit") && !errors.measurement_unit
                }
                isMulti={false}
                label="Unidade de medida"
                error={errors.measurement_unit}
                isRequired={true}
                zIndex={zIndex.measurement_unit}
                onFocus={() =>
                  setZIndex({
                    measurement_unit: 10,
                    merchandise_subcategory_id: 8,
                  })
                }
              />
              <MultiSelectControl
                options={merchandiseSubcategories.map(
                  (merchandiseSubcategory) => ({
                    label: merchandiseSubcategory.name,
                    value: merchandiseSubcategory.id,
                  })
                )}
                {...register("merchandise_subcategory_id")}
                onChange={(selected) => {
                  setValue("merchandise_subcategory_id", selected.value, {
                    shouldValidate: true,
                  });

                  setValue("merchandise_subcategory_name", selected.label, {
                    shouldValidate: true,
                  });
                }}
                value={
                  getValues("merchandise_subcategory_id")
                    ? {
                        label: getValues("merchandise_subcategory_name"),
                        value: getValues("merchandise_subcategory_id"),
                      }
                    : ""
                }
                validaded={
                  getValues("merchandise_subcategory_id") &&
                  !errors.merchandise_subcategory_id
                }
                isMulti={false}
                label="Subcategoria da Mercadoria"
                error={errors.merchandise_subcategory_id}
                isRequired={true}
                zIndex={zIndex.merchandise_subcategory_id}
                onFocus={() =>
                  setZIndex({
                    measurement_unit: 8,
                    merchandise_subcategory_id: 10,
                  })
                }
              />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() => {
                setIsOpen(false);
                reset();
              }}
            >
              Cancelar
            </Button>
            <SubmitButton
              mt={"0"}
              text={actionType === "edit" ? "Editar" : "Cadastrar"}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
