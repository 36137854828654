import { Button, Icon } from "@chakra-ui/react";
import { ElementType } from "react";
import { shade } from "polished";

interface TableListButtonProps {
  icon: ElementType;
  bg: string;
  color: string;
}

export function TableListButtonDisabled({
  bg,
  icon,
  color,
}: TableListButtonProps) {
  return (
    <Button
      bg={bg}
      color={color}
      _hover={{
        backgroundColor: shade(0.2, bg),
      }}
      _active={{
        backgroundColor: shade(0.2, bg),
      }}
      mr="4px"
      disabled
    >
      <Icon as={icon} />
    </Button>
  );
}
