import {
  Button,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ModalCogsTargetBody } from "../../../organisms/Modal/ModalCogsTarget";

export function ModalCogsTarget() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        bg="#216ca5"
        _hover={{ bg: "#216ca5", opacity: 0.8 }}
        _active={{ bg: "#216ca5", opacity: 0.6 }}
        color="#ffffff"
        onClick={onOpen}
      >
        Definir Meta
      </Button>
      <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar Meta</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalCogsTargetBody onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
}
