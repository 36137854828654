import { Button, Flex, Image, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  FaCommentDots,
  FaHandHoldingUsd,
  FaRegMeh,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { NpsBox } from "../../../components/atoms/Box/NpsBox";
import { Card } from "../../../components/Card";
import { DateRange } from "../../../components/templates/Filters/DateRangePicker";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { Box } from "../../../components/templates/Widget/Box";
import { useAuth } from "../../../hooks/auth";
import { useQuery } from "../../../hooks/query";
import { api } from "../../../services/api";
import { checkPermission } from "../../../utils/checkPermission";
import {
  formatDatabaseWithOutHours,
  formatDateBrIndex,
} from "../../../utils/formatDate";
import { calcNpsWithOutAnswers, translateNps } from "../../../utils/nps";
import LogoFalae from "../../../assets/falae_logo.png";
import { Table } from "../../../components/templates/Table";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
interface IData {
  answers: {
    total: string | null;
    promoter: string | null;
    neutral: string | null;
    detractor: string | null;
    goal: string | null;
    discursive_question: {
      id: string;
      discursive_question: string;
      nps: string | null;
      created_at: Date;
      client_name: string;
    }[];
    total_discursive_question: number;
  };
  clients: number | null;
}

interface Selected {
  date_start: string;
  date_end: string;
  nps?: string;
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

export function ClientExperienceDashboard() {
  const { user } = useAuth();
  const query = useQuery();
  const [selected, setSelected] = useState<Selected>({
    date_end: query.get("date_end") ?? formatDatabaseWithOutHours(new Date()),
    date_start:
      query.get("date_start") ?? formatDatabaseWithOutHours(thirtyDaysAgo),
    nps: query.get("nps") ?? "detractor",
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  const [data, setData] = useState<IData>({
    answers: {
      total: "0",
      promoter: "0",
      neutral: "0",
      detractor: "0",
      goal: "0",
      discursive_question: [],
      total_discursive_question: 0,
    },
    clients: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (user.company.falae_id) {
      api
        .get(
          `/falae/dashboard/${user.company_id}?nps=${selected.nps}&date_start=${selected.date_start}&date_end=${selected.date_end}&offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
        )
        .then((response) => {
          setData(response.data);
          setTotal(response.data.answers.total_discursive_question);
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
    }
  }, [
    defaultParams.limit_per_page,
    defaultParams.order_column,
    defaultParams.order_type,
    defaultParams.page,
    defaultParams.search,
    navigate,
    user.company.falae_id,
    user.company_id,
    selected.date_end,
    selected.date_start,
    selected.nps,
  ]);

  return (
    <>
      <InternalHeader
        title="Experiência do Cliente"
        has_filter={checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        })}
        defaultOpen={checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        })}
      >
        {checkPermission({
          is_admin: user.is_admin,
          type: "Manager",
        }) ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} w="100%" spacingX={2}>
            <DateRange
              showClearDates={false}
              label="Data"
              dateEnd={selected.date_end}
              dateStart={selected.date_start}
              onSelectDate={(start, end) => {
                setSelected({
                  ...selected,
                  date_start: start,
                  date_end: end,
                });
                navigate(`?date_start=${start}&date_end=${end}`);
              }}
            />
          </SimpleGrid>
        ) : (
          <></>
        )}
      </InternalHeader>
      {!user.company.falae_id && (
        <Flex
          position={"absolute"}
          left="50%"
          top="50%"
          justifyContent={"center"}
          alignItems="center"
        >
          <Flex
            bg="white"
            zIndex={10}
            boxShadow="lg"
            padding={"35px"}
            borderRadius="50%"
            border="1px solid #2b88a1"
          >
            <Image src={LogoFalae} width="100px" height={"100px"} />
          </Flex>
        </Flex>
      )}
      <Card
        title=""
        filter={!user.company.falae_id ? "blur(4px)" : ""}
        header={<></>}
        border="#2b88a1"
        body={
          <>
            <SimpleGrid columns={{ base: 1, lg: 3, xl: 3 }} spacingX={2}>
              <NpsBox
                title={`NPS`}
                nps={calcNpsWithOutAnswers(
                  Number(data.answers.promoter),
                  Number(data.answers.detractor),
                  Number(data.answers.total)
                )}
              />

              <Box
                bg="cyan.650"
                color="#fff"
                icon={FaCommentDots}
                title="Respostas"
                value={`${Number(data.answers.total)}`}
                border="#2b88a1"
                footer={`Meta: ${Number(data.answers.goal)}`}
              />

              <Box
                bg="orange.450"
                color="#fff"
                icon={FaHandHoldingUsd}
                title="Novos Clientes"
                value={`${Number(data.clients)}`}
                border="#FD9000"
              />
              <Box
                title="Promotores"
                icon={FaThumbsUp}
                color="white"
                bg="green.450"
                value={`${Number(data.answers.promoter)}`}
                border="#178b00"
                onClick={() => {
                  setSelected({
                    ...selected,
                    nps: "promoter",
                  });
                }}
                footer={"Ver mais"}
              />
              <Box
                title="Neutros"
                icon={FaRegMeh}
                color="black"
                bg="yellow.450"
                value={`${Number(data.answers.neutral)}`}
                border="#ffcf30"
                onClick={() => {
                  setSelected({
                    ...selected,
                    nps: "neutral",
                  });
                }}
                footer={"Ver mais"}
              />
              <Box
                title="Detratores"
                icon={FaThumbsDown}
                color="white"
                bg="red.450"
                value={`${Number(data.answers.detractor)}`}
                border="#d30000"
                onClick={() => {
                  setSelected({
                    ...selected,
                    nps: "detractor",
                  });
                }}
                footer={"Ver mais"}
              />
            </SimpleGrid>
          </>
        }
      />
      <Card
        mt="3"
        title={`Sugestões${
          selected.nps ? ` - ${translateNps(selected.nps)}` : ""
        }`}
        filter={!user.company.falae_id ? "blur(4px)" : ""}
        header={<></>}
        border="#2b88a1"
        body={
          <>
            <Table
              loading={loading}
              message="Buscando as empresas"
              params={defaultParams}
              setDefaultParams={(params) => {
                setDefaultParams(params);
                navigate(
                  `/experiencia?date_start=${selected.date_start}&date_end=${selected.date_end}&offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
                );
              }}
              total={total}
              data={data.answers.discursive_question.map((answer) => {
                return {
                  tr: [
                    {
                      name: formatDateBrIndex(answer.created_at),
                    },
                    {
                      name: answer.client_name,
                    },
                    {
                      name: answer.nps,
                    },
                    {
                      name: answer.discursive_question,
                    },
                    {
                      name: (
                        <ButtonListButton>
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_FALAE_URL}/resposta/${answer.id}`,
                                "_blank"
                              );
                            }}
                          >
                            Ver
                          </Button>
                        </ButtonListButton>
                      ),
                    },
                  ],
                };
              })}
              columns={[
                {
                  name: "created_at",
                  label: "Horário",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "nps",
                  label: "NPS",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "discursive_question",
                  label: "Sugestão",
                  options: {
                    sort: true,
                  },
                },
                {
                  name: "action",
                  label: "Ações",
                  options: {
                    sort: false,
                  },
                },
              ]}
            />
          </>
        }
      />
    </>
  );
}
