import { Icon } from "@chakra-ui/react";
import { ElementType } from "react";

interface SidebarIconProps {
  icon: ElementType;
  color?: string;
}

export function SidebarIcon({ icon, color = "black" }: SidebarIconProps) {
  return <Icon as={icon} ml="4" fontSize="18" color={color} />;
}
