import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { BoxLoading } from "../../molecules/BoxLoading";

interface PreLoaderProps {
  children: ReactNode;
  isLoaded: boolean;
  message: string;
  minH?: string;
}

export function PreLoader({
  children,
  isLoaded,
  message,
  minH = "calc(100vh - 135px)",
}: PreLoaderProps) {
  return !isLoaded ? (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="1"
      height="40vh"
      minH={minH}
    >
      <BoxLoading message={message} />
    </Flex>
  ) : (
    <>{children}</>
  );
}
