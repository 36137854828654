import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface AuthCardFormProps {
  children: ReactNode;
  onSubmit: () => void;
}

export function AuthCardForm({ children, onSubmit }: AuthCardFormProps) {
  return (
    <Flex
      as="form"
      w="100%"
      maxWidth={{ base: "320", md: "400" }}
      minW={{ md: "400" }}
      bg="white"
      p="4"
      flexDir="column"
      onSubmit={onSubmit}
      boxShadow="md"
      id="send-form"
    >
      {children}
    </Flex>
  );
}
