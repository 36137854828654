import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { useQuery } from "../../../hooks/query";
import { useNavigate } from "react-router-dom";
import { Table } from "../../../components/templates/Table";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { checkPermission } from "../../../utils/checkPermission";
import { useAuth } from "../../../hooks/auth";
import { InternalHeader } from "../../../components/templates/InternalHeader";

interface Favored {
  id: string;
  name: string;
  account_plan_subcategory: {
    name: string;
  };
}
interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListFavored() {
  const query = useQuery();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [favored, setFavored] = useState<Favored[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 30,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "name",
    order_type: query.get("order_type") ?? "ASC",
  });

  useEffect(() => {
    api
      .get(
        `favored/pagination/company/${user.company_id}?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setFavored(response.data.favored);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate, defaultParams, user.company_id]);

  return (
    <>
      <InternalHeader has_filter={false} title="Favorecidos">
        <AddButton link="/favorecido/cadastrar" />
      </InternalHeader>
      <Card
        border="#216ca5"
        body={
          <Table
            loading={loading}
            message="Buscando os favorecidos"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/favorecido?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={favored.map((f) => {
              return {
                tr: [
                  {
                    name: f.name,
                  },
                  {
                    name: f.account_plan_subcategory.name,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditButton link={`/favorecido/editar/${f.id}`} />
                        {checkPermission({
                          is_admin: user.is_admin,
                          type: "Owner",
                        }) ? (
                          <DeleteModal
                            onDelete={(id) => {
                              setFavored(favored.filter((f) => f.id !== id));
                            }}
                            id={f.id}
                            route="favored/"
                            error_message="Não foi possível remover a favorecido"
                            success_message="Favorecido removida com sucesso"
                            error_descrption="Ele pode estar sendo usado em alguma despesa"
                          />
                        ) : (
                          <></>
                        )}
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "accoun_plan_subcategory",
                label: "Despesa",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
