import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCaretDown,
  FaCaretUp,
  FaSearch,
} from "react-icons/fa";
import { PreLoader } from "../PreLoader";

interface DefaultParams {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

interface TableProps {
  total: number;
  data: {
    bg?: string;
    color?: string;
    tr: {
      name: ReactNode;
    }[];
  }[];
  columns: {
    label: string;
    name: string;
    options: {
      sort: boolean;
      display?: boolean;
    };
    filter?: JSX.Element;
  }[];
  setDefaultParams: (params: DefaultParams) => void;
  params: DefaultParams;
  loading: boolean;
  message: string;
  variant?: "simple" | "striped";
  subtitle?: string;
}

export function Table({
  columns,
  data,
  setDefaultParams,
  params,
  total,
  loading,
  message,
  variant = "striped",
  subtitle,
}: TableProps) {
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);
  const [started, setStarted] = useState(false);
  const { limitPage, minRange, maxRange } = useMemo(() => {
    let minRange =
      params.page === 1 ? 1 : params.limit_per_page * (params.page - 1) + 1;
    return {
      limitPage: Math.ceil(total / params.limit_per_page),
      minRange,
      maxRange:
        minRange + params.limit_per_page - 1 > total
          ? total
          : minRange + params.limit_per_page - 1,
    };
  }, [params.limit_per_page, params.page, total]);

  useEffect(() => {
    if (debouncedTerm.length > 0 || started) {
      setStarted(true);
      const timer = setTimeout(() => {
        setTerm(debouncedTerm);
        setDefaultParams({
          ...params,
          page: 1,
          search: debouncedTerm,
        });
      }, 300);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTerm, started]);
  return (
    <>
      <Flex
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <InputGroup size="md" mb={1} flex={{ base: 1, md: 0.4, lg: 0.25 }}>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={FaSearch} color="gray.300" />}
          />
          <Input
            pr="4.5rem"
            type="text"
            placeholder="Pesquisar"
            onChange={(e) => {
              setDebouncedTerm(e.target.value);
            }}
          />
        </InputGroup>
      </Flex>

      <Flex flexWrap={"wrap"} p={"4"}>
        {columns
          .filter((column) => column.filter)
          .map((column) => column.filter)}
      </Flex>
      <TableContainer>
        <ChakraTable
          variant={variant}
          size="sm"
          w="100%"
          overflowX="scroll"
          whiteSpace="break-spaces"
        >
          <Thead>
            {columns.map((column) => (
              <Th
                cursor={column.options.sort ? "pointer" : "default"}
                onClick={() => {
                  if (column.options?.sort) {
                    setDefaultParams({
                      ...params,
                      order_column: column.name,
                      order_type:
                        params.order_column !== column.name
                          ? "DESC"
                          : params.order_type === "ASC"
                          ? "DESC"
                          : "ASC",
                    });
                  }
                }}
              >
                <Flex align="center">
                  {column.label}
                  {column.options?.sort && (
                    <Flex flexDir="column">
                      <Icon
                        as={FaCaretUp}
                        ml={1}
                        color={
                          params.order_column === column.name &&
                          params.order_type === "ASC"
                            ? "black"
                            : "gray.400"
                        }
                      />
                      <Icon
                        as={FaCaretDown}
                        ml={1}
                        color={
                          params.order_column === column.name &&
                          params.order_type === "DESC"
                            ? "black"
                            : "gray.400"
                        }
                      />
                    </Flex>
                  )}
                </Flex>
              </Th>
            ))}
          </Thead>
          <Tbody>
            {!loading ? (
              <Tr>
                <Td colSpan={columns.length}>
                  <PreLoader message={message} isLoaded={loading}>
                    <></>
                  </PreLoader>
                </Td>
              </Tr>
            ) : data.length > 0 ? (
              data.map((body) => (
                <Tr bg={body.bg ? body.bg : "default"}>
                  {body.tr.map((result) => (
                    <Td
                      maxWidth="400px"
                      lineHeight="1.5"
                      alignItems="center"
                      color={body.color ? body.color : "default"}
                    >
                      {result.name}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={columns.length} textAlign="center">
                  Nenhum dado foi encontrado
                </Td>
              </Tr>
            )}
          </Tbody>
        </ChakraTable>
      </TableContainer>
      <Flex justify="flex-end" mr={10} align="center">
        <Text>Itens por página</Text>
        <Select
          mx={1}
          _active={{
            borderColor: "transparent",
          }}
          _selected={{
            borderColor: "transparent",
          }}
          _hover={{
            borderColor: "transparent",
          }}
          borderColor="transparent"
          maxW="80px"
          value={params.limit_per_page}
          onChange={(e) => {
            setDefaultParams({
              ...params,
              limit_per_page: Number(e.target.value),
            });
          }}
        >
          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>

        <Box>
          <Text>
            {minRange}-{maxRange} de {total}
          </Text>{" "}
        </Box>
        <Button
          bg="transparent"
          borderRadius="100%"
          isDisabled={params.page === 1}
          onClick={() =>
            setDefaultParams({
              ...params,
              page: params.page - 1,
            })
          }
        >
          <Icon as={FaAngleLeft} />
        </Button>
        <Button
          bg="transparent"
          borderRadius="100%"
          isDisabled={params.page === limitPage || total === 0}
          onClick={() => {
            setDefaultParams({
              ...params,
              page: params.page + 1,
            });
          }}
        >
          <Icon as={FaAngleRight} />
        </Button>
      </Flex>
    </>
  );
}
