import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
interface MUITableProps {
  columns: {
    label?: string;
    name: string;
    options?: object;
  }[];
  data: any[];
  title?: string;
  rowsPerPage?: number;
  print?: boolean;
  options?: object;
}
export function MUITable({
  columns,
  data,
  title = "",
  print = false,
  rowsPerPage = 10,
  options: optionsTable,
}: MUITableProps) {
  const options = {
    ...optionsTable,
    print,
    download: false,
    selectableRows: undefined,
    responsive: undefined,
    rowsPerPage,
    textLabels: {
      body: {
        noMatch: "Nenhum dado foi encontrado",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Itens por página",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisa",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Visualizar colunas",
        filterTable: "Filtrar",
        sort: "Ordenar",
      },
      filter: {
        all: "TODOS",
        title: "FILTRAR",
        reset: "LIMPAR",
      },
      viewColumns: {
        title: "Exibir coluna",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Deletar",
        deleteAria: "Deletar linhas selecionadas",
      },
    },
  } as MUIDataTableOptions;
  return (
    <MUIDataTable
      title={title}
      data={data ?? []}
      columns={columns}
      options={options}
    />
  );
}
