import { Flex, Icon, Select } from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";
import { FaCheckDouble, FaChevronDown } from "react-icons/fa";
import { FormControl } from "../../../Form/FormControl";

interface SelectControlProps {
  name: string;
  label?: string;
  control: Control<any, object>;
  error?: any;
  isRequired?: boolean;
  description?: string;
  validaded?: boolean;
  options: {
    id: any;
    name: string;
  }[];
  isDisabled?: boolean;
}

export function SelectControl({
  name,
  isRequired = false,
  description,
  error,
  label,
  options,
  validaded = false,
  control,
  isDisabled = false,
}: SelectControlProps) {
  return (
    <FormControl
      name={name}
      isRequired={isRequired}
      label={label}
      error={error}
      description={description}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={control._defaultValues}
        render={({ field }) => {
          return (
            <Select
              {...field}
              placeholder="Selecione uma opção"
              borderWidth="2px"
              borderColor={validaded && !error ? "green.450" : "gray.400"}
              isReadOnly={isDisabled}
              isDisabled={isDisabled}
              _disabled={{
                color: "#000"
              }}
              icon={
                validaded && !error ? (
                  <Flex alignItems="center" p="1">
                    <Icon
                      as={FaCheckDouble}
                      boxSize={4}
                      color="#53a846"
                      style={{ color: "#53a846 !important" }}
                    />
                  </Flex>
                ) : (
                  <Flex alignItems="center" p="1">
                    <FaChevronDown />
                  </Flex>
                )
              }
            >
              {options.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
}
