import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import { Card } from "../../../components/Card";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { FormControl } from "../../../components/Form/FormControl";
import { useQuery } from "../../../hooks/query";
import { useToast } from "../../../hooks/toast";

export function ShowUserTokenPage() {
  const query = useQuery();
  const toast = useToast();
  const [link, setLink] = useState("");
  const [email, setEmail] = useState("");

  function fallbackCopyTextToClipboard(text: string) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.log(err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        toast({
          title: "Link copiado com sucesso.",
          status: "success",
        });
      },
      function (err) {
        toast({
          title: "Erro ao copiar link.",
          status: "error",
        });
      }
    );
  }

  useEffect(() => {
    const token = query.get("token");
    const emailUser = query.get("email");

    if (emailUser) {
      setEmail(emailUser);
      setLink(`${process.env.REACT_APP_URL}/cadastro/${token}`);
    }
  }, [query]);
  return (
    <>
      <InternalHeader
        title="Link de Cadastro do Usuário"
        has_filter={false}
        has_back_button={true}
      />
      <Card
        body={
          <Stack>
            <FormControl name="email" label="E-mail">
              <InputGroup size="md">
                <Input
                  pr="5rem"
                  value={email}
                  focusBorderColor="blue.350"
                  bg="white"
                  border="1px"
                  borderColor="gray.400"
                  color="black"
                />
                <InputRightElement width="6rem">
                  <Button
                    h="1.75rem"
                    size="md"
                    mr="2"
                    bg="#2b88a1"
                    color="#fff"
                    _hover={{
                      background: "#2b88a1",
                      opacity: 0.8,
                    }}
                    _active={{
                      background: "#2b88a1",
                      opacity: 0.8,
                    }}
                    onClick={() => {
                      copyTextToClipboard(email);
                    }}
                  >
                    <Icon as={FaCopy} mr="2" />
                    Copiar
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl name="link" label="Link">
              <InputGroup size="md">
                <Input
                  pr="5rem"
                  value={link}
                  focusBorderColor="blue.350"
                  bg="white"
                  border="1px"
                  borderColor="gray.400"
                  color="black"
                />
                <InputRightElement width="6rem">
                  <Button
                    h="1.75rem"
                    size="md"
                    mr="2"
                    bg="#2b88a1"
                    color="#fff"
                    _hover={{
                      background: "#2b88a1",
                      opacity: 0.8,
                    }}
                    _active={{
                      background: "#2b88a1",
                      opacity: 0.8,
                    }}
                    onClick={() => {
                      copyTextToClipboard(link);
                    }}
                  >
                    <Icon as={FaCopy} mr="2" />
                    Copiar
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Stack>
        }
      />
    </>
  );
}
