import { Button } from "@chakra-ui/react";
interface AuthSubmitButtonProps {
  text: string;
  isLoading?: boolean;
}
export function AuthSubmitButton({ text, isLoading }: AuthSubmitButtonProps) {
  return (
    <Button
      type="submit"
      color="white"
      form="send-form"
      colorScheme="blue"
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
}
