import { useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { useQuery } from "../../../hooks/query";
import { Table } from "../../../components/templates/Table";
import { KeyButton } from "../../../components/molecules/KeyBytton";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { ModalBranchPath } from "../../../components/templates/ModalBranchPath";
import { FaPlus } from "react-icons/fa";
interface User {
  id: string;
  name: string;
  email: string;
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListUsers() {
  const query = useQuery();
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });
  useEffect(() => {
    api
      .get(
        `/users/pagination?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setUsers(response.data.users);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [
    defaultParams.limit_per_page,
    defaultParams.order_column,
    defaultParams.order_type,
    defaultParams.page,
    defaultParams.search,
    navigate,
  ]);

  return (
    <>
      <InternalHeader
        title="Usuários"
        has_filter={false}
        has_back_button={true}
      >
        <ModalBranchPath
          label="Adicionar"
          title="Tipo de Cadastro"
          text="Escolho como deseja cadastrar o usuário"
          firstOptionLink="/usuario/registro"
          firstOptionName=" 2 Etapas"
          secondOptionLink="/usuario/cadastrar"
          secondOptionName="Completo"
          icon={FaPlus}
        />
      </InternalHeader>
      <Card
        border="#216ca5"
        body={
          <Table
            loading={loading}
            message="Buscando as usuários"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/usuarios?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={users.map((user) => {
              return {
                tr: [
                  {
                    name: user.name,
                  },
                  {
                    name: user.email,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditButton link={`/usuario/${user.id}`} />
                        <KeyButton link={`/usuario/senha/${user.id}`} />
                        <DeleteModal
                          onDelete={(id) => {
                            setUsers(users.filter((user) => user.id !== id));
                          }}
                          id={user.id}
                          route="users/"
                          error_message="Não foi possível remover o usuário"
                          success_message="Usuário removida com sucesso"
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "email",
                label: "E-mail",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
