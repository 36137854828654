import React, { ElementType } from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { InternalLink } from "../../Link/InternalLink";
import { FaExternalLinkAlt } from "react-icons/fa";
interface BoxProps {
  value: number | string;
  title: string;
  bg: string;
  color: string;
  icon: ElementType;
  link?: string;
  onClick?: () => void;
}

export function Box({
  value,
  title,
  bg,
  color,
  icon,
  link,
  onClick,
}: BoxProps) {
  return (
    <>
      {link ? (
        <InternalLink link={link}>
          <Flex
            borderRadius=".35rem"
            bg="white"
            fontSize="2.2rem"
            margin="0 0 10px 0 "
            whiteSpace="nowrap"
            mb="1rem"
            minH="70"
            p=".50rem"
            w="100%"
            shadow="md"
            borderTop={`3px solid ${bg}`}
          >
            <Flex
              borderRadius="50%"
              alignItems="center"
              fontSize="1.875rem"
              justifyContent="center"
              textAlign="center"
              w="65px"
              h="65px"
              bg={bg}
            >
              <Icon as={icon} color={color} />
            </Flex>
            <Flex flexDir="column" justify="space-around" flex="1" p="0 10px">
              <Text
                display="block"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                fontSize="16px"
              >
                {title}
              </Text>
              <Text
                display="block"
                fontSize="16px"
                mt=".25rem"
                fontWeight="bold"
              >
                {value}
              </Text>
            </Flex>
            <Flex
              flexDir="row"
              align="flex-end"
              justify="center"
              color="gray.400"
            >
              <Flex align="center" _hover={{ color: "#2b88a1"}}>
                <Text fontSize="12px" mr={1}>
                  Ver mais
                </Text>
                <Icon as={FaExternalLinkAlt} fontSize="12" />
              </Flex>
            </Flex>
          </Flex>
        </InternalLink>
      ) : (
        <Flex
          onClick={onClick}
          cursor={onClick ? "pointer" : "unset"}
          borderRadius=".25rem"
          bg="white"
          fontSize="2.2rem"
          margin="0 0 10px 0 "
          whiteSpace="nowrap"
          mb="1rem"
          minH="80px"
          p=".5rem"
          w="100%"
          shadow="md"
          borderTop={`3px solid ${bg}`}
        >
          <Flex
            borderRadius="50%"
            alignItems="center"
            fontSize="1.875rem"
            justify="center"
            textAlign="center"
            w="65px"
            h="65px"
            bg={bg}
          >
            <Icon as={icon} color={color} />
          </Flex>
          <Flex flexDir="column" justify="space-around" flex="1" p="0 10px">
            <Text
              display="block"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              fontSize="16px"
            >
              {title}
            </Text>
            <Text display="block" fontSize="16px" mt=".25rem" fontWeight="bold">
              {value}
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
}
