import {
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card } from "../../../components/Card";
import { Input } from "../../../components/Form/Input";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import * as yup from "yup";
import { api } from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDatabaseWithOutHours } from "../../../utils/formatDate";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useParams } from "react-router-dom";

const formOfReceipt = [
  {
    id: "dinheiro",
    name: "Dinheiro",
  },
  {
    id: "credito",
    name: "Cartão de crédito",
  },

  {
    id: "debito",
    name: "Cartão de débito",
  },
  {
    id: "vale",
    name: "Vale",
  },
  {
    id: "online",
    name: "Pagamento online",
  },
  {
    id: "pix",
    name: "Pix",
  },
  {
    id: "assinado",
    name: "Assinado",
  },
  {
    id: "boleto",
    name: "Boleto",
  },
  {
    id: "cortesia",
    name: "Cortesia",
  },
];

const cashClosingValidation = yup.object().shape({
  revenues: yup.array().of(
    yup.object().shape({
      observation: yup.string().notRequired(),
      value: yup.string(),
      form_of_receipt: yup
        .string()
        .required("Forma de recebimento é obrigatória"),
      account_plan_subcategory_id: yup
        .string()
        .required("Receita é obrigatória"),
      date_of_competence: yup
        .string()
        .required("Data de competência é obrigatória"),
    })
  ),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
  priority: number;
}

interface ILiquidityOfPayment {
  money: number;
  debit: number;
  credit: number;
  voucher: number;
  online_payment: number;
  pix: number;
  subscribed: number;
  courtesy: number;
  bank_slip: number;
}

interface IRevenue {
  id: string;
  account_plan_subcategory_id: string;
  date_of_competence: Date;
  value: string;
  form_of_receipt: string;
  date_of_receipt_of_money: Date;
  company_id: string;
}
export function EditCashClosing() {
  const { user } = useAuth();
  const { date } = useParams();

  const [liquidityOfPayment, setLiquidityOfPayment] =
    useState<ILiquidityOfPayment>({} as ILiquidityOfPayment);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);

  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    control,
    watch,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(cashClosingValidation),
    defaultValues: {
      revenues: [] as IRevenue[],
      date_of_competence: new Date(),
      customer_served: undefined,
    },
  });

  const revenues = watch("revenues") as IRevenue[];
  const generatedReceiptPayment = useCallback(
    (date: string, formOfReceipt: string) => {
      const splitString = date.split("-");

      const split = splitString.map((i) => Number(i));

      switch (formOfReceipt) {
        case "dinheiro": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.money
          );
          return formatDatabaseWithOutHours(date);
        }
        case "credito": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.credit
          );
          return formatDatabaseWithOutHours(date);
        }
        case "debito": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.debit
          );
          return formatDatabaseWithOutHours(date);
        }
        case "vale": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.voucher
          );
          return formatDatabaseWithOutHours(date);
        }
        case "online": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.online_payment
          );
          return formatDatabaseWithOutHours(date);
        }
        case "pix": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.pix
          );
          return formatDatabaseWithOutHours(date);
        }
        case "assinado": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.subscribed
          );
          return formatDatabaseWithOutHours(date);
        }

        case "boleto": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.bank_slip
          );
          return formatDatabaseWithOutHours(date);
        }

        case "cortesia": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.courtesy
          );
          return formatDatabaseWithOutHours(date);
        }
        default: {
          return "";
        }
      }
    },
    [liquidityOfPayment]
  );

  const formatRevenues = useCallback(
    (revenues: IRevenue[], date_of_competence: string) => {
      let initialRevenues: IRevenue[] = [];

      accountPlanSubcategories.forEach((acc) => {
        formOfReceipt.forEach((form) => {
          initialRevenues.push({
            id: "",
            account_plan_subcategory_id: acc.id,
            form_of_receipt: form.id,
            date_of_competence: new Date(date_of_competence),
            date_of_receipt_of_money: new Date(
              generatedReceiptPayment(date_of_competence, form.id)
            ),
            value: "0",
            company_id: "",
          });
        });
      });

      initialRevenues = initialRevenues.map((revenue) => {
        const foundRevenue = revenues.find(
          (rev) =>
            revenue.account_plan_subcategory_id ===
              rev.account_plan_subcategory_id &&
            revenue.form_of_receipt === rev.form_of_receipt
        );

        if (foundRevenue) {
          revenue = {
            ...foundRevenue,
            value: foundRevenue.value,
          };
        }

        return revenue;
      });

      setValue("revenues", initialRevenues);
    },
    [accountPlanSubcategories, setValue, generatedReceiptPayment]
  );

  useEffect(() => {
    api.get("/accountPlanSubcategories/type?type=Receita").then((response) => {
      const accPlanSubcategories = response.data.filter(
        (acc: AccountPlanSubcategory) => acc.priority !== 0
      ) as AccountPlanSubcategory[];

      setAccountPlanSubcategories(accPlanSubcategories);
    });

    if (date) {
      api
        .get(
          `/revenues/cashClosing/${user.company_id}?date_of_competence=${date}`
        )
        .then((response) => {
          formatRevenues(response.data, String(date));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    api
      .get<ILiquidityOfPayment>(
        `/liquidityOfPayments/company/${user.company_id}`
      )
      .then((response) => {
        setLiquidityOfPayment(response.data);
      });
  }, [user.company_id, date, formatRevenues]);

  const handleDateOfCompetence = useCallback(
    (date: string) => {
      const revenues = getValues("revenues") as IRevenue[];

      const newRevenues = revenues.map((revenue) => {
        return {
          ...revenue,
          date_of_competence: new Date(date),
          date_of_receipt_of_money: new Date(
            generatedReceiptPayment(date, revenue.form_of_receipt)
          ),
        };
      });

      setValue("revenues", newRevenues);
    },
    [getValues, setValue, generatedReceiptPayment]
  );

  const handleSubmitEditCashClosing = useCallback((formValue: any) => {}, []);

  return (
    <>
      <InternalHeader
        title="Editando Fechamento de Caixa"
        has_back_button
        has_filter={false}
      />
      <Card
        border="#216ca5"
        body={
          <Stack
            as="form"
            id="cash-closing"
            onSubmit={handleSubmit(handleSubmitEditCashClosing)}
          >
            <SimpleGrid columns={{ base: 1, md: 3 }}>
              <Input
                type="date"
                label="Data da Receita"
                isRequired={true}
                error={errors.date_of_competence}
                name="date_of_compentence"
                value={date}
                onChange={(e) => {
                  handleDateOfCompetence(e.target.value);
                }}
              />
            </SimpleGrid>
            <Tabs>
              <TabList>
                {accountPlanSubcategories.map((acc) => (
                  <Tab key={acc.id}>{acc.name}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {accountPlanSubcategories.map((acc, indexAcc) => (
                  <TabPanel key={acc.id}>
                    {/* <SimpleGrid columns={{ base: 1, md: 3 }}>
                      <Input
                        type="number"
                        label="Clientes Atendidos"
                        validaded={getValues("customer_served") !== undefined}
                        error={errors.customer_served}
                        {...register("customer_served")}
                      />
                    </SimpleGrid> */}
                    <SimpleGrid mt="4">
                      {revenues
                        .filter(
                          (revenue) =>
                            revenue.account_plan_subcategory_id === acc.id
                        )
                        .map((revenue, index) => (
                          <>
                            <SimpleGrid
                              key={index}
                              columns={{ base: 1, md: 3 }}
                              spacingX={3}
                              py="6"
                              px="4"
                              boxShadow={"md"}
                              borderTop={"2px solid #e0e0e0"}
                              bg={index % 2 === 0 ? "#f0f0f0" : "#ffffff"}
                            >
                              <SelectControl
                                control={control}
                                isDisabled
                                {...register(
                                  `revenues.${
                                    index + indexAcc * formOfReceipt.length
                                  }.form_of_receipt`
                                )}
                                isRequired={true}
                                label="Forma de Recebimento"
                                options={[
                                  {
                                    id: "dinheiro",
                                    name: "Dinheiro",
                                  },
                                  {
                                    id: "credito",
                                    name: "Cartão de crédito",
                                  },

                                  {
                                    id: "debito",
                                    name: "Cartão de débito",
                                  },
                                  {
                                    id: "vale",
                                    name: "Vale",
                                  },
                                  {
                                    id: "online",
                                    name: "Pagamento online",
                                  },
                                  {
                                    id: "pix",
                                    name: "Pix",
                                  },
                                  {
                                    id: "assinado",
                                    name: "Assinado",
                                  },
                                  {
                                    id: "boleto",
                                    name: "Boleto",
                                  },
                                  {
                                    id: "cortesia",
                                    name: "Cortesia",
                                  },
                                ]}
                              />
                              <Input
                                type="date"
                                label="Data do Recebimento"
                                name={`revenues.${
                                  index + indexAcc * formOfReceipt.length
                                }.date_of_receipt_of_money`}
                                value={formatDatabaseWithOutHours(
                                  new Date(
                                    getValues(
                                      `revenues.${
                                        index + indexAcc * formOfReceipt.length
                                      }.date_of_receipt_of_money`
                                    )
                                  )
                                )}
                                isRequired={true}
                                isDisabled
                              />
                              <Input
                                label="Valor"
                                isRequired={true}
                                {...register(
                                  `revenues.${
                                    index + indexAcc * formOfReceipt.length
                                  }.value`
                                )}
                                leftAddon="R$"
                                //   validaded={
                                //     getValues("value") &&
                                //     getValues("value") !== ""
                                //   }
                                onChange={(e) => {
                                  var value = e.target.value;
                                  if (value.length > 2) {
                                    value = value
                                      .replace(/\D+/g, "")
                                      .replace(/([0-9]{2})$/g, ",$1");
                                    e.target.value = value;
                                    setValue(
                                      `revenues.${
                                        index + indexAcc * formOfReceipt.length
                                      }.value`,
                                      value
                                    );
                                  }
                                }}
                              />
                            </SimpleGrid>
                          </>
                        ))}
                    </SimpleGrid>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Stack>
        }
        footer={<SubmitButton text="Salvar" form="cash-closing" />}
      />
    </>
  );
}
