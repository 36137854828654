import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  FaCheckSquare,
  FaDollarSign,
  FaPlus,
  FaRegSquare,
  FaSearch,
} from "react-icons/fa";

interface DraggableAccountPlanProps {
  title: string;
  subcategories: {
    id: string;
    name: string;
    accountPlanCategory: {
      type: string;
      name: string;
      subtype: string;
    };
  }[];
  droppableId: string;
  isCheck: boolean;
  has_created?: boolean;
  onOpen?: () => void;
  setDisplay: (type: string, subtype: string) => void;
  has_block?: boolean;
}

export function DraggableAccountPlan({
  subcategories,
  droppableId,
  title,
  isCheck,
  has_created = false,
  onOpen,
  setDisplay,
  has_block = false,
}: DraggableAccountPlanProps) {
  const [search, setSearch] = useState("");
  const [filterSubcategories, setFilterSubcategories] = useState(subcategories);
  useEffect(() => {
    setFilterSubcategories(subcategories);
  }, [subcategories]);
  return (
    <Droppable droppableId={droppableId} type="COLUMN" isCombineEnabled>
      {(provided: any) => {
        return (
          <Flex
            m="2"
            bg="#f0f0f0"
            borderRadius={8}
            boxShadow="md"
            padding={8}
            {...provided.droppableProps}
            ref={provided.innerRef}
            flexDirection="column"
            minWidth="320px"
          >
            <InputGroup size="sm" mb={2} bg="white" borderRadius="5px">
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FaSearch} color="gray.300" />}
              />
              <Input
                placeholder="Pesquisar"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>

            <Flex justify="space-between" mb={1}>
              <Text fontWeight="bold">{title}</Text>
              {has_created && (
                <Button
                  size="xs"
                  colorScheme="blue"
                  onClick={() => {
                    if (onOpen) {
                      onOpen();
                    }
                  }}
                >
                  <FaPlus />
                </Button>
              )}
            </Flex>

            <List
              w="100%"
              height="400px"
              overflowY="scroll"
              sx={has_block ? { filter: "blur(2px)" } : {}}
            >
              {!has_block
                ? filterSubcategories
                    .filter((subcategory) => {
                      if (search === "") {
                        return true;
                      }
                      if (new RegExp(search, "gi").test(subcategory.name)) {
                        return true;
                      }
                      return false;
                    })
                    .map((subcategory, index) => (
                      <Draggable
                        key={subcategory.id}
                        draggableId={`${subcategory.id}/${subcategory.accountPlanCategory.type}/${subcategory.accountPlanCategory.subtype}`}
                        index={index}
                      >
                        {(provided: any) => {
                          return (
                            <Box
                              onMouseDown={() => {
                                setDisplay(
                                  subcategory.accountPlanCategory.type,
                                  subcategory.accountPlanCategory.subtype
                                );
                              }}
                            >
                              <Flex
                                key={subcategory.id}
                                boxShadow="md"
                                borderRadius={4}
                                bg="#ffffff"
                                p="2"
                                my="2"
                                mx="2"
                                alignItems="center"
                                justifyContent={"space-between"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Flex alignItems="center">
                                  <Icon
                                    as={isCheck ? FaCheckSquare : FaRegSquare}
                                    mr="2"
                                    color={
                                      isCheck ? "#216ca5" : "blackAlpha.400"
                                    }
                                  />
                                  <Flex flexDirection={"column"}>
                                    <Text lineHeight={"16px"}>
                                      {subcategory.name}
                                    </Text>
                                    <Text lineHeight={"16px"} as="small">
                                      {subcategory.accountPlanCategory.name}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Box
                                  p={0.5}
                                  borderRadius={4}
                                  bg={
                                    subcategory.accountPlanCategory.type ===
                                    "Despesa"
                                      ? "red"
                                      : "green"
                                  }
                                  color="#ffffff"
                                  fontSize={10}
                                  fontWeight="bold"
                                >
                                  <FaDollarSign />
                                </Box>
                              </Flex>
                            </Box>
                          );
                        }}
                      </Draggable>
                    ))
                : filterSubcategories
                    .filter((subcategory) => {
                      if (search === "") {
                        return true;
                      }
                      if (new RegExp(search, "gi").test(subcategory.name)) {
                        return true;
                      }
                      return false;
                    })
                    .map((subcategory) => (
                      <Flex
                        boxShadow="md"
                        borderRadius={4}
                        bg="#ffffff"
                        p="2"
                        my="2"
                        mx="2"
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Flex
                          alignItems="center"
                          onMouseDown={() =>
                            setDisplay(
                              subcategory.accountPlanCategory.type,
                              subcategory.accountPlanCategory.subtype
                            )
                          }
                        >
                          <Icon
                            as={isCheck ? FaCheckSquare : FaRegSquare}
                            mr="2"
                            color={isCheck ? "#216ca5" : "blackAlpha.400"}
                          />
                          {subcategory.name}
                        </Flex>
                        <Box
                          p={0.5}
                          borderRadius={4}
                          bg={
                            subcategory.accountPlanCategory.type === "Despesa"
                              ? "red"
                              : "green"
                          }
                          color="#ffffff"
                          fontSize={10}
                          fontWeight="bold"
                        >
                          <FaDollarSign />
                        </Box>
                      </Flex>
                    ))}
            </List>
          </Flex>
        );
      }}
    </Droppable>
  );
}
