import {
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Card } from "../../../components/Card";
import { Input } from "../../../components/Form/Input";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import * as yup from "yup";
import { api } from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDatabaseWithOutHours } from "../../../utils/formatDate";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks/query";

const formOfReceipt = [
  {
    id: "assinado",
    name: "Assinado",
  },
  {
    id: "boleto",
    name: "Boleto",
  },
  {
    id: "cortesia",
    name: "Cortesia",
  },
  {
    id: "credito",
    name: "Cartão de crédito",
  },

  {
    id: "debito",
    name: "Cartão de débito",
  },
  {
    id: "dinheiro",
    name: "Dinheiro",
  },
  {
    id: "online",
    name: "Pagamento online",
  },
  {
    id: "pix",
    name: "Pix",
  },
  {
    id: "vale",
    name: "Vale",
  },
];

type dictionaryType =
  | "dinheiro"
  | "credito"
  | "debito"
  | "vale"
  | "online"
  | "pix"
  | "assinado"
  | "boleto"
  | "cortesia";

const dictionary = {
  dinheiro: "Dinheiro",
  credito: "Cartão de Crédito",
  debito: "Cartão de Débito",
  vale: "Vale",
  online: "Pagamento Online",
  pix: "Pix",
  assinado: "Assinado",
  boleto: "Boleto",
  cortesia: "Cortesia",
};

const cashClosingValidation = yup.object().shape({
  date_of_competence: yup
    .string()
    .required("Data de competência é obrigatória"),
  cashClosings: yup.array().of(
    yup.object().shape({
      account_plan_subcategory_id: yup
        .string()
        .required("Receita é obrigatória"),
      total_clients: yup
        .number()
        .integer("Valor inválido")
        .min(0, "Valor inválido")
        .required("Total de Clientes é obrigatório"),
      revenues: yup.array().of(
        yup.object().shape({
          value: yup.string(),
          form_of_receipt: yup
            .string()
            .required("Forma de recebimento é obrigatória"),
        })
      ),
    })
  ),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
  priority: number;
}

interface ILiquidityOfPayment {
  money: number;
  debit: number;
  credit: number;
  voucher: number;
  online_payment: number;
  pix: number;
  subscribed: number;
  courtesy: number;
  bank_slip: number;
}

interface IRevenue {
  id?: string;
  value: string;
  form_of_receipt: string;
  date_of_receipt_of_money: Date;
}

interface ICashClosing {
  account_plan_subcategory_id: string;
  company_id: string;
  total_clients: string;
  revenues: IRevenue[];
}

export function CreateCashClosing() {
  const { user } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const query = useQuery();
  const [liquidityOfPayment, setLiquidityOfPayment] =
    useState<ILiquidityOfPayment>({} as ILiquidityOfPayment);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);

  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(cashClosingValidation),
    defaultValues: {
      cashClosings: [] as ICashClosing[],
      date_of_competence: query.get("date_of_competence") ?? "",
    },
  });
  const cashClosings = watch("cashClosings");
  const dateOfCompetence = watch("date_of_competence");

  useMemo(() => {
    const initialCashClosing: ICashClosing[] = [];

    accountPlanSubcategories.forEach((acc) => {
      const initialRevenue: IRevenue[] = [];
      formOfReceipt.forEach((form) => {
        initialRevenue.push({
          form_of_receipt: form.id,
          value: "0",
          date_of_receipt_of_money: new Date(),
        });
      });
      initialCashClosing.push({
        revenues: initialRevenue,
        account_plan_subcategory_id: acc.id,
        company_id: user.company_id,
        total_clients: "0",
      });
    });

    setValue("cashClosings", initialCashClosing);
  }, [accountPlanSubcategories, setValue, user.company_id]);

  useEffect(() => {
    api.get("/accountPlanSubcategories/type?type=Receita").then((response) => {
      const accPlanSubcategories = response.data.filter(
        (acc: AccountPlanSubcategory) => acc.priority !== 0
      ) as AccountPlanSubcategory[];

      setAccountPlanSubcategories(accPlanSubcategories);
    });

    api
      .get<ILiquidityOfPayment>(
        `/liquidityOfPayments/company/${user.company_id}`
      )
      .then((response) => {
        setLiquidityOfPayment(response.data);
      });
  }, [user.company_id, setValue]);

  const generatedReceiptPayment = useCallback(
    (date: string, formOfReceipt: string) => {
      const splitString = date.split("-");

      const split = splitString.map((i) => Number(i));

      switch (formOfReceipt) {
        case "dinheiro": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.money
          );
          return formatDatabaseWithOutHours(date);
        }
        case "credito": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.credit
          );
          return formatDatabaseWithOutHours(date);
        }
        case "debito": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.debit
          );
          return formatDatabaseWithOutHours(date);
        }
        case "vale": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.voucher
          );
          return formatDatabaseWithOutHours(date);
        }
        case "online": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.online_payment
          );
          return formatDatabaseWithOutHours(date);
        }
        case "pix": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.pix
          );
          return formatDatabaseWithOutHours(date);
        }
        case "assinado": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.subscribed
          );
          return formatDatabaseWithOutHours(date);
        }

        case "boleto": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.bank_slip
          );
          return formatDatabaseWithOutHours(date);
        }

        case "cortesia": {
          const date = new Date(
            split[0],
            split[1] - 1,
            Number(split[2]) + liquidityOfPayment.courtesy
          );
          return formatDatabaseWithOutHours(date);
        }
        default: {
          return "";
        }
      }
    },
    [liquidityOfPayment]
  );

  const handleDateOfCompetence = useCallback(
    (date: string) => {
      const cashClosings = getValues("cashClosings") as ICashClosing[];

      const newCashClosings = cashClosings.map((cashClosing) => {
        return {
          ...cashClosing,
          date,
          revenues: cashClosing.revenues.map((revenue) => {
            return {
              ...revenue,
              date_of_competence: new Date(date),
              date_of_receipt_of_money: new Date(
                generatedReceiptPayment(date, revenue.form_of_receipt)
              ),
            };
          }),
        };
      });

      setValue("cashClosings", newCashClosings);
    },
    [getValues, setValue, generatedReceiptPayment]
  );

  const handleSubmitCashClosing = useCallback(
    (formValue: any) => {
      const revenues = cashClosings.filter((cashClosing) => {
        const findRevenueDiffZero = cashClosing.revenues.findIndex(
          (revenue) => revenue.value !== "0"
        );
        return findRevenueDiffZero === -1 ? false : true;
      });

      if (revenues.length > 0) {
        api
          .post("/revenues/cashClosing", {
            cashClosings: formValue.cashClosings,
            date_of_competence: formValue.date_of_competence,
          })
          .then((response) => {
            toast({
              title: "Cadastro do fechamento realizado com sucesso",
              status: "success",
            });
            navigate("/contas-receber");
          })
          .catch((err) => {
            console.log(err);
            toast({
              title: "Não foi possível realizar o cadastro",
              status: "error",
            });
          });
      } else {
        toast({
          title: "É necessário informar ao menos uma receita",
          status: "error",
        });
      }
    },
    [cashClosings, toast, navigate]
  );

  useEffect(() => {
    if (dateOfCompetence && accountPlanSubcategories.length > 0) {
      api
        .get(
          `/revenues/cashClosing/${user.company_id}?date_of_competence=${dateOfCompetence}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            toast({
              title: "Já existe um fechamento de caixa nessa data",
              status: "info",
            });
            const initialRevenue: IRevenue[] = [];
            formOfReceipt.forEach((form) => {
              initialRevenue.push({
                form_of_receipt: form.id,
                value: "0",
                date_of_receipt_of_money: new Date(),
              });
            });

            const cashClosings = response.data;
            setValue(
              "cashClosings",
              accountPlanSubcategories.map((acc) => {
                const findCashClosing = cashClosings.find(
                  (cashClosing: ICashClosing) =>
                    cashClosing.account_plan_subcategory_id === acc.id
                );

                if (findCashClosing) {
                  return {
                    id: findCashClosing.id,
                    account_plan_subcategory_id:
                      findCashClosing.account_plan_subcategory_id,
                    company_id: findCashClosing.company_id,
                    total_clients: findCashClosing.total_clients,
                    revenues: initialRevenue.map((revenue) => {
                      const findRevenue = findCashClosing.revenues.find(
                        (cashClosingRevenue: IRevenue) =>
                          cashClosingRevenue.form_of_receipt ===
                          revenue.form_of_receipt
                      );

                      if (findRevenue) {
                        return {
                          value: Number(findRevenue.value)
                            .toFixed(2)
                            .replace(/\D+/g, "")
                            .replace(/([0-9]{2})$/g, ",$1"),
                          form_of_receipt: findRevenue.form_of_receipt,
                          date_of_receipt_of_money:
                            findRevenue.date_of_receipt_of_money,
                          id: findRevenue.id,
                        };
                      } else {
                        return revenue;
                      }
                    }),
                  };
                } else {
                  
                  return {
                    revenues: initialRevenue,
                    account_plan_subcategory_id: acc.id,
                    company_id: user.company_id,
                    total_clients: "0",
                  };
                }
              })
            );
          } else {
            const initialCashClosing: ICashClosing[] = [];

            accountPlanSubcategories.forEach((acc) => {
              const initialRevenue: IRevenue[] = [];
              formOfReceipt.forEach((form) => {
                initialRevenue.push({
                  form_of_receipt: form.id,
                  value: "0",
                  date_of_receipt_of_money: new Date(),
                });
              });
              initialCashClosing.push({
                revenues: initialRevenue,
                account_plan_subcategory_id: acc.id,
                company_id: user.company_id,
                total_clients: "0",
              });
            });

            setValue("cashClosings", initialCashClosing);
            handleDateOfCompetence(dateOfCompetence);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    dateOfCompetence,
    user.company_id,
    setValue,
    handleDateOfCompetence,
    toast,
    accountPlanSubcategories,
  ]);

  return (
    <>
      <InternalHeader
        title="Fechamento de Caixa"
        has_back_button
        has_filter={false}
      />
      <Card
        border="#216ca5"
        body={
          <Stack
            as="form"
            id="cash-closing"
            onSubmit={handleSubmit(handleSubmitCashClosing)}
          >
            <Flex justifyContent={"space-between"} flexWrap="wrap">
              <Flex alignItems={"center"} flexWrap="wrap">
                <Flex width={"130px"} alignItems={"center"}>
                  <Text>Data da Receita</Text>
                </Flex>
                <SimpleGrid>
                  <Input
                    type="date"
                    isRequired={true}
                    error={errors.date_of_competence}
                    isDisabled={query.get("date_of_competence") !== null}
                    name="date_of_compentence"
                    onChange={(e) => {
                      setValue("date_of_competence", e.target.value);
                      handleDateOfCompetence(e.target.value);
                    }}
                    validaded={
                      getValues("date_of_competence") !== undefined &&
                      getValues("date_of_competence") !== ""
                    }
                    value={getValues("date_of_competence")}
                  />
                </SimpleGrid>
              </Flex>

              <Flex
                bg="#f0f0f0"
                width={{ base: "100%", sm: "150px" }}
                borderRadius="8"
                boxShadow={"md"}
                borderTop="2px solid #2b88a1"
                flexDirection={"column"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Text fontSize="18px" fontWeight="bold">
                  Total
                </Text>
                <Text>
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    cashClosings
                      .map((cashClosing) =>
                        cashClosing.revenues.reduce(
                          (total, revenue) =>
                            total + Number(revenue.value.replace(",", ".")),
                          0
                        )
                      )
                      .reduce((total, actual) => total + actual, 0)
                  )}
                </Text>
              </Flex>
            </Flex>
            <Tabs>
              <TabList>
                {accountPlanSubcategories.map((acc) => (
                  <Tab key={acc.id}>{acc.name}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {accountPlanSubcategories.map((acc, indexAcc) => (
                  <TabPanel key={acc.id}>
                    <SimpleGrid mt="4">
                      <Flex
                        key={indexAcc}
                        px="4"
                        flexWrap={"wrap"}
                        boxShadow={"md"}
                        borderTop={"2px solid #e0e0e0"}
                        bg={indexAcc % 2 === 0 ? "#f0f0f0" : "#ffffff"}
                        mb={5}
                      >
                        <SimpleGrid width="150px" ml="4">
                          <Flex alignItems={"center"}>
                            <Text>Total de Clientes:</Text>
                          </Flex>
                        </SimpleGrid>
                        <SimpleGrid
                          width={{ base: "100%", sm: "150px" }}
                          ml="4"
                        >
                          <Input
                            type={"number"}
                            step={1}
                            min={0}
                            defaultValue={0}
                            isRequired={true}
                            {...register(
                              `cashClosings.${indexAcc}.total_clients`
                            )}
                            validaded={
                              getValues(
                                `cashClosings.${indexAcc}.total_clients`
                              ) !== "" &&
                              getValues(
                                `cashClosings.${indexAcc}.total_clients`
                              ) !== "0"
                            }
                            onChange={(e) => {
                              setValue(
                                `cashClosings.${indexAcc}.total_clients`,
                                e.target.value
                              );
                            }}
                          />
                        </SimpleGrid>
                      </Flex>
                      {cashClosings
                        .find(
                          (cashClosing) =>
                            cashClosing.account_plan_subcategory_id === acc.id
                        )
                        ?.revenues.map((revenue, index) => (
                          <Flex
                            key={index}
                            px="4"
                            flexWrap={"wrap"}
                            boxShadow={"md"}
                            borderTop={"2px solid #e0e0e0"}
                            bg={index % 2 === 0 ? "#f0f0f0" : "#ffffff"}
                          >
                            <SimpleGrid width="150px" ml="4">
                              <Flex alignItems={"center"}>
                                <Text>
                                  {
                                    dictionary[
                                      getValues(
                                        `cashClosings.${indexAcc}.revenues.${index}.form_of_receipt`
                                      ) as dictionaryType
                                    ]
                                  }
                                </Text>
                              </Flex>
                            </SimpleGrid>
                            <SimpleGrid
                              width={{ base: "100%", sm: "150px" }}
                              ml="4"
                            >
                              <Input
                                isRequired={true}
                                {...register(
                                  `cashClosings.${indexAcc}.revenues.${index}.value`
                                )}
                                leftElement={"R$"}
                                validaded={
                                  getValues(
                                    `cashClosings.${indexAcc}.revenues.${index}.value`
                                  ).length > 2 &&
                                  getValues(
                                    `cashClosings.${indexAcc}.revenues.${index}.value`
                                  ) !== "0"
                                }
                                onChange={(e) => {
                                  var value = e.target.value;
                                  if (value.length > 2) {
                                    value = value
                                      .replace(/\D+/g, "")
                                      .replace(/([0-9]{2})$/g, ",$1");
                                    e.target.value = value;
                                    setValue(
                                      `cashClosings.${indexAcc}.revenues.${index}.value`,
                                      value
                                    );
                                  }
                                }}
                              />
                            </SimpleGrid>
                          </Flex>
                        ))}
                      <Flex
                        key={indexAcc}
                        px="4"
                        flexWrap={"wrap"}
                        boxShadow={"md"}
                        borderTop={"2px solid #e0e0e0"}
                        bg={indexAcc % 2 === 0 ? "#f0f0f0" : "#ffffff"}
                        mt={5}
                        h="40px"
                      >
                        <SimpleGrid width="150px" ml="4">
                          <Flex alignItems={"center"}>
                            <Text fontWeight="bold">Total {acc.name}:</Text>
                          </Flex>
                        </SimpleGrid>
                        <SimpleGrid
                          width={{ base: "100%", sm: "150px" }}
                          ml="4"
                        >
                          <Flex alignItems={"center"}>
                            <Text alignItems={"center"}>
                              {Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(
                                cashClosings
                                  .filter(
                                    (cashClosing) =>
                                      cashClosing.account_plan_subcategory_id ===
                                      acc.id
                                  )
                                  .map((cashClosing) =>
                                    cashClosing.revenues.reduce(
                                      (total, revenue) =>
                                        total +
                                        Number(revenue.value.replace(",", ".")),
                                      0
                                    )
                                  )
                                  .reduce((total, actual) => total + actual, 0)
                              )}
                            </Text>
                          </Flex>
                        </SimpleGrid>
                      </Flex>
                    </SimpleGrid>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Stack>
        }
        footer={<SubmitButton text="Salvar" form="cash-closing" />}
      />
    </>
  );
}
