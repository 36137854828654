import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useParams } from "react-router";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";
import { PreLoader } from "../../../components/templates/PreLoader";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório"),
  account_plan_subcategory_id: yup.object().required("Despesa é obrigatória"),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

export function EditFavored() {
  const toast = useToast();
  let { id } = useParams();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const { register, handleSubmit, formState, reset, setValue, getValues } =
    useForm({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Despesa")
      .then((response: any) => {
        setAccountPlanSubcategories(response.data);
        api.get(`/favored/${id}`).then((responseFavored) => {
          reset(responseFavored.data);
          setValue("account_plan_subcategory_id", {
            label:
              response.data.find(
                (accountPlanSubcategory: any) =>
                  accountPlanSubcategory.id ===
                  responseFavored.data.account_plan_subcategory_id
              ).name ?? "",
            value: responseFavored.data.account_plan_subcategory_id,
          });
          setIsLoaded(true);
        });
      });
  }, [id, reset, setValue]);

  const handleEditFavored: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/favored/${id}`, {
          name: formValue.name,
          account_plan_subcategory_id:
            formValue.account_plan_subcategory_id.value,
        });
        navigate("/favorecido");
        toast({
          title: "Favorecido editado com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível editar o favorecido",
          description: "Esse favorecido pode já estar cadastrado.",
          status: "error",
        });
      }
    },
    [id, navigate, toast]
  );

  const { errors } = formState;

  return (
    <>
      <InternalHeader
        title="Editar Favorecido"
        has_back_button={true}
        has_filter={false}
      />
      <Card
        border="#216ca5"
        body={
          <PreLoader message="Buscando favorecido..." isLoaded={isLoaded}>
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditFavored)}
              id="add-form"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                  validaded={getValues("name") && !errors.name}
                />
                <MultiSelectControl
                  options={accountPlanSubcategories.map((acc) => ({
                    label: acc.name,
                    value: acc.id,
                  }))}
                  {...register("account_plan_subcategory_id")}
                  onChange={(selected) => {
                    setValue("account_plan_subcategory_id", selected, {
                      shouldValidate: true,
                    });
                  }}
                  value={getValues("account_plan_subcategory_id")}
                  validaded={
                    getValues("account_plan_subcategory_id") &&
                    getValues("account_plan_subcategory_id").value &&
                    !errors.account_plan_subcategory_id
                  }
                  isMulti={false}
                  label="Despesa"
                  error={errors.account_plan_subcategory_id}
                  isRequired={true}
                />
              </Stack>
            </Flex>
          </PreLoader>
        }
        footer={<SubmitButton formState={formState} text="Editar" />}
      />
    </>
  );
}
