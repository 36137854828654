import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { Input } from "../../../Form/Input";
import Falae from "../../../../assets/falae_logo.png";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import { useToast } from "../../../../hooks/toast";

interface Company {
  id: string;
  name: string;
}

const falaeSchema = yup.object().shape({
  email: yup.string().email("Email inválido").required("Email é obrigatório"),
  password: yup.string().required("Senha é obrigatória"),
  first_step: yup.boolean(),
  company_id: yup.string().when("first_step", {
    is: (val: boolean) => val,
    then: yup.string().required("Empresa é obrigatória"),
  }),
});

export function ModalUpdateFalaId() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { user, updateCompany } = useAuth();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
    trigger,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(falaeSchema),
    mode: "onChange",
    defaultValues: {
      first_step: false,
      company_id: "",
      email: "",
      password: "",
    },
  });

  const handleAutenticateFalae = useCallback(
    (formValue: any) => {
      api
        .post("/falae/companies", {
          email: formValue.email,
          password: formValue.password,
        })
        .then((response) => {
          setCompanies(response.data.companies);
          setValue("first_step", true);
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: "Falha ao conectar no Falaê",
            status: "error",
          });
        });
    },
    [setValue, toast]
  );

  const handleCompaniesUser = useCallback(
    (company_id: string) => {
      api
        .patch(`/companies/falae/${user.company_id}`, {
          falae_id: company_id,
        })
        .then((response) => {
          updateCompany(response.data);
          toast({
            title: "Falaê integrado com sucesso",
            status: "success",
          });
          onClose();
        })
        .catch((err) => {
          toast({
            title: "Não foi possível integrar com Falaê",
            status: "error",
          });
          console.log(err);
        });
    },
    [user.company_id, toast, updateCompany, onClose]
  );

  const { errors } = formState;

  return (
    <>
      <Button onClick={onOpen} colorScheme={"blue"}>
        Gerenciar
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          reset();
          onClose();
        }}
        isCentered
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Autenticação - Falaê</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {companies.length > 0 ? (
              <Stack flexDir="column" w="100%" as="form" id="company-falae">
                <Flex justifyContent={"center"}>
                  <Image src={Falae} alt="Falaê" mb="4" width="160px" />
                </Flex>
                <Controller
                  name="company_id"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <option value="" hidden>
                        Selecione uma opção
                      </option>
                      {companies.map((company) => (
                        <option value={company.id}>{company.name}</option>
                      ))}
                    </Select>
                  )}
                />
              </Stack>
            ) : (
              <Stack
                as="form"
                flexDir="column"
                w="100%"
                id="form-falae"
                onSubmit={handleSubmit(handleAutenticateFalae)}
              >
                <Flex justifyContent={"center"}>
                  <Image src={Falae} alt="Falaê" mb="4" width="160px" />
                </Flex>
                <Input
                  type="text"
                  label="Email"
                  isRequired={true}
                  {...register("email")}
                  error={errors.email}
                />
                <Input
                  type="password"
                  label="Senha"
                  isRequired={true}
                  {...register("password")}
                  error={errors.password}
                />
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() => {
                setCompanies([]);
                reset();
                onClose();
              }}
            >
              Cancelar
            </Button>
            {companies.length > 0 ? (
              <Button
                onClick={async () => {
                  const result = await trigger("company_id");
                  if (result) {
                    handleCompaniesUser(getValues("company_id"));
                  }
                }}
              >
                Cadastrar
              </Button>
            ) : (
              <SubmitButton mt={"0"} text={"Entrar"} form={"form-falae"} />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
