import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface ModalContentBranchPathProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
}

export function ModalContentBranchPath({
  isOpen,
  onClose,
  children,
  text,
  title,
}: ModalContentBranchPathProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{text}</ModalBody>
        <ModalFooter justifyContent="center">{children}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}
