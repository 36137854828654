import React from "react";
import { FormState, FieldValues } from "react-hook-form";
import { Button } from "@chakra-ui/react";
import { shade } from "polished";
interface SubmitButtonProps {
  text?: string;
  formState?: FormState<FieldValues>;
  isLoading?: boolean;
  loadingText?: string;
  form?: string;
  mt?: string;
  bg?: string;
  color?: string;
}
export function SubmitButton({
  formState,
  text = "Cadastrar",
  isLoading,
  loadingText,
  form = "add-form",
  mt = "6",
  color = "white",
  bg,
}: SubmitButtonProps) {
  return (
    <Button
      type="submit"
      form={form}
      color={color}
      bg={bg === undefined ? "#216ca5" : bg}
      _hover={{
        backgroundColor: shade(0.2, bg === undefined ? "#216ca5" : bg),
      }}
      _active={{
        backgroundColor: shade(0.2, bg === undefined ? "#216ca5" : bg),
      }}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
}
