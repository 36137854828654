import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  Flex,
  Link,
  SimpleGrid,
  Skeleton,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";
import { SelectControl } from "../../../components/templates/Form/SelectControl";
import { formatMoney } from "../../../utils/money";
import {
  createDate,
  formatDatabaseWithOutHours,
} from "../../../utils/formatDate";
import { config } from "../../../services/config";
import { FormControl } from "../../../components/Form/FormControl";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";
import { CreateSelectControl } from "../../../components/templates/Form/CreateSelectControl";
import { checkPermission } from "../../../utils/checkPermission";

const editExpenseFormSchema = yup.object().shape({
  observation: yup.string().nullable().typeError("Valor inválido"),
  value: yup.string().required("Valor é obrigatório"),
  form_of_payment: yup.string().required("Forma de pagamento é obrigatória"),
  account_plan_subcategory_id: yup
    .string()
    .uuid("Despesa inválida")
    .required("Despesa é obrigatória"),
  due_date: yup.string().required("Data do vencimento é obrigatória"),
  favored_id: yup.string().required("O favorecido é obrigatória"),
  file: yup
    .mixed()
    .test("format", "Formato inválido", (value: any) => {
      const imageExtensions = /\.(jpg|jpeg|png|gif|pdf)$/i;
      return value.length > 0 ? imageExtensions.test(value[0].name) : true;
    })
    .test("size", "O arquivo é maior que o mínimo de 3MB", (value: any) => {
      const imageExtensions = /\.(pdf)$/i;
      if (value.length > 0 && imageExtensions.test(value[0].name)) {
        return value[0].size < threeMBInBytes;
      } else {
        return true;
      }
    }),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface Favored {
  id: string;
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}

const threeMBInBytes = 3 * 10 ** 6;

export function EditExpense() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();

  const [favored, setFavored] = useState<Favored[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(editExpenseFormSchema),
  });

  const accountPlanSubcategoryId = watch("account_plan_subcategory_id");

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Despesa")
      .then((response) => setAccountPlanSubcategories(response.data));
    api.get(`/expenses/${id}`).then((response) => {
      setHasFile(response.data.file);
      reset({
        ...response.data,
        file: [{
          name: response.data.file
        }],
        favored_name: response.data?.favored ? response.data.favored.name : "",
        favored_id: response.data?.favored ? response.data.favored.id : "",
        ...(response.data.due_date
          ? {
              due_date: formatDatabaseWithOutHours(
                createDate(response.data.due_date)
              ),
            }
          : ""),
        ...(response.data.date_of_competence
          ? {
              date_of_competence: formatDatabaseWithOutHours(
                createDate(response.data.date_of_competence)
              ),
            }
          : ""),
        ...(response.data.effective_write_off_date
          ? {
              effective_write_off_date: formatDatabaseWithOutHours(
                createDate(response.data.effective_write_off_date)
              ),
            }
          : ""),
        value: formatMoney(response.data.value)
          .replace("R$ ", "")
          .replace(".", ""),
      });
      setLoading(true);
    });
  }, [id, reset]);

  const handleEditExpense: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
       
        const data = new FormData();
        if (formValue.file) {
          data.append("file", formValue.file[0]);
        }
        data.append("observation", formValue.observation);
        data.append("due_date", formValue.due_date);
        data.append("date_of_competence", formValue.date_of_competence);
        data.append(
          "effective_write_off_date",
          formValue.effective_write_off_date
        );
        data.append("form_of_payment", formValue.form_of_payment);
        data.append(
          "account_plan_subcategory_id",
          formValue.account_plan_subcategory_id
        );
        data.append("favored_name", formValue.favored_name);
        data.append("value", formValue.value);
        data.append("company_id", user.company_id);
        await api.put(`/expenses/${id}`, data, config);

        navigate(-1);
        toast({
          title: "Conta a pagar editada com sucesso!",
          status: "success",
        });
      } catch (err) {
        console.log(err);
        toast({
          title: "Não foi possível editar a conta a pagar",
          status: "error",
        });
      }
    },
    [id, navigate, toast, user.company_id]
  );

  useEffect(() => {
    api.get(`/favored/company/${user.company_id}`).then((response) => {
      setFavored(response.data);
    });
  }, [user.company_id]);
  const { errors } = formState;

  return (
    <>
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar despesa"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditExpense)}
              id="add-form"
            >
              <Stack spacing="4">
                <SimpleGrid spacingX={4}>
                  <CreateSelectControl
                    onCreateOption={(value: string) => {
                      setValue("favored_name", value, {
                        shouldValidate: true,
                      });
                      setValue("favored_id", value, {
                        shouldValidate: true,
                      });
                      setFavored((prev) => [
                        ...prev,
                        {
                          id: value.toLowerCase(),
                          name: value,
                          account_plan_subcategory_id: "",
                          company_id: user.company_id,
                          account_plan_subcategory: {
                            name: "",
                          },
                        },
                      ]);
                    }}
                    onChange={(select: any) => {
                      const selected = favored.find(
                        (f) => f.id === select.value
                      );
                      if (selected) {
                        setValue("favored_id", selected.id, {
                          shouldValidate: true,
                        });
                        setValue("favored_name", selected.name);
                        if (selected.account_plan_subcategory.name) {
                          setValue(
                            "account_plan_subcategory_id",
                            selected.account_plan_subcategory_id
                          );
                          setValue(
                            "account_plan_subcategory_name",
                            selected.account_plan_subcategory.name
                          );
                        }
                      }
                    }}
                    options={favored.map((f) => ({
                      label: `${f.name} ${
                        f.account_plan_subcategory.name
                          ? `- ${f.account_plan_subcategory.name}`
                          : ""
                      }`,
                      value: f.id,
                    }))}
                    name="favored"
                    label="Favorecido"
                    isRequired
                    value={
                      getValues("favored_id")
                        ? {
                            label: getValues("favored_name"),
                            value: getValues("favored_id"),
                          }
                        : undefined
                    }
                    error={errors.favored_id}
                    validate={getValues("favored_name") && !errors.favored}
                  />
                  <MultiSelectControl
                    options={accountPlanSubcategories.map((acc) => ({
                      label: acc.name,
                      value: acc.id,
                    }))}
                    {...register("account_plan_subcategory_id")}
                    onChange={(selected) => {
                      setValue("account_plan_subcategory_id", selected.value, {
                        shouldValidate: true,
                      });
                      setValue(
                        "account_plan_subcategory_name",
                        selected.label,
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                    value={
                      accountPlanSubcategoryId
                        ? {
                            label: accountPlanSubcategories.find(
                              (acc) => acc.id === accountPlanSubcategoryId
                            )?.name,
                            value: accountPlanSubcategoryId,
                          }
                        : undefined
                    }
                    validaded={
                      getValues("account_plan_subcategory_id") &&
                      !errors.account_plan_subcategory_id
                    }
                    isMulti={false}
                    label="Despesa"
                    error={errors.account_plan_subcategory_id}
                    isRequired={true}
                  />
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={4}>
                  <SimpleGrid borderRadius={8} boxShadow="md" py="4" px="8">
                    <SelectControl
                      control={control}
                      error={errors.form_of_payment}
                      {...register("form_of_payment")}
                      validaded={
                        getValues("form_of_payment") &&
                        getValues("form_of_payment") !== ""
                      }
                      isRequired={true}
                      label="Forma de Pagamento"
                      options={[
                        {
                          id: "boleto",
                          name: "Boleto",
                        },
                        {
                          id: "caixa",
                          name: "Fundo de Caixa",
                        },
                        {
                          id: "credito",
                          name: "Cartão de crédito",
                        },

                        {
                          id: "debito",
                          name: "Cartão de débito",
                        },
                        {
                          id: "dinheiro",
                          name: "Dinheiro",
                        },
                        {
                          id: "pix",
                          name: "Pix",
                        },
                        {
                          id: "transferencia",
                          name: "Transferência",
                        },
                      ]}
                    />
                    <Input
                      label="Valor"
                      isRequired={true}
                      error={errors.value}
                      validaded={
                        getValues("value") && getValues("value") !== ""
                      }
                      {...register("value")}
                      leftAddon="R$"
                      onChange={(e) => {
                        var value = e.target.value;
                        if (value.length > 2) {
                          value = value
                            .replace(/\D+/g, "")
                            .replace(/([0-9]{2})$/g, ",$1");
                          e.target.value = value;
                          setValue("value", value, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          });
                        } else {
                          setValue("value", e.target.value);
                        }
                      }}
                    />
                    <FormControl
                      label="Conta"
                      isRequired={false}
                      name="file"
                      error={errors.file}
                    >
                      <input
                        type="file"
                        {...register("file")}
                        accept="image/png,image/jpeg,image/gif,.pdf"
                      />
                    </FormControl>
                    {hasFile && (
                      <Tooltip label="A visualização do arquivo cadastrado anteriormente será apresentada. Se você adicionar um novo arquivo durante a edição, a visualização só estará disponível após finalizar todas as alterações.">
                        <Button
                          as={Link}
                          mt={2}
                          colorScheme="cyan"
                          variant="outline"
                          target="_blank"
                          href={getValues("file_url").replace(
                            "localhost:3000/",
                            ""
                          )}
                        >
                          Visualizar Arquivo armazenado
                        </Button>
                      </Tooltip>
                    )}
                  </SimpleGrid>
                  <SimpleGrid
                    bg="#f0f0f0"
                    borderRadius={8}
                    boxShadow="md"
                    py="4"
                    px="8"
                  >
                    <Input
                      type="date"
                      label="Data de Competência"
                      isRequired={true}
                      validaded={
                        getValues("date_of_competence") &&
                        getValues("dete_of_competence") !== ""
                      }
                      error={errors.date_of_competence}
                      {...register("date_of_competence")}
                    />
                    <Input
                      type="date"
                      label="Data do Vencimento"
                      isRequired={true}
                      error={errors.due_date}
                      validaded={
                        getValues("due_date") && getValues("due_date") !== ""
                      }
                      {...register("due_date")}
                    />
                    {checkPermission({
                      is_admin: user.is_admin,
                      type: "Manager",
                    }) ? (
                      <Input
                        type="date"
                        label="Data de Baixa Efetiva"
                        isRequired={false}
                        validaded={
                          getValues("effective_write_off_date") &&
                          getValues("effective_write_off_date") !== ""
                        }
                        error={errors.effective_write_off_date}
                        {...register("effective_write_off_date")}
                      />
                    ) : (
                      <></>
                    )}
                  </SimpleGrid>
                </SimpleGrid>
                <Input
                  type="text"
                  label="Descrição"
                  isRequired={false}
                  validaded={
                    getValues("observation") && getValues("observation") !== ""
                  }
                  error={errors.observation}
                  {...register("observation")}
                />
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
