import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "../../../components/Card";
import { useAuth } from "../../../hooks/auth";
import { api } from "../../../services/api";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { formatMonthAndYear } from "../../../utils/formatDate";
import { DRECard } from "../../../components/templates/Card/DRECard";
import { DREFilter } from "../../../components/templates/Filters/DREFilter";
import { DRETable } from "../../../components/templates/Table/DRETable";

interface AccountPlan {
  id: string;
  name: string;
  description: string;
}

interface Selected {
  period: string;
  last_month: string;
  type: "cashier" | "competence";
  account_plan_id: string;
}

interface Month {
  first_date?: string;
  second_date?: string;
  third_date?: string;
  fourth_date?: string;
  fifth_date?: string;
  sixth_date?: string;
  seventh_date?: string;
  eighth_date?: string;
  ninth_date?: string;
  eleventh_date: string;
  tenth_date: string;
  twelfth_date: string;
}

interface ClassificationProps {
  first: number;
  second: number;
  third: number;
  fourth: number;
  fifth: number;
  sixth: number;
  seventh: number;
  eighth: number;
  ninth: number;
  tenth: number;
  eleventh: number;
  twelfth: number;
}

interface Classification {
  expenses_operational: ClassificationProps;
  expenses_non_operational: ClassificationProps;
  revenues_operational: ClassificationProps;
  revenues_non_operational: ClassificationProps;
  expenses_about_recipe: ClassificationProps;
  operating_profit: ClassificationProps;
  gross_profit: ClassificationProps;
  final_result: ClassificationProps;
}

interface DataProps {
  id: string;
  name: string;
  first?: number;
  second?: number;
  third?: number;
  fourth?: number;
  fifth?: number;
  sixth?: number;
  seventh?: number;
  eighth?: number;
  ninth?: number;
  tenth: number;
  eleventh: number;
  twelfth: number;
}

interface Data {
  expenses_operational: DataProps[];
  expenses_non_operational: DataProps[];
  expenses_about_recipe: DataProps[];
  revenues_operational: DataProps[];
  revenues_non_operational: DataProps[];
}

export function DashboardDRE() {
  const { user } = useAuth();
  const [accountPlansCompany, setAccountPlansCompany] = useState<AccountPlan[]>(
    []
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState<Selected>({
    account_plan_id: searchParams.get("account_plan_id") ?? "",
    period: searchParams.get("period") ?? "Trimestral",
    type:
      (searchParams.get("type") as "cashier" | "competence") ?? "competence",
    last_month:
      searchParams.get("last_month") ?? new Date().getMonth() === 0
        ? `${new Date().getFullYear() - 1}-12`
        : `${new Date().getFullYear()}-${String(new Date().getMonth()).padStart(
            2,
            "0"
          )}`,
  });
  const [data, setData] = useState<Data>({
    expenses_operational: [],
    expenses_non_operational: [],
    expenses_about_recipe: [],
    revenues_operational: [],
    revenues_non_operational: [],
  });

  const initialClassificationProps = {
    tenth: 0,
    eleventh: 0,
    twelfth: 0,
    eighth: 0,
    first: 0,
    fifth: 0,
    fourth: 0,
    ninth: 0,
    second: 0,
    seventh: 0,
    sixth: 0,
    third: 0,
  };

  const initialClassification: Classification = {
    expenses_operational: { ...initialClassificationProps },
    expenses_non_operational: { ...initialClassificationProps },
    revenues_operational: { ...initialClassificationProps },
    revenues_non_operational: { ...initialClassificationProps },
    expenses_about_recipe: { ...initialClassificationProps },
    operating_profit: { ...initialClassificationProps },
    gross_profit: { ...initialClassificationProps },
    final_result: { ...initialClassificationProps },
  };
  const [classification, setClassification] = useState<Classification>(
    initialClassification
  );
  const [months, setMonths] = useState({
    eleventh_date: "",
    tenth_date: "",
    twelfth_date: "",
  } as Month);

  useEffect(() => {
    if (
      selected.account_plan_id &&
      selected.period &&
      selected.last_month &&
      selected.type
    ) {
      api
        .get(
          `/dashboard/dre?account_plan_id=${selected.account_plan_id}&last_month=${selected.last_month}&period=${selected.period}&type=${selected.type}`
        )
        .then((response) => {
          setData(response.data.data);
          setClassification(response.data.classification);
          setMonths(response.data.months);
          setIsLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selected]);

  useEffect(() => {
    api
      .get(`/accountPlansCompanies/company/${user.company_id}`)
      .then((response) => {
        if (response.data) {
          setAccountPlansCompany(
            response.data.map((acc: any) => acc.account_plan)
          );
          if (!selected.account_plan_id) {
            setSelected((prev) => ({
              ...prev,
              account_plan_id:
                prev.account_plan_id === "" && response.data[0]
                  ? response.data[0].account_plan_id
                  : prev.account_plan_id,
            }));
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <InternalHeader title="Painel DRE" has_filter={true} defaultOpen={true}>
        <DREFilter
          accountPlansCompany={accountPlansCompany}
          selected={selected}
          setSearchParams={(s) => {
            setIsLoaded(false);
            setSearchParams(s);
          }}
          setSelected={(s) => {
            setIsLoaded(false);
            setSelected(s);
          }}
        />
      </InternalHeader>
      <DRECard
        revenues={
          classification.revenues_non_operational.twelfth +
          classification.revenues_operational.twelfth
        }
        expenses={
          classification.expenses_about_recipe.twelfth +
          classification.expenses_non_operational.twelfth +
          classification.expenses_operational.twelfth
        }
        total={classification.final_result.twelfth}
      />
      <SimpleGrid spacingX={4}>
        <Card
          border="#216ca5"
          body={
            <Flex flexDirection="column">
              <Text fontWeight={"bold"} textAlign={"center"}>
                DRE - Demonstrativo do Resultado do Exercício -{" "}
                {selected.period}
              </Text>
              <DRETable
                type={selected.type}
                isLoaded={isLoaded}
                classification={classification}
                data={data}
                months={months}
                period={selected.period}
                columns={[
                  {
                    title: "",
                  },
                  {
                    title: "",
                  },
                  ...(months.first_date
                    ? [{ title: formatMonthAndYear(months.first_date) }]
                    : []),
                  ...(months.second_date
                    ? [{ title: formatMonthAndYear(months.second_date) }]
                    : []),
                  ...(months.third_date
                    ? [{ title: formatMonthAndYear(months.third_date) }]
                    : []),
                  ...(months.fourth_date
                    ? [{ title: formatMonthAndYear(months.fourth_date) }]
                    : []),
                  ...(months.fifth_date
                    ? [{ title: formatMonthAndYear(months.fifth_date) }]
                    : []),
                  ...(selected.period === "Anual"
                    ? [{ title: formatMonthAndYear(months.sixth_date) }]
                    : []),
                  ...(months.seventh_date
                    ? [{ title: formatMonthAndYear(months.seventh_date) }]
                    : []),
                  ...(months.eighth_date
                    ? [{ title: formatMonthAndYear(months.eighth_date) }]
                    : []),
                  ...(selected.period !== "Trimestral" &&
                  selected.period !== "Mensal"
                    ? [{ title: formatMonthAndYear(months.ninth_date) }]
                    : []),
                  ...(months.tenth_date
                    ? [{ title: formatMonthAndYear(months.tenth_date) }]
                    : []),
                  ...(selected.period !== "Mensal"
                    ? [
                        {
                          title: formatMonthAndYear(months.eleventh_date),
                        },
                      ]
                    : []),
                  {
                    title: formatMonthAndYear(months.twelfth_date),
                  },
                ]}
              />
            </Flex>
          }
        />
      </SimpleGrid>
    </>
  );
}
