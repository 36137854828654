import { useEffect, useState } from "react";

import { Card } from "../../../../components/Card";
import { api } from "../../../../services/api";
import { AxiosError } from "axios";
import { ButtonListButton } from "../../../../components/organisms/ButtonListBox";
import { useQuery } from "../../../../hooks/query";
import { useNavigate } from "react-router-dom";
import { Table } from "../../../../components/templates/Table";
import { DeleteModal } from "../../../../components/templates/DeleteModal";
import { InternalHeader } from "../../../../components/templates/InternalHeader";
import { MerchandiseSubcategoryModal } from "../../../../components/templates/Modal/MerchandiseSubcategoryModal";
import { EditModalButton } from "../../../../components/molecules/TableListButton/EditModalButton";
import { AddButtonWithTextModal } from "../../../../components/molecules/AddButtonWithTextModal";

interface MerchandiseSubcategory {
  id: string;
  name: string;
  merchandise_category_id: string;
  merchandise_category: {
    name: string;
  };
}

interface MerchandiseCategory {
  id: string;
  name: string;
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListMerchandiseSubcategories() {
  const query = useQuery();
  const navigate = useNavigate();
  const [merchandiseSubcategories, setMerchandiseSubcategories] = useState<
    MerchandiseSubcategory[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState<"create" | "edit">("create");
  const [defaultValue, setDefaultValue] = useState<MerchandiseSubcategory>(
    {} as MerchandiseSubcategory
  );
  const [merchandiseCategories, setMerchandiseCategories] = useState<
    MerchandiseCategory[]
  >([]);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  useEffect(() => {
    api
      .get(
        `pricing/merchandiseSubcategories/pagination?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setMerchandiseSubcategories(response.data.merchandiseSubcategories);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate, defaultParams]);

  useEffect(() => {
    api
      .get(`pricing/merchandiseCategories`)
      .then((response) => setMerchandiseCategories(response.data));
  }, []);

  return (
    <>
      <InternalHeader
        title="Subcategorias da Mercadoria"
        has_back_button={true}
        has_filter={false}
      >
        <AddButtonWithTextModal
          onClick={() => {
            setIsOpen(true);
            setActionType("create");
            setDefaultValue({
              id: "",
              name: "",
              merchandise_category_id: "",
              merchandise_category: {
                name: "",
              },
            });
          }}
        />
      </InternalHeader>
      <MerchandiseSubcategoryModal
        setMerchandiseCategories={(value) => setMerchandiseCategories(value)}
        merchandiseCategories={merchandiseCategories}
        entity="Subcategorias da Mercadoria"
        isOpen={isOpen}
        setIsOpen={(value) => setIsOpen(value)}
        actionType={actionType}
        defaultValue={defaultValue}
        defaultLink="pricing/merchandiseSubcategories"
        setMerchandiseSubcategory={(merchandiseSubcategory) => {
          if (actionType === "create") {
            setMerchandiseSubcategories([
              ...merchandiseSubcategories,
              merchandiseSubcategory,
            ]);
          } else {
            setMerchandiseSubcategories(
              merchandiseSubcategories.map((c) => {
                if (c.id === merchandiseSubcategory.id) {
                  return merchandiseSubcategory;
                }
                return c;
              })
            );
          }
        }}
      />
      <Card
        body={
          <Table
            loading={loading}
            message="Buscando as subcategorias"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/precificacao/subcategoria-mercadoria?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={merchandiseSubcategories.map((merchandiseSubcategory) => {
              return {
                tr: [
                  {
                    name: merchandiseSubcategory.name,
                  },
                  {
                    name: merchandiseSubcategory.merchandise_category.name,
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditModalButton
                          onClick={() => {
                            setActionType("edit");
                            setIsOpen(true);
                            setDefaultValue(merchandiseSubcategory);
                          }}
                        />

                        <DeleteModal
                          onDelete={(id) => {
                            setMerchandiseSubcategories(
                              merchandiseSubcategories.filter(
                                (merchandiseSubcategory) =>
                                  merchandiseSubcategory.id !== id
                              )
                            );
                          }}
                          id={merchandiseSubcategory.id}
                          route="pricing/merchandiseSubcategories/"
                          error_message="Não foi possível remover a categoria da mercadoria"
                          success_message="Subcategoria da mercadoria removida com sucesso"
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "merchandise_categories",
                label: "Categoria da Mercadoria",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
