import { useCallback, useEffect, useMemo, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  Flex,
  SimpleGrid,
  Stack,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";

import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { useToast } from "../../../hooks/toast";
import { DragDropContext } from "react-beautiful-dnd";
import { ModalCreateAccountPlanSubcategory } from "../../../components/templates/Modal/ModalCreateAccountPlanSubcategory";
import { DraggableAccountPlan } from "../../../components/templates/DraggableAccountPlan";

const editAccountPlanFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  description: yup.string().notRequired(),
});

const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);

  const result = {} as any;
  result["source"] = sourceClone;
  result["dest"] = destClone;
  return result;
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Subcategory {
  id: string;
  name: string;
  accountPlanCategory: {
    id: string;
    name: string;
    type: string;
    subtype: string;
  };
}

interface OrderSubcategory {
  id: string;
  name: string;
  position: number;
  accountPlanCategory: {
    id: string;
    name: string;
    type: string;
    subtype: string;
  };
}

export function EditAccountPlan() {
  const navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [displayElements, setDisplayElements] = useState({
    expensesOperational: true,
    expensesNonOperational: true,
    recipesOperational: true,
    recipesNonOperational: true,
    expensesAboutRecipe: true,
  });

  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [selectedExpensesOperational, setSelectedExpensesOperational] =
    useState<Subcategory[]>([]);
  const [selectedExpensesNonOperational, setSelectedExpensesNonOperational] =
    useState<Subcategory[]>([]);
  const [selectedExpensesAboutRecipe, setSelectedExpensesAboutRecipe] =
    useState<Subcategory[]>([]);
  const [selectedRecipesOperational, setSelectedRecipesOperational] = useState<
    Subcategory[]
  >([]);
  const [selectedRecipesNonOperational, setSelectedRecipesNonOperational] =
    useState<Subcategory[]>([]);
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(editAccountPlanFormSchema),
  });

  const formatAccountPlanSubcategories = useMemo(() => {
    const helperSelectedRecipesOperational: string[] = [];
    const helperSelectedRecipesNonOperational: string[] = [];
    const helperSelectedExpensesOperational: string[] = [];
    const helperSelectedExpensesNonOperational: string[] = [];
    const helperSelectedExpensesAboutRecipe: string[] = [];

    selectedRecipesOperational.forEach((recipe) =>
      helperSelectedRecipesOperational.push(recipe.id)
    );
    selectedRecipesNonOperational.forEach((recipe) =>
      helperSelectedRecipesNonOperational.push(recipe.id)
    );
    selectedExpensesOperational.forEach((expense) =>
      helperSelectedExpensesOperational.push(expense.id)
    );
    selectedExpensesNonOperational.forEach((expense) =>
      helperSelectedExpensesNonOperational.push(expense.id)
    );
    selectedExpensesAboutRecipe.forEach((expense) =>
      helperSelectedExpensesAboutRecipe.push(expense.id)
    );

    const account_plan_subcategories_id =
      helperSelectedRecipesOperational.concat(
        helperSelectedRecipesNonOperational,
        helperSelectedExpensesOperational,
        helperSelectedExpensesNonOperational,
        helperSelectedExpensesAboutRecipe
      );
    return account_plan_subcategories_id;
  }, [
    selectedExpensesAboutRecipe,
    selectedExpensesNonOperational,
    selectedExpensesOperational,
    selectedRecipesNonOperational,
    selectedRecipesOperational,
  ]);

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/categories")
      .then((response) => {
        api.get(`/accountPlans/${id}`).then((responseAccoutPlan) => {
          var nonSelected: Subcategory[] = [];
          var helperSelectedExpensesOperational: OrderSubcategory[] = [];
          var helperSelectedExpensesNonOperational: OrderSubcategory[] = [];
          var helperSelectedExpensesAboutRecipe: OrderSubcategory[] = [];
          var helperSelectedRecipesOperational: OrderSubcategory[] = [];
          var helperSelectedRecipesNonOperational: OrderSubcategory[] = [];
          response.data.forEach((s: any) => {
            const findIndex =
              responseAccoutPlan.data.account_plan_subcategories_account_plan.findIndex(
                (r: any) => r.account_plan_subcategory.id === s.id
              );
            if (findIndex !== -1) {
              var account_plan_subcategory =
                responseAccoutPlan.data.account_plan_subcategories_account_plan[
                  findIndex
                ].account_plan_subcategory;
              var type = account_plan_subcategory.account_plan_category.type;
              var subtype =
                account_plan_subcategory.account_plan_category.subtype;
              var position =
                responseAccoutPlan.data.account_plan_subcategories_account_plan[
                  findIndex
                ].position;
              if (type === "Receita") {
                if (subtype === "Operacional") {
                  helperSelectedRecipesOperational.push({
                    id: account_plan_subcategory.id,
                    name: account_plan_subcategory.name,
                    position,
                    accountPlanCategory: {
                      id: account_plan_subcategory.account_plan_category.id,
                      name: account_plan_subcategory.account_plan_category.name,
                      type: account_plan_subcategory.account_plan_category.type,
                      subtype:
                        account_plan_subcategory.account_plan_category.subtype,
                    },
                  });
                } else if (subtype === "Não operacional") {
                  helperSelectedRecipesNonOperational.push({
                    id: account_plan_subcategory.id,
                    name: account_plan_subcategory.name,
                    position,

                    accountPlanCategory: {
                      id: account_plan_subcategory.account_plan_category.id,
                      name: account_plan_subcategory.account_plan_category.name,
                      type: account_plan_subcategory.account_plan_category.type,
                      subtype:
                        account_plan_subcategory.account_plan_category.subtype,
                    },
                  });
                }
              } else if (type === "Despesa") {
                if (subtype === "Operacional") {
                  helperSelectedExpensesOperational.push({
                    id: account_plan_subcategory.id,
                    name: account_plan_subcategory.name,
                    position,

                    accountPlanCategory: {
                      id: account_plan_subcategory.account_plan_category.id,
                      name: account_plan_subcategory.account_plan_category.name,
                      type: account_plan_subcategory.account_plan_category.type,
                      subtype:
                        account_plan_subcategory.account_plan_category.subtype,
                    },
                  });
                } else if (subtype === "Não operacional") {
                  helperSelectedExpensesNonOperational.push({
                    id: account_plan_subcategory.id,
                    name: account_plan_subcategory.name,
                    position,

                    accountPlanCategory: {
                      id: account_plan_subcategory.account_plan_category.id,
                      name: account_plan_subcategory.account_plan_category.name,
                      type: account_plan_subcategory.account_plan_category.type,
                      subtype:
                        account_plan_subcategory.account_plan_category.subtype,
                    },
                  });
                } else if (subtype === "Sobre receita") {
                  helperSelectedExpensesAboutRecipe.push({
                    id: account_plan_subcategory.id,
                    name: account_plan_subcategory.name,
                    position,
                    accountPlanCategory: {
                      id: account_plan_subcategory.account_plan_category.id,
                      name: account_plan_subcategory.account_plan_category.name,
                      type: account_plan_subcategory.account_plan_category.type,
                      subtype:
                        account_plan_subcategory.account_plan_category.subtype,
                    },
                  });
                }
              }
            } else {
              nonSelected.push({
                id: s.id,
                name: s.name,
                accountPlanCategory: {
                  id: s.account_plan_category.id,
                  name: s.account_plan_category.name,
                  type: s.account_plan_category.type,
                  subtype: s.account_plan_category.subtype,
                },
              });
            }
          });

          setSelectedRecipesOperational(
            helperSelectedRecipesOperational.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
          setSelectedRecipesNonOperational(
            helperSelectedRecipesNonOperational.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
          setSelectedExpensesOperational(
            helperSelectedExpensesOperational.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
          setSelectedExpensesNonOperational(
            helperSelectedExpensesNonOperational.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
          setSelectedExpensesAboutRecipe(
            helperSelectedExpensesAboutRecipe.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
          setSubcategories(nonSelected);
          reset(responseAccoutPlan.data);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, reset]);

  useEffect(() => {
    setLoading(true);
  }, [id, reset]);

  const handleEditAccountPlan: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.put(`/accountPlans/subcategories/${id}`, {
          name: formValue.name,
          description: formValue.description,
          account_plan_subcategories_id: formatAccountPlanSubcategories,
        });
        navigate("/planos-de-conta");
        toast({
          title: "Plano de conta editado com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível editar o plano de conta",
          status: "error",
        });
      }
    },
    [formatAccountPlanSubcategories, id, navigate, toast]
  );
  const selectedArray = useCallback(
    (type: string, subtype: string) => {
      if (type === "Receita") {
        if (subtype === "Operacional") {
          return selectedRecipesOperational;
        } else if (subtype === "Não operacional") {
          return selectedRecipesNonOperational;
        }
      } else if (type === "Despesa") {
        if (subtype === "Operacional") {
          return selectedExpensesOperational;
        } else if (subtype === "Não operacional") {
          return selectedExpensesNonOperational;
        } else if (subtype === "Sobre receita") {
          return selectedExpensesAboutRecipe;
        }
      }
      return subcategories;
    },
    [
      selectedExpensesAboutRecipe,
      selectedExpensesNonOperational,
      selectedExpensesOperational,
      selectedRecipesNonOperational,
      selectedRecipesOperational,
      subcategories,
    ]
  );

  const setSelectedArray = useCallback(
    (type: string, subtype: string, sub: Subcategory[]) => {
      if (type === "Receita") {
        if (subtype === "Operacional") {
          setSelectedRecipesOperational(sub);
        } else if (subtype === "Não operacional") {
          setSelectedRecipesNonOperational(sub);
        }
      } else if (type === "Despesa") {
        if (subtype === "Operacional") {
          setSelectedExpensesOperational(sub);
        } else if (subtype === "Não operacional") {
          setSelectedExpensesNonOperational(sub);
        } else if (subtype === "Sobre receita") {
          setSelectedExpensesAboutRecipe(sub);
        }
      }
    },
    []
  );

  const onDragEnd = useCallback(
    (event: any) => {
      const { source, destination, draggableId } = event;
      const [, type, subtype] = draggableId.split("/");

      if (!destination) {
        return;
      }

      const array = selectedArray(type, subtype);
      if (source.droppableId !== destination.droppableId) {
        if (source.droppableId !== "items") {
          const result = move(array, subcategories, source, destination);
          setSubcategories(result.dest);
          setSelectedArray(type, subtype, result.source);
        } else {
          const result = move(
            source.droppableId === "items" ? subcategories : array,
            destination.droppableId === "items" ? subcategories : array,
            source,
            destination
          );
          setSubcategories(result.source);
          setSelectedArray(type, subtype, result.dest);
        }
      } else if (source.droppableId === destination.droppableId) {
        const result = reorder(
          source.droppableId === "items" ? subcategories : array,
          source.index,
          destination.index
        );
        if (source.droppableId === "items") {
          setSubcategories(result);
        } else {
          setSelectedArray(type, subtype, result);
        }
      }
      setDisplayElements({
        expensesOperational: true,
        expensesNonOperational: true,
        recipesOperational: true,
        recipesNonOperational: true,
        expensesAboutRecipe: true,
      });
    },
    [selectedArray, subcategories, setSelectedArray, setDisplayElements]
  );

  const chooseDisplay = useCallback((type: string, subtype: string) => {
    if (type === "Receita") {
      if (subtype === "Operacional") {
        setDisplayElements({
          expensesAboutRecipe: false,
          expensesNonOperational: false,
          expensesOperational: false,
          recipesNonOperational: false,
          recipesOperational: true,
        });
      } else if (subtype === "Não operacional") {
        setDisplayElements({
          expensesAboutRecipe: false,
          expensesNonOperational: false,
          expensesOperational: false,
          recipesNonOperational: true,
          recipesOperational: false,
        });
      }
    } else if (type === "Despesa") {
      if (subtype === "Operacional") {
        setDisplayElements({
          expensesAboutRecipe: false,
          expensesNonOperational: false,
          expensesOperational: true,
          recipesNonOperational: false,
          recipesOperational: false,
        });
      } else if (subtype === "Não operacional") {
        setDisplayElements({
          expensesAboutRecipe: false,
          expensesNonOperational: true,
          expensesOperational: false,
          recipesNonOperational: false,
          recipesOperational: false,
        });
      } else if (subtype === "Sobre") {
        setDisplayElements({
          expensesAboutRecipe: true,
          expensesNonOperational: false,
          expensesOperational: false,
          recipesNonOperational: false,
          recipesOperational: false,
        });
      }
    } else {
      setDisplayElements({
        expensesAboutRecipe: true,
        expensesNonOperational: true,
        expensesOperational: true,
        recipesNonOperational: true,
        recipesOperational: true,
      });
    }
  }, []);

  const { errors } = formState;

  return (
    <>
      <ModalCreateAccountPlanSubcategory
        isOpen={isOpen}
        onClose={onClose}
        cb={(sub) => {
          setSubcategories([sub, ...subcategories]);
        }}
      />
      <Skeleton isLoaded={loading}>
        <Card
          border="#216ca5"
          title="Editar Plano de Conta"
          header={<BackButton onClick={() => navigate(-1)} />}
          body={
            <Flex
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={handleSubmit(handleEditAccountPlan)}
              id="add-form"
            >
              <Stack spacing="4">
                <Input
                  type="text"
                  label="Nome"
                  isRequired={true}
                  error={errors.name}
                  {...register("name")}
                />
                <Input
                  type="text"
                  label="Descrição"
                  isRequired={false}
                  error={errors.description}
                  {...register("description")}
                />
                <SimpleGrid>
                  <Flex overflowX="scroll">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <DraggableAccountPlan
                        setDisplay={(type: string, subtype: string) =>
                          chooseDisplay(type, subtype)
                        }
                        isCheck={false}
                        subcategories={subcategories}
                        droppableId="items"
                        title="Receitas e despesas"
                        has_created={true}
                        onOpen={onOpen}
                      />
                      <Flex flexDir="row" flex="1">
                        <DraggableAccountPlan
                          setDisplay={(type: string, subtype: string) =>
                            chooseDisplay(type, subtype)
                          }
                          isCheck={true}
                          subcategories={selectedRecipesOperational}
                          droppableId="recipes-operational"
                          title="Receita operacional"
                          has_block={!displayElements.recipesOperational}
                        />
                        <DraggableAccountPlan
                          setDisplay={(type: string, subtype: string) =>
                            chooseDisplay(type, subtype)
                          }
                          isCheck={true}
                          subcategories={selectedRecipesNonOperational}
                          droppableId="recipes-non-operational"
                          title="Receita não operacional"
                          has_block={!displayElements.recipesNonOperational}
                        />
                        <DraggableAccountPlan
                          setDisplay={(type: string, subtype: string) =>
                            chooseDisplay(type, subtype)
                          }
                          isCheck={true}
                          subcategories={selectedExpensesOperational}
                          droppableId="expenses-operational"
                          title="Despesa operacional"
                          has_block={!displayElements.expensesOperational}
                        />
                        <DraggableAccountPlan
                          setDisplay={(type: string, subtype: string) =>
                            chooseDisplay(type, subtype)
                          }
                          isCheck={true}
                          subcategories={selectedExpensesNonOperational}
                          droppableId="expenses-non-operational"
                          title="Despesa não operacional"
                          has_block={!displayElements.expensesNonOperational}
                        />

                        <DraggableAccountPlan
                          setDisplay={(type: string, subtype: string) =>
                            chooseDisplay(type, subtype)
                          }
                          isCheck={true}
                          subcategories={selectedExpensesAboutRecipe}
                          droppableId="about-recipe"
                          title="Despesa sobre receita"
                          has_block={!displayElements.expensesAboutRecipe}
                        />
                      </Flex>
                    </DragDropContext>
                  </Flex>
                </SimpleGrid>
              </Stack>
            </Flex>
          }
          footer={<SubmitButton formState={formState} text="Editar" />}
        />
      </Skeleton>
    </>
  );
}
