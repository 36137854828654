export function calcNpsWithOutAnswers(
    promoter: number,
    detractor: number,
    total: number
  ) {
    if (total === 0) {
      return 0;
    }
    const nps = (100 * (promoter - detractor)) / total;
  
    return Number(nps.toFixed(0));
  }

  export function  translateNps(nps: string){

    switch(nps){
      case "promoter":
        return "Promotores";
      case "detractor":
        return "Detratores";
      case "neutral":
        return "Neutros";
      default:
        return ""
    }
  }