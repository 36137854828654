import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Box, Flex } from "@chakra-ui/react";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/atoms/Button/BackButton";
import { useToast } from "../../../hooks/toast";
import { useAuth } from "../../../hooks/auth";
import { formatMoney } from "../../../utils/money";
import { MUITable } from "../../../components/Table/MUITable";
import { dateDatabaseToIndex } from "../../../utils/formatDate";
import { ExpensesForm } from "../../../components/templates/Forms/ExpensesForm";
import { config } from "../../../services/config";
import { SubmitButtonAndUpdateStatus } from "../../../components/atoms/Button/SubmitButtonAndUpdateStatus";

const createExpenseFormSchema = yup.object().shape({
  observation: yup.string().notRequired(),
  value: yup.string().required("Valor é obrigatório"),
  form_of_payment: yup.string().required("Forma de pagamento é obrigatória"),
  account_plan_subcategory_id: yup.object().required("Despesa é obrigatória"),
  due_date: yup.string().required("Data do vencimento é obrigatória"),
  favored_id: yup.string().required("O favorecido é obrigatória"),
  file: yup
    .mixed()
    .required("Required")
    .test("required", "O comprovante é obrigatória", (value: any) => {
      return value.length > 0;
    })
    .test("format", "Formato inválido", (value: any) => {
      const imageExtensions = /\.(jpg|jpeg|png|gif|pdf)$/i;
      return value.length > 0 && imageExtensions.test(value[0].name);
    })
    .test("size", "O arquivo é maior que o mínimo de 3MB", (value: any) => {
      const imageExtensions = /\.(pdf)$/i;
      if (value.length > 0 && imageExtensions.test(value[0].name)) {
        return value[0].size < threeMBInBytes;
      } else {
        return true;
      }
    }),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface IExpense {
  name: string;
  account_plan_subcategory_id: string;
  due_date: Date;
  value: string;
  form_of_payment: string;
  favored?: string;
}
interface Favored {
  id: string;
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}

const threeMBInBytes = 3 * 10 ** 6;

export function CreateExpense() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [reload, setReload] = useState(false);
  const [expenses, setExpenses] = useState<IExpense[]>([]);
  const [favored, setFavored] = useState<Favored[]>([]);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    getValues,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createExpenseFormSchema),
  });

  useEffect(() => {
    api.get(`/favored/company/${user.company_id}`).then((response) => {
      setFavored(response.data);
    });
  }, [user.company_id]);

  watch();

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Despesa")
      .then((response) => setAccountPlanSubcategories(response.data));
  }, []);

  const handleCreateExpense: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        if (
          formValue.file[0].name.endsWith(".pdf") &&
          formValue.file[0].size > threeMBInBytes
        ) {
          toast({
            title: "Não foi possível cadastrar a conta a pagar",
            description: "O arquivo PDF importado é maior que 3MB",
            status: "error",
          });
          return;
        }

        const data = new FormData();
        data.append("file", formValue.file[0]);
        data.append("observation", formValue.observation);
        data.append("due_date", formValue.due_date);
        data.append("date_of_competence", formValue.date_of_competence);
        data.append("favored_name", formValue.favored_name);
        data.append(
          "effective_write_off_date",
          formValue.effective_write_off_date
        );
        data.append("form_of_payment", formValue.form_of_payment);
        data.append(
          "account_plan_subcategory_id",
          formValue.account_plan_subcategory_id.value
        );
        data.append("value", formValue.value);
        data.append("company_id", user.company_id);
        await api.post("/expenses", data, config);
        if (!reload) {
          navigate("/contas-pagar");
        } else {
          reset();
          setValue("account_plan_subcategory_id", "");
          api.get(`/favored/company/${user.company_id}`).then((response) => {
            setFavored(response.data);
          });
          setExpenses([
            ...expenses,
            {
              name:
                accountPlanSubcategories.find(
                  (accountPlanSubcategory) =>
                    accountPlanSubcategory.id ===
                    formValue.account_plan_subcategory_id.value
                )?.name ?? "",
              due_date: formValue.due_date,
              form_of_payment: formValue.form_of_payment,
              account_plan_subcategory_id:
                formValue.account_plan_subcategory_id,
              value: formValue.value,
              favored: formValue.favored_name,
            },
          ]);
        }
        toast({
          title: "Conta a pagar cadastrada com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível cadastrar a conta a pagar",
          status: "error",
        });
      }
    },
    [
      accountPlanSubcategories,
      expenses,
      navigate,
      reload,
      reset,
      setValue,
      toast,
      user.company_id,
    ]
  );

  const { errors } = formState;
  return (
    <>
      <Card
        border="#216ca5"
        title="Cadastrar despesas"
        header={<BackButton onClick={() => navigate(-1)} />}
        body={
          <ExpensesForm
            register={register}
            accountPlanSubcategories={accountPlanSubcategories}
            handle={handleCreateExpense}
            control={control}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            setValue={setValue}
            favored={favored}
            setFavored={setFavored}
          />
        }
        footer={
          <Flex align="baseline">
            <Box mr={1}>
              <SubmitButtonAndUpdateStatus
                formState={formState}
                text="Cadastrar"
                bg="#ff9000"
                isLoading={formState.isSubmitting && reload}
                onClick={() => {
                  setReload(true);
                }}
              />
            </Box>
            <Box onClick={() => setReload(false)}>
              <SubmitButtonAndUpdateStatus
                formState={formState}
                text="Cadastrar e Finalizar"
                isLoading={formState.isSubmitting && !reload}
                onClick={() => {
                  setReload(false);
                }}
              />
            </Box>
          </Flex>
        }
      />
      {expenses.length > 0 && (
        <Box mt={2}>
          <MUITable
            title="Contas a pagar - Adicionadas"
            data={expenses.map((expense) => [
              expense.favored,
              expense.name,
              expense.form_of_payment,
              expense.due_date,
              expense.value,
            ])}
            columns={[
              {
                name: "Favorecido",
              },
              {
                name: "Despesa",
              },
              {
                name: "Forma de Pagamento",
              },
              {
                name: "Vencimento",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: string) => {
                    return dateDatabaseToIndex(value);
                  },
                },
              },
              {
                name: "Valor",
                options: {
                  filter: true,
                  sort: true,
                  customBodyRender: (value: number) => {
                    return formatMoney(value);
                  },
                },
              },
            ]}
          />
        </Box>
      )}
    </>
  );
}
