import { useEffect, useState } from "react";
import { Card } from "../../../components/Card";
import { DualAxesChart } from "../../../components/templates/Chart/DualAxes";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { Button, Flex } from "@chakra-ui/react";
import { DateRange } from "../../../components/templates/Filters/DateRangePicker";
import {
  dateFromDay,
  formatDatabaseWithOutHours,
  formatDateBrIndexWithOutHours,
  getStartAndEndDateOfWeek,
} from "../../../utils/formatDate";
import { useQuery } from "../../../hooks/query";
import { daysBetweenDates } from "../../../utils/compareDate";

interface IColumn {
  time: string;
  type: "Insumos" | "Receitas";
  value: number;
}

interface ILine {
  time: string;
  type: "Meta CMV" | "CMV Mensal";
  cogs: number;
}

interface Data {
  column: IColumn[];
  line: ILine[];
}

const ninetyDaysAgo = new Date();
ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

export function CogsDashboard() {
  const query = useQuery();
  const [data, setData] = useState<Data>({} as Data);
  const [selected, setSelected] = useState<{
    date_end: string;
    date_start: string;
    interval: string;
  }>({
    interval: "weekly",
    date_end: query.get("date_end") ?? formatDatabaseWithOutHours(new Date()),
    date_start:
      query.get("date_start") ?? formatDatabaseWithOutHours(ninetyDaysAgo),
  });
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get(
        `/dashboard/evolution/cogs?interval=${selected.interval}&date_start=${selected.date_start}&date_end=${selected.date_end}`
      )
      .then((response) => {
        if (selected.interval === "daily") {
          const lines = response.data.line.map((d: any) => ({
            ...d,
            time: formatDateBrIndexWithOutHours(
              dateFromDay(
                Number(d.time.split("/")[1]),
                Number(d.time.split("/")[0])
              )
            ),
          }));
          const columns = response.data.column.map((d: any) => ({
            ...d,
            time: formatDateBrIndexWithOutHours(
              dateFromDay(
                Number(d.time.split("/")[1]),
                Number(d.time.split("/")[0])
              )
            ),
          }));

          setData({ line: lines, column: columns });
        } else if (selected.interval === "weekly") {
          const lines = response.data.line.map((d: any) => {
            let { endDate, startDate } = getStartAndEndDateOfWeek(
              Number(d.time.split("/")[1]),
              Number(d.time.split("/")[0])
            );
            return {
              ...d,
              time: `De: ${startDate} a ${endDate}`,
            };
          });

          const columns = response.data.column.map((d: any) => {
            let { endDate, startDate } = getStartAndEndDateOfWeek(
              Number(d.time.split("/")[1]),
              Number(d.time.split("/")[0])
            );
            return {
              ...d,
              time: `De: ${startDate} a ${endDate}`,
            };
          });

          setData({ line: lines, column: columns });
        } else {
          setData(response.data);
        }
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [navigate, selected.interval, selected.date_end, selected.date_start]);

  return (
    <>
      <InternalHeader
        title="Evolução do CMV"
        has_back_button={true}
        has_filter={false}
      />

      <Card
        body={
          <>
            <Flex mb="4" flexWrap={"wrap"} justifyContent="space-between">
              <DateRange
                showClearDates={false}
                label="Data de Competência"
                dateEnd={selected.date_end}
                dateStart={selected.date_start}
                onSelectDate={(start, end) => {
                  const dateStart = new Date(start);
                  const dateEnd = new Date(end);
                  let interval = "weekly";
                  if (daysBetweenDates(dateStart, dateEnd) <= 35) {
                    interval = "daily";
                  } else if (daysBetweenDates(dateStart, dateEnd) <= 95) {
                    interval = "weekly";
                  } else {
                    interval = "monthly";
                  }
                  setSelected({
                    interval,
                    date_start: start,
                    date_end: end,
                  });
                  navigate(
                    `/evolucao/cmv?interval=${interval}&date_start=${start}&date_end=${end}`
                  );
                }}
              />
              <Flex alignItems={"flex-end"}>
                <Button
                  variant="ghost"
                  isActive={selected.interval === "daily"}
                  onClick={() => {
                    setSelected({
                      ...selected,

                      interval: "daily",
                    });
                  }}
                >
                  Diário
                </Button>
                <Button
                  variant="ghost"
                  isActive={selected.interval === "weekly"}
                  onClick={() => {
                    setSelected({
                      ...selected,

                      interval: "weekly",
                    });
                  }}
                >
                  Semanal
                </Button>
                <Button
                  variant="ghost"
                  isActive={selected.interval === "monthly"}
                  onClick={() => {
                    setSelected({
                      ...selected,

                      interval: "monthly",
                    });
                  }}
                >
                  Mensal
                </Button>
              </Flex>
            </Flex>
            <DualAxesChart column={data.column ?? []} line={data.line ?? []} />
          </>
        }
      />
    </>
  );
}
