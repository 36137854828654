import { ReactNode } from "react";
import { Stack } from "@chakra-ui/react";

interface StackSidebarSectionProps {
  children: ReactNode;
  unique?: boolean;
}

export function StackSidebarSection({
  children,
  unique = false,
}: StackSidebarSectionProps) {
  return (
    <Stack
      spacing="4"
      align="stretch"
      borderLeft={unique ? "none" : "1px solid #777"}
      ml={unique ? "0" : "3"}>
      {children}
    </Stack>
  );
}
