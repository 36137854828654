import { Button, Icon, Text } from "@chakra-ui/react";
import { shade } from "polished";
import { FaPlus } from "react-icons/fa";

interface AddButtonWithTextModalProps {
  onClick: () => void;
  loading?: boolean;
}

export function AddButtonWithTextModal({
  onClick,
  loading,
}: AddButtonWithTextModalProps) {
  return (
    <Button
      _hover={{
        backgroundColor: shade(0.2, "#216ca5"),
      }}
      _active={{
        backgroundColor: shade(0.2, "#216ca5"),
      }}
      mr="4px"
      color="white"
      onClick={onClick}
      bg="#216ca5"
      isLoading={loading}
    >
      <Icon as={FaPlus} mr={2} /> <Text>Adicionar</Text>
    </Button>
  );
}
