import {
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../../../services/api";
import { Input } from "../../../Form/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { useToast } from "../../../../hooks/toast";
import { MultiSelectControl } from "../../Form/MultiSelectControl";

interface Merchandise {
  id: string;
  name: string;
  merchandise_subcategory_id: string;
  measurement_unit: string;
  merchandise_subcategory: {
    name: string;
  };
}

interface Product {
  id: string;
  name: string;
  efficiency: number;
  merchandise_id: string;
  merchandise?: { name: string };
}

interface ProductModalProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  setProduct: (product: Product) => void;
  defaultLink: string;
  actionType: "edit" | "create";
  defaultValue?: Product;
  merchandises: Merchandise[];
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres")
    .typeError("Valor inválido"),
  merchandise_id: yup
    .string()
    .required("A mercadoria é obrigatória")
    .uuid("Formato inválido")
    .typeError("Valor inválido"),
  efficiency: yup
    .number()
    .required("O rendimento é obrigatório")
    .typeError("Valor inválido"),
});

export function ProductModal({
  isOpen,
  setIsOpen,
  defaultValue,
  actionType,
  defaultLink,
  setProduct,
  merchandises,
}: ProductModalProps) {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    setError,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (actionType === "edit" && defaultValue) {
      setValue("name", defaultValue.name);
      setValue("efficiency", defaultValue.efficiency);
      setValue("merchandise_id", defaultValue.merchandise_id);
      setValue("merchandise_name", defaultValue.merchandise?.name);
    } else {
      reset();
    }
  }, [actionType, defaultValue, setValue, isOpen, reset]);
  const handleCreateMerchandise: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .post(`/${defaultLink}`, {
          name: formValue.name,
          merchandise_id: formValue.merchandise_id,
          efficiency: formValue.efficiency,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `Produto de ficha técnica cadastrado com sucesso`,
          });
          var product = response.data;
          setProduct({
            id: product.id,
            name: product.name,
            merchandise_id: product.merchandise_id,
            efficiency: product.efficiency,
            merchandise:{
              name: formValue.merchandise_name,
            }
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "Name already exist"
          ) {
            toast({
              status: "error",
              title: `Esse nome de produto já existe`,
            });
            setError(
              "name",
              { message: "Esse nome já está sendo usado." },
              {
                shouldFocus: true,
              }
            );
          } else {
            toast({
              status: "error",
              title: `Não foi possível editar o produto de ficha técnica`,
            });
          }
        });
    },
    [defaultLink, toast, setProduct, setIsOpen, reset, setError]
  );

  const handleEditMerchandise: SubmitHandler<FieldValues> = useCallback(
    (formValue) => {
      api
        .put(`/${defaultLink}/${defaultValue?.id}`, {
          name: formValue.name,
          measurement_unit: formValue.measurement_unit,
          merchandise_subcategory_id: formValue.merchandise_subcategory_id,
          efficiency: formValue.efficiency,
        })
        .then((response) => {
          toast({
            status: "success",
            title: `Produto de ficha técnica editado com sucesso`,
          });
          var product = response.data;
          setProduct({
            id: product.id,
            name: product.name,
            merchandise_id: product.merchandise_id,
            efficiency: product.efficiency,
            merchandise: {
              name: formValue.merchandise_name,
            },
          });
          setIsOpen(false);
          reset();
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "Name already exist"
          ) {
            toast({
              status: "error",
              title: `Esse nome de produto já existe`,
            });
            setError(
              "name",
              { message: "Esse nome já está sendo usado." },
              {
                shouldFocus: true,
              }
            );
          } else {
            toast({
              status: "error",
              title: `Não foi possível editar o produto de ficha técnica`,
            });
          }
        });
    },
    [defaultLink, defaultValue?.id, toast, setProduct, setIsOpen, reset,setError]
  );
  watch();
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          reset();
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === "create" ? "Adicionar" : "Editar"} Produto de Ficha
            Técnica
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid
              spacingX={2}
              columns={{ base: 1 }}
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={
                actionType === "edit"
                  ? handleSubmit(handleEditMerchandise)
                  : handleSubmit(handleCreateMerchandise)
              }
              id="add-form"
            >
              <MultiSelectControl
                options={merchandises.map((merchandise) => ({
                  label: merchandise.name,
                  value: merchandise.id,
                }))}
                {...register("merchandise_id")}
                onChange={(selected) => {
                  setValue("merchandise_id", selected.value, {
                    shouldValidate: true,
                  });

                  setValue("merchandise_name", selected.label, {
                    shouldValidate: true,
                  });
                }}
                value={
                  getValues("merchandise_id")
                    ? {
                        label: getValues("merchandise_name"),
                        value: getValues("merchandise_id"),
                      }
                    : ""
                }
                validaded={
                  getValues("merchandise_id") && !errors.merchandise_id
                }
                isMulti={false}
                label="Mercadoria"
                error={errors.merchandise_id}
                isRequired={true}
              />
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                {...register("name")}
                error={errors.name}
                validaded={getValues("name") && !errors.name}
              />
              <Input
                type="number"
                label="Rendimento"
                step="0.01"
                isRequired={true}
                {...register("efficiency")}
                error={errors.efficiency}
                validaded={getValues("efficiency") && !errors.efficiency}
              />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() => {
                setIsOpen(false);
                reset();
              }}
            >
              Cancelar
            </Button>
            <SubmitButton
              mt={"0"}
              text={actionType === "edit" ? "Editar" : "Cadastrar"}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
