import React, { createContext, useCallback, useContext, useState } from "react";
import { api } from "../services/api";
interface Company {
  name: string;
  created_at: Date;
  cogs_target: number;
  id: string;
  falae_id?: string;
}
interface User {
  id: string;
  avatar_url: string;
  name: string;
  email: string;
  is_admin: number;
  company_id: string;
  company: Company;
}
interface SignInCredentials {
  email: string;
  password: string;
}

interface AccountPlan {
  id: string;
  name: string;
  description: string;
}

interface AuthContextData {
  user: User;
  account_plans: AccountPlan[];
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  updateCogs(cogs_target: number): void;
  updateCompany(company: Company): void;
}

interface AuthState {
  token: string;
  user: User;
  account_plans: AccountPlan[];
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: any): JSX.Element => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@guima:token");
    const user = localStorage.getItem("@guima:user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.headers.company_id = String(JSON.parse(user).company_id);
      api.defaults.headers.franchise_id = String(
        JSON.parse(user).company.franchise_id
      );
      return { token, user: JSON.parse(user), account_plans: [] };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    const response = await api.post("sessions", {
      email,
      password,
    });

    const { token, user, refresh_token } = response.data;

    localStorage.setItem("@guima:token", token);
    localStorage.setItem("@guima:refresh_token", refresh_token);
    localStorage.setItem("@guima:user", JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.headers.company_id = String(user.company_id);
    api.defaults.headers.franchise_id = user.company.franchise_id;
    setData({ token, user, account_plans: [] });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@guima:token");
    localStorage.removeItem("@guima:user");
    localStorage.removeItem("@guima:refresh_token");

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem("@guima:user", JSON.stringify(user));
      api.defaults.headers.company_id = user.company_id;

      setData({
        token: data.token,
        account_plans: data.account_plans,
        user,
      });
    },
    [setData, data.token, data.account_plans]
  );

  const updateCogs = useCallback(
    (cogs_target: number) => {
      const local = localStorage.getItem("@guima:user");

      if (local) {
        const user = JSON.parse(local) as User;
        user.company.cogs_target = cogs_target;
        localStorage.removeItem("@guima:user");
        localStorage.setItem("@guima:user", JSON.stringify(user));
        api.defaults.headers.company_id = user.company_id;
        setData((prev) => ({ ...prev, user }));
      }
    },
    [setData]
  );

  const updateCompany = useCallback(
    (company: Company) => {
      setData((prev) => ({
        ...prev,
        company,
        user: {
          ...prev.user,
          company_id: company.id,
          company: company,
        },
      }));
    },
    [setData]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        account_plans: data.account_plans,
        signIn,
        signOut,
        updateUser,
        updateCogs,
        updateCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
