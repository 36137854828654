import React from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../hooks/auth";

type RouteProps = {
  element: JSX.Element;
} & ReactDOMRouteProps;

export function PrivateRoute({ element, ...rest }: RouteProps) {
  const { user } = useAuth();
  const location = useLocation();

  if (user) {
    return element;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}
