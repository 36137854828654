import {
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../../../services/api";
import { Input } from "../../../../components/Form/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../atoms/Button/SubmitButton";
import { useToast } from "../../../../hooks/toast";
import { CreateSelectControl } from "../../Form/CreateSelectControl";
interface MerchandiseSubcategory {
  id: string;
  name: string;
  merchandise_category_id: string;
  merchandise_category: {
    name: string;
  };
}

interface MerchandiseCategory {
  id: string;
  name: string;
}
interface MerchandiseSubcategoryModalProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  setMerchandiseSubcategory: (
    merchandiseSubcategory: MerchandiseSubcategory
  ) => void;
  merchandiseCategories: MerchandiseCategory[];
  setMerchandiseCategories: Dispatch<SetStateAction<MerchandiseCategory[]>>;
  defaultLink: string;
  actionType: "edit" | "create";
  defaultValue?: {
    name: string;
    merchandise_category_id: string;
    id: string;
    merchandise_category: {
      name: string;
    };
  };
  entity: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  merchandise_category_name: yup
    .string()
    .required("Categoria da mercadoria é obrigatória")
    .min(3, "A categoria da mercadoria  deve conter no mínimo 3 caracteres"),
});

export function MerchandiseSubcategoryModal({
  isOpen,
  setIsOpen,
  setMerchandiseSubcategory,
  defaultValue,
  actionType,
  defaultLink,
  entity,
  merchandiseCategories,
  setMerchandiseCategories,
}: MerchandiseSubcategoryModalProps) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (actionType === "edit" && defaultValue) {
      setValue("name", defaultValue.name);
      setValue(
        "merchandise_category_name",
        defaultValue.merchandise_category.name
      );
    } else {
      reset();
    }
  }, [actionType, defaultValue, setValue, isOpen, reset]);
  const handleCreateMerchandiseSubcategory: SubmitHandler<FieldValues> =
    useCallback(
      (formValue) => {
        api
          .post(`/${defaultLink}`, {
            name: formValue.name,
            merchandise_category_name: formValue.merchandise_category_name,
          })
          .then((response) => {
            toast({
              status: "success",
              title: `${entity} cadastrada com sucesso`,
            });
            var subcategory = response.data;
            setMerchandiseSubcategory({
              id: subcategory.id,
              name: subcategory.name,
              merchandise_category_id: subcategory.merchandise_category_id,
              merchandise_category: {
                name: formValue.merchandise_category_name,
              },
            });
            setIsOpen(false);
            reset();
          })
          .catch((err) => {
            toast({
              status: "error",
              title: `Não foi possível cadastrar a ${entity}`,
            });
          });
      },
      [defaultLink, toast, entity, setMerchandiseSubcategory, setIsOpen, reset]
    );

  const handleEditMerchandiseSubcategory: SubmitHandler<FieldValues> =
    useCallback(
      (formValue) => {
        api
          .put(`/${defaultLink}/${defaultValue?.id}`, {
            name: formValue.name,
            merchandise_category_name: formValue.merchandise_category_name,
          })
          .then((response) => {
            toast({
              status: "success",
              title: `${entity} editada com sucesso`,
            });
            var subcategory = response.data;
            setMerchandiseSubcategory({
              id: subcategory.id,
              name: subcategory.name,
              merchandise_category_id: subcategory.merchandise_category_id,
              merchandise_category: {
                name: formValue.merchandise_category_name,
              },
            });
            setIsOpen(false);
            reset();
          })
          .catch((err) => {
            toast({
              status: "error",
              title: `Não foi possível editar a ${entity}`,
            });
          });
      },
      [
        defaultLink,
        defaultValue?.id,
        toast,
        entity,
        setMerchandiseSubcategory,
        setIsOpen,
        reset,
      ]
    );

  watch();
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          reset();
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === "create" ? "Adicionar" : "Editar"} {entity}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid
              spacingX={2}
              columns={{ base: 1 }}
              as="form"
              flexDir="column"
              w="100%"
              onSubmit={
                actionType === "edit"
                  ? handleSubmit(handleEditMerchandiseSubcategory)
                  : handleSubmit(handleCreateMerchandiseSubcategory)
              }
              id="add-form"
            >
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                {...register("name")}
                error={errors.name}
                validaded={getValues("name") && !errors.name}
              />
              <CreateSelectControl
                onCreateOption={(value: string) => {
                  setValue("merchandise_category_name", value, {
                    shouldValidate: true,
                  });
                  setMerchandiseCategories((prev) => [
                    ...prev,
                    {
                      id: value.toLowerCase(),
                      name: value,
                    },
                  ]);
                }}
                onChange={(select: any) => {
                  const selected = merchandiseCategories.find(
                    (category) => category.name === select.value
                  );
                  console.log({ select, selected });
                  if (selected) {
                    setValue("merchandise_category_name", selected.name);
                  }
                }}
                options={merchandiseCategories.map((merchandiseCategory) => ({
                  label: merchandiseCategory.name,
                  value: merchandiseCategory.name,
                }))}
                name="merchandise_category_name"
                label="Categoria da Mercadoria"
                isRequired
                value={
                  getValues("merchandise_category_name")
                    ? {
                        label: getValues("merchandise_category_name"),
                        value: getValues("merchandise_category_name"),
                      }
                    : ""
                }
                error={errors.merchandise_category_name}
                validate={
                  getValues("merchandise_category_name") &&
                  !errors.merchandise_category_name
                }
              />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() => {
                setIsOpen(false);
                reset();
              }}
            >
              Cancelar
            </Button>
            <SubmitButton
              mt={"0"}
              text={actionType === "edit" ? "Editar" : "Cadastrar"}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
