import { Flex, Icon, Text } from "@chakra-ui/react";
import { FaCheckDouble, FaTimes } from "react-icons/fa";
import Select from "react-select";

interface MultiSelectProps {
  options: {
    label: string;
    value: any;
  }[];
  name: string;
  validaded?: boolean;
  error?: {
    message?: string;
  };
  onChange?: (select: any) => void;
  onFocus?: (select: any) => void;
  initialSelected?: any;
  reset?: boolean;
  setReset?: (value: boolean) => void;
  value: {
    label: string;
    value: any;
  }[];
  isMulti?: boolean;
  isRequired?: boolean;
  zIndex?: number;
}

export function MultiSelect({
  options,
  validaded,
  error,
  initialSelected = [],
  onChange,
  value,
  isMulti = true,
  isRequired = false,
  zIndex = 10,
  onFocus,
  ...rest
}: MultiSelectProps) {
  return (
    <Flex flexDirection={"column"}>
      <Flex
        borderRadius={"8px"}
        bg="white"
        border={validaded && !error ? "2px" : "1px"}
        borderColor={
          validaded && !error
            ? "green.450"
            : error
            ? "red.450"
            : document.getElementById(`react-select-${rest.name}`) ===
              document.activeElement
            ? "blue.350"
            : "gray.400"
        }
        color="black"
        p="0"
      >
        <Select
          onFocus={onFocus}
          required={isRequired}
          options={options}
          isMulti={isMulti}
          id={`react-select-${rest.name}`}
          isClearable={isMulti}
          isSearchable
          value={value}
          closeMenuOnSelect={!isMulti}
          onChange={onChange}
          {...rest}
          placeholder={"Selecione uma opção..."}
          noOptionsMessage={() => <Text my="2">Nenhuma opções</Text>}
          styles={{
            container: (base, state) => ({
              ...base,
              backgroundColor: "transparent",
              width: "100%",
              border: "none",
              ":focus": {
                outline: "none",
                border: "none",
              },
              ":active": {
                outline: "none",
                border: "none",
              },
              zIndex,
            }),
            control: (base, props) => ({
              ...base,
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              ":focus": {
                outline: "none",
                border: "none",
                boxShadow: "none",
              },
              ":active": {
                outline: "none",
                border: "none",
                boxShadow: "none",
              },
            }),
          }}
        />
        {validaded && !error && (
          <Icon my="auto" mr="2" color="green" as={FaCheckDouble} />
        )}
        {error && <Icon my="auto" mr="2" color="red" as={FaTimes} />}
      </Flex>
      {error && (
        <Text mt="1" ml="1" color="red.450">
          {error.message}
        </Text>
      )}
    </Flex>
  );
}
