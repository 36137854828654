import { useCallback, useEffect, useState } from "react";
import { ButtonListButton } from "../../../components/organisms/ButtonListBox";
import { api } from "../../../services/api";

import { Card } from "../../../components/Card";
import { AddButton } from "../../../components/atoms/Button/AddButton";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { EditButton } from "../../../components/molecules/EditButton";
import { DeleteModal } from "../../../components/templates/DeleteModal";
import { PowerButtonList } from "../../../components/organisms/PowerButtonList";
import { Table } from "../../../components/templates/Table";
import { useQuery } from "../../../hooks/query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "../../../utils/checkPermission";

interface Company {
  id: string;
  name: string;
  city: string;
  state: string;
  active: boolean;
}
interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}
export function ListCompanies() {
  const query = useQuery();

  const { user } = useAuth();
  const toast = useToast();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });
  const navigate = useNavigate();

  const handleUpdateStatus = useCallback(
    (id: string) => {
      api.patch(`/companies/status/${id}`).then((response) => {
        setCompanies(
          companies.map((company) => {
            if (company.id === id) {
              company.active = !company.active;
            }
            return company;
          })
        );
        toast({
          title: "Empresa editada com sucesso!",
          status: "success",
        });
      });
    },
    [companies, toast]
  );
  useEffect(() => {
    api
      .get(
        `/companies/pagination?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setCompanies(response.data.companies);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });
  }, [
    defaultParams.limit_per_page,
    defaultParams.order_column,
    defaultParams.order_type,
    defaultParams.page,
    defaultParams.search,
    navigate,
  ]);
  return (
    <>
      <Card
        border="#216ca5"
        title="Empresas"
        header={
          user.is_admin <= 1 ? <AddButton link="/empresa/cadastrar" /> : <></>
        }
        body={
          <Table
            loading={loading}
            message="Buscando as empresas"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/empresas?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={companies.map((company) => {
              return {
                tr: [
                  {
                    name: company.name,
                  },
                  {
                    name: company.city,
                  },
                  {
                    name: company.state,
                  },
                  {
                    name: company.active ? "Ativa" : "Inativa",
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <PowerButtonList
                          link="/empresas"
                          onClick={async () => {
                            handleUpdateStatus(company.id);
                          }}
                          validCorrectButton={company.active}
                        />
                        <EditButton link={`/empresa/editar/${company.id}`} />
                        {checkPermission({
                          is_admin: user.is_admin,
                          type: "Super Admin",
                        }) ? (
                          <DeleteModal
                            onDelete={(id) => {
                              setCompanies(
                                companies.filter((company) => company.id !== id)
                              );
                            }}
                            id={company.id}
                            route="companies/"
                            error_message="Não foi possível remover a empresa"
                            success_message="Empresa removida com sucesso"
                          />
                        ) : (
                          <></>
                        )}
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "city",
                label: "Cidade",
                options: {
                  sort: true,
                },
              },
              {
                name: "state",
                label: "Estado",
                options: {
                  sort: true,
                },
              },
              {
                name: "active",
                label: "Status",
                options: {
                  sort: false,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
