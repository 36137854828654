import React, { useCallback, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Icon,
  useDisclosure,
  Heading,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { BsExclamationCircle } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { api } from "../../../services/api";
import { AxiosError } from "axios";
import { useToast } from "../../../hooks/toast";

interface DeleteModalProps {
  id: string;
  route: string;
  success_message: string;
  error_message: string;
  error_descrption?: string;
  onDelete: (id: string) => void;
}

export function DeleteModal({
  route,
  id,
  error_message,
  success_message,
  error_descrption = "",
  onDelete,
}: DeleteModalProps) {
  const toast = useToast();
  const cancelRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleDelete = useCallback(async () => {
    api
      .delete(route + id)
      .then((response) => {
        onDelete!(id);
        toast({ title: success_message, status: "success" });
        onClose();
      })
      .catch((err: AxiosError) => {
        toast({
          title: error_message,
          description: error_descrption,
          status: "error",
        });
        onClose();
      });
  }, [
    route,
    id,
    onDelete,
    toast,
    success_message,
    onClose,
    error_message,
    error_descrption,
  ]);

  return (
    <>
      <Tooltip hasArrow label="Apagar">
        <Button onClick={onOpen} colorScheme="red">
          <FaTrash />
        </Button>
      </Tooltip>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxW={{ base: "340px", md: "440px" }}>
          <AlertDialogHeader
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <Icon
              as={BsExclamationCircle}
              fontSize="70"
              color="red.200"
              mb="10px"
            />
            <Heading textAlign="center">
              Deseja mesmo confirmar essa ação?
            </Heading>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text textAlign="center">Essa ação será irreversível</Text>
          </AlertDialogBody>
          <AlertDialogFooter display="flex" justifyContent="center">
            <Button
              colorScheme="red"
              h="46px"
              w="100px"
              fontSize="1.0625em"
              onClick={onClose}
            >
              Não
            </Button>
            <Button
              colorScheme="green"
              fontSize="1.0625em"
              h="46px"
              w="100px"
              ml={3}
              onClick={handleDelete}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
