import React from "react";

import { Routes as ReactRoutes, Route } from "react-router-dom";
import { ListAccountPlanCategories } from "../pages/Admin/AccountPlanCategories";
import { CreateAccountPlanCategory } from "../pages/Admin/AccountPlanCategories/create";
import { EditAccountPlanCategory } from "../pages/Admin/AccountPlanCategories/edit";
import { ListAccountPlans } from "../pages/Admin/AccountPlans";
import { CreateAccountPlan } from "../pages/Admin/AccountPlans/create";
import { EditAccountPlan } from "../pages/Admin/AccountPlans/edit";
import { CreateAccountPlansCompany } from "../pages/Admin/AccountPlansCompanies/create";
import { ListAccountPlanSubcategories } from "../pages/Admin/AccountPlanSubcategories";
import { CreateAccountPlanSubcategory } from "../pages/Admin/AccountPlanSubcategories/create";
import { EditAccountPlanSubcategory } from "../pages/Admin/AccountPlanSubcategories/edit";
import { ListCategories } from "../pages/Admin/Categories";
import { CreateCategory } from "../pages/Admin/Categories/create";
import { EditCategory } from "../pages/Admin/Categories/edit";
import { ListCompanies } from "../pages/Admin/Companies";
import { CreateCompany } from "../pages/Admin/Companies/create";
import { EditCompany } from "../pages/Admin/Companies/edit";
import { Error } from "../pages/Admin/Errors/error";
import { DashboardDRE } from "../pages/Admin/DRE";
import { ListExpenses } from "../pages/Admin/Expenses";
import { CreateExpense } from "../pages/Admin/Expenses/create";
import { EditExpense } from "../pages/Admin/Expenses/edit";
import { ListFranchises } from "../pages/Admin/Franchises/ index";
import { CreateFranchise } from "../pages/Admin/Franchises/create";
import { EditFranchise } from "../pages/Admin/Franchises/edit";
import { Home } from "../pages/Admin/Home";
import { ListRevenues } from "../pages/Admin/Revenues";
import { CreateRevenue } from "../pages/Admin/Revenues/create";
import { EditRevenue } from "../pages/Admin/Revenues/edit";
import { ListUsers } from "../pages/Admin/Users";
import { CreateUser } from "../pages/Admin/Users/create";
import { EditUser } from "../pages/Admin/Users/edit";
import { EditUserCompany } from "../pages/Admin/Users/editCompany";
import { Login } from "../pages/Auth/Login";
import { Admin } from "../templates/Admin";
import { AdminRoute } from "./AdminRoute";
import { PrivateRoute } from "./PrivateRoute";
import { EditPaymentLiquidity } from "../pages/Admin/PaymentsLiquidity/edit";
import { ConfigGeneralAdminPage } from "../pages/Admin/Config/configGeneral";
import { Prepayment } from "../pages/Admin/Revenues/prepayment";
import { Conciliations } from "../pages/Admin/Conciliations";
import { ListFixedExpenses } from "../pages/Admin/FixedExpenses";
import { CreateFixedExpense } from "../pages/Admin/FixedExpenses/create";
import { UpdateFixedExpense } from "../pages/Admin/FixedExpenses/edit";
import { CogsTargetCompany } from "../pages/Admin/Companies/cogsTarget";
import { CogsDashboard } from "../pages/Admin/Dashboards/CogsDashboard";
import { EditUserPassword } from "../pages/Admin/Users/editPassword";
import { ManagerRoute } from "./ManagerRoute";
import { OwnerRoute } from "./OwnerRoute";
import { ListLogs } from "../pages/Admin/Logs";
import { CreateUserRegistrationPage } from "../pages/Admin/Users/createUserRegistration";
import { ShowUserTokenPage } from "../pages/Admin/Users/showToken";
import { UserRegistrationPage } from "../pages/Auth/Registration/user";
import { ConfigPricingAdminPage } from "../pages/Admin/Config/configPricing";
import { ConfigShoppingAdminPage } from "../pages/Admin/Config/configShopping";
import { ListSalesProductCategories } from "../pages/Admin/Pricing/SalesProductCategories";
import { ListMerchandiseCategories } from "../pages/Admin/Pricing/MerchandiseCategories";
import { ListMerchandiseSubcategories } from "../pages/Admin/Pricing/MerchandiseSubcategories";
import { ListMerchandises } from "../pages/Admin/Pricing/Merchandises";
import { ManagerClientExperience } from "../pages/Admin/ClientExperience";
import { ClientExperienceDashboard } from "../pages/Admin/Dashboards/ClientExperienceDashboard";
import { CreateCashClosing } from "../pages/Admin/Revenues/createCashClosing";
import { EditCashClosing } from "../pages/Admin/Revenues/editCashClosing";
import { ListProducts } from "../pages/Admin/Pricing/Products";
import { ListTechnicalSheet } from "../pages/Admin/Pricing/TechnicalSheets";
import { ListSaleProducts } from "../pages/Admin/Pricing/SaleProducts";
import { ListFavored } from "../pages/Admin/Favored";
import { CreateFavored } from "../pages/Admin/Favored/create";
import { EditFavored } from "../pages/Admin/Favored/edit";

const AdminRoutes = () => (
  <Admin>
    <ReactRoutes>
      <Route path="/" element={<PrivateRoute element={<Home />} />} />
      <Route
        path="/evolucao/cmv"
        element={<ManagerRoute element={<CogsDashboard />} />}
      />
      <Route
        path="/fechamento-caixa"
        element={<PrivateRoute element={<CreateCashClosing />} />}
      />
      <Route
        path="/fechamento-caixa/:date"
        element={<ManagerRoute element={<EditCashClosing />} />}
      />
      <Route
        path="/dre"
        element={<ManagerRoute element={<DashboardDRE />} />}
      />
      <Route
        path="/forma-de-pagamento"
        element={<OwnerRoute element={<EditPaymentLiquidity />} />}
      />
      <Route
        path="/antecipacao"
        element={<ManagerRoute element={<Prepayment />} />}
      />
      <Route
        path="/usuarios"
        element={<AdminRoute element={<ListUsers />} />}
      />
      <Route
        path="/usuario/registro"
        element={<AdminRoute element={<CreateUserRegistrationPage />} />}
      />
      <Route
        path="/usuario/link"
        element={<AdminRoute element={<ShowUserTokenPage />} />}
      />
      <Route
        path="/usuario/:id"
        element={<AdminRoute element={<EditUser />} />}
      />
      <Route
        path="/usuario/senha/:id"
        element={<PrivateRoute element={<EditUserPassword />} />}
      />
      <Route
        path="/usuario/cadastrar"
        element={<AdminRoute element={<CreateUser />} />}
      />
      <Route
        path="/usuario/empresa"
        element={<AdminRoute element={<EditUserCompany />} />}
      />
      <Route
        path="/redes"
        element={<PrivateRoute element={<ListFranchises />} />}
      />
      <Route
        path="/rede/:id"
        element={<AdminRoute element={<EditFranchise />} />}
      />
      <Route
        path="/rede/cadastrar"
        element={<AdminRoute element={<CreateFranchise />} />}
      />
      <Route
        path="/categorias"
        element={<AdminRoute element={<ListCategories />} />}
      />
      <Route
        path="/categoria/:id"
        element={<AdminRoute element={<EditCategory />} />}
      />
      <Route
        path="/categoria/cadastrar"
        element={<AdminRoute element={<CreateCategory />} />}
      />
      <Route
        path="/contas-receber"
        element={<PrivateRoute element={<ListRevenues />} />}
      />
      <Route
        path="/conta-receber/:id"
        element={<PrivateRoute element={<EditRevenue />} />}
      />
      <Route
        path="/conta-receber/cadastrar"
        element={<PrivateRoute element={<CreateRevenue />} />}
      />
      <Route
        path="/conciliacao"
        element={
          <ManagerRoute path="/conciliacao" element={<Conciliations />} />
        }
      />
      <Route
        path="/contas-pagar"
        element={<PrivateRoute element={<ListExpenses />} />}
      />
      <Route
        path="/contas-fixa-pagar"
        element={<PrivateRoute element={<ListFixedExpenses />} />}
      />
      <Route
        path="/conta-fixa-pagar/:id"
        element={<PrivateRoute element={<UpdateFixedExpense />} />}
      />
      <Route
        path="/conta-fixa-pagar/cadastrar"
        element={<PrivateRoute element={<CreateFixedExpense />} />}
      />
      <Route
        path="/conta-pagar/:id"
        element={<PrivateRoute element={<EditExpense />} />}
      />
      <Route
        path="/conta-pagar/cadastrar"
        element={<PrivateRoute element={<CreateExpense />} />}
      />
      <Route
        path="/planos-de-conta"
        element={<PrivateRoute element={<ListAccountPlans />} />}
      />
      <Route
        path="/plano-de-conta/:id"
        element={<PrivateRoute element={<EditAccountPlan />} />}
      />
      <Route
        path="/plano-de-conta/cadastrar"
        element={<PrivateRoute element={<CreateAccountPlan />} />}
      />
      <Route
        path="/contas"
        element={<PrivateRoute element={<ListAccountPlanCategories />} />}
      />
      <Route
        path="/conta/:id"
        element={<PrivateRoute element={<EditAccountPlanCategory />} />}
      />
      <Route
        path="/conta/cadastrar"
        element={<PrivateRoute element={<CreateAccountPlanCategory />} />}
      />
      <Route
        path="/receitas/despesas"
        element={<PrivateRoute element={<ListAccountPlanSubcategories />} />}
      />
      <Route
        path="/receita/despesa/:id"
        element={<PrivateRoute element={<EditAccountPlanSubcategory />} />}
      />
      <Route
        path="/receita/despesa/cadastrar"
        element={<PrivateRoute element={<CreateAccountPlanSubcategory />} />}
      />
      <Route
        path="/empresa/meta/cmv"
        element={<OwnerRoute element={<CogsTargetCompany />} />}
      />
      <Route
        path="/experiencia/gerenciar"
        element={<OwnerRoute element={<ManagerClientExperience />} />}
      />
      <Route
        path="/experiencia"
        element={<OwnerRoute element={<ClientExperienceDashboard />} />}
      />
      <Route
        path="/empresas"
        element={<AdminRoute element={<ListCompanies />} />}
      />
      <Route
        path="/empresa/cadastrar"
        element={<AdminRoute element={<CreateCompany />} />}
      />
      <Route
        path="/empresas/cadastrar/planos-de-conta/:id"
        element={<OwnerRoute element={<CreateAccountPlansCompany />} />}
      />
      <Route
        path="/empresa/editar/:id"
        element={<AdminRoute element={<EditCompany />} />}
      />
      <Route
        path="/error/:code"
        element={<PrivateRoute path="/error/:code" element={<Error />} />}
      />
      <Route
        path="/precificacao/categoria-produto-venda"
        element={<AdminRoute element={<ListSalesProductCategories />} />}
      />
      <Route
        path="/precificacao/categoria-mercadoria"
        element={<AdminRoute element={<ListMerchandiseCategories />} />}
      />
      <Route
        path="/precificacao/subcategoria-mercadoria"
        element={<AdminRoute element={<ListMerchandiseSubcategories />} />}
      />
      <Route
        path="/precificacao/mercadoria"
        element={<AdminRoute element={<ListMerchandises />} />}
      />
      <Route
        path="/precificacao/produtos"
        element={<AdminRoute element={<ListProducts />} />}
      />
      <Route
        path="/precificacao/ficha-tecnica"
        element={<AdminRoute element={<ListTechnicalSheet />} />}
      />
      <Route
        path="/precificacao/produto-venda"
        element={<AdminRoute element={<ListSaleProducts />} />}
      />
      <Route
        path="/favorecido"
        element={<PrivateRoute element={<ListFavored />} />}
      />
      <Route
        path="/favorecido/cadastrar"
        element={<PrivateRoute element={<CreateFavored />} />}
      />
      <Route
        path="/favorecido/editar/:id"
        element={<PrivateRoute element={<EditFavored />} />}
      />

      <Route
        path="/config"
        element={
          <AdminRoute path="/config" element={<ConfigGeneralAdminPage />} />
        }
      />
      <Route
        path="/config/precificacao"
        element={
          <AdminRoute
            path="/config/precificacao"
            element={<ConfigPricingAdminPage />}
          />
        }
      />
      <Route
        path="/config/compras"
        element={
          <AdminRoute
            path="/config/compras"
            element={<ConfigShoppingAdminPage />}
          />
        }
      />
      <Route
        path="/logs"
        element={<AdminRoute path="/logs" element={<ListLogs />} />}
      />
    </ReactRoutes>
  </Admin>
);
const Routes: React.FC = () => (
  <>
    <ReactRoutes>
      <Route path="*" element={<AdminRoutes />} />
      <Route path="/login" element={<Login />} />
      <Route path="/cadastro/:token" element={<UserRegistrationPage />} />
    </ReactRoutes>
  </>
);

export default Routes;
