import { useMemo } from "react";
import { Box } from "../SimpleBox";
import { FaAngry, FaRegFrown, FaGrin, FaRegSmile } from "react-icons/fa";

interface BoxProps {
  nps: number | string;
  title: string;
  link?: string;
}

export function NpsBox({ nps, title, link }: BoxProps) {
  const formatBox = useMemo(() => {
    if (nps <= 0) {
      return { bg: "#dc3545", icon: FaAngry, color: "#fff" };
    } else if (nps <= 50) {
      return { bg: "#f58634", icon: FaRegFrown, color: "#000" };
    } else if (nps <= 75) {
      return { bg: "#ffc107", icon: FaRegSmile, color: "#000" };
    } else {
      return { bg: "#28a745", icon: FaGrin, color: "#fff" };
    }
  }, [nps]);
  return (
    <Box
      value={nps}
      title={title}
      link={link}
      bg={formatBox.bg}
      color={formatBox.color}
      icon={formatBox.icon}
    />
  );
}
