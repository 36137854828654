import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../../components/Card";
import { AddButtonWithTextModal } from "../../../../components/molecules/AddButtonWithTextModal";
import { EditModalButton } from "../../../../components/molecules/TableListButton/EditModalButton";
import { ButtonListButton } from "../../../../components/organisms/ButtonListBox";
import { DeleteModal } from "../../../../components/templates/DeleteModal";
import { InternalHeader } from "../../../../components/templates/InternalHeader";
import { ProductsTechnicalSheetModal } from "../../../../components/templates/Modal/ProductsTechnicalSheetModal";
import { Table } from "../../../../components/templates/Table";
import { useAuth } from "../../../../hooks/auth";
import { useQuery } from "../../../../hooks/query";
import { api } from "../../../../services/api";
import { formatMoney } from "../../../../utils/money";

interface Product {
  id: string;
  name: string;
  efficiency: number;
  merchandise_id: string;
  merchandise?: { name: string };
  measurement_unit: string;
}

interface TechnicalSheet {
  id: string;
  name: string;
  products_technical_sheet?: any[];
  portion_quantity: number;
  company_id: string;
  cost_price?: number;
  unit_of_measurement: string;
}

interface Params {
  page: number;
  limit_per_page: number;
  search: string;
  order_column?: string;
  order_type?: string;
}

export function ListTechnicalSheet() {
  const { user } = useAuth();
  const query = useQuery();
  const navigate = useNavigate();
  const [technicalSheets, setTechnicalSheets] = useState<TechnicalSheet[]>([]);
  const [products, SetProducts] = useState<Product[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [defaultParams, setDefaultParams] = useState<Params>({
    limit_per_page: query.get("limit") ? Number(query.get("limit")) : 10,
    page: query.get("offset") ? Number(query.get("offset")) : 1,
    search: query.get("search") ?? "",
    order_column: query.get("order_column") ?? "",
    order_type: query.get("order_type") ?? "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState<"create" | "edit">("create");
  const [defaultValue, setDefaultValue] = useState<TechnicalSheet>(
    {} as TechnicalSheet
  );

  useEffect(() => {
    setLoading(false);
    api
      .get(
        `/pricing/technicalSheets/pagination/${user.company_id}?offset=${defaultParams.page}&limit=${defaultParams.limit_per_page}&search=${defaultParams.search}&order_column=${defaultParams.order_column}&order_type=${defaultParams.order_type}`
      )
      .then((response) => {
        setTechnicalSheets(response.data.technicalSheets);
        setTotal(response.data.total);
        setLoading(true);
      })
      .catch((err: AxiosError) => {
        navigate(`/error/${err.response?.status}`);
      });

    api
      .get(`/pricing/products`)
      .then((response) => {
        SetProducts(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [defaultParams, navigate, user]);

  return (
    <>
      <InternalHeader
        title="Fichas Técnicas"
        has_filter={false}
        has_back_button
      >
        <AddButtonWithTextModal
          onClick={() => {
            setIsOpen(true);
            setActionType("create");
            setDefaultValue({
              id: "",
              name: "",
              products_technical_sheet: [],
              portion_quantity: 0,
              company_id: "",
              cost_price: 0,
              unit_of_measurement: "",
            });
          }}
        />
      </InternalHeader>
      <ProductsTechnicalSheetModal
        actionType={actionType}
        defaultLink="pricing/productsTechnicalSheet"
        isOpen={isOpen}
        products={products}
        setIsOpen={setIsOpen}
        setTechnicalSheet={(technicalSheet) => {
          if (actionType === "create") {
            setTechnicalSheets([...technicalSheets, technicalSheet]);
          } else {
            setTechnicalSheets(
              technicalSheets.map((c) => {
                if (c.id === technicalSheet.id) {
                  return technicalSheet;
                }
                return c;
              })
            );
          }
        }}
        defaultValue={defaultValue}
      />
      <Card
        body={
          <Table
            loading={loading}
            message="Buscando as mercadorias"
            params={defaultParams}
            setDefaultParams={(params) => {
              setDefaultParams(params);
              navigate(
                `/precificacao/ficha-tecnica?offset=${params.page}&limit=${params.limit_per_page}&search=${params.search}&order_column=${params.order_column}&order_type=${params.order_type}`
              );
            }}
            total={total}
            data={technicalSheets.map((technicalSheet) => {
              return {
                tr: [
                  {
                    name: technicalSheet.name,
                  },
                  {
                    name: technicalSheet.portion_quantity,
                  },
                  {
                    name: technicalSheet.unit_of_measurement,
                  },
                  {
                    name: technicalSheet.cost_price
                      ? formatMoney(technicalSheet.cost_price)
                      : "-",
                  },
                  {
                    name: (
                      <ButtonListButton>
                        <EditModalButton
                          onClick={() => {
                            setActionType("edit");
                            setIsOpen(true);
                            setDefaultValue(technicalSheet);
                          }}
                        />
                        <DeleteModal
                          onDelete={(id) => {
                            setTechnicalSheets(
                              technicalSheets.filter(
                                (technicalSheet) => technicalSheet.id !== id
                              )
                            );
                          }}
                          id={technicalSheet.id}
                          route="pricing/technicalSheets/"
                          error_message="Não foi possível remover a ficha técnica"
                          success_message="A ficha técnica removida com sucesso"
                        />
                      </ButtonListButton>
                    ),
                  },
                ],
              };
            })}
            columns={[
              {
                name: "technical_sheets.name",
                label: "Nome",
                options: {
                  sort: true,
                },
              },
              {
                name: "technical_sheets.portion_quantity",
                label: "Porção",
                options: {
                  sort: true,
                },
              },
              {
                name: "technical_sheets.unit_of_measurement",
                label: "Unidade de Medida",
                options: {
                  sort: true,
                },
              },
              {
                name: "technical_sheets.cost_price",
                label: "Preço Cadastrado",
                options: {
                  sort: true,
                },
              },
              {
                name: "action",
                label: "Ações",
                options: {
                  sort: false,
                },
              },
            ]}
          />
        }
      />
    </>
  );
}
