import { SimpleGrid } from "@chakra-ui/react";
import { Box } from "../../Widget/Box";
import { FaChartLine, FaClipboardList, FaDollarSign } from "react-icons/fa";
import { formatMoney } from "../../../../utils/money";

interface IDRECardProps {
  revenues: number;
  total: number;
  expenses: number;
}

export function DRECard({ expenses, revenues, total }: IDRECardProps) {
  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} spacingX={2}>
      <Box
        bg="#CC8956"
        color="#f0f0f0"
        icon={FaChartLine}
        title="Receitas"
        value={formatMoney(revenues)}
        border="#CC8956"
      />
      <Box
        bg="#C44A54"
        color="#f0f0f0"
        icon={FaClipboardList}
        title="Despesas"
        value={formatMoney(expenses)}
        border="#C44A54"
      />
      <Box
        bg="#43A16A"
        color="#f0f0f0"
        icon={FaDollarSign}
        title="Resultado Final"
        value={formatMoney(total)}
        border="#43A16A"
      />
    </SimpleGrid>
  );
}
