import { Badge } from "@chakra-ui/react";

export function SoonBagdeNavLink() {
  return (
    <Badge
      bg="orange.300"
      ml="auto"
      mr="1"
      fontSize="10px"
      paddingY="0.5"
      height="min-content"
      color="#ffffff"
    >
      Em Breve
    </Badge>
  );
}
