import { useCallback, useEffect, useState } from "react";

import { Card } from "../../../components/Card";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import { MUITable } from "../../../components/Table/MUITable";
import { SubmitButtonAndUpdateStatus } from "../../../components/atoms/Button/SubmitButtonAndUpdateStatus";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { Input } from "../../../components/Form/Input";
import { MultiSelectControl } from "../../../components/templates/Form/MultiSelectControl";
import { useAuth } from "../../../hooks/auth";

const schema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório"),
  account_plan_subcategory_id: yup.object().required("Despesa é obrigatória"),
});

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface Favored {
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}

export function CreateFavored() {
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [favored, setFavored] = useState<Favored[]>([]);
  const [accountPlanSubcategories, setAccountPlanSubcategories] = useState<
    AccountPlanSubcategory[]
  >([]);
  const toast = useToast();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  watch();

  useEffect(() => {
    api
      .get("/accountPlanSubcategories/type?type=Despesa")
      .then((response) => setAccountPlanSubcategories(response.data));
  }, []);

  const handleCreateFavored: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        await api.post("/favored", {
          name: formValue.name,
          account_plan_subcategory_id:
            formValue.account_plan_subcategory_id.value,
          company_id: user.company_id,
        });

        if (!reload) {
          navigate("/favorecido");
        } else {
          reset();
          setValue("account_plan_subcategory_id", "");
          setFavored([
            ...favored,
            {
              name: formValue.name,
              account_plan_subcategory_id:
                formValue.account_plan_subcategory_id,
              account_plan_subcategory: {
                name:
                  accountPlanSubcategories.find(
                    (accountPlanSubcategory) =>
                      accountPlanSubcategory.id ===
                      formValue.account_plan_subcategory_id.value
                  )?.name ?? "",
              },
            },
          ]);
        }
        toast({
          title: "Favorecido cadastrado com sucesso!",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Não foi possível cadastrar o favorecido",
          status: "error",
        });
      }
    },
    [
      user.company_id,
      reload,
      toast,
      navigate,
      reset,
      setValue,
      favored,
      accountPlanSubcategories,
    ]
  );

  const { errors } = formState;
  return (
    <>
      <InternalHeader
        has_filter={false}
        has_back_button={true}
        title="Cadastrar Favorecido"
      />
      <Card
        border="#216ca5"
        body={
          <Flex
            as="form"
            flexDir="column"
            w="100%"
            onSubmit={handleSubmit(handleCreateFavored)}
            id="add-form"
          >
            <Stack spacing="4">
              <Input
                type="text"
                label="Nome"
                isRequired={true}
                error={errors.name}
                {...register("name")}
                validaded={getValues("name") && !errors.name}
              />
              <MultiSelectControl
                options={accountPlanSubcategories.map((acc) => ({
                  label: acc.name,
                  value: acc.id,
                }))}
                {...register("account_plan_subcategory_id")}
                onChange={(selected) => {
                  setValue("account_plan_subcategory_id", selected, {
                    shouldValidate: true,
                  });
                }}
                value={getValues("account_plan_subcategory_id")}
                validaded={
                  getValues("account_plan_subcategory_id") &&
                  getValues("account_plan_subcategory_id").value &&
                  !errors.account_plan_subcategory_id
                }
                isMulti={false}
                label="Despesa"
                error={errors.account_plan_subcategory_id}
                isRequired={true}
              />
            </Stack>
          </Flex>
        }
        footer={
          <Flex align="baseline">
            <Box mr={1}>
              <SubmitButtonAndUpdateStatus
                formState={formState}
                text="Cadastrar"
                bg="#ff9000"
                isLoading={formState.isSubmitting && reload}
                onClick={() => {
                  setReload(true);
                }}
              />
            </Box>
            <Box onClick={() => setReload(false)}>
              <SubmitButtonAndUpdateStatus
                formState={formState}
                text="Cadastrar e Finalizar"
                isLoading={formState.isSubmitting && !reload}
                onClick={() => {
                  setReload(false);
                }}
              />
            </Box>
          </Flex>
        }
      />
      {favored.length > 0 && (
        <Box mt={2}>
          <MUITable
            title="Favorecido - Cadastrados"
            data={favored.map((f) => [f.name, f.account_plan_subcategory.name])}
            columns={[
              {
                name: "Favorecido",
              },
              {
                name: "Despesa",
              },
            ]}
          />
        </Box>
      )}
    </>
  );
}
