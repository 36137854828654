import { Button } from "@chakra-ui/react";

interface SolidButtonProps {
  color?: string;
  bg?: string;
  onClick?: () => void;
  text: string;
}

export function SolidButton({
  bg = "#216ca5",
  color = "#ffffff",
  onClick,
  text,
}: SolidButtonProps) {
  return (
    <Button
      bg={bg}
      color={color}
      _hover={{
        opacity: 0.8,
        background: bg,
      }}
      _active={{
        opacity: 0.6,
        background: bg,
      }}
      onClick={onClick}
      mx="2"
    >
      {text}
    </Button>
  );
}
