import axios from "axios";

const user = localStorage.getItem("@guima:user");
var parsedUser = "";
if (user) {
  parsedUser = JSON.parse(user).company_id;
}
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    company_id: parsedUser,
  },
});

api.interceptors.response.use(
  function successRequest(response) {
    return response;
  },
  async function errorRequest(error) {
    try {
      const originalRequest = error.config;

      const token = localStorage.getItem("@guima:token");
      if (
        error.response.data.message === "Invalid JWT token" &&
        error.response.status === 401
      ) {
        window.dispatchEvent(new Event("logout"));

        return Promise.reject(error);
      }
      if (error.response.status === 401 && token) {
        const refresh_token = localStorage.getItem("@guima:refresh_token");
        if (refresh_token) {
          try {
            const response = await axios
              .create({
                baseURL: process.env.REACT_APP_API_URL,
              })
              .post(`auth/refresh-token`, {
                refresh_token,
              });

            api.defaults.headers.authorization = `Bearer ${response.data.token}`;
            originalRequest.headers.authorization = `Bearer ${response.data.token}`;
            localStorage.setItem("@guima:token", response.data.token);

            const res = await api(originalRequest);

            return res;
          } catch (err) {
            window.dispatchEvent(new Event("logout"));

            return Promise.reject(error);
          }
        } else {
          window.dispatchEvent(new Event("logout"));

          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    } catch {
      return Promise.reject(error);
    }
  }
);

export { api };
