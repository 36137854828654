import { Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { Card } from "../../../components/Card";
import { InternalHeader } from "../../../components/templates/InternalHeader";
import { useAuth } from "../../../hooks/auth";
import Falae from "../../../assets/falae_logo.png";
import { ModalUpdateFalaId } from "../../../components/templates/Modal/ModalUpdateFalaId";

export function ManagerClientExperience() {
  const { user } = useAuth();

  return (
    <>
      <InternalHeader
        title="Experiência do Cliente"
        has_filter={false}
        has_back_button
      />

      <>
        <SimpleGrid columns={{ base: 1, lg: 3 }}>
          <Card
            border="#2b88a1"
            body={
              <Flex
                width={"100%"}
                alignItems="center"
                justifyContent={"center"}
                flexDirection="column"
              >
                <Image src={Falae} alt="Falaê" mb="4" width="160px" />
                <Text my="4" textAlign={"center"}>
                  Gerencie a Integração com o Falaê para visualizar informações
                  da experiência do cliente
                </Text>
                {user.company.falae_id && (
                  <>
                    <Text fontWeight={"bold"}>Ativo</Text>
                  </>
                )}
                <ModalUpdateFalaId />
              </Flex>
            }
          />
        </SimpleGrid>
      </>
    </>
  );
}
