import { useCallback, useEffect, useState } from "react";
import { SpinnigButton } from "../../molecules/TableListButton/SpinnigButton";
import { TurnOffButton } from "../../molecules/TableListButton/TurnOffButton";
import { TurnOnButton } from "../../molecules/TableListButton/TurnOnButton";

interface PowerButtonListProps {
  link: string;
  onClick: () => Promise<void>;
  validCorrectButton: boolean;
  disabledTurnOn?: boolean;
}

export function PowerButtonList({
  link,
  onClick,
  validCorrectButton,
  disabledTurnOn = false,
}: PowerButtonListProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const onClickWithSpinner = useCallback(() => {
    setLoading(true);
    onClick();
  }, [onClick]);

  useEffect(() => {
    if (validCorrectButton) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [validCorrectButton]);

  if (loading) {
    return <SpinnigButton />;
  }

  return validCorrectButton ? (
    <TurnOffButton link={link} onClick={onClickWithSpinner} />
  ) : disabledTurnOn ? (
    <></>
  ) : (
    <TurnOnButton link={link} onClick={onClickWithSpinner} />
  );
}
