import { MultiSelect } from "../../../atoms/Select/MultiSelect";
import { FormControl } from "../../../Form/FormControl";

interface MultiSelectControlProps {
  name: string;
  error: any;
  value: any;
  options: {
    label: string;
    value: any;
  }[];
  isMulti: boolean;
  label: string;
  isRequired: boolean;
  onChange: (selected: any) => void;
  validaded: boolean;
  zIndex?: number;
  onFocus?: (select: any) => void;
  description?: string;
}

export function MultiSelectControl({
  options,
  error,
  value,
  isMulti,
  label,
  onChange,
  validaded,
  name,
  isRequired,
  zIndex,
  onFocus,
  description,
}: MultiSelectControlProps) {
  return (
    <FormControl
      name="revenue"
      label={label}
      isRequired={isRequired}
      description={description}
    >
      <MultiSelect
        options={options}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        validaded={validaded}
        isMulti={isMulti}
        error={error}
        isRequired={isRequired}
        zIndex={zIndex}
      />
    </FormControl>
  );
}
