import { Select, SimpleGrid, Input } from "@chakra-ui/react";
import { FormControl } from "../../../Form/FormControl";
import { URLSearchParamsInit } from "react-router-dom";

interface AccountPlan {
  id: string;
  name: string;
  description: string;
}

interface ISelected {
  period: string;
  last_month: string;
  type: "cashier" | "competence"
  account_plan_id: string;
}

interface IDREFilterProps {
  selected: ISelected;
  setSearchParams: (search: URLSearchParamsInit) => void;
  setSelected: (selected: ISelected) => void;
  accountPlansCompany: AccountPlan[];
}

export function DREFilter({
  selected,
  setSearchParams,
  setSelected,
  accountPlansCompany,
}: IDREFilterProps) {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} w="100%" spacingX={2}>
      <FormControl name="filter" label="Visualização">
        <Select
          value={selected.type}
          onChange={(e) => {
            setSearchParams({
              ...selected,
              type: e.target.value,
            });

            setSelected({
              ...selected,
              type: e.target.value as "cashier" | "competence",
            });
          }}
        >
          <option value={"cashier"}>Caixa</option>
          <option value={"competence"}>Competência</option>
        </Select>
      </FormControl>
      <FormControl name="account-plan" label="Plano de Conta">
        <Select
          value={selected.account_plan_id}
          onChange={(e) => {
            setSearchParams({
              ...selected,
              account_plan_id: e.target.value,
            });

            setSelected({
              ...selected,
              account_plan_id: e.target.value,
            });
          }}
        >
          {accountPlansCompany.map((acc) => (
            <option value={acc.id}>{acc.name}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl name="account-plan" label="Período">
        <Select
          value={selected.period}
          onChange={(e) => {
            setSearchParams({
              ...selected,
              period: e.target.value,
            });

            setSelected({
              ...selected,
              period: e.target.value,
            });
          }}
        >
          <option>Mensal</option>
          <option>Trimestral</option>
          <option>Semestral</option>
          <option>Anual</option>
        </Select>
      </FormControl>
      <FormControl name="month" label="Mês">
        <Input
          type="month"
          defaultValue={selected.last_month}
          onChange={(e) => {
            if (e.target.value.length === 7) {
              setSearchParams({
                ...selected,
                last_month: e.target.value,
              });

              setSelected({
                ...selected,
                last_month: e.target.value,
              });
            }
          }}
        />
      </FormControl>
    </SimpleGrid>
  );
}
