import { FaEdit } from "react-icons/fa";
import { TableListButton } from "../../atoms/Button/TableListButton";

interface EditButtonProps {
  link: string;
  label?: string;
}

export function EditButton({ link, label }: EditButtonProps) {
  return (
    <TableListButton
      color="white"
      link={link}
      bg="#216ca5"
      icon={FaEdit}
      label="Editar"
    />
  );
}
