import { FaPowerOff } from "react-icons/fa";
import { TableListButton } from "../../../atoms/Button/TableListButton";

interface TurnOnButtonProps {
  link: string;
  onClick: () => void;
}

export function TurnOnButton({ link, onClick }: TurnOnButtonProps) {
  return (
    <TableListButton
      color="white"
      link={link}
      bg="#38a169"
      icon={FaPowerOff}
      onClick={onClick}
    />
  );
}
