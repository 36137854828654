import { Badge } from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";

export function BagdeLockNavLink() {
  return (
    <Badge
      bg="transparent"
      ml="auto"
      mr="1"
      fontSize="15px"
      paddingY="0.5"
      height="min-content"
      color="#ffffff"
    >
      <FaLock color="#fb9000" />
    </Badge>
  );
}
