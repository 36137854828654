import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Th,
  Tr,
  Td,
  Table,
  TableContainer,
  Text,
  Thead,
  useDisclosure,
  Tbody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { api } from "../../../../services/api";
import { formatDateBrIndex } from "../../../../utils/formatDate";
import { formatFieldCompare } from "../../../../utils/formatFieldCompare";

interface AccountPlanSubcategory {
  id: string;
  name: string;
  account_plan_category_id: string;
}

interface Favored {
  id: string;
  name: string;
  account_plan_subcategory_id: string;
  account_plan_subcategory: {
    name: string;
  };
}

interface Log {
  id: string;
  entity_id: string;
  created_at: Date;
  user_id: string;
  user: {
    name: string;
  };
  entity: string;
  current: string;
  previous: string;
  type: string;
  account_plan_subcategory_id: string;
}

interface FieldCompare {
  current: any;
  previous: any;
  name:
    | "id"
    | "observation"
    | "date_of_competence"
    | "date_of_receipt_of_money"
    | "form_of_receipt"
    | "has_prepayment"
    | "company_id"
    | "account_plan_subcategory_id"
    | "value"
    | "created_at"
    | "updated_at"
    | "due_date"
    | "effective_write_off_date"
    | "form_of_payment"
    | "file"
    | "favored_id";
}

interface CompareCurrentPreviousProps {
  entity_id: string;
  accountPlanSubcategories: AccountPlanSubcategory[];
  favored: Favored[];
}

const dictionary = {
  id: "ID",
  observation: "Observação",
  date_of_competence: "Data de Competência",
  date_of_receipt_of_money: "Data de Recebimento do Dinheiro",
  form_of_receipt: "Forma de Recebimento",
  has_prepayment: "Possui Pré-pagamento",
  company_id: "ID da Empresa",
  account_plan_subcategory_id: "Conta",
  value: "Valor",
  created_at: "Criado em",
  updated_at: "Atualizado em",
  due_date: "Data de Vencimento",
  effective_write_off_date: "Data Efetiva de Baixa",
  form_of_payment: "Forma de Pagamento",
  file: "Arquivo",
  favored_id: "Favorecido",
  has_cash_closing: "Fechamento de Caixa",
};

interface GenereteCompare {
  current: string;
  previous: string;
  log: Log;
}

const hiddenFields = [
  "id",
  "created_at",
  "updated_at",
  "has_prepayment",
  "company_id",
];

export function CompareCurrentPrevius({
  entity_id,
  accountPlanSubcategories,
  favored,
}: CompareCurrentPreviousProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [logs, setLogs] = useState<Log[]>([]);

  useEffect(() => {
    if (isOpen) {
      api
        .get(`/logs/entity/${entity_id}`)
        .then((response) => setLogs(response.data));
    }
  }, [isOpen, entity_id]);

  const generateCompare = useCallback(
    ({ current, previous, log }: GenereteCompare) => {
      const currentObject = current ? JSON.parse(current) : {};
      const previousObject = previous ? JSON.parse(previous) : {};
      const compareObject: FieldCompare[] = [];

      for (const [key, value] of Object.entries(previousObject)) {
        const currentValue = currentObject[key];

        let { current, previous } = formatFieldCompare({
          field: key,
          current: currentValue,
          previous: value,
        });

        if (key === "account_plan_subcategory_id") {
          if (!previous) {
            previous = log.account_plan_subcategory_id;
          }

          if (!current && log.type.toLowerCase().includes("Apagado")) {
            current = log.account_plan_subcategory_id;
          }

          const accountPlanSubcategoryCurrent = accountPlanSubcategories.find(
            (subcategory) => subcategory.id === current
          );
          current = accountPlanSubcategoryCurrent?.name;
          const accountPlanSubcategoryPrevious = accountPlanSubcategories.find(
            (subcategory) => subcategory.id === previous
          );
          previous = accountPlanSubcategoryPrevious?.name;
        }

        if(key === "has_cash_closing"){
          if(log.type.toLowerCase().includes("apagado") && current === "Não"){
            current = "";
          }
        }

        if (key === "favored_id") {
          const favoredCurrent = favored.find((f) => f.id === current);
          current = favoredCurrent?.name;
          const favoredPrevious = favored.find((f) => f.id === previous);
          previous = favoredPrevious?.name;
        }

        if (key === "favored") {
          continue;
        }

        if (!key) {
          continue;
        }

        compareObject.push({
          current,
          previous,
          name: key as
            | "id"
            | "observation"
            | "date_of_competence"
            | "date_of_receipt_of_money"
            | "form_of_receipt"
            | "has_prepayment"
            | "company_id"
            | "account_plan_subcategory_id"
            | "value"
            | "created_at"
            | "updated_at"
            | "due_date"
            | "effective_write_off_date"
            | "form_of_payment"
            | "file"
            | "favored_id",
        });
      }

      return compareObject;
    },
    [accountPlanSubcategories, favored]
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Comparação</Text>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <Tabs>
              <TabList>
                {logs.map((log) => (
                  <Tab>{formatDateBrIndex(log.created_at)}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {logs.map((log) => (
                  <TabPanel>
                    <Flex flexDirection={"row"} justifyContent="space-between">
                      <Text fontWeight={"bold"} ml="2">
                        {log.user.name}
                      </Text>
                      <Text>{log.type}</Text>
                    </Flex>
                    <TableContainer>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th>Campo</Th>
                            <Th>Valor atual</Th>
                            <Th>Valor anterior</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {generateCompare({
                            current: log.current,
                            previous: log.previous,
                            log,
                          })
                            .filter(
                              (field) => !hiddenFields.includes(field.name)
                            )
                            .map((field) => (
                              <Tr>
                                <Td>{dictionary[field.name]}</Td>

                                <Td
                                  bg={
                                    field.current !== field.previous
                                      ? "#71F07B"
                                      : ""
                                  }
                                >
                                  {field.current}
                                </Td>
                                <Td
                                  bg={
                                    field.current !== field.previous
                                      ? "#F0615A"
                                      : ""
                                  }
                                >
                                  {field.previous}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button colorScheme={"cyan"} onClick={onOpen}>
        <Icon as={FaSearch} />
      </Button>
    </>
  );
}
